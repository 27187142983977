<section class="flex items-center">
    <article class="pt-2 pb-2 pl-4 pr-4 rounded border w-full">
      <app-input-search [placeholder]="placeholder" [width]="'full'" (submitEventEmitter)="search($event)"></app-input-search>
    </article>
    @if (searching) {
      <article class="flex items-center pb-2">
        <img [src]="images.loading" alt="loading" class="h-10 w-10 ml-4" />
        <label class="font-semibold text-base ml-4">{{ literalService.get('services.searchingServices', true) }}...</label>
      </article>
    }
  </section>
  
<div [id]="id" tabindex="-1" class="modal absolute top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
    <div class="relative w-72 max-h-full rounded-lg bg-white">
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div class="flex justify-center items-center">
                <h3 class="w-full text-base text-center font-bold mb-5 text-gray-500 dark:text-gray-400 mt-5">{{ literalService.get('trips.actions.addPax.name', true) }}</h3>
                <button (click)="modal.hide()" type="button" class="absolute right-2 mr-2 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="addPax">
                    <img [src]="images.close" class="h-6 w-6" />
                </button>
            </div>
            <div class="pl-6 pr-6 pt-2 pb-2">
                <input class="w-full" type="number" [(ngModel)]="nPax" (change)="change(nPax)" [ngModelOptions]="{standalone: true}"/>
            </div>
            <div class="flex justify-center items-center pb-4">
                <button data-modal-hide="addPax" type="button" class="secondary mt-2 gap-4 p-4 border-2 font-semibold h-14 w-[7.5rem] uppercase cursor-pointer bg-white" (click)="action.emit(nPax)">{{ literalService.get('update', true) }}</button>
            </div>
        </div>
    </div>
</div>
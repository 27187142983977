<section id="sortContainer" class="container h-48">
    <article class="pl-4 pt-4">
        <h1 class="title">{{ literalService.get('orderBy', true) }}</h1>
    </article>
    <section id="orders" class="pl-4 pt-4">
        @for (sortType of sortTypes; track $index) {
            <article [id]="sortType.toLowerCase()" class="lg:flex box pt-2 pb-2 pl-4 pr-4 rounded w-48 h-12 mr-4 mb-2 items-center cursor-pointer bg-white" [ngClass]="{ 'active': userSortTypeSelected === sortType }" (click)="changeSort(sortType)">
                <img [src]="getImage(sortType)" class="w-6 h-6" />
                <label class="ml-2 cursor-pointer">{{ literalService.get(sortType.toLowerCase(), true) }}</label>
            </article>
        }
    </section>
</section>

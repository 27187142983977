import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Modal } from 'flowbite';
import { IBase, IClient, ITown } from 'src/app/models';
import { LiteralService, UsersService, UtilsService } from 'src/app/services';
import { images } from 'src/images';
import { TooltipPosition } from '../../tooltip/tooltip-position';
import { AnimationOptions } from 'ngx-lottie';

@Component({
    selector: 'app-modal-gtfs',
    templateUrl: './modal-gtfs.component.html',
    styleUrl: './modal-gtfs.component.scss',
    standalone: false
})
export class ModalGtfsComponent implements OnInit {

  @Input() id: string;
  @Input() modal: Modal;
  @Input() edit: boolean = false;
  @Input() loading: boolean = false;
  @Output() action = new EventEmitter<any>();

  @ViewChild('form') form: ElementRef;
  public formGroup: FormGroup = new FormGroup({});

  public agency = new FormControl('');
  public routes = new FormControl('');
  public stops = new FormControl('');
  public stopsTimes = new FormControl('');
  public trips = new FormControl('');
  public calendar = new FormControl('');
  public calendarDates = new FormControl('');

  public towns: any[] = [];
  public clients: any[] = [];

  public TooltipPosition = TooltipPosition;
  public document = document;
  public images = images;
  public uploaded = [
    { type: 'trips', value: false, file: { fileName: '', value: {} as File}, formControl: this.trips, required: true },
    { type: 'routes', value: false, file: { fileName: '', value: {} as File}, formControl: this.routes, required: true },
    { type: 'stops', value: false, file: { fileName: '', value: {} as File}, formControl: this.stops, required: true },
    { type: 'stopTimes', value: false, file: { fileName: '', value: {} as File}, formControl: this.stopsTimes, required: true },
    { type: 'calendarDates', extraInfo: 'services.modal.requiredOnlyOne', value: false, file: { fileName: '', value: {} as File}, formControl: this.calendar, required: true, disabled: false },
    { type: 'calendar', value: false, file: { fileName: '', value: {} as File}, formControl: this.calendar, required: true, disabled: false },
    { type: 'agency', value: false, file: { fileName: '', value: {} as File}, formControl: this.agency, required: false },
  ];

  public importing: AnimationOptions = {
    path: '/assets/animations/importing.json'
  };

  constructor(public literalService: LiteralService, private usersService: UsersService, public utilsService: UtilsService) {}

  async ngOnInit() {
    await this.usersService.getPossibleClientTowns().then((res) => {
      res.towns.sort((firstItem: IBase, secondItem: IBase) => firstItem.id! - secondItem.id!);
      res.clients.forEach((client: IClient) => {
        this.clients.push({ id: client.id, value: client.id, label: client.name });
      });
    });
    this.formGroup = new FormGroup({});
    this.setControls();
  }

  async setControls(type?: string) {
    this.uploaded.forEach((u: any) => {
      if (type && type === 'calendar' && u.type === 'calendar') {
        this.formGroup.setControl('calendar', new FormControl('calendar', [Validators.required]));
        this.formGroup.setControl('calendarDates', new FormControl('calendarDates', []));
      } else if (type && type === 'calendarDates' && u.type === 'calendarDates') {
        this.formGroup.setControl('calendar', new FormControl('calendar', []));
        this.formGroup.setControl('calendarDates', new FormControl('calendarDates', [Validators.required]));
      } else if (type !== 'calendar' && type !== 'calendarDates'){
        const validators = u.required ? [Validators.required] : []; 
        const control = new FormControl('', validators); 
        u.formControl = control;
        this.formGroup.addControl(u.type, control);
      }
    });
    this.formGroup.addControl('townId', new FormControl('', [Validators.required]));
    this.formGroup.addControl('clientId', new FormControl('', [Validators.required]));
    if (this.clients.length === 1) {
      this.towns = [];
      this.formGroup.get('clientId')?.setValue(this.clients[0].id);
      await this.usersService.getTownsByClient([this.clients[0].id!]).then((res) => {
        const towns = res as ITown[];
        towns.forEach((town: ITown) => {
          this.towns.push({ id: town.id, value: town.id, label: town.name });
        });
      });
    }
  }

  onFileSelected(event: any, type: string) {
    const file: File = event.target.files[0];
    if (!file) return;
  
    const fileName = file.name;
    const uploadedItem = this.uploaded.find((u: any) => u.type === type);
    
    if (!uploadedItem) return;
  
    uploadedItem.file = { fileName, value: file };
    uploadedItem.value = true;
  
    this.resetRelatedFile(type, 'calendar', 'calendarDates');
    this.resetRelatedFile(type, 'calendarDates', 'calendar');
  
    const control = this.formGroup.get(type);
    control?.setValue(fileName);
    control?.markAsTouched();
    control?.updateValueAndValidity();
  
    this.setControls(type);
  }
  
  private resetRelatedFile(selectedType: string, targetType: string, relatedType: string) {
    if (this.uploaded.some((u: any) => u.type === targetType && u.type === selectedType && u.value)) {
      const relatedItem = this.uploaded.find((u: any) => u.type === relatedType);
      if (!relatedItem) return;
  
      relatedItem.value = true;
      const relatedIndex = this.uploaded.findIndex((u: any) => u.type === relatedType);
      this.uploaded[relatedIndex].file = { fileName: '', value: {} as File };
  
      const element = document.querySelector(`#${relatedType}`) as HTMLInputElement;
      if (element) element.value = '';
    }
  }

  async setClientId(event: any) {
    this.towns = [];
    this.formGroup.get('clientId')?.setValue(event.value);
    await this.usersService.getTownsByClient([event.value]).then((res) => {
      const towns = res as ITown[];
      towns.forEach((town: ITown) => {
        this.towns.push({ id: town.id, value: town.id, label: town.name });
      });
    });
  }
  
  setTownId(event: any) {
    this.formGroup.get('townId')?.setValue(event.value);
  }

  onSubmit() {
    const files = this.uploaded.filter((u: any) => u.value);
    this.action.emit({files: files, clientId: this.formGroup.get('clientId')?.value, townId: this.formGroup.get('townId')?.value});
  }

}

export class APP {
  // STORAGE
  public static LOCAL_STORAGE_TOKEN_KEY_NAME = 'token';
  public static LOCAL_STORAGE_TOKEN_DECODED = 'decoded_token';
  public static LOCAL_STORAGE_TOKEN_TYPE_KEY_NAME = 'token_type';
  public static LOCAL_STORAGE_ROLE = 'role';

  // AUTH
  public static HEADER_RESPONSE_TOKEN_AUTH_KEY = 'Authorization';

  public static appsPrivacyAndTermsOptions = [
    { name: 'Nemi', privacy: `http://www.nemi.mobi/app/legal/`, terms: `http://www.nemi.mobi/app/terms/`, owner: 'Nemi Mobility Solutions SL', someLanguages: true },
    { name: 'AMB', privacy: 'http://www.amb.cat/web/mobilitat/autobus/busademanda/cervello', terms: 'http://www.amb.cat/web/mobilitat/autobus/busademanda/cervello', owner: null, someLanguages: false },
    { name: 'SierraCar', privacy: 'https://sierracar.es/politica-privacidad/', terms: 'https://sierracar.es/wp-content/uploads/2021/03/TC_SierraCar.pdf', owner: 'la Mancomunidad de servicios Valle Norte del Lozoya', someLanguages: false },
    { name: 'Cotsalsina', privacy: `http://www.nemi.mobi/cotsalsina/legal/`, terms: `http://www.nemi.mobi/cotsalsina/terms/`, owner: 'Cots Alsina SL', someLanguages: false },
    { name: 'Pnds', privacy: `http://www.nemi.mobi/pnds/legal/`, terms: `http://www.nemi.mobi/pnds/terms/`, owner: 'Autocares del Panades SA', someLanguages: false },
    { name: 'Tuvisa', privacy: `http://www.nemi.mobi/tuvisa/legal/`, terms: `http://www.nemi.mobi/tuvisa/terms/`, owner: 'el Ayuntamiento de Vitoria-Gasteiz', someLanguages: false },
    { name: 'Dipucar', privacy: `http://www.nemi.mobi/dipucar/legal/`, terms: `http://www.nemi.mobi/dipucar/terms/`, owner: 'la Diputación Provincial de Palencia', someLanguages: false }
  ]
}

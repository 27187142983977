<div>
    <div class="flex items-center px-4 pt-4 pb-2">
        @for (value of elements; track $index) {
            <input [id]="value.id" type="checkbox" [checked]="checked(value)" (change)="changeEventEmitter.emit(value)">
            <label [for]="value.id" class="ml-2 text-sm font-medium mr-4 neutrals-grey-06-text dark:text-gray-300">{{ translateValue ? literalService.get(value.name || value.code || value.id, true) : value.name || value.code || value.id }}</label>
        }
    </div>
    @if (extraInfo) {
        <label class="font-normal text-xs neutrals-grey-04-text -mt-4 ml-4">{{ literalService.get(extraInfo, true) }}</label>
    }
</div>
import { Injectable } from '@angular/core';
import { BaseHttpService } from '../http/base.http.service';

@Injectable({
  providedIn: 'root',
})
export class VehiclesService extends BaseHttpService {
  // constructor() {
  //   super()
  // }
  // public getVehicle = async (id: number): Promise<IVehicles> => {
  //   return this.get(`vehicles/${id}`)
  // }
  // public getVehicles = async (): Promise<IVehicles[]> => {
  //   return this.get(`vehicles`)
  // }
}

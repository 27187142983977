<form [formGroup]="formGroup" novalidate (ngSubmit)="onSubmit()">
    <div id="serviceDetailsContainer" class="container absolute top-20 right-0 z-10 hiddenDetails"
        [ngClass]="{ 
            'openDetails': (serviceInfoDetail || newService), 
            'edit': (serviceInfoDetail || newService) && edit, 
            'h-[calc(100vh-80px)]': !edit && !newService, 
            'xl:h-[calc(100vh)] 2xl:h-[calc(100vh-80px)]': edit || newService
        }">
        <section class="topbar h-14 mt-4 flex items-center">
            @if (serviceInfoDetail && serviceInfoDetail.id) {
                <h1 class="ml-8 text-xl font-[600] max-w-[25%] truncate">{{ literalService.get('services.serviceTitle', true) }}</h1>
                <section id="actions" class="flex ml-auto mr-8">
                    <div class="flex items-center justify-center content-image mr-2">
                        <img [src]="images.services.edit" (click)="this.editEvent.emit()" [tooltip]="literalService.get('services.tooltips.edit', true)" alt="copy" class="h-6 w-6 mr-4 ml-4 cursor-pointer" >
                    </div>
                    <div class="flex items-center justify-center cursor-pointer mr-2" (click)="close()">
                        <img [src]="images.close" alt="closeDetails" class="h-6 w-6"/>
                    </div>
                </section>
            } @else {
                <div class="ml-8 mr-4 justify-end">
                    <div class="flex main rounded-[50%] h-10 w-10 justify-center items-center">
                        <div class="quarter half1" [ngClass]="{ 'block': steps.includes(1), 'hidden': !steps.includes(1) }"></div>
                        <div class="quarter half2" [ngClass]="{ 'block': steps.includes(2), 'hidden': !steps.includes(2) }"></div>
                        <label class="flex font-bold text-[16px] primary-purple-text cutout justify-center items-center">
                            {{ currentStep }}/{{ numberSteps }}
                        </label>
                    </div>
                </div>
                <h1 class="text-xl font-[600] max-w-[25%] truncate">{{ literalService.get('services.newService', true) }}</h1>
                <section id="actions" class="flex ml-auto mr-8">
                    <div class="flex items-center justify-center cursor-pointer mr-2" (click)="close()">
                        <img [src]="images.close" alt="closeDetails" class="h-6 w-6"/>
                    </div>
                </section>
            }
        </section>  
        <section class="h-14 mt-8 ml-8 items-center">
            <div class="grid grid-flow-col grid-rows-7" [ngClass]="{'gap-3': !edit && !newService}">
                @if (!edit && !newService) {
                    <!-- <div class="grid grid-flow-col grid-rows-7 gap-3"> -->
                        <article *ngFor="let element of serviceForm" [ngClass]="{'hidden': element.hidden}">
                            <app-detail [data]="element" [value]="element.value" /> 
                        </article>
                    <!-- </div> -->
                } @else if (edit || newService) {
                    <!-- <div class="grid grid-flow-col grid-rows-7"> -->
                        @for (element of serviceForm; track $index) {
                            <div class="mr-10 mb-[10px]" [ngClass]="{'hidden': element.edit.hidden, 'mb-7': currentStep === 1 && newService}">
                                <app-input  [data]="element"
                                            [edit]="true"
                                            [independent]="false"
                                            [translateValue]="element.edit?.translateValue"
                                            [formGroup]="formGroup"
                                            (setEventEmitter)="changeData($event, element)"
                                            (changeEventEmitter)="changeData($event, element)" />
                            </div>
                        }
                    <!-- </div> -->
                }
            </div>
            <!-- <div class="grid grid-rows-7 grid-flow-col" [ngClass]="{'containerDetails': route(), 'gap-0': edit(), 'gap-10': !edit()}">
                    <ng-container>
                    @if (!edit() && !newRoute()) {
                        <article *ngFor="let element of routeForm" class="mr-4 -mb-[14px]" [ngClass]="{'hidden': element.hidden}">
                            @if (expanded ? true : !element.expanded) {
                                <app-detail [data]="element" [value]="element.value" />
                            }
                        </article>
                    } @else if (edit() || newRoute()) {
                        <article *ngFor="let element of routeForm" class="mr-8" [ngClass]="{'hidden': element.hidden}">
                            @if (expanded && element.edit ? true : !element?.expanded) {
                                <app-input  [data]="element"
                                            [edit]="true"
                                            [independent]="false"
                                            [translateValue]="element.edit?.translateValue"
                                            (setEventEmitter)="changeData($event, element)"
                                            (changeEventEmitter)="changeData($event, element)" />

                            }
                        </article>
                    }
                    </ng-container> -->
            <div class="flex absolute bottom-8 right-11">
                @if (edit || (newService && currentStep === 2)) {
                    <div class="flex">
                        <input type="submit" class="primary text-white gap-4 border-2 font-semibold h-10 w-[7.5rem] uppercase mr-2.5 cursor-pointer" [disabled]="formGroup.invalid" [value]="literalService.get('save', true)" />
                        <input type="button" class="secondary gap-4 border-2 font-semibold h-10 w-[7.5rem] uppercase cursor-pointer" [value]="literalService.get('cancel', true)" (click)="close()" />
                    </div>
                }
            </div>
        </section>
    </div>
</form>
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { StorageService } from 'src/app/services';
import { LanguageService } from 'src/app/services/language/language.service';
import { LiteralService } from 'src/app/services/literal/literal.service';
import { flags } from 'src/images';

@Component({
    selector: 'app-user-profile-language',
    templateUrl: './user-profile-language.component.html',
    styleUrls: ['./user-profile-language.component.scss'],
    standalone: false
})
export class UserProfileLanguageComponent implements OnInit {
  @Input() image = '';
  @Input() text = '';
  @Input() value = '';
  @Input() language = '';
  @Output() getLanguage = new EventEmitter();
  @Output() setLanguage = new EventEmitter<string>();

  public flags = flags;

  constructor(
    public literalService: LiteralService,
    public storageService: StorageService,
    private languageService: LanguageService,
  ) {}

  async ngOnInit() {
    this.language = !this.language
      ? (await this.storageService.getLanguage()) || 'es'
      : this.language;
  }

  getImage() {
    return this.flags.find((img) => img.name === this.text)?.img;
  }

  public changeLanguage(language: string) {
    this.setLanguage.emit(language);
  }
}

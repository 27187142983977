import { images } from '../../images';
import { IBookingDetails } from '../models/bookings/bookingDetails';
import { ServiceTagComponent } from '../components/service-tag/service-tag.component';
import { BookingSeatsComponent } from '../components/booking/booking-seats/booking-seats.component';
import { IBookingCreate, IServiceInfo, ITown, IUser, IUserRegular } from '../models';
import { UsersService } from '../services';
import { InputType, UserSortType, UserStatus, UserType } from '../models/enums';

export async function getBookingForm(booking: IBookingDetails = {} as IBookingDetails): Promise<unknown[]> {
  const data = [
    {
      img: images.booking.user,
      title: 'bookings.data.user',
      value: `${booking.firstName} ${booking.lastName}`,
    },
    {
      img: images.booking.service,
      title: 'bookings.data.service',
      component: ServiceTagComponent,
      value: booking.service,
    },
    {
      img: images.origin,
      title: 'bookings.data.origin',
      setColor: false,
      value: `${booking.origin.time} - ${booking.origin.name}`,
    },
    {
      img: images.destination,
      title: 'bookings.data.destination',
      setColor: false,
      value: `${booking.destination.time} - ${booking.destination.name}`,
    },
    {
      img: images.booking.date,
      title: 'bookings.data.date',
      value: `${booking.date}`,
    },
    {
      img: images.booking.seats,
      title: 'bookings.data.seats',
      component: BookingSeatsComponent,
      value: booking,
    },
    {
      img: images.booking.searchCriteria,
      title: 'bookings.data.searchCriteria',
      value: `bookings.data.${booking.searchCriteria}`,
      translateValue: true,
    },
    {
      img: images.clock,
      title: 'bookings.data.requestedTime',
      value: booking.requestedTime ? `${booking.requestedTime}` : '-',
      extraText: 'bookings.data.requestedTimeDiff',
      extraValue: booking.requestedTimeDiff,
    },
    {
      img: images.clock,
      title: 'bookings.data.initialOriginTime',
      value: booking.initialOriginTime ? `${booking.initialOriginTime}` : '-',
      extraText: 'bookings.data.originCommunicatedTime',
      extraValue: booking.originCommunicatedTime,
    },
    {
      img: images.clock,
      title: 'bookings.data.initialDestinationTime',
      value: booking.initialDestinationTime
        ? `${booking.initialDestinationTime}`
        : '-',
      extraText: 'bookings.data.destinationCommunicatedTime',
      extraValue: booking.destinationCommunicatedTime,
    },
    {
      img: images.booking.smartPhone,
      title: 'bookings.data.channel',
      value: `${booking.channel}`,
    },
    {
      img: images.booking.notifications,
      title: 'bookings.data.notified',
      value: `bookings.data.notified${booking.isNotified || 'false'}`,
      translateValue: true,
    },
    {
      img: images.booking.vehicle,
      title: 'bookings.data.expeditionVehicle',
      value: booking.vehicle.name,
      expanded: true,
    },
    {
      img: images.booking.driver,
      title: 'bookings.data.expeditionDriver',
      value: booking.driver.name,
      expanded: true,
    },
    {
      img: images.booking.pickUpTime,
      title: 'bookings.data.originPassingTime',
      value: booking.originPassingTime,
      extraText: 'bookings.data.originConfirmedTime',
      extraValue: booking.originConfirmedTime,
      expanded: true,
    },
    {
      img: images.booking.dropOffTime,
      title: 'bookings.data.destinationPassingTime',
      value: booking.destinationPassingTime,
      extraText: 'bookings.data.destinationConfirmedTime',
      extraValue: booking.destinationConfirmedTime,
      expanded: true,
    },
    {
      img: images.booking.feedback,
      title: 'bookings.data.feedback',
      value: booking.feedback ? `bookings.data.feedback${booking.feedback?.positive || 'false'}` : '-',
      translateValue: true,
      extraInfo: booking.feedback,
      extraInfoText: `bookings.tooltips.feedback.${booking.feedback?.option.id}`,
      extraInfoImage: images.booking.feedback,
      expanded: true,
    },
    {
      img: images.distance,
      title: 'bookings.data.distance',
      value: booking.kms,
      translateValue: false,
      expanded: true,
    },
  ];
  return data;
}

let users: unknown[] = [];
let page = 0;

let usersSearched: unknown[] = [];
let pageSearched = 0;

let towns: unknown[] = [];
let stops: any[] = [];
let destinations: unknown[] = [];

export async function getUsers(usersService: UsersService, userSelected: IUserRegular) {
  // let userSelected = undefined;
  await usersService.getUsers(UserSortType.ALPHABETICALLY, page, 10, undefined, UserType.Regular).then((res) => {
    res.content.map((user: IUserRegular) => {
      if (user.status?.toUpperCase() === UserStatus.ACTIVE || user.status?.toUpperCase() === UserStatus.BLOCKED) {
        // if (user.id === userId) {
        //   userSelected = { id: user.id, value: user.id, label: `${user.firstName} ${user.lastName}` }
        // }
        const extraInfo = user && (user.dni && user.dni || user.phone && user.phone || user.email && user.email);
        users.push(
          { 
            id: user.id, 
            value: user, 
            label:
              user.firstName === null && user.lastName === null ? `${user.email}` : `${user.firstName} ${user.lastName} (${extraInfo})`
          }
        );
      }
    });
  });

  const exists = userSelected && users.find((user: any) => user.id === userSelected.id);
  if (!exists) {
    const extraInfo = userSelected && (userSelected.dni && userSelected.dni || userSelected.phone && userSelected.phone || userSelected.email && userSelected.email);
    userSelected && users.unshift(
      {
        id: userSelected.id,
        value: userSelected,
        label:
          userSelected.firstName === null && userSelected.lastName === null ? `${userSelected.email}` : `${userSelected.firstName} ${userSelected.lastName} (${extraInfo})` });
  }
}

export async function searchUser(usersService: UsersService, showMoreUsers: boolean, value: string) {
  await usersService.getUsers(UserSortType.ALPHABETICALLY, pageSearched, 10, value, UserType.Regular).then((res) => {
    usersSearched = [];
    res.content.map((user: IUserRegular) => {
      if (user.status?.toUpperCase() === UserStatus.ACTIVE || user.status?.toUpperCase() === UserStatus.BLOCKED) {
        const extraInfo = user && (user.dni && user.dni || user.phone && user.phone || user.email && user.email);
        usersSearched.push({
          id: user.id,
          value: user,
          label:
            user.firstName === null && user.lastName === null ? `${user.email}` : `${user.firstName} ${user.lastName} (${extraInfo})` });
      }
    });
  });
}

export class UserBookingData {
  showMoreUsers: boolean;
  search?: boolean;
  value?: string;

  constructor(data: Partial<UserBookingData>) {
    Object.assign(this, data);
  }
}

export async function getClientsTowns(usersService: UsersService, user: IUser) {
  return await usersService.getTownsByUser(user.id!).then((res: any) => {
    const towns: any[] = [];
    res.map((town: ITown) => {
      towns.push({ id: town.id, value: town.id, label: town.name });
    });
    return { towns: towns };
  });
}

export async function newBookingForm(booking: IBookingCreate = {} as IBookingCreate, usersService: UsersService, userData?: UserBookingData, serviceInfos?: IServiceInfo[], destinationsStops?: unknown[], changes?: any): Promise<unknown[]> {
  if (userData) {
    if (userData.search) {
      if (userData.showMoreUsers) {
        pageSearched += 1;
      } else {
        users = [];
        page = 0;
        usersSearched = [];
        pageSearched = 0;
      }
      await searchUser(usersService, userData.showMoreUsers, userData.value!);
    } else {
      page += 1;
      await getUsers(usersService, booking.user);
    }
  }
  
  if (changes && changes.includes('user')) {
    towns = [];
  } else if (changes && changes.includes('town')) {
    stops = [];
    destinations = [];
  } else if (changes && changes.includes('stop')) {
    destinations = [];
  }

  // NOTE: TOWNS
  if (!towns.length && booking.targetUserId) {
    await getClientsTowns(usersService, booking.user).then((res) => {
      towns = res.towns;
    });
  }
  
  // NOTE: ORIGINS
  if (!stops.length) {
    serviceInfos?.map((serviceInfo: IServiceInfo) => {
      serviceInfo.stops.map((stop: any) => {
        const exists = stops.find((s: any) => s.id === stop.id);
        if (!exists && stop.id < 0) {
          const available = serviceInfo.restrictedOrigins.some((origin: any) => origin === stop.id);
          if (!available) {
            stops.push({ id: stop.id, value: stop.id, label: stop.name });
          }
        }
      });
    });
    stops.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      } else if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
  }

  // NOTE: DESTINATIONS
  if (!destinations.length || changes && changes.includes('stop')) {
    destinationsStops && destinationsStops.map((destination: any) => {
      destinations.push({ id: destination.id, value: destination.id, label: destination.name });
    });
  } 

  booking.stops = stops;
  console.log(booking.destinationStops);
  console.log(booking.user, booking.user ? true : false);
  const data = [
    {
      img: images.booking.user,
      title: 'bookings.data.user',
      inputType: InputType.INFINITE_SCROLL,
      value: booking.user,
      color: false,
      step: 1,
      informativeToNoChange: booking.user ? true : false,
      edit: {
        inputType: InputType.INFINITE_SCROLL,
        // translateValue: false,
        // required: false,
        // disabled: booking.user && true,
        values: userData && userData.search ? usersSearched : users,
        valueToChange: 'targetUserId',
      },
    },
    {
      img: images.booking.area,
      title: 'bookings.data.town',
      inputType: InputType.SELECT_SEARCH,
      value: booking.townId,
      color: false,
      step: 2,
      edit: {
        inputType: InputType.SELECT_SEARCH,
        // translateValue: false,
        // required: false,
        // disabled: false,
        values: towns,
        valueToChange: 'town',
      },
    },
    {
      img: images.origin,
      title: 'bookings.data.originStop',
      inputType: InputType.SELECT_SEARCH,
      value: booking.originStopId,
      color: false,
      step: 2,
      edit: {
        inputType: InputType.SELECT_SEARCH,
        // translateValue: false,
        // required: false,
        // disabled: false,
        values: stops,
        valueToChange: 'originStopId',
      },
    },
    {
      img: images.destination,
      title: 'bookings.data.destinationStop',
      inputType: InputType.SELECT_SEARCH,
      value: booking.destinationStops && booking.destinationStops[0] && booking.destinationStops[0].exitStop.id,
      color: false,
      step: 2,
      edit: {
        inputType: InputType.SELECT_SEARCH,
        // translateValue: false,
        // required: false,
        // disabled: false,
        values: destinations,
        valueToChange: 'destinationsStops',
      },
    },
    {
      // img: images.destination,
      // title: 'bookings.data.seats',
      inputType: InputType.SEATS,
      value: booking.destinationStops,
      color: false,
      step: 2,
      edit: {
        inputType: InputType.SEATS,
        // translateValue: false,
        // required: false,
        // disabled: false,
        values: booking.destinationStops,
        valueToChange: 'destinationsStops',
      },
    },
    {
      img: images.booking.date,
      title: 'bookings.data.date',
      inputType: InputType.DATE,
      value: booking.date,
      color: false,
      step: 2,
      showOnTop: true,
      onlyFutureDays: true,
      multipleDates: false,
      hideCalendar: false,
      edit: {
        inputType: InputType.DATE,
        // translateValue: false,
        // required: false,
        // disabled: false,
        valueToChange: 'date',
      },
    },
    {
      img: images.clock,
      title: 'bookings.data.time',
      inputType: InputType.TIME,
      value: booking.time,
      color: false,
      step: 2,
      edit: {
        inputType: InputType.TIME,
        // translateValue: false,
        // required: false,
        // disabled: false,
        valueToChange: 'time',
      },
    },
  ];

  return data;
}


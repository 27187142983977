import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output } from '@angular/core';
import { images } from '../../../../images';
import { LiteralService } from '../../../services';

@Component({
    selector: 'app-seats-input',
    templateUrl: './seats-input.component.html',
    styleUrl: './seats-input.component.scss',
    standalone: false
})
export class SeatsInputComponent implements OnInit, OnChanges {

  @Input() data: any;
  @Input() value: any;
  @Input() paxBooking?: number; 
  @Input() prmBooking?: number; 
  @Output() changeEventEmitter = new EventEmitter<any>();

  public images = images;

  public pax = 0;
  public prm = 0;

  public navigationKeys = ['Delete', 'Backspace', 'Tab', 'Escape', 'Enter'];

  @HostListener('change', ['$event'])
  onChange(event: Event) {
    console.log('onChange', event);
    setTimeout(() => {
      this.changeEventEmitter.emit(event);
    });
  }

  @HostListener('keyup', ['$event'])
  onKeyUp(e: any) {
    if (
      // Allow: Delete, Backspace, Tab, Escape, Enter, etc
      this.navigationKeys.indexOf(e.key) > -1 || 
      (e.key === 'a' && e.ctrlKey === true) || // Allow: Ctrl+A
      (e.key === 'c' && e.ctrlKey === true) || // Allow: Ctrl+C
      (e.key === 'v' && e.ctrlKey === true) || // Allow: Ctrl+V
      (e.key === 'x' && e.ctrlKey === true) || // Allow: Ctrl+X
      (e.key === 'a' && e.metaKey === true) || // Cmd+A (Mac)
      (e.key === 'c' && e.metaKey === true) || // Cmd+C (Mac)
      (e.key === 'v' && e.metaKey === true) || // Cmd+V (Mac)
      (e.key === 'x' && e.metaKey === true)    // Cmd+X (Mac)
    ) {
      
      if (e.target.id === 'pax') {
        let pax = 0;
        if (e.target.value === '' || e.target.value === 0) {
          if (this.prm > 0) pax = 0;
          else pax = e.target.value;
        } else {
          pax = e.target.value;
        }
        this.pax = pax;
        this.changeEventEmitter.emit({ key: 'value', value: this.pax, type: 'pax' });
      } 
      if (e.target.id === 'prm') {
        this.prm = e.target.value === '' ? 0 : e.target.value;
        this.changeEventEmitter.emit({ key: 'value', value: this.prm, type: 'prm' });
      }
      return;  // let it happen, don't do anything
    }
    // Ensure that it is a number and stop the keypress
    if (e.key === ' ' || isNaN(Number(e.key))) {
      e.preventDefault();
    }

    if (e.target.value[0] === '0') {
      e.target.value = e.target.value.replace('0', '');
    }
  }

  constructor(public literalService: LiteralService) { }

  ngOnInit(): void {
    this.setValue(this.data);
  }

  ngOnChanges(): void {
    this.setValue(this.data);
  }

  setValue = (data: any) => {
    if (data) {
      this.pax = data && data.filter((seats: any) => !seats.prm).length || 0;
      this.prm = data && data.filter((seats: any) => seats.prm).length || 0;
    } else {
      this.pax = this.paxBooking!;
      this.prm = this.prmBooking!;
    }
  };

}

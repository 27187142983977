import { Injectable } from '@angular/core';
import { StorageService } from '../services';
import { ActivatedRouteSnapshot } from '@angular/router';
import { objectToCamel } from 'ts-case-convert';
import { IServiceInfoDetail, ITown } from 'src/app/models';
import { initialPaginationState } from '../store/initialisation/pagination';
import { ServiceInfoService } from '../services/service-info/service-info.service';
import { initialServiceInfoDetailsState } from '../store/initialisation/service-infos';

@Injectable({
  providedIn: 'root',
})
export class ServiceInfosResolver {

  constructor(private serviceInfoService: ServiceInfoService, public storageService: StorageService) {}

  async resolve(route: ActivatedRouteSnapshot) {
    this.storageService.setRouteName('services')
    const page = route.data['services']?.pagination.actualPage || 1;
    const size = route.data['services']?.pagination.size || 6;

    const storedAreas: any = await this.storageService.getServicesAreas().catch(() => []);
    const selectedTowns = storedAreas ? JSON.parse(storedAreas) : [];

    const {
      content,
      number_of_elements,
      total_elements,
      total_pages,
      first,
      last,
    } = await this.serviceInfoService.getServiceInfos(size, page, selectedTowns);

    return this.getData(
      content,
      number_of_elements,
      total_elements,
      total_pages,
      first,
      last,
      page,
    );
  }

  async resolveWithTowns(size: number, page: number, towns: ITown[]) {
      const {
        content,
        number_of_elements,
        total_elements,
        total_pages,
        first,
        last,
      } = await this.serviceInfoService.getServiceInfos(size, page, towns);
      return this.getData(
        content,
        number_of_elements,
        total_elements,
        total_pages,
        first,
        last,
        page,
      );
    }

  getFilteredData(townId: any) {
    // this.storageService.removeUserSelected();
    // const data = initialServicesState;
    // const newValues = objectToCamel(content) as Array<IService>;
    // data.value = newValues.filter((value) => value.userId === Number(userId));
    // data.isFetchingServices = false;
    // data.pagination = initialPaginationState;
    // data.pagination.numberOfElements = data.value.length;
    // data.pagination.totalPages = Math.ceil(data.value.length / size);
    // data.pagination.totalElements = data.value.length;
    // data.pagination.actualPage = 1;
    // data.pagination.first = first;
    // data.pagination.last = last;
    // return data;
  }

  getData(
    content: any,
    number_of_elements: any,
    total_elements: any,
    total_pages: any,
    first: any,
    last: any,
    page: any,
  ) {
    const data = initialServiceInfoDetailsState;
    data.value = objectToCamel(content) as Array<IServiceInfoDetail>;
    data.isFetchingServiceInfos = false;
    data.pagination = {...initialPaginationState};
    data.pagination.numberOfElements = number_of_elements;
    data.pagination.totalPages = total_pages;
    data.pagination.totalElements = total_elements;
    data.pagination.actualPage = page;
    data.pagination.first = first;
    data.pagination.last = last;
    console.log(data)
    return data;
  }
}
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { images } from 'src/images';

@Component({
  selector: 'app-input-password',
  templateUrl: './input-password.component.html',
  styleUrls: ['./input-password.component.scss'],
})
export class InputPasswordComponent {
  @Input() id: string;
  @Input() value?: string;
  @Input() placeholder: string;
  @Input() hasErrors: boolean;
  @Input() hasIcon?: boolean = false;
  @Output() changeEventEmitter = new EventEmitter<unknown>();

  public images = images;

  @ViewChild('password') password: ElementRef;

  public togglePassword() {
    if (this.password.nativeElement.type === 'password')
      this.password.nativeElement.type = 'text';
    else this.password.nativeElement.type = 'password';
  }
}

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import moment from 'moment';
import { IAvailability, IBookingCreate, IReservationAvailability, IReservationAvailabilityFailure } from 'src/app/models';
import { LiteralService } from 'src/app/services';
import { TooltipPosition } from 'src/app/shared/tooltip/tooltip-position';
import { images } from 'src/images';
import {
  BookingsService,
} from 'src/app/services';

@Component({
  selector: 'app-booking-availabilities',
  templateUrl: './booking-availabilities.component.html',
  styleUrl: './booking-availabilities.component.scss'
})
export class BookingAvailabilitiesComponent implements OnInit, OnChanges {

  @Input() availabilities: any;
  @Input() userId: any;
  @Input() newBooking: IBookingCreate;
  @Output() manual: EventEmitter<any> = new EventEmitter();
  @Output() book: EventEmitter<IReservationAvailability> = new EventEmitter<IReservationAvailability>();
  @Output() bookMultiple: EventEmitter<IReservationAvailability[]> = new EventEmitter<IReservationAvailability[]>();
  @Output() showMaxReservationTimeModalEventEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() showExceedingKmLimitModalEventEmitter: EventEmitter<any> = new EventEmitter<any>();

  public images = images;
  public moment = moment;
  public TooltipPosition = TooltipPosition;

  public availabilitySelected: IReservationAvailability | IReservationAvailabilityFailure | null;
  public isManualBooking = false;

  public interval: any;
  public timeCounter = 0;

  constructor(
    public literalService: LiteralService,
    public bookingsService: BookingsService) {

  }

  ngOnInit(): void {
    this.startTimer();
  }

  startTimer() {
    this.interval = setInterval(() => {
      this.timeCounter++;
    }, 1000);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.availabilities = changes['availabilities'].currentValue;
  }

  selectAvailability = (availability: IReservationAvailability | IReservationAvailabilityFailure) => {
    this.availabilitySelected = availability;
  };

  manualBooking = () => {
    this.isManualBooking = true;
    this.manual.emit();
  };

  isIReservationAvailability = (obj: any): obj is IReservationAvailability => 'optimal' in obj;

  confirmAvailability = async () => {
    // const exceedsKmLimit = await this.bookingsService.isExceedingKmLimit(this.userId, this.availabilitySelected?.inStop.id, this.availabilitySelected?.outStop.id, this.availabilitySelected?.serviceId);
    const maxReservationTime = this.availabilitySelected!.maxBookingTime > 0 && this.timeCounter >= this.availabilitySelected!.maxBookingTime;
    if (maxReservationTime) {
      this.showMaxReservationTimeModalEventEmitter.emit();
    // } else if (exceedsKmLimit) {
    //   this.showExceedingKmLimitModalEventEmitter.emit(this.availabilitySelected);
    } else if (this.isIReservationAvailability(this.availabilitySelected)) {
      this.book.emit(this.availabilitySelected!);
    }
    this.availabilitySelected = null;
  };

  multipleBooking = () => {
    this.bookMultiple.emit(this.availabilities);
  };

  checkIfAlternativeIsOnInitial = (availability: IAvailability) => {
    if (this.newBooking.originStopId !== availability.inStop.id) {
      return true;
    }
    return false;
  };

  checkIfAlternativeIsOnFinal = (availability: IAvailability) => {
    if (this.newBooking.destinationStops[0].exitStop.id !== availability.outStop.id) {
      return true;
    }
    return false;
  };

  getAlterationDestinationName = (availability: IAvailability, initial: boolean = true) => {
    if (this.checkIfAlternativeIsOnInitial(availability) && initial) {
      const origin = this.newBooking.stops.find((stop: any) => stop.id === this.newBooking.originStopId);
      return origin.label;
    }
    if (this.checkIfAlternativeIsOnFinal(availability) && !initial) {
      const destination = this.newBooking.stops.find((stop: any) => stop.id === this.newBooking.destinationStops[0].exitStop.id);
      return destination.label;
    }
    return null;
  };

}

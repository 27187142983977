import { Injectable } from '@angular/core';
import { INotification } from 'src/app/models';
import { NotificationTypes } from 'src/app/models/enums';
// import { clearNotificationAction, setNotificationAction } from 'src/app/store/actions';
import { initialNotificationState } from 'src/app/store/initialisation/notification';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  public notification: INotification = initialNotificationState;

  private readonly DEFAULT_SHOW_TIME: number = 3000;

  set image(image: string) {
    this.notification.image = image;
  }

  set title(title: string) {
    this.notification.title = title;
  }

  set message(message: string) {
    this.notification.message = message;
  }

  set link(link: string) {
    this.notification.link = link;
  }

  set translate(translate: boolean) {
    this.notification.translate = translate;
  }

  set typeError(typeError: string) {
    this.notification.typeError = typeError;
  }

  show = (type: NotificationTypes) => {
    this.notification.type = type;
    this.notification.show = true;
    setTimeout(() => {
      this.notification.show = false;
      // configureStore.dispatch(clearNotificationAction())
    }, this.DEFAULT_SHOW_TIME);
  };

  get isShowed() {
    return this.notification.show;
  }
}

import { HttpResponse } from '@angular/common/http';
export class Cache {
  url: string;
  response: HttpResponse<any>;
  expire: Date;

  constructor(url: string, response: HttpResponse<any>, expire: Date) {
    this.url = url;
    this.response = response;
    this.expire = expire;
  }
}

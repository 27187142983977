[
  {
    "name": "Andorra",
    "dial_code": "+376",
    "emoji": "🇦🇩",
    "code": "AD"
  },
  {
    "name": "Belgium",
    "dial_code": "+32",
    "emoji": "🇧🇪",
    "code": "BE"
  },
  {
    "name": "France",
    "dial_code": "+33",
    "emoji": "🇫🇷",
    "code": "FR"
  },
  {
    "name": "Germany",
    "dial_code": "+49",
    "emoji": "🇩🇪",
    "code": "DE"
  },
  {
    "name": "Greece",
    "dial_code": "+30",
    "emoji": "🇬🇷",
    "code": "GR"
  },
  {
    "name": "Italy",
    "dial_code": "+39",
    "emoji": "🇮🇹",
    "code": "IT"
  },
  {
    "name": "Portugal",
    "dial_code": "+351",
    "emoji": "🇵🇹",
    "code": "PT"
  },
  {
    "name": "Spain",
    "dial_code": "+34",
    "emoji": "🇪🇸",
    "code": "ES"
  },
  {
    "name": "United Kingdom",
    "dial_code": "+44",
    "emoji": "🇬🇧",
    "code": "GB"
  }
]

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UtilsService } from 'src/app/services';
import { images } from 'src/images';

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html',
    styleUrl: './pagination.component.scss',
    standalone: false
})
export class PaginationComponent {
  
  @Input() id?: any;
  @Input() pagination: any;
  @Input() right?: boolean = true;
  @Input() small?: boolean = false;
  @Input() maxLength?: number = 7;
  @Output() changePage = new EventEmitter<{isNext: boolean, specificPage?: number}>();

  public images = images;
  public isNaN = Number.isNaN;

  constructor(public utilsService: UtilsService) { }

}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Modal } from 'flowbite';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { InputType } from 'src/app/models/enums';
import { LiteralService } from 'src/app/services/literal/literal.service';
import { images } from 'src/images';

@Component({
  selector: 'app-modal-confirmation-text',
  templateUrl: './modal-confirmation-text.component.html',
  styleUrl: './modal-confirmation-text.component.scss',
})
export class ModalConfirmationTextComponent {
  @Input() id: string;
  @Input() modal: Modal;
  @Input() title: string;
  @Input() text: string;
  @Input() reason: string;
  @Input() deleting?: boolean;
  @Output() action = new EventEmitter<any>();

  public InputType = InputType;
  public images = images;

  public optionsBus: AnimationOptions = {
    path: '/assets/animations/bus.json'
  };

  constructor(public literalService: LiteralService) {}

  change = (reason: any) => {
    this.reason = reason;
  };

  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
    animationItem.setSpeed(1.5);
  }
}

import { Component } from '@angular/core';
import { TooltipPosition } from './tooltip-position';
import { TooltipTheme } from './tooltip-theme';

@Component({
    selector: 'app-tooltip',
    templateUrl: './tooltip.component.html',
    styleUrls: ['./tooltip.component.scss'],
    standalone: false
})
export class TooltipComponent {
  position: TooltipPosition = TooltipPosition.ABOVE;
  theme: TooltipTheme = TooltipTheme.LIGHT;
  tooltip = '';
  left = 0;
  top = 0;
  visible = false;

  constructor() {}
}

import { Injectable, input } from '@angular/core';
import { Cluster, MarkerClusterer } from '@googlemaps/markerclusterer';
import { NotificationTypes } from 'src/app/models/enums';
import { images } from 'src/images';
import { GeoJsonObject, IServiceInfo, ITripPosition } from '../../models';
import { NotificationService } from '../notification/notification.service';
import { UtilsService } from '../utils/utils.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MapUtilsService {
  public markersGlobal: any[] = [];
  public images = images;
  public polygons: google.maps.Polygon[] = [];
  public clusterMarkersGlobal: any[] = [];
  public markerClusterer: MarkerClusterer;
  public map: google.maps.Map;
  public infoWindows: google.maps.InfoWindow[] = [];

  constructor(
    private utilsService: UtilsService,
    private notificationService: NotificationService
  ) {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible' && this.map) {
        this.showPolygons(true, this.map);
      }
    });
  }

  addMarker = async (
    map: google.maps.Map,
    marker: ITripPosition,
    image: string,
    clear: boolean = false,
    zIndex?: number
  ) => {
    if (clear) {
      this.markersGlobal.map((map: any) => (map.map = null));
      return;
    }
    const { AdvancedMarkerElement, PinElement } =
      (await google.maps.importLibrary('marker')) as google.maps.MarkerLibrary;

    let img = '';
    await this.utilsService.getSvgImageData(image).then((res: any) => {
      img = res;
    });

    const parser = new DOMParser();
    const pinSvgElement = parser.parseFromString(
      img,
      'image/svg+xml',
    ).documentElement;

    const pin = new PinElement({
      scale: 1.5,
      background: '#FFF',
      borderColor: marker.color && marker.color,
      glyph: pinSvgElement,
    });

    const vehicleId = marker.vehicleId;
    const title = vehicleId ? vehicleId.toString() : '';

    // Remove old ones
    const markerToRemove = this.markersGlobal.find((mkr: any) => title != '' && mkr.title == title);
    if (markerToRemove) {
      markerToRemove.setMap(null); // This removes the marker from the map
      // Optionally, remove the marker object from the markersGlobal array
      this.markersGlobal = this.markersGlobal.filter(marker => marker !== markerToRemove);
    }

    this.markersGlobal.push(
      new AdvancedMarkerElement({
        map: map,
        position: { lat: marker.latitude, lng: marker.longitude },
        content: pin.element,
        title: title,
        zIndex: zIndex ?? 40
      }),
    );
  };

  addCustomMarkers = async (
    map: google.maps.Map,
    markers?: any[],
    clear: boolean = false,
  ) => {
    if (clear) {
      this.markersGlobal.map((map: any) => (map.map = null));
      this.clearInfoWindows();
      return;
    }
    this.markersGlobal.map((map: any) => (map.map = null));
    this.clearInfoWindows();
    const { AdvancedMarkerElement } = (await google.maps.importLibrary(
      'marker',
    )) as google.maps.MarkerLibrary;

    markers!.map((marker: any) => {
      const mark = new AdvancedMarkerElement({
        map,
        position: { lat: marker.latitude, lng: marker.longitude },
        content: marker.element,
      });
      const infoWindow = new google.maps.InfoWindow({
        content: marker.name,
        disableAutoPan: true,
      });
      mark.addListener('click', () => {
        infoWindow.open(map, mark);
        this.infoWindows.push(infoWindow);
      });
      this.markersGlobal.push(
        mark,
      );
    });
  };

  setUserCurrentPosition = async (map: google.maps.Map, image: string) => {
    const { AdvancedMarkerElement, PinElement } =
      (await google.maps.importLibrary('marker')) as google.maps.MarkerLibrary;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position: GeolocationPosition) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          let img = '';
          await this.utilsService.getSvgImageData(image).then((res: any) => {
            img = res;
          });

          const parser = new DOMParser();
          const pinSvgElement = parser.parseFromString(
            img,
            'image/svg+xml',
          ).documentElement;

          const pin = new PinElement({
            scale: 1.5,
            background: '#FFF',
            glyph: pinSvgElement,
          });

          new AdvancedMarkerElement({
            map: map,
            position: pos,
            content: pin.element,
          });
        },
        () => {
          this.handleLocationError();
        },
      );
    } else {
      // Browser doesn't support Geolocation
      this.handleLocationError();
    }
  };

  handleLocationError = () => {
    this.notificationService.image = images.sidebar.users;
    this.notificationService.title = 'Location user error';
    this.notificationService.message =
      'Error getting the user current position';
    this.notificationService.show(NotificationTypes.WARNING);
  };

  drawOutlines(map: google.maps.Map, serviceInfos: IServiceInfo[]) {
    this.clearPolygons();
    this.map = map;
    serviceInfos
    .map((serviceInfo: IServiceInfo) => {
      if (serviceInfo.outlines && serviceInfo.outlines.length > 0) {
        serviceInfo.outlines.filter((outline) => outline).map((outline) => {
          const coords: any[] = [];
          outline.geometry.coordinates[0].map((coord: any) => {
            coords.push({ lat: coord[1], lng: coord[0] });
          });
          const outlinePolygon = new google.maps.Polygon({
            paths: coords,
            strokeColor: serviceInfo.color === '#FFFFFFFF' ? '#000000' : serviceInfo.color,
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: serviceInfo.color === '#FFFFFFFF' ? '#000000' : serviceInfo.color,
            fillOpacity: 0.10,
          });
          outlinePolygon.setMap(map);
          this.polygons.push(outlinePolygon);
        })
      }
    });
  }

  drawOutlinesFromOutlines(map: google.maps.Map, outlines: any[], colors: any[]) {
    this.clearPolygons();
    this.map = map;
    
    outlines.forEach((outline: GeoJsonObject, index: number) => {
      const coords: any[] = [];
      outline.geometry?.coordinates[0].map((coord: any) => {
        coords.push({ lat: coord[1], lng: coord[0] });
      });
      console.log("coords", coords)
      const ol = new google.maps.Polygon({
        paths: coords,
        strokeColor: colors[index],
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: colors[index],
        fillOpacity: 0.10,
      });
      ol.setMap(map);
      this.polygons.push(ol);
    });
  }

  showPolygons = (show: boolean, map: google.maps.Map) => {
    this.polygons.forEach((polygon: google.maps.Polygon) => {
      polygon.setMap(null);
      if (show) polygon.setMap(map);
    });
  };

  clearPolygons = () => {
    this.polygons.forEach((polygon: google.maps.Polygon) => {
      polygon.setMap(null); // Remove polygon from map
    });
    this.polygons = []; // Clear the array
  };

  isPointInsideOutline(lat: number, lng: number) {
    let isInside = false;
    this.polygons.map((polygon) => {
      let isPointInsidePolygon = google.maps.geometry.poly.containsLocation(new google.maps.LatLng(lat, lng), polygon);
      if (isPointInsidePolygon) isInside = true;
    });
    return isInside;
  }

  addClustersMarkers = async (map: google.maps.Map, stops: any[]) => {
    this.clearClustersMarkers();
    const { AdvancedMarkerElement } = (await google.maps.importLibrary(
      'marker',
    )) as google.maps.MarkerLibrary;

    const markers = stops.map((stop) => {
      const infoWindow = new google.maps.InfoWindow();
      const infoWindowContent = `<div class="font-semibold">${stop.name}</div>`;
      infoWindow.setContent(infoWindowContent);
      const marker = new AdvancedMarkerElement({
        position: stop,
        content: stop.marker,
        zIndex: 1
      });
  
      // markers can only be keyboard focusable when they have click listeners
      // open info window when marker is clicked
      marker.addListener("click", () => {
        infoWindow.open(map, marker);
        this.infoWindows.push(infoWindow);
      });

      map.addListener("click", () => infoWindow.close());
      this.polygons.map((polygon: google.maps.Polygon) => polygon.addListener("click", () => infoWindow.close()));
      this.clusterMarkersGlobal.push(marker);
  
      return marker;
    });

    this.markerClusterer = new MarkerClusterer({map, markers, onClusterClick: this.onClusterClick});
    
    map.addListener('zoom_changed', () => {
      this.setClustersZIndex();
    });

    this.setClustersZIndex();
  };

  showClusterMarkers = (show: boolean) => {
    if (this.clusterMarkersGlobal.length > 0) {
      if (show) {
        this.markerClusterer.addMarkers(this.clusterMarkersGlobal);
      } else {
        this.markerClusterer.clearMarkers(false);
      }
    }
  };

  clearClustersMarkers = () => {
    this.clusterMarkersGlobal.forEach((marker: any) => {
      marker.setMap(null);
    });
    if (this.clusterMarkersGlobal.length > 0) this.markerClusterer.clearMarkers(false);
    this.clusterMarkersGlobal = [];
  };

  clearInfoWindows = () => {
    this.infoWindows.forEach((infoView) => {
      infoView.close();
    });
    this.infoWindows = [];
  };

  setClustersZIndex = () => {
    setTimeout(() => {
      const clusterElements = document.querySelectorAll('[aria-label*="markers"]');
      clusterElements.forEach((element: Node) => {
        if (element instanceof HTMLElement) {
          element.style.zIndex = '-1';
        }
      });
      
      const markers = document.querySelectorAll('[class*="marker-view"]');
      markers.forEach((element: Node) => {
        if (element instanceof HTMLElement) {
          element.style.zIndex = '-10';
        }
      });

      const stopsMarkers = Array.from(document.getElementsByTagName('app-marker-od'));
      stopsMarkers.forEach((element: Node) => {
        if (element.parentElement!.parentElement instanceof HTMLElement) {
          element.parentElement!.parentElement.style.zIndex = '10';
        }
      });
    }, 500);
  };

  onClusterClick = (event: google.maps.MapMouseEvent, cluster: Cluster, map: google.maps.Map) => {
    const bounds = cluster.bounds!;
    google.maps.event.addListenerOnce(map, 'zoom_changed', function() {
      if (map.getZoom()! > 18)
        map.setZoom(18);
    });

    const margin = 100;
    const paddingLeft = 600;
    const paddingTop = 10;
    const screenWidth = window.innerWidth - paddingLeft;
    const screenHeight = window.innerHeight - paddingTop;
    const left = paddingLeft + screenWidth * 0.5 - margin;
    const top = paddingTop + screenHeight * 0.5 - margin;
    const right = screenWidth * 0.5 - margin;
    const bottom = screenHeight * 0.5 - margin;

    const padding = { top: top, bottom: bottom, left: left, right: right };
    map.fitBounds(bounds, padding);
  };

  async getNearestRoad(lat: number, lng: number) {
    try {
      const response = await fetch(`https://roads.googleapis.com/v1/snapToRoads?path=${lat},${lng}&key=${environment.googleMapsApiKey}`);

      const resJSON = await response.json();
      const snappedPoint = resJSON.snappedPoints[0];

      return await this.getPlaceDetailGeocode(snappedPoint.location.latitude, snappedPoint.location.longitude);
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.error(error);
      return [];
    }
  }

  async getPlaceDetailGeocode(lat: number, lng: number) {
    const geocoder = new google.maps.Geocoder();
    const geoCoderRequest = { location: new google.maps.LatLng(lat, lng) };
    const placeDetailsGeoResults = await geocoder.geocode(geoCoderRequest);
    const placeDetail = placeDetailsGeoResults.results.find((res: any) => res.types.includes('street_address'));
    const addressComponents = placeDetail?.address_components;
    const streetName = addressComponents?.find((component: any) => component.types.includes('route'));
    const streetNumber = addressComponents?.find((component: any) => component.types.includes('street_number'));
    const locality = addressComponents?.find((component: any) => component.types.includes('locality'));
    return {
      id: placeDetail?.place_id,
      location: {
        lat: lat,
        lng: lng
      },
      name: `${streetName?.long_name}, ${streetNumber?.long_name ?? ''}${ locality && `, ${locality.long_name}`}`
    }
  }

  async getPlaceLocation(placeId: string) {
    // @ts-ignore
    const { Place } = await google.maps.importLibrary('places') as google.maps.PlacesLibrary;
    const place = new Place({
      id: placeId
    });
    await place.fetchFields({
      fields: [
        'displayName',
        'location'
      ]
    });
    return {
      id: place.id,
      location: {
        lat: place.location?.lat(),
        lng: place.location?.lng()
      },
      name: `${place.displayName}`
    }
  }

  async getPlaces(nameToSearch: string, originLat: number, originLng: number) {
    // @ts-ignore
    const { AutocompleteSuggestion } = await google.maps.importLibrary("places") as google.maps.PlacesLibrary;

    const request: google.maps.places.AutocompleteRequest = {
      input: nameToSearch,
      includedPrimaryTypes: ['geocode'],
      locationBias: { lat: originLat, lng: originLng }
    };
    const { suggestions } = await AutocompleteSuggestion.fetchAutocompleteSuggestions(request);
    let results: any[] = [];
    const placePrediction = suggestions[0].placePrediction?.toPlace();
    const placeDetails = await this.getPlaceLocation(placePrediction!.id);
    if (this.isPointInsideOutline(placeDetails.location.lat!, placeDetails.location.lng!)) {
      results.push({
        id: placeDetails?.id,
        name: placeDetails?.name,
        location: placeDetails.location
      });
    }
    return results;
  }
}

import { Injectable } from '@angular/core';
import CryptoJS from 'crypto-js';
import tinycolor from 'tinycolor2';
import { StorageService } from '../storage/storage.service';
import { environment } from 'src/environments/environment';
import { NotificationTypes } from 'src/app/models/enums';
import { NotificationService } from '../notification/notification.service';
import { AnimationItem } from 'lottie-web';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  public urlsToCache = [];

  public storageService = new StorageService();

  constructor(private notificationService?: NotificationService) {}

  public getUrl() {
      if (environment.location === 'tst') {
        return 'https://tst.nemi.mobi/backoffice/';
      } else if (environment.location === 'sta') {
        return 'https://sta.nemi.mobi/backoffice/';
      } else if (environment.location === 'demo') {
        return 'https://demo.nemi.mobi/backoffice/';
      } else if (environment.location === 'prod') {
        return 'https://prod.nemi.mobi/backoffice/';
      } else if (environment.location === 'dev') {
        return 'http://localhost:8080/backoffice/';
      }
      return 'https://prod.nemi.mobi/backoffice/';
  }

  public async getLanguage() {
    return this.storageService.getLanguage();
  };

  public capitalize(text: string) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  public visibleTooltip(id: any) {
    const e = document.getElementById(id);
    return e!.offsetWidth < e!.scrollWidth || e!.offsetHeight < e!.scrollHeight;
  };

  public closeComponent(
    e: Event,
    inputs: HTMLElement[],
    dropdowns: HTMLElement[],
    title: string,
  ): boolean {
    const input = inputs.find(
      (input: HTMLElement) => input.id === 'input-' + title,
    );
    const dropdown = dropdowns.find(
      (dropdown: HTMLElement) => dropdown.id === 'dropdown-' + title,
    );

    if (input && dropdown) {
      const isClickInsideInput = input.contains(e.target as Node);
      const isClickInsideDropdown = dropdown.contains(e.target as Node);

      if (!isClickInsideInput && !isClickInsideDropdown) {
        return true;
      }
    }
    return false;
  };

  public closeComponentOnlyWithDropdown(
    e: Event,
    dropdownsParent: HTMLElement,
    dropdowns: HTMLElement[],
    title: string,
  ): boolean {
    const dropdown = dropdowns.find(
      (dropdown: HTMLElement) => dropdown.id === 'dropdown-' + title,
    );

    if (dropdown && dropdownsParent) {
      const isClickInsideDropdown = dropdown.contains(e.target as Node);
      const isClickInsideDropdownParent = dropdownsParent.contains(
        e.target as Node,
      );

      if (!isClickInsideDropdown && !isClickInsideDropdownParent) {
        return true;
      }
    }
    return false;
  };

  public openComponentOnlyWithDropdown(
    e: Event,
    dropdownsParent: HTMLElement,
    dropdowns: HTMLElement[],
    title: string,
  ): boolean {
    const dropdown = dropdowns.find(
      (dropdown: HTMLElement) => dropdown.id === 'dropdown-' + title,
    );

    if (dropdown && dropdownsParent) {
      const isClickInsideDropdown = dropdown.contains(e.target as Node);
      const isClickInsideDropdownParent = dropdownsParent.contains(
        e.target as Node,
      );

      if (isClickInsideDropdown || isClickInsideDropdownParent) {
        return true;
      }
    }
    return false;
  };

  public closeCustomModals(
    e: Event,
    modal: HTMLElement,
    componentOpen: HTMLElement,
  ): boolean {
    if (modal && componentOpen) {
      const isClickInsideModal = modal.contains(e.target as Node);
      const isClickInsideComponentOpen = componentOpen.contains(
        e.target as Node,
      );

      if (!isClickInsideModal && !isClickInsideComponentOpen) {
        return true;
      }
    }
    return false;
  };

  public closeCustomModalsWithModals(
    e: Event,
    modal: HTMLElement,
    modals: Node[],
    componentOpen: HTMLElement,
  ): boolean {
    if (modal && componentOpen) {
      const isClickInsideModal = modal.contains(e.target as Node);
      const isClickInsideComponentOpen = componentOpen.contains(
        e.target as Node,
      );
      const isClickInsideModals = modals.find((modal: Node) => {
        return modal.contains(e.target as Node);
      });

      if (!isClickInsideModal && !isClickInsideComponentOpen && !isClickInsideModals) {
        return true;
      }
    }
    return false;
  };

  public isDark(hexcolor: string) {
    return hexcolor ? tinycolor(hexcolor).isDark() : false;
  }

  public async getSvgImageData(image: string) {
    return await fetch(image)
      .then((response) => response.text())
      .then((svg) => svg);
  };

  public encryptSHA256(password: string): string {
    return CryptoJS.SHA256(password).toString();
  };

  public compareArrays(arr1: any, arr2: any) {
    return arr1
      .filter((item: any) => !arr2.some((item2: any) => {
        if (typeof item === 'object') return Object.keys(item).every((k) => item[k] === item2[k]);
        return item === item2;
      }))
      .concat(arr2 && arr2
        .filter((item2: any) => !arr1.some((item: any) => {
          if (typeof item2 === 'object') return Object.keys(item2).every((k) => item2[k] === item[k]);
          return item2 === item;
        })))
  }

  public compareObjects(obj1: any, obj2: any): string[] {
    return Object.keys(obj1)
      .filter((key) => {
        if (obj1[key] && Array.isArray(obj1[key])) {
          return this.compareArrays(obj1[key], obj2[key]).length > 0;
        }
        if (obj1[key] && typeof (obj1[key]) === 'object') {
          return this.compareObjects(obj1[key], obj2[key]).length > 0;
        }
        return obj1 && obj2 && obj1[key] !== obj2[key];
      })
      .concat(obj2 && Object.keys(obj2).filter((key) => obj2[key] && !obj1[key]));
  }

  public numberHasDecimals(number: number) {
    const result = number - Math.floor(number) !== 0;
    if (result) return true;
    return false;
  };

  public compare(propName: string) {
    return function(a: any, b: any) {
        if (a[propName] < b[propName])
            return -1;
        if (a[propName] > b[propName])
            return 1;
        return 0;
    };
  }

  public convertDate(dateTime: any) {
    const date = new Date(dateTime).toString();
    let dateString = date.toString().replace(/GMT.*/g, "");
    dateString = dateString + " UTC";
    return new Date(dateString);
  };

  public counter(i: number) {
    return new Array(i);
  }

  public isTextSelected() {
    return window.getSelection()!.toString().length > 0;
  }

  public getPaginationItems(currentPage: number, lastPage: number, maxLength: number) {
    const res: Array<number> = [];
  
    // handle lastPage less than maxLength
    if (lastPage <= maxLength) {
      for (let i = 1; i <= lastPage; i++) {
        res.push(i);
      }
    }
    else if (currentPage === 1) {
      for (let i = 1; i <= maxLength - 2; i++) {
        res.push(i);
      }
      res.push(NaN);
      res.push(lastPage);
      return res;
    }
    else if (currentPage === lastPage) {
      res.push(1);
      res.push(NaN);
      for (let i = lastPage - 2; i <= lastPage; i++) {
        res.push(i);
      }
      return res;
    }
    // handle ellipsis logics
    else {
      const firstPage = 1;
      const confirmedPagesCount = 3;
      const deductedMaxLength = maxLength - confirmedPagesCount;
      const sideLength = deductedMaxLength / 2;
  
      // handle ellipsis in the middle
      if (
        currentPage - firstPage < sideLength ||
        lastPage - currentPage < sideLength
      ) {
        for (let j = 1; j <= sideLength + firstPage; j++) {
          res.push(j);
        }
  
        res.push(NaN);
  
        for (let k = lastPage - sideLength; k <= lastPage; k++) {
          res.push(k);
        }
      }
      // handle two ellipsis
      else if (
        currentPage - firstPage >= deductedMaxLength &&
        lastPage - currentPage >= deductedMaxLength
      ) {
        const deductedSideLength = sideLength - 1;
  
        res.push(1);
        res.push(NaN);
  
        for (
          let l = currentPage - deductedSideLength;
          l <= currentPage + deductedSideLength;
          l++
        ) {
          res.push(l);
        }
  
        res.push(NaN);
        res.push(lastPage);
      }
      // handle ellipsis not in the middle
      else {
        const isNearFirstPage = currentPage - firstPage < lastPage - currentPage;
        let remainingLength = maxLength;
  
        if (isNearFirstPage) {
          for (let m = 1; m <= currentPage + 1; m++) {
            res.push(m);
            remainingLength -= 1;
          }
  
          res.push(NaN);
          remainingLength -= 1;
  
          for (let n = lastPage - (remainingLength - 1); n <= lastPage; n++) {
            res.push(n);
          }
        } else {
          for (let o = lastPage; o >= currentPage - 1; o--) {
            res.unshift(o);
            remainingLength -= 1;
          }
  
          res.unshift(NaN);
          remainingLength -= 1;
  
          for (let p = remainingLength; p >= 1; p--) {
            res.unshift(p);
          }
        }
      }
    }
    return res;
  }

  downloadDocument(response: ArrayBuffer, filename: string, extension: string) {
    const blob = new Blob([response], { type: 'application/zip' });
    const url = window.URL.createObjectURL(blob);
    
    const doc = document.createElement('a');
    doc.href = url;
    doc.download = `${filename}.${extension}`;
    document.body.appendChild(doc);
    doc.click();
  
    window.URL.revokeObjectURL(url);
  }

  showNotification(img: string, actionKey: string, type: NotificationTypes, specificTitle?: string, specificText?: string) {
    this.notificationService!.image = img;
    this.notificationService!.title = specificTitle ? `${actionKey}.${specificTitle}` : `${actionKey}.title`;
    this.notificationService!.message = specificText ? `${actionKey}.${specificText}` : `${actionKey}.text`;
    this.notificationService!.translate = true;
    this.notificationService!.show(type);
  }

  convertSecondsToMinutesAndSeconds(value: number = 0) {
    const minutes = Math.floor(value / 60);
    const seconds = value % 60;

    const formattedMinutes = String(minutes).padStart(minutes === 0 ? 1 : 2, '0');
    const formattedSeconds = String(seconds).padStart(2, '0');

    if (formattedSeconds === '00') {
      return `${formattedMinutes}`;  
    }
    return `${formattedMinutes}:${formattedSeconds}`;
  }

  animationCreated(animationItem: AnimationItem, time: number): void {
    animationItem.setSpeed(time);
  }
}

import moment from 'moment';
import { images } from '../../images';
import { BookingSeatsComponent } from '../components/booking/booking-seats/booking-seats.component';
import { ServiceTagComponent } from '../components/service-tag/service-tag.component';
import { IBookingCreate, IServiceAvailability, IServiceInfo, IStopWithDate, ITown, IUser, IUserRegular } from '../models';
import { IBookingDetails } from '../models/booking/bookingDetails';
import { ExpeditionStatus, ExpeditionType, InputType, SingleStopReservationType, UserSortType, UserStatus, UserType } from '../models/enums';
import { LiteralService, RouteService, TownsService, UsersService } from '../services';
import { formCreationData, formData } from './bookingData';

export async function getBookingForm(booking: IBookingDetails = {} as IBookingDetails, literalService: LiteralService, routeService: RouteService, townsService: TownsService): Promise<unknown[]> {
  getAvailabilities(routeService, literalService, booking);
  getStops(townsService, booking);
  const isD2DBooking = booking.tripType === ExpeditionType.Door2Door;
  const data: formData[] = [
    {
      img: images.booking.user,
      title: 'bookings.data.user',
      value: `${booking.firstName} ${booking.lastName}`,
      expanded: false
    },
    {
      img: images.booking.service,
      title: 'bookings.data.service',
      component: ServiceTagComponent,
      value: booking.service,
      expanded: false
    },
    {
      img: images.origin,
      title: 'bookings.data.originStop',
      value: `${booking.origin.time} - ${booking.origin.name}`,
      setColor: false,
      expanded: false,
      edit: isD2DBooking ? false : booking.availability.expeditionStatus !== ExpeditionStatus.CLOSED && booking.availability.expeditionStatus !== ExpeditionStatus.DELETED && {
        inputType: InputType.SELECT_SEARCH,
        values: stops,
        valueToChange: 'origin',
      },
    },
    {
      img: images.destination,
      title: 'bookings.data.destinationStop',
      value: `${booking.destination.time} - ${booking.destination.name}`,
      setColor: false,
      expanded: false,
      edit: isD2DBooking ? false : booking.availability.expeditionStatus !== ExpeditionStatus.CLOSED && booking.availability.expeditionStatus !== ExpeditionStatus.DELETED && {
        inputType: InputType.SELECT_SEARCH,
        values: stops,
        valueToChange: 'destination',
      }
    },
    {
      img: images.booking.availability,
      title: 'bookings.data.availability',
      value: formatDate(booking.availability.dateTime, literalService),
      expanded: false,
      edit: isD2DBooking ? false : booking.availability.expeditionStatus !== ExpeditionStatus.CLOSED && booking.availability.expeditionStatus !== ExpeditionStatus.DELETED && {
        inputType: InputType.SELECT_SEARCH,
        values: availabilities,
        valueToChange: 'availability',
      },
    },
    {
      img: images.booking.seats,
      title: 'bookings.data.seats',
      component: BookingSeatsComponent,
      value: booking,
      expanded: false,
      edit: isD2DBooking ? false : booking.availability.expeditionStatus !== ExpeditionStatus.CLOSED && booking.availability.expeditionStatus !== ExpeditionStatus.DELETED && {
        inputType: InputType.SEATS,
        values: 0,
        valueToChange: 'seats',
      },
    },
    {
      img: images.booking.searchCriteria,
      title: 'bookings.data.searchCriteria',
      value: `bookings.data.${booking.searchCriteria}`,
      expanded: false,
      translateValue: true,
    },
    {
      img: images.clock,
      title: 'bookings.data.requestedTime',
      value: booking.requestedTime ? `${booking.requestedTime}` : '-',
      expanded: false,
      extraInfo: {
        text: 'bookings.data.requestedTimeDiff',
        value: booking.requestedTimeDiff,
      }
    },
    {
      img: images.clock,
      title: 'bookings.data.initialOriginTime',
      value: booking.initialOriginTime ? `${booking.initialOriginTime}` : '-',
      expanded: false,
      extraInfo: {
        text: 'bookings.data.originCommunicatedTime',
        value: booking.originCommunicatedTime,
      }
    },
    {
      img: images.clock,
      title: 'bookings.data.initialDestinationTime',
      value: booking.initialDestinationTime
        ? `${booking.initialDestinationTime}`
        : '-',
      expanded: false,
      extraInfo: {
        text: 'bookings.data.destinationCommunicatedTime',
        value: booking.destinationCommunicatedTime,
      }
    },
    {
      img: images.booking.smartPhone,
      title: 'bookings.data.channel',
      value: `${booking.channel}`,
      expanded: false,
    },
    {
      img: images.booking.notifications,
      title: 'bookings.data.notified',
      value: `bookings.data.notified${booking.isNotified || 'false'}`,
      expanded: false,
      translateValue: true,
    },
    {
      img: images.booking.vehicle,
      title: 'bookings.data.expeditionVehicle',
      value: booking.vehicle.name,
      expanded: true,
    },
    {
      img: images.booking.driver,
      title: 'bookings.data.expeditionDriver',
      value: booking.driver.firstName + ' '  + booking.driver.lastName,
      expanded: true,
    },
    {
      img: images.booking.pickUpTime,
      title: 'bookings.data.originPassingTime',
      value: booking.originPassingTime,
      expanded: true,
      extraInfo: {
        text: 'bookings.data.originConfirmedTime',
        value: booking.originConfirmedTime,
      }
    },
    {
      img: images.booking.dropOffTime,
      title: 'bookings.data.destinationPassingTime',
      value: booking.destinationPassingTime,
      expanded: true,
      extraInfo: {
        text: 'bookings.data.destinationConfirmedTime',
        value: booking.destinationConfirmedTime,
      }
    },
    {
      img: images.booking.feedback,
      title: 'bookings.data.feedback',
      value: booking.feedback ? `bookings.data.feedback${booking.feedback?.positive || 'false'}` : '-',
      expanded: true,
      translateValue: true,
      extraInfo: {
        text: booking.feedback,
        value: `bookings.tooltips.feedback.${booking.feedback?.option.id}`,
        image: images.booking.feedback,
      }
    },
    {
      img: images.distance,
      title: 'bookings.actions.updateKms.distance',
      value: booking.kms && booking.kms.toFixed(2),
      expanded: true,
      translateValue: false,
      edit: {
        inputType: InputType.NUMBER,
        values: booking.kms,
        valueToChange: 'kms',
      },
    },
  ];
  return data;
}


export async function getCancelledBookingForm(booking: IBookingDetails = {} as IBookingDetails): Promise<unknown[]> {
  const data: formData[] = [
    {
      img: images.booking.user,
      title: 'bookings.data.user',
      value: `${booking.firstName} ${booking.lastName}`,
      expanded: false
    },
    {
      img: images.booking.service,
      title: 'bookings.data.service',
      component: ServiceTagComponent,
      value: booking.service,
      expanded: false
    },
    {
      img: images.origin,
      title: 'bookings.data.origin',
      setColor: false,
      value: booking.origin?.name ?? '-',
      expanded: false
    },
    {
      img: images.destination,
      title: 'bookings.data.destination',
      setColor: false,
      value:  booking.destination?.name ?? '-',
      expanded: false
    },
    {
      img: images.booking.availability,
      title: 'bookings.data.availability',
      value: undefined,
      expanded: false
    },
    {
      img: images.booking.seats,
      title: 'bookings.data.seats',
      component: BookingSeatsComponent,
      value: booking,
      expanded: false
    },
    {
      img: images.booking.searchCriteria,
      title: 'bookings.data.searchCriteria',
      value: undefined,
      expanded: false,
      translateValue: true,
    },
    {
      img: images.clock,
      title: 'bookings.data.requestedTime',
      value:'-',
      expanded: false
    },
    {
      img: images.clock,
      title: 'bookings.data.initialOriginTime',
      value: booking.initialOriginTime ? `${booking.initialOriginTime}` : '-',
      expanded: false
    },
    {
      img: images.clock,
      title: 'bookings.data.initialDestinationTime',
      value: undefined,
      expanded: false
    },
    {
      img: images.booking.smartPhone,
      title: 'bookings.data.channel',
      value: undefined,
      expanded: false
    },
    {
      img: images.booking.notifications,
      title: 'bookings.data.notified',
      value: undefined,
      expanded: false,
      translateValue: true,
    },
    {
      img: images.booking.vehicle,
      title: 'bookings.data.expeditionVehicle',
      value: undefined,
      expanded: true,
    },
    {
      img: images.booking.driver,
      title: 'bookings.data.expeditionDriver',
      value: undefined,
      expanded: true,
    },
    {
      img: images.booking.pickUpTime,
      title: 'bookings.data.originPassingTime',
      value: undefined,
      expanded: true,
    },
    {
      img: images.booking.dropOffTime,
      title: 'bookings.data.destinationPassingTime',
      value: undefined,
      expanded: true,
    },
    {
      img: images.booking.feedback,
      title: 'bookings.data.feedback',
      value: '-',
      translateValue: true,
      expanded: true,
    },
    {
      img: images.distance,
      title: 'bookings.actions.updateKms.distance',
      value: 0.00,
      translateValue: false,
      expanded: true,
    },
  ];
  return data;
}

let users: unknown[] = [];
const availabilities: unknown[] = [];
let page = 0;

let usersSearched: unknown[] = [];
let pageSearched = 0;

let towns: unknown[] = [];
let stops: any[] = [];
let stopsBooking: any[] = [];
let destinations: any[] = [];

export async function getUsers(usersService: UsersService, userSelected: IUserRegular) {
  await usersService.getUsers(UserSortType.ALPHABETICALLY, page, 10, undefined, UserType.Regular).then((res) => {
    res.content.map((user: IUserRegular) => {
      if (user.status?.toUpperCase() === UserStatus.ACTIVE || user.status?.toUpperCase() === UserStatus.BLOCKED) {
        const extraInfo = user && (user.dni && user.dni || user.phone && user.phone || user.email && user.email);
        users.push(
          {
            id: user.id,
            value: user,
            label:
              user.firstName === null && user.lastName === null ? `${user.email}` : `${user.firstName} ${user.lastName} (${extraInfo})`
          }
        );
      }
    });
  });

  const exists = userSelected && users.find((user: any) => user.id === userSelected.id);
  if (!exists) {
    const extraInfo = userSelected && (userSelected.dni && userSelected.dni || userSelected.phone && userSelected.phone || userSelected.email && userSelected.email);
    userSelected && users.unshift(
      {
        id: userSelected.id,
        value: userSelected,
        label:
          userSelected.firstName === null && userSelected.lastName === null ? `${userSelected.email}` : `${userSelected.firstName} ${userSelected.lastName} (${extraInfo})`
      });
  }
}
export async function getAvailabilities(routeService: RouteService, literalService: LiteralService, booking: IBookingDetails) {
  await routeService.getAvailabilities(booking.routeId, 0, 100).then((res) => {
    res.content.map((availability: IServiceAvailability) => {
      availabilities.push(
        {
          id: availability.id,
          value: availability,
          label: formatDate(availability.dateTime, literalService)
        }
      );
    });
  });
}

export async function getStops(townsService: TownsService, booking: IBookingDetails) {

  const options: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  };
  stops = [];
  await townsService.getStopsFrom(booking.service.town.id!, undefined, false).then((res) => {
      res.map((stop: any) =>{
        stops.push(
          {
            id: stop.id,
            value: stop,
            label: stop.name,
          }
        );
      });
    });
  stops.sort((a, b) => {
    if (a.label < b.label) {
      return -1;
    } else if (a.label > b.label) {
      return 1;
    }
    return 0;
  });
  return stops;
}


export async function searchUser(usersService: UsersService, showMoreUsers: boolean, value: string) {
  await usersService.getUsers(UserSortType.ALPHABETICALLY, pageSearched, 10, value, UserType.Regular).then((res) => {
    usersSearched = [];
    res.content.map((user: IUserRegular) => {
      if (user.status?.toUpperCase() === UserStatus.ACTIVE || user.status?.toUpperCase() === UserStatus.BLOCKED) {
        const extraInfo = user && (user.dni && user.dni || user.phone && user.phone || user.email && user.email);
        usersSearched.push({
          id: user.id,
          value: user,
          label:
            user.firstName === null && user.lastName === null ? `${user.email}` : `${user.firstName} ${user.lastName} (${extraInfo})`
        });
      }
    });
  });
}

export class UserBookingData {
  showMoreUsers: boolean;
  search?: boolean;
  value?: string;

  constructor(data: Partial<UserBookingData>) {
    Object.assign(this, data);
  }
}

export async function getClientsTowns(usersService: UsersService, user: IUser) {
  return await usersService.getTownsByUser(user.id!).then((res: any) => {
    const towns: any[] = [];
    res.map((town: ITown) => {
      towns.push({ id: town.id, value: town.id, label: town.name });
    });
    return { towns: towns };
  });
}

// Function to format the date
function formatDate(dateString: any, literalService: LiteralService): string {
  const [year, month, day, hours, minutes, seconds] = dateString.split(/[-T:]/);
  const date = new Date(Date.UTC(
    parseInt(year),
    parseInt(month) - 1, // Months are 0-based in JavaScript
    parseInt(day),
    parseInt(hours),
    parseInt(minutes),
    parseInt(seconds)
  ));


  const daysOfWeek = ["calendar.weekDays.sun", "calendar.weekDays.mon", "calendar.weekDays.tue", "calendar.weekDays.wed", "calendar.weekDays.thu", "calendar.weekDays.fri", "calendar.weekDays.sat"];
  if (!(date instanceof Date) || isNaN(date.getTime())) {
    throw new TypeError("Invalid date object");
  }
  const dayOfWeek = literalService.get(daysOfWeek[date.getDay()]);
  return `${hours}:${minutes} - ${dayOfWeek} - ${day}/${month}/${year}`;
}

export async function newBookingForm(booking: IBookingCreate = {} as IBookingCreate, usersService: UsersService, literalService: LiteralService, userData?: UserBookingData, serviceInfos?: IServiceInfo[], stops?: unknown[], changes?: any, favouriteOrRebookSingle: boolean = false): Promise<unknown[]> {
  const isD2D = serviceInfos ? isDoor2DoorService(serviceInfos) : false;

  if (userData) {
    if (userData.search) {
      if (userData.showMoreUsers) {
        pageSearched += 1;
      } else {
        users = [];
        page = 0;
        usersSearched = [];
        pageSearched = 0;
      }
      await searchUser(usersService, userData.showMoreUsers, userData.value!);
    } else {
      page += 1;
      await getUsers(usersService, booking.user);
    }
  }

  if (changes && changes.includes('user')) {
    towns = [];
    stopsBooking = [];
  } else if (changes && changes.includes('town')) {
    stopsBooking = [];
    destinations = [];
    booking.originStopId = 0;
    booking.destinationStops = [];
    booking.originStop = null;
    booking.destinationStop = null;
  } else if (changes && changes.includes('stop')) {
    destinations = [];
  } else if (changes && (changes.includes('originStopId') || changes && changes.includes('destinationsStops'))) {
    stopsBooking = [];
    destinations = [];
  }

  // NOTE: TOWNS
  if (!towns.length && booking.targetUserId) {
    await getClientsTowns(usersService, booking.user).then((res) => {
      towns = res.towns;
    });
  }

  const isSingleReservation = serviceInfos && serviceInfos?.some((serviceInfo: IServiceInfo) => serviceInfo.singleStopReservations);
  let singleStopReservationType;
  if (!favouriteOrRebookSingle) {
    if (serviceInfos && serviceInfos?.length > 0 && changes && changes.includes('stop')) {
      singleStopReservationType = SingleStopReservationType.ORIGIN;
    } else if (serviceInfos && serviceInfos?.length > 0 && changes && (changes.includes('destination') || changes.includes(''))) {
      singleStopReservationType = booking.destinationStops.length > 0 && booking.destinationStops[0].exitStop.id !== 0 ? SingleStopReservationType.DESTINATION: SingleStopReservationType.ORIGIN;
    }
  }

  if (isD2D) {
    if (changes) {
      let formattedStops: any[] = [];
      stops?.filter((stop: any) => booking.originStop ? stop.name !== booking.originStop.name : true)
      .map((stop: any) => {
        formattedStops.push({
          id: stop.id,
          value: stop.name,
          label: stop.name
        });
      });
      if (changes.includes('destinationsStops')) {
        destinations = formattedStops;
      } else if (changes.includes('originStopId')) {
        stopsBooking = formattedStops;
      }
    }
    if (booking.originStop) {
      const stopIndex = stopsBooking.findIndex((stop: any) => stop.value === (booking.originStop?.name));
      if (stopIndex !== -1) {
        stopsBooking[stopIndex!].disabled = true;
      } else {
        stopsBooking.push({
          id: null,
          value: booking.originStop.name,
          label: booking.originStop.name,
          disabled: true
        });
      }
    }
    if (booking.destinationStop) {
      const stopIndex = destinations.findIndex((stop: any) => (stop.value === booking.destinationStop?.name));
      if (stopIndex !== -1) {
        destinations[stopIndex!].disabled = true;
      } else {
        destinations.push({
          id: null,
          value: booking.destinationStop.name,
          label: booking.destinationStop.name,
          disabled: true
        });
      }
    }
  } else {
    // NOTE: ORIGINS
    if (!stopsBooking.length) {
      serviceInfos?.map((serviceInfo: IServiceInfo) => {
        serviceInfo.stops.map((stop: any) => {
          const exists = stopsBooking.find((s: any) => s.id === stop.id);
          if (!exists && stop.id < 0) {
            const available = serviceInfo.restrictedOrigins.some((origin: any) => origin === stop.id);
            if (!available) {
              stopsBooking.push({ id: stop.id, value: stop.id, label: stop.name });
            }
          }
        })
        if (isSingleReservation) {
          stops = stopsBooking;
        }
      });
      stopsBooking.sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        } else if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
    }

    // NOTE: DESTINATIONS
    if (!destinations.length || (changes && changes.includes('stop') || isSingleReservation)) {
      destinations = [];
      stops && stops.map((destination: any) => {
        destinations.push({ id: destination.id, value: destination.id, label: destination.name || destination.label });
      });
    } 
  }

  if(serviceInfos && !isD2D) booking.stops = stopsBooking;
  const data: formCreationData[] = [
    {
      img: images.booking.user,
      title: 'bookings.data.user',
      value: booking.user,
      color: false,
      step: 1,
      informativeToNoChange: booking.user ? true : false,
      informativeToNoChangeText: booking.user && booking.user.firstName + ' ' + booking.user.lastName,
      edit: {
        inputType: InputType.INFINITE_SCROLL,
        values: userData && userData.search ? usersSearched : users,
        valueToChange: 'targetUserId',
      },
    },
    {
      img: images.booking.area,
      title: 'bookings.data.town',
      value: booking.townId,
      color: false,
      step: 2,
      edit: {
        inputType: InputType.SELECT_SEARCH,
        values: towns,
        valueToChange: 'town',
      },
    },
    {
      img: images.origin,
      title: 'bookings.data.originStop',
      value: isD2D ? booking.originStop?.name || null : booking.originStopId,
      color: false,
      step: 2,
      placeholder: literalService.get('bookings.data.d2dInstructions', true),
      searchPlaceholder: literalService.get('bookings.data.d2dSearchPlaceholder', true),
      edit: {
        inputType: isD2D ? InputType.AUTOCOMPLETE_SEARCH : InputType.SELECT_SEARCH,
        values: stopsBooking,
        disabled: (isSingleReservation && singleStopReservationType === SingleStopReservationType.DESTINATION) && !favouriteOrRebookSingle,
        valueToChange: 'originStopId'
      },
    },
    {
      img: images.destination,
      title: 'bookings.data.destinationStop',
      value: isD2D ? booking.destinationStop?.name || null : booking.destinationStops && booking.destinationStops[0] && booking.destinationStops[0].exitStop.id,
      color: false,
      step: 2,
      placeholder: literalService.get('bookings.data.d2dInstructions', true),
      searchPlaceholder: literalService.get('bookings.data.d2dSearchPlaceholder', true),
      edit: {
        inputType: isD2D ? InputType.AUTOCOMPLETE_SEARCH : InputType.SELECT_SEARCH,
        values: destinations,
        disabled: ((isSingleReservation && singleStopReservationType === SingleStopReservationType.ORIGIN) && !favouriteOrRebookSingle) || (!isSingleReservation && !booking.originStop && !booking.destinationStop),
        valueToChange: 'destinationsStops'
      },
    },
    {
      value: booking.destinationStops,
      color: false,
      step: 2,
      edit: {
        inputType: InputType.SEATS,
        values: booking.destinationStops,
        valueToChange: 'destinationsStops',
      },
    },
    {
      img: images.booking.date,
      title: 'bookings.data.date',
      value: booking.date,
      color: false,
      step: 2,
      calendar: {
        showOnTop: true,
        onlyFutureDays: true,
        daysInAdvance: booking.serviceInfo?.bookingDaysInAdvance,
        hideCalendar: false,
        showYear: false,
        possibleMultipleDates: true,
      },
      fullData: booking,
      edit: {
        inputType: InputType.DATE,
        valueToChange: 'date',
      },
    },
    {
      img: images.clock,
      title: 'bookings.data.time',
      value: booking.time,
      color: false,
      step: 2,
      edit: {
        inputType: InputType.TIME,
        valueToChange: 'time',
      },
    },
  ];
  return data;
}

export function isDoor2DoorService(serviceInfos: IServiceInfo[]) {
  return serviceInfos.some((serviceInfo: IServiceInfo) => serviceInfo.expeditionType === ExpeditionType.Door2Door);
}

import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { IBase, IClient, ITown } from 'src/app/models';
import { Gender, UserRole, UserStatus, UserType } from 'src/app/models/enums';
import { StorageService, UsersService, UtilsService } from 'src/app/services';
import { LiteralService } from 'src/app/services/literal/literal.service';
import { TooltipPosition } from 'src/app/shared/tooltip/tooltip-position';
import { images } from 'src/images';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrl: './filter.component.scss',
})
export class FilterComponent implements OnInit, OnChanges {
  public clients: unknown[] = [];
  public selectedValuesClients: IBase[] = [];
  public towns: unknown[] = [];
  public selectedValuesTowns: IBase[] = [];
  public userStatus: any[] = [];
  public userTypes: any[] = [];
  public userGender: any[] = [];

  public UserStatus = UserStatus;
  public images = images;
  public TooltipPosition = TooltipPosition;

  public filterSelected = 'all';
  public filters: any[] = [];
  public filtersAdded: any[] = [];
  public showClients: boolean = false;
  public adminRole: UserRole;

  @Output() filter = new EventEmitter();
  @Output() closeFilter = new EventEmitter();

  @Input() componentOpen: string;

  @ViewChild('select') select: ElementRef;

  constructor(
    renderer: Renderer2,
    public literalService: LiteralService,
    public usersService: UsersService,
    public utilsService: UtilsService,
    public storageService: StorageService
  ) {
    (Object.keys(Gender) as Array<keyof typeof Gender>).map(
      (gender: keyof typeof Gender) => {
        this.userGender.push({ value: gender, checked: false });
      },
    );
    this.filters.push({
      key: 'all',
      value: this.literalService.get('all', true),
    });
    this.filters.push({
      key: 'firstName',
      value: this.literalService.get('users.data.firstName', true),
    });
    this.filters.push({
      key: 'lastName',
      value: this.literalService.get('users.data.lastName', true),
    });
    this.filters.push({
      key: 'email',
      value: this.literalService.get('users.data.email', true),
    });
    this.filters.push({
      key: 'phoneNumber',
      value: this.literalService.get('users.data.phoneNumber', true),
    });
    this.filters.push({
      key: 'dni',
      value: this.literalService.get('users.data.dni', true),
    });

    renderer.listen('window', 'click', (e: Event) => {
      const modal = document.getElementById('filterContainer')!;
      const componentOpen = document.getElementById(this.componentOpen)!;
      const close = utilsService.closeCustomModals(e, modal, componentOpen);
      if (close) {
        this.closeFilter.emit();
      }
    });
  }

  async ngOnInit() {
    this.adminRole = await this.storageService.getRole();
    (Object.keys(UserStatus) as Array<keyof typeof UserStatus>).map(
      (userStatus: keyof typeof UserStatus) => {
        if (this.adminRole == 'SUPERADMIN' || userStatus != UserStatus.DELETED) {
          this.userStatus.push({ value: userStatus, checked: false });
        }
      },
    );
    if (this.adminRole !== 'DRIVER_MANAGER') this.userTypes.push({ value: UserType.Regular, checked: false });
    if (this.adminRole !== 'OPERATOR') this.userTypes.push({ value: UserType.Driver, checked: false });

    const { clients, towns } = await this.usersService.getPossibleClientTowns();
    clients.map((client: IClient) => {
      this.clients.push({
        id: client.id,
        code: client.name,
        name: client.name,
      });
    });
    towns.map((town: ITown) => {
      this.towns.push({ id: town.id, code: town.name, name: town.name });
    });
    this.showClients = clients.length > 1;
  }

  ngOnChanges(): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    this.filters = [];
    this.filters.push({
      key: 'all',
      value: this.literalService.get('all', true),
    });
    this.filters.push({
      key: 'firstName',
      value: this.literalService.get('users.data.firstName', true),
    });
    this.filters.push({
      key: 'lastName',
      value: this.literalService.get('users.data.lastName', true),
    });
    this.filters.push({
      key: 'email',
      value: this.literalService.get('users.data.email', true),
    });
    this.filters.push({
      key: 'phoneNumber',
      value: this.literalService.get('users.data.phoneNumber', true),
    });
    this.filters.push({
      key: 'dni',
      value: this.literalService.get('users.data.dni', true),
    });
  }

  addFilter = (data: string) => {
    console.log('addFilter');
    data.trim() !== '' &&
      this.filtersAdded.push({ key: this.filterSelected, value: data });
  };

  changeFilter = (event: any) => {
    this.filterSelected = event.target.value;
  };

  getImage = (val: string, imageFrom: string) => {
    const indexImage =
      imageFrom === 'status'
        ? Object.keys(images.user).findIndex((key: string) => {
            return (
              key === 'user' + this.utilsService.capitalize(val.toLowerCase())
            );
          })
        : Object.keys(images.types).findIndex((key: string) => {
            return key === val.toLowerCase();
          });
    const image = Object.values(
      imageFrom === 'status' ? images.user : images.types,
    ).find((value, index) => {
      return index === indexImage ? value : null;
    });
    return image;
  };

  selectAll = (event: any) => {
    this.userStatus.map(
      (status: any) => (status.checked = event.currentTarget.checked),
    );
    this.userTypes.map(
      (type: any) => (type.checked = event.currentTarget.checked),
    );
    if (event.currentTarget.checked) {
      this.clients.map((client: any) =>
        this.selectedValuesClients.push({ id: client.id, name: client.code }),
      );
      this.towns.map((town: any) =>
        this.selectedValuesTowns.push({ id: town.id, name: town.code }),
      );
    } else {
      this.selectedValuesClients = [];
      this.selectedValuesTowns = [];
      this.filtersAdded = [];
    }
  };

  clearSelection = () => {
    this.userStatus.map((status: any) => (status.checked = false));
    this.userTypes.map((type: any) => (type.checked = false));
    this.selectedValuesClients = [];
    this.selectedValuesTowns = [];
    this.filtersAdded = [];
    this.select.nativeElement.checked = false;
  };

  onSubmit = async () => {

    const selectedClients: number[] = [];
    console.log(this.selectedValuesClients);
    this.selectedValuesClients.forEach((client: IBase) => {
      selectedClients.push(client.id!);
    });

    const selectedTowns: number[] = [];
    console.log(this.selectedValuesClients);
    this.selectedValuesTowns.forEach((town: IBase) => {
      selectedTowns.push(town.id!);
    });
        
    const selectedTypes: string[] = [];
    this.userTypes.forEach((type: any) => {
      type.checked && selectedTypes.push(this.utilsService.capitalize(type.value.toLowerCase()));
    });

    const selectedStatus: string[] = [];
    this.userStatus.forEach((status: any) => {
      status.checked && selectedStatus.push(this.utilsService.capitalize(status.value.toLowerCase()));
    });

    this.filter.emit({selectedClients, selectedTowns, selectedTypes, selectedStatus});
  };
}

import { IUserRegular } from 'src/app/models';
import { Gender, UserRole, UserStatus, UserType } from 'src/app/models/enums';
import { UtilsService } from 'src/app/services';

const utilsService = new UtilsService();

export function initialUserRegularState(language: string): IUserRegular {
  return {
    name: '',
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    language: language,
    phone: '',
    additionalPhone: '',
    clients: [],
    town: undefined,
    role: UserRole.USER,
    status: utilsService.capitalize(UserStatus.ACTIVE.toLowerCase()),
    type: UserType.Regular,
    dni: '',
    sms: false,
    push: true,
    sendEmail: false,
    deviceType: undefined,
    deviceToken: '',
    settlement: '',
    policyAccepted: false,
    birthDate: undefined,
    gender: Gender.PREFER_NOT_TO_SAY,
    prm: false,
    penaltyEndDate: undefined, 
  };
}

import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { InputType } from 'src/app/models/enums';
import { LiteralService } from 'src/app/services';

@Component({
    selector: 'app-select-with-enum',
    templateUrl: './select-with-enum.component.html',
    styleUrl: './select-with-enum.component.scss',
    standalone: false
})
export class SelectWithEnumComponent implements OnChanges {

  @Input() id?: any;
  @Input() type: InputType;
  @Input() value: any;
  @Input() values: any[];
  @Input() translateValue?: boolean = false;
  @Output() changeEventEmitter = new EventEmitter<unknown>();

  public itemSelected = '';

  constructor(public literalService: LiteralService, public componentElement: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.value) {
      const test = this.componentElement.nativeElement;
      const selectElement = test.children[0];
      selectElement.focus();
    }
  }

}

<div [id]="id()" tabindex="-1" class="absolute top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
    <div class="relative w-[20%] max-h-full rounded-lg">
        <div class="relative primary-purple-light-2-background rounded-lg shadow dark:bg-gray-700 p-6">
            @if (!loading()) {
                <form novalidate #form (ngSubmit)="onSubmit()">
                    <section id="header">
                        <div class="flex items-center">
                            <h3 class="w-full text-xl font-bold primary-purple-text dark:text-gray-400">{{ literalService.get('services.modal.exportGTFS.title', true) }}</h3>
                            <button (click)="modal()!.hide()" type="button" class="right-6 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                                <img [src]="images.close" class="h-6 w-6" />
                            </button>
                        </div>
                    </section>
                    <section id="body" class="mt-6">
                        <label class="text-base font-semibold primary-purple-text">{{ literalService.get('services.modal.exportGTFS.text', true) }}</label>
                        <section class="flex justify-between mt-8">
                            <article class="w-full">
                                <div class="flex items-center mb-[6px]">
                                    <img [src]="images.services.client" alt="service" class="h-6 w-6 mr-[6px]" />
                                    <label class="font-normal text-base">{{ literalService.get('services.modal.service', true) }}</label>
                                </div>
                                <select2 class="block" [placeholder]="literalService.get('services.modal.selectService', true)" [data]="allServiceInfo" [value]="serviceIdSelected" (update)="changeService($event)" />
                            </article>
                        </section>
                    </section>
                    <section id="footer" class="flex mt-10">
                        <input [attr.data-modal-hide]="id()" type="button" class="secondary mt-2 gap-4 border-2 font-semibold p-2 w-full uppercase cursor-pointer items-center bg-white mr-1" (click)="modal()!.hide()" [value]="literalService.get('cancel', true)"/>
                        <input [attr.data-modal-hide]="id()" type="submit" [disabled]="!serviceIdSelected" class="primary mt-2 gap-4 border-2 font-semibold p-2 w-full uppercase ml-1 cursor-pointer" [value]="literalService.get('export', true)"/>
                    </section>
                </form>
            } @else {
                <article class="flex w-full pb-6 pt-4 pl-7 pr-7 justify-center rounded-es-lg rounded-ee-lg">
                    <ng-lottie class="w-52" [options]="exporting" (animationCreated)="utilsService.animationCreated($event, 1)" />
                </article>
                <label class="flex justify-center items-center text-lg font-bold">{{ literalService.get('services.modal.exportGTFS.action', true) }}</label>
            }
        </div>
    </div>
</div>
<div class="flex">
  <section class="w-[30rem]" [ngClass]="{'h-auto max-h-[calc(100vh-80px)]': loadingAvailabilities}">
    <article id="header" class="flex primary-green-2-background rounded-ss-lg rounded-se-lg">
      <div class="mt-4 ml-8 mb-4 w-full">
        @if (currentStep === 1) {
          <h1 class="font-bold text-[22px] primary-purple-text">{{ literalService.get('bookings.newBooking', true) }}</h1>
          <label class="font-semibold text-lg">{{ literalService.get('bookings.information', true) }}</label>
        } @else if (currentStep === 2) {
          <div class="flex items-center">
            <img [src]="images.arrowLeft" alt="back" class="h-6 w-6 cursor-pointer" (click)="back()" />
            <div class="ml-5">
              <h1 class="font-bold text-[22px] primary-purple-text">{{ literalService.get('bookings.newBooking', true) }}</h1>
              <label class="font-semibold text-lg">{{ literalService.get('bookings.information', true) }}</label>
            </div>
          </div>
        } @else if (currentStep === 3) {
          <div class="flex items-center">
            <img [src]="images.arrowLeft" alt="back" class="h-6 w-6 cursor-pointer" (click)="back()" />
            <div class="ml-5">
              <h1 class="font-bold text-[22px] primary-purple-text">{{ literalService.get((newBooking.multipleDates ? 'bookings.newBookings' : 'bookings.newBooking'), true) }}</h1>
              <label class="font-semibold text-lg">{{ literalService.get('bookings.availabilities', true) }}</label>
            </div>
          </div>
        }
      </div>
      <div class="mt-4 mr-5 justify-end">
        <div class="flex main rounded-[50%] h-14 w-14 justify-center items-center">
          <div class="quarter third1" [ngClass]="{ 'block': steps.includes(1), 'hidden': !steps.includes(1) }"></div>
          <div class="quarter third2" [ngClass]="{ 'block': steps.includes(2), 'hidden': !steps.includes(2) }"></div>
          <div class="quarter third3" [ngClass]="{ 'block': steps.includes(3), 'hidden': !steps.includes(3) }"></div>
          <label class="flex font-bold text-[22px] primary-purple-text cutout justify-center items-center">
            {{ currentStep }}/{{ numberSteps }}
          </label>
        </div>
      </div>
    </article>
    @if (currentStep === 3 && !loadingAvailabilities && (availabilities.failure.length > 0 || availabilities.success.length > 0)) {
      <article class="flex items-center primary-purple-light-2-background p-2 pl-8">
        <div class="flex items-center">
          <img [src]="images.booking.date" class="h-6 w-6 mr-2" />
          <label class="font-normal text-base mr-6">{{ getDate() }}</label>
          <img [src]="images.pax" class="h-6 w-6 mr-1" />
          <label class="font-normal text-base mr-2">{{ pax }}</label>
          <img [src]="images.prm" class="h-6 w-6 mr-1" />
          <label class="font-normal text-base">{{ prm }}</label>
        </div>
        @if (newBooking.multipleDates) {
          <div class="flex absolute items-center right-0" [ngClass]="{'pr-8': !hasAlterations, 'pr-24': hasAlterations}">
            <div class="cursor-pointer w-5 h-5 rounded neutrals-grey-02-background" (click)="!previousAvailabilityDisabled() && previousAvailability()">
              <img [src]="images.arrow" alt="previousAvailability" class="h-full w-full rounded rotate-180 p-1" [ngClass]="{'opacity-40 disabled': previousAvailabilityDisabled()}" />
            </div>
            <div class="mx-1">
              <label class="font-normal text-base">{{ currentPage + 1 }}/{{ totalElements }}</label>
            </div>
            <div class="cursor-pointer w-5 h-5 rounded neutrals-grey-02-background" (click)="!nextAvailabilityDisabled() && nextAvailability()">
              <img [src]="images.arrow" alt="nextAvailability" class="h-full w-full rounded p-1" [ngClass]="{'opacity-40 disabled': nextAvailabilityDisabled()}" />
            </div>
          </div>
        }
      </article>
    }
    @if (currentStep === 1 || currentStep === 2) {
      <article id="body" class="bg-white pb-6 pt-1 pl-7 pr-7">
        @for (element of bookingForm; track $index) {
          <div class="relative mt-4" [ngClass]="{'hidden': !steps.includes(element.step) }">
            <app-input  [data]="element"
                        [edit]="true"
                        [independent]="true"
                        (setEventEmitter)="set($event.value, element.edit?.valueToChange)"
                        (scrollEventEmitter)="scroll($event)"
                        (searchEventEmitter)="search($event, element.edit?.valueToChange)"
                        (changeEventEmitter)="change($event, element.edit?.valueToChange)"
                        (onFocusEventEmitter)="focusElement(element.edit?.valueToChange)" />
            @if (element.title === 'bookings.data.originStop') {
              <button class="absolute flex justify-center items-center rounded-full neutrals-grey-01-background h-7 w-7 right-0 mt-2 z-[1]" [ngClass]="{'hidden': !possibleSwap}" (click)="changeStops()">
                <img [src]="images.booking.changeStops" class="h-6 w-6 rotate-90 p-[2px]" />
              </button>
            }
            @if (element.title === 'bookings.data.originStop' && stopOriginNotification) {
              <app-input-notifications class="absolute" [ngClass]="{ 'fadeIn': stopOriginNotification, 'fadeOut': !stopOriginNotification }" [title]="element.title+'IncompatibleTitle'" [text]="element.title+'IncompatibleText'"></app-input-notifications>
            }
            @if (element.title === 'bookings.data.destinationStop' && stopDestinationNotification) {
              <app-input-notifications class="absolute" [ngClass]="{ 'fadeIn': stopDestinationNotification, 'fadeOut': !stopDestinationNotification }" [title]="element.title+'IncompatibleTitle'" [text]="element.title+'IncompatibleText'"></app-input-notifications>
            }
            @if (element.informativeToNoChange) {
              <div class="inline-flex p-1 cursor-pointer neutrals-grey-01-background rounded justify-end items-center gap-1" (click)="openFavourite.emit(newBooking.user.id)">
                  <img [src]="images.user.star" class="w-6 h-6 mr-1 cursor-pointer" />
                  <div class="neutrals-grey-06-text text-base font-normal">{{ literalService.get('favourites.routes', true)}}</div>
              </div>
            }
            @if (isD2DBooking && element.title === 'bookings.data.originStop') {
              <button class="absolute flex justify-center items-center rounded-full h-7 w-7 right-2 bottom-2" (click)="onEditCustomStop('origin')" [ngClass]="{'primary-purple-light-background': !newBooking.originStop, 'neutrals-grey-01-background': newBooking.originStop}">
                <img [src]="images.booking.stops" class="h-6 w-6 p-[2px]" [ngClass]="{'white-icon': !newBooking.originStop}" />
              </button>
            }
            @if (isD2DBooking && element.title === 'bookings.data.destinationStop') {
              <button class="absolute flex justify-center items-center rounded-full h-7 w-7 right-2 bottom-2" (click)="onEditCustomStop('destination')" [ngClass]="{'primary-green-background': newBooking.originStop && !newBooking.destinationStop, 'neutrals-grey-01-background': !newBooking.originStop || newBooking.destinationStop}">
                <img [src]="images.booking.stops" class="h-6 w-6 p-[2px]" />
              </button>
            }
          </div>
        }
      </article>
      <article id="footer" class="flex bg-white pb-6 justify-center rounded-es-lg rounded-ee-lg pt-1 pl-7 pr-7" [ngClass]="{'hidden': !newBooking.targetUserId}">
        <input type="button" class="primary text-white gap-4 border-2 font-semibold h-14 w-3/4  uppercase cursor-pointer" [disabled]="disabledViewAvailabilities()" (click)="getAvailabilities()" [value]="literalService.get('bookings.viewAvailabilities', true)" />
      </article>
    } @else if (currentStep === 3) {
      @if (loadingAvailabilities) {
        <article class="flex bg-white w-full pb-6 pt-4 pl-7 pr-7 justify-center rounded-es-lg rounded-ee-lg">
            <ng-lottie [options]="optionsPin" (animationCreated)="utilsService.animationCreated($event, 1.5)" />
        </article>
      } @else if (creatingBooking) {
        <article class="flex bg-white w-full pb-6 pt-4 pl-7 pr-7 justify-center rounded-es-lg rounded-ee-lg">
          <ng-lottie [options]="optionsBus" (animationCreated)="utilsService.animationCreated($event, 1.5)" />
        </article>
      } @else {
        <article id="body" class="bg-white pb-6 pt-1 pl-7 pr-7 rounded-es-lg rounded-ee-lg">
          <app-booking-availabilities [availabilities]="availabilitiesSeparateByDate" [showedDate]="getDate()" [newBooking]="newBooking" [serviceInfos]="serviceInfos" [loadingAvailabilities]="loadingAvailabilities" [availabilitiesSelected]="availabilitiesSelected" [allAvailabilitiesSelected]="checkAllAvailabilitiesSelected()" (nextAvailability)="nextAvailability($event)" (book)="createBookings($event)" (bookMultiple)="createBookings($event)" (checkAllAvailabilitiesSelected)="checkAllAvailabilitiesSelected()" (showMaxReservationTimeModalEventEmitter)="showMaxReservationTimeModal()" (showExceedingKmLimitModalEventEmitter)="showExceedingKmLimitModal()"></app-booking-availabilities>
        </article>
      }
    }
  </section>
  @if (hasAlterations) {
    <img [src]="images.alterationWarning" class="h-10 w-10 m-5 cursor-pointer" alt="alterationWarning" (click)="showAlterationModal.emit()" />
  }
</div>

<div [id]="id" tabindex="-1" class="absolute top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
    <div class="relative w-96 max-h-full rounded-lg primary-purple-light-2-background">
        <div class="relative rounded-lg shadow dark:bg-gray-700">
            @if (creating) {
                <div class="p-6">
                    <article class="flex w-full ml-10 justify-center items-center">
                        <ng-lottie width="75%" height="75%" [options]="optionsBus" (animationCreated)="utilsService.animationCreated($event, 1)" />
                    </article>
                    <div class="flex items-center justify-center">
                        <h3 class="text-xl font-bold mb-4 -mt-4 text-gray-500 dark:text-gray-400 primary-purple-light-text">{{ literalService.get('bookings.actions.createFavourite.creating', true)}}</h3>
                    </div>
                </div>
            } @else {
                <form #form novalidate (ngSubmit)="onSubmit()">
                    <div class="items-center px-6 pt-6 mb-5">
                        <h3 class="w-full text-lg font-bold primary-purple-text">{{ literalService.get(title, true) }}</h3>
                        <p class="w-full whitespace-pre-line text-base font-normal primary-purple-text">{{ literalService.get(text, true) }}</p>
                    </div>
                    <div class="px-6 mb-5">
                        <div class="flex items-center">
                            <img [src]="images.user.star" alt="star" class="h-6 w-6 mb-2 mr-2" />
                            <label class="font-normal text-base">{{ literalService.get('bookings.actions.createFavourite.titleInput', true) }}</label>
                        </div>
                        <input type="text" [(ngModel)]="favouriteName" (change)="change(favouriteName)" [ngModelOptions]="{standalone: true}" [placeholder]="literalService.get('bookings.actions.createFavourite.placeholderInput', true)" class="block w-full px-3 py-2 bg-white border rounded-s rounded-e text-base font-normal placeholder-slate-400 focus:outline-none" />
                    </div>
                    <div class="flex px-6 pb-6">
                        <button type="button" class="secondary mt-2 mr-2 gap-4 border-2 font-semibold h-11 w-full uppercase cursor-pointer bg-white" (click)="modal.hide()">{{ literalService.get('cancel', true) }}</button>
                        <button type="submit" class="primary mt-2 gap-4 border-2 font-semibold h-11 w-full uppercase cursor-pointer text-white">{{ literalService.get('confirm', true) }}</button>
                    </div>
                </form>
            }
        </div>
    </div>
</div>
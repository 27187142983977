import { Component, EventEmitter, OnInit, Output, input, output } from '@angular/core';
import { LiteralService, RouteService } from 'src/app/services';
import { initialServicesState } from 'src/app/store/initialisation/services';
import { IServicesState } from 'src/app/store/states';
import { images } from 'src/images';

@Component({
    selector: 'app-service-routes-modal',
    templateUrl: './service-routes-modal.component.html',
    styleUrl: './service-routes-modal.component.scss',
    standalone: false
})
export class ServiceRoutesModalComponent implements OnInit {

  readonly routes = input<IServicesState>();
  readonly selectRoute = output<any>();
  readonly close = output();
  readonly createEventEmitter = output();

  public images = images;
  public searching = false;

  constructor(public literalService: LiteralService, private routeService: RouteService) {}

  ngOnInit(): void {
    const routes = this.routes();
    
  }

  public async search(event: any) {
    // this.searching = true;
    // this.services = initialServicesState;
    // this.services.value = [];
    // // this.searchValue = event;
    // this.services.pagination.actualPage = 0;
    // await this.getServices();
    // this.searching = false;
  }

  async changePageRoutes(event: { isNext: boolean, specificPage?: number }) {
    // this.searching = true;
    // this.serviceInfos.set(initialServiceInfoDetailsState);
    // const serviceInfos = this.serviceInfos();
    // serviceInfos.value = [];
    // if (!event.specificPage) {
    //   serviceInfos.pagination.actualPage = event.isNext
    //     ? serviceInfos.pagination.actualPage + 1
    //     : serviceInfos.pagination.actualPage - 1;
    // } else {
    //   serviceInfos.pagination.actualPage = event.specificPage;
    // }
    // await this.getServiceInfos();
    // this.searching = false;
  }

  createNewRoute() {
    this.createEventEmitter.emit();
  }

}

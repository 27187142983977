import { Injectable } from '@angular/core';
import { StorageService, TripsService } from '../services';
import { ITrip, IBase } from '../models';
import { objectToCamel } from 'ts-case-convert';
import { initialPaginationState } from '../store/initialisation/pagination';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { initialTripsState } from '../store/initialisation/trips';
import { ExpeditionStatus, ExpeditionType } from '../models/enums';

@Injectable({
  providedIn: 'root',
})
export class TripsResolver {
  constructor(
    private tripsService: TripsService,
    public storageService: StorageService,
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async resolve(route: ActivatedRouteSnapshot, state?: RouterStateSnapshot) {
    const page = route.data['trips']?.pagination.actualPage || 1;
    const size = route.data['trips']?.pagination.number_of_elements || 8;
    let towns: IBase[] = [];
    this.storageService.getArea().then((areas: string) => {
      if (areas) towns = JSON.parse(areas);
      return this.resolveWithTowns(size, page, towns);
    });
  }

  async resolveWithTowns(size: number, page: number, towns: IBase[]) {
    const {
      content,
      number_of_elements,
      total_elements,
      total_pages,
      first,
      last,
    } = await this.tripsService.getTripsFiltered(size, page, [], [], towns, [], []);
    return this.getData(
      content,
      number_of_elements,
      total_elements,
      total_pages,
      first,
      last,
      page,
    );
  }

  async getTrips(
    route: ActivatedRouteSnapshot,
    expeditionClients: IBase[],
    expeditionOperators: IBase[],
    expeditionTowns: IBase[],
    expeditionTypes: ExpeditionType[],
    expeditionStatus: ExpeditionStatus[],
    searchValue?: string
  ) {
    const page = route.data['trips']?.pagination.actualPage || 1;
    const size = route.data['trips']?.pagination.number_of_elements || 8;
    const {
      content,
      number_of_elements,
      total_elements,
      total_pages,
      first,
      last,
    } = await this.tripsService.getTripsFiltered(size, page, expeditionClients, expeditionOperators, expeditionTowns, expeditionTypes, expeditionStatus, searchValue);
    return this.getData(
      content,
      number_of_elements,
      total_elements,
      total_pages,
      first,
      last,
      page,
    );
  }

  getData = (
    content: any,
    number_of_elements: any,
    total_elements: any,
    total_pages: any,
    first: any,
    last: any,
    page: any,
  ) => {
    const data = initialTripsState;
    data.value = objectToCamel(content) as Array<ITrip>;
    data.isFetchingTrips = false;
    data.pagination = initialPaginationState;
    data.pagination.numberOfElements = number_of_elements;
    data.pagination.totalPages = total_pages;
    data.pagination.totalElements = total_elements;
    data.pagination.actualPage = page;
    data.pagination.first = first;
    data.pagination.last = last;
    return data;
  };
}

<section>
    <article class="flex justify-center items-center">
        <img [src]="images.pax" alt="pax" class="h-6 w-6">
        <label class="ml-2">{{ booking.seats }}</label>
    </article>
    <article class="flex justify-center items-center">
        <img [src]="images.prm" alt="prm" class="h-6 w-6">
        <label class="ml-2">{{ booking.prmSeats }}</label>
    </article>
</section>

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Modal } from 'flowbite';
import { InputType } from 'src/app/models/enums';
import { LiteralService } from 'src/app/services/literal/literal.service';
import { images } from 'src/images';

@Component({
    selector: 'app-modal-change-vehicle',
    templateUrl: './modal-change-vehicle.component.html',
    styleUrl: './modal-change-vehicle.component.scss',
    standalone: false
})
export class ModalChangeVehicleComponent {
  @Input() id: string;
  @Input() modal: Modal;
  @Input() values?: any[];
  @Input() valueSelected?: any;
  @Output() action = new EventEmitter<any>();

  public InputType = InputType;
  public images = images;

  public buttonDisabled = false;

  public changeVehicleFormControl: FormGroup;
  public hasErrors: boolean = false;
  public error: string = '';

  constructor(public literalService: LiteralService) {}

  change = (event: any) => {
    this.valueSelected = event.target.value;
  };
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService as AuthGuard } from './services';
import {
  SignInComponent,
  ClientsComponent,
  IdentityProviderComponent,
  AppsComponent,
  UsersComponent,
  OperatorsComponent,
  VehiclesComponent,
  TownsComponent,
  ServicesComponent,
  BookingsComponent,
  MessageUsersComponent,
  DashboardComponent,
} from './pages';
import { UserRole } from './models/enums/userRole';
import { TripsComponent } from './pages/trips/trips.component';
import { BookingsResolver, TripsResolver, UsersResolver } from './resolvers';

const routes: Routes = [
  { path: '', component: SignInComponent },
  {
    path: 'clients',
    component: ClientsComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [UserRole.SUPERADMIN],
    },
  },
  {
    path: 'idp',
    component: IdentityProviderComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [UserRole.SUPERADMIN],
    },
  },
  {
    path: 'apps',
    component: AppsComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [UserRole.SUPERADMIN],
    },
  },
  {
    path: 'users',
    component: UsersComponent,
    canActivate: [ AuthGuard ],
    data: {
      roles: [
        UserRole.SUPERADMIN,
        UserRole.ADMIN,
        UserRole.OPERATOR,
        UserRole.DRIVER_MANAGER,
      ],
    },
    resolve: { users: UsersResolver },
  },
  {
    path: 'users/:id',
    component: UsersComponent,
    canActivate: [ AuthGuard ],
    data: {
      roles: [
        UserRole.SUPERADMIN,
        UserRole.ADMIN,
        UserRole.OPERATOR,
        UserRole.DRIVER_MANAGER,
      ],
    },
    resolve: { users: UsersResolver },
  },
  {
    path: 'users/:type/:id',
    component: UsersComponent,
    canActivate: [ AuthGuard ],
    data: {
      roles: [
        UserRole.SUPERADMIN,
        UserRole.ADMIN,
        UserRole.OPERATOR,
        UserRole.DRIVER_MANAGER,
      ],
    },
    resolve: { users: UsersResolver },
  },
  {
    path: 'operators',
    component: OperatorsComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [UserRole.SUPERADMIN],
    },
  },
  {
    path: 'vehicles',
    component: VehiclesComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.DRIVER_MANAGER],
    },
  },
  {
    path: 'towns',
    component: TownsComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [UserRole.SUPERADMIN, UserRole.ADMIN],
    },
  },
  {
    path: 'services',
    component: ServicesComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [UserRole.SUPERADMIN, UserRole.ADMIN],
    },
  },
  {
    path: 'trips',
    component: TripsComponent,
    canActivate: [ AuthGuard ],
    data: {
      roles: [
        UserRole.SUPERADMIN,
        UserRole.ADMIN,
        UserRole.OPERATOR,
        UserRole.DRIVER_MANAGER,
      ],
    },
    resolve: { trips: TripsResolver },
  },
  {
    path: 'trips/:id',
    component: TripsComponent,
    canActivate: [ AuthGuard ],
    data: {
      roles: [
        UserRole.SUPERADMIN,
        UserRole.ADMIN,
        UserRole.OPERATOR,
        UserRole.DRIVER_MANAGER,
      ],
    },
    resolve: { trips: TripsResolver },
  },
  {
    path: 'bookings',
    component: BookingsComponent,
    canActivate: [ AuthGuard ],
    data: {
      roles: [
        UserRole.SUPERADMIN,
        UserRole.ADMIN,
        UserRole.OPERATOR,
        UserRole.DRIVER_MANAGER,
      ],
    },
    resolve: { bookings: BookingsResolver },
  },
  {
    path: 'bookings/new',
    component: BookingsComponent,
    canActivate: [ AuthGuard ],
    data: {
      new: true,
      roles: [
        UserRole.SUPERADMIN,
        UserRole.ADMIN,
        UserRole.OPERATOR,
        UserRole.DRIVER_MANAGER,
      ],
    },
    resolve: { bookings: BookingsResolver },
  },
  {
    path: 'bookings/:id',
    component: BookingsComponent,
    canActivate: [ AuthGuard ],
    data: {
      roles: [
        UserRole.SUPERADMIN,
        UserRole.ADMIN,
        UserRole.OPERATOR,
        UserRole.DRIVER_MANAGER,
      ],
    },
    resolve: { bookings: BookingsResolver },
  },
  {
    path: 'messages',
    component: MessageUsersComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [UserRole.SUPERADMIN, UserRole.ADMIN],
    },
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [UserRole.SUPERADMIN, UserRole.ADMIN],
    },
  },
  { path: '**', redirectTo: 'users', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}

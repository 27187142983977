<div [id]="id" tabindex="-1" class="modal absolute top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
    <div class="relative w-96 max-h-full rounded-lg">
        <div class="relative primary-purple-light-2-background rounded-lg shadow dark:bg-gray-700 p-6">
            <div class="justify-center items-center primary-purple-text object-contain flex flex-col">
                <img
                    [src]="icon"
                    alt="icon"
                    *ngIf="icon"
                    class="h-16 w-16 img-center object-contain filterPurple"
                />
                <h3 class="w-full text-base text-center font-bold mb-5">{{ literalService.get(title, true) }}</h3>
                <p class="w-full whitespace-pre-line text-base text-center mb-5">{{ literalService.get(text, true) }}</p>
            </div>
            <div class="flex justify-center items-center mt-4" [ngClass]="{'flex-col': !sameLineButtons}">
                <button type="button" class="secondary mt-2 p-2 gap-4 border-2 font-semibold w-full uppercase cursor-pointer bg-white" [ngClass]="{'mr-5':sameLineButtons}" (click)="modal.hide()">{{ literalService.get('cancel', true) }}</button>
                <button type="button" class="primary mt-2 p-2 gap-4 border-2 font-semibold w-full uppercase cursor-pointer text-white" (click)="action.emit(true)">{{ literalService.get('confirm', true) }}</button>
            </div>
        </div>
    </div>
</div>
import { Injectable } from '@angular/core';
import { UtilsService } from '../utils/utils.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class LiteralService {
  constructor(
    private utilService: UtilsService,
    private translateService: TranslateService,
  ) {}

  public get(key: string, capitalized: boolean = false): string {
    const res = this.translateService.instant(key);
    return capitalized ? this.utilService.capitalize(res) : res;
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { images } from 'src/images';

@Component({
  selector: 'app-input-search',
  templateUrl: './input-search.component.html',
  styleUrls: ['./input-search.component.scss'],
})
export class InputSearchComponent {
  @Input() placeholder = '';
  @Input() width = 'auto';
  @Output() submitEventEmitter = new EventEmitter<string>();

  public images = images;

  handleEnter = (event: any) => {
    this.submitEventEmitter.emit(event.target.value);
  };
}

import { Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class JWTTokenService {
  jwtToken: string;
  decodedToken: { [key: string]: string };

  constructor() {}

  setToken(jwtToken: any) {
    if (jwtToken) {
      this.jwtToken = jwtToken;
    }
  }

  decodeToken() {
    if (this.jwtToken) {
      this.decodedToken = jwtDecode(this.jwtToken);
    }
  }

  getDecodeToken() {
    return jwtDecode(this.jwtToken);
  }

  getUserFirstName() {
    this.decodeToken();
    return this.decodedToken ? this.decodedToken['user_first_name'] : null;
  }

  getRole() {
    this.decodeToken();
    return this.decodedToken ? this.decodedToken['role'] : null;
  }

  getTownName() {
    this.decodeToken();
    return this.decodedToken ? this.decodedToken['town_name'] : null;
  }

  getId() {
    this.decodeToken();
    return this.decodedToken ? this.decodedToken['id'] : null;
  }

  getUsername() {
    this.decodeToken();
    return this.decodedToken ? this.decodedToken['username'] : null;
  }

  getExpiryTime(): number {
    this.decodeToken();
    return this.decodedToken ? Number(this.decodedToken['exp']) : 0;
  }

  isTokenExpired(): boolean {
    const expiryTime: number = this.getExpiryTime();
    if (expiryTime) {
      return 1000 * expiryTime - new Date().getTime() < 5000;
    }
    return false;
  }
}

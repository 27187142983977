export enum Colors {
  NONE = "",
  TRANSPARENT = "#FFFFFFFF",
  BLUE = "#6083FF",
  ORANGE_1 = "#ED8936",
  ORANGE_2 = "#E77230",
  PINK = "#D8359C",
  PURPLE = "#280A54",
  PURPLE_LIGHT = "#8136DB",
  PRIMARY_GREEN = "#0DEDAB",
  SECONDARY_GREEN = "#06CB60",
  RED = "#E42313",
  YELLOW = "#F5C600",
  GREY = "#232530",
}

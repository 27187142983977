<div id="mapContainer" class="pt-20 pl-20 h-screen">
    <app-trip-modal [trips]="trips"
                    [showTripDetails]="!!this.selectedTrip" 
                    [selectedTrip]="this.selectedTrip!" 
                    [tripDetails]="tripDetails!" 
                    [percentages]="percentages" 
                    (getTripEventEmitter)="getTrip($event)" 
                    (clearMap)="clearMap()" 
                    (showChangeDriverEventEmitter)="showChangeDriver($event)"
                    (showChangeVehicleEventEmitter)="showChangeVehicle($event)"
                    (showViewBookingsEventEmitter)="showViewBookings($event)"
                    (showViewPickUpsEventEmitter)="showViewPickUps($event)"
                    (showViewDropOffsEventEmitter)="showViewDropOffs($event)"
                    (showViewChangeOversEventEmitter)="showViewChangeOvers($event)"
                    (showAddPaxEventEmitter)="showAddPax($event)"
                    (showSetReadyEventEmitter)="showSetReady($event)"
                    (showSetClosedEventEmitter)="showSetClosed($event)"
                    (showDeleteTripEventEmitter)="showDeleteTrip($event)"
                    (refreshTripsEventEmitter)="refreshTrips($event)"/>
    @if (tripDetails) {
        <app-marker-od id="origin" [textColor]="'text-white'" [color]="'primary-purple-light-background'" [text]="literalService.get('origin').toUpperCase()" ></app-marker-od>
        <app-marker-od id="destination" [textColor]="'text-black'" [color]="'primary-green-background'" [text]="literalService.get('destination').toUpperCase()" ></app-marker-od>
        <ng-container *ngFor="let stop of tripDetails.stops.slice(0, tripDetails.stops.length - 2); let index = index; trackBy: trackByFn">
            <app-marker-stop [id]="'stop' + index" [color]="getColor()"></app-marker-stop>
        </ng-container>
    }
    <app-trip-change-area (changeEventEmitter)="centerToTheTown($event)"></app-trip-change-area>
    <div id="map" class="w-full h-full"></div>
    <app-marker-stop [id]="'stopMarker'" class="invisible" [color]="'#000000'"></app-marker-stop>
</div>
<app-modal-change-driver [id]="'changeDriver'" [modal]="changeDriver" [hidden]="!changeDriverShowed" [values]="drivers" [valueSelected]="driverSelected" (action)="changeDriverAction($event)" />
<app-modal-change-vehicle [id]="'changeVehicle'" [modal]="changeVehicle" [hidden]="!changeVehicleShowed" [values]="vehicles" [valueSelected]="vehicleSelected" (action)="changeVehicleAction($event)" />
<app-modal-view-bookings [id]="'viewBookings'" [modal]="viewBookings" [hidden]="!viewBookingsShowed" [values]="bookings" [showDate]="showDate" (action)="viewBookingsAction($event)" 
                         (validatePickUpEventEmitter)="validatePickUpAction($event)" (validateDropOffEventEmitter)="validateDropOffAction($event)" />
<app-modal-add-pax [id]="'addPax'" [modal]="addPax" [hidden]="!addPaxShowed" (action)="addPaxAction($event)" />
<app-modal-confirmation [id]="'setReady'" [modal]="setReady" [hidden]="!setReadyShowed" [text]="'trips.actions.setReady.description'" [title]="'trips.actions.setReady.name'" (action)="setReadyAction()" />
<app-modal-confirmation [id]="'setClosed'" [modal]="setClosed" [hidden]="!setClosedShowed" [text]="'trips.actions.setClosed.description'" [title]="'trips.actions.setClosed.name'" (action)="setClosedAction($event)" />
<app-modal-confirmation-text [id]="'deleteTrip'" [modal]="deleteTrip" [hidden]="!deleteTripShowed" [reason]="reason" [text]="'trips.actions.deleteTrip.description'" [title]="'trips.actions.deleteTrip.name'" (action)="deleteTripAction($event)" [deleting]="deletingTrip" />
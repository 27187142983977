import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Modal } from 'flowbite';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { InputType } from 'src/app/models/enums';
import { UtilsService } from 'src/app/services';
import { LiteralService } from 'src/app/services/literal/literal.service';
import { images } from 'src/images';

@Component({
    selector: 'app-modal-confirmation-text',
    templateUrl: './modal-confirmation-text.component.html',
    styleUrl: './modal-confirmation-text.component.scss',
    standalone: false
})
export class ModalConfirmationTextComponent {
  @Input() id: string;
  @Input() modal: Modal;
  @Input() title: string;
  @Input() text: string;
  @Input() reason: string;
  @Input() deleting?: boolean;
  @Output() action = new EventEmitter<any>();

  public InputType = InputType;
  public images = images;

  public formControl: FormGroup;
  public invalid: any[] = [];

  public hasError: boolean = false;
  public error: string = '';
  public lengthError = true;

  public chars = 0;
  public maxLength = 150;

  public optionsBus: AnimationOptions = {
    path: '/assets/animations/bus.json'
  };

  constructor(public literalService: LiteralService, public utilsService: UtilsService) {}

  change = (reason: any) => {
    this.reason = reason;
  };

  close() {
    this.modal.toggle();
  }

  onChange = () => {
    this.chars = this.reason.length;
    this.lengthError = this.chars > this.maxLength;
  };

  onSubmit = (element: any) => {
    const errors = this.findInvalidControls();
    if (errors && 'required' in errors) {
      this.hasError = true;
      this.error = this.literalService.get('errors.requiredField', true);
    } else if (errors && 'maxlength' in errors) {
      this.hasError = true;
      this.error = this.literalService.get('errors.maxlength', true).replace('{0}', this.maxLength.toString());
    } else {
      this.hasError = false;
      this.error = '';
      this.action.emit(element); 
    }
  };

  findInvalidControls = () => {
    this.formControl = new FormGroup({
      reason: new FormControl(this.reason, [
        // Validators.required,
        Validators.maxLength(this.maxLength),
      ])
    });
    return this.formControl.controls['reason'].errors;
  };
}

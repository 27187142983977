import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LiteralService } from 'src/app/services/literal/literal.service';
import { images } from '../../../../images';

@Component({
    selector: 'app-trip-search-bar',
    templateUrl: './trip-search-bar.component.html',
    styleUrl: './trip-search-bar.component.scss',
    standalone: false
})
export class TripSearchBarComponent {

  @Input() placeholder = '';
  @Input() searching = false;
  @Output() filter = new EventEmitter();
  @Output() searchEventEmitter = new EventEmitter<string>();

  public filterOpened = false;

  public images = images;

  constructor(public literalService: LiteralService) {

  }

  search = (event: any) => {
    this.searchEventEmitter.emit(event);
  };

}

import { Component, Input } from '@angular/core';
import { IService, IServiceInfo, IServiceInfoDetail } from 'src/app/models';
import { UtilsService } from 'src/app/services';

@Component({
    selector: 'app-service-tag',
    templateUrl: './service-tag.component.html',
    styleUrl: './service-tag.component.scss',
    standalone: false
})
export class ServiceTagComponent {
  @Input() serviceInfo: IService | IServiceInfo | IServiceInfoDetail;

  constructor(public utilsService: UtilsService) {}
}

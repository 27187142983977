import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Modal } from 'flowbite';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { IServiceAvailability } from 'src/app/models';
import { IBookingDetails } from 'src/app/models/booking/bookingDetails';
import { TownsService, UtilsService } from 'src/app/services';
import { LiteralService } from 'src/app/services/literal/literal.service';
import { images } from 'src/images';

@Component({
    selector: 'app-modal-change-booking-origin-destination',
    templateUrl: './modal-change-booking-origin-destination.component.html',
    styleUrl: './modal-change-booking-origin-destination.component.scss',
    standalone: false
})
export class ModalChangeBookingOriginDestinationComponent {
  @Input() id: string;
  @Input() modal: Modal;
  @Input() originElement: any;
  @Input() destinationElement: any;
  @Input() booking: IBookingDetails;
  @Input() updating: boolean;
  @Output() action = new EventEmitter<any>();
  public originSelected: any;
  public destinationSelected: any;
  public availabilitySelected: IServiceAvailability;
  public images = images;
  
  public  options: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  };

  public optionsBus: AnimationOptions = {
    path: '/assets/animations/bus.json'
  };
  
  constructor(public literalService: LiteralService, public townsService: TownsService, public utilsService: UtilsService) {}

  setOrigin = async (event: any) => {
    const stops: any[] = [];
    await this.townsService.getStopsFrom(this.booking.service.town.id!, event.id, false).then((res: any[]) => {
      res.map((stop: any) =>{
        stops.push(
          {
            id: stop.id,
            value: stop,
            label: stop.name,
          }
        );
      });
    });
    stops.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      } else if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
    this.destinationElement.edit.values = stops;
    this.originSelected = event;
  };

  setDestination = async (event: any) => {
    this.destinationSelected = event;
  };

  onSubmit = () => {
    this.booking.destination = {
      id: this.destinationSelected.id, 
      serviceStopId: this.destinationSelected.id, 
      time: new Date(this.destinationSelected.dateTime).toLocaleTimeString('en-GB', this.options), 
      name: this.destinationSelected.name };
    this.booking.origin = {
      id: this.originSelected.id, 
      serviceStopId: this.originSelected.id, 
      time: new Date(this.originSelected.dateTime).toLocaleTimeString('en-GB', this.options), 
      name: this.originSelected.name };
    this.action.emit(this.booking);
    this.modal.toggle();
    this.destinationElement.value = "";
    this.originElement.value = "";
    this.modal.toggle();
  };
}

<div class="box h-14 w-60 mb-2 rounded" [ngClass]="{ 'selected': selected }" (click)="setSelected()">
  <div class="flex h-full float-left pl-4">
    <div class="flex m-auto left-0">
      <img [src]="getImage()" [alt]="image" class="h-6 w-6" />
      <label class="pl-2 font-semibold text-base">{{ literalService.get(text, true) }}{{ arrow && ' - ' + literalService.get('languages.' + value, true) }}</label>
    </div>
  </div>
  <div *ngIf="arrow" class="flex h-full float-right pr-4">
    <div class="flex m-auto left-0">
      <img [src]="images.arrowRight" alt="arrow" class="h-5" />
    </div>
  </div>
</div>

<!-- <article class="flex border-2 rounded neutrals-grey-02-border items-center h-14 justify-between px-[15px] py-2 mb-[10px]" [ngClass]="{'primary-purple-light-border': showRoutesService && showRoutesService.id === service()!.id}"> -->
<article class="flex border-2 rounded neutrals-grey-02-border items-center h-14 justify-between px-[15px] py-2" (click)="showServiceInfoData.emit()">
    <app-service-tag [serviceInfo]="service()!"></app-service-tag>
    <label class="ml-[10px] w-full font-normal text-base cursor-pointer truncate">{{ service()!.name }}</label>

    <div class="w-[1px] h-full neutrals-grey-02-background"></div>

    <div class="absolute w-[52px] h-[52px] right-[22px] cursor-pointer" (click)="$event.stopPropagation(); showRoutes.emit()"></div>
    <img [src]="images.services.route" class="w-6 h-6 ml-[15px]" />
</article>

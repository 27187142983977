import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService } from '../http/base.http.service';
import { ApiRouterService } from '../http/api.router';
import { LoginScope } from 'src/app/models/enums';
import { UtilsService } from '../utils/utils.service';

@Injectable({
  providedIn: 'root',
})
export class AuthHttpService extends BaseHttpService {
  private baseURL = ``;

  constructor(
    httpClient: HttpClient,
    private routerService: ApiRouterService,
    private utilsService: UtilsService,
  ) {
    super(httpClient);
  }

  public login(email: string, password: string) {
    const path = `login`;
    const url = this.routerService.mount(`${this.baseURL}${path}`, true);
    // TODO:
    //return this.create({scope: LoginScope.BACKOFFICE, username: email, password: password, sha: this.utilsService.encryptSHA256(password)}, url);
    return this.create(
      { scope: LoginScope.BACKOFFICE, username: email, password: password },
      url,
    );
  }
}

import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { event } from 'cypress/types/jquery';
import { getServiceForm } from 'src/app/forms/serviceForm';
import { IServiceInfoDetail } from 'src/app/models';
import { LiteralService, NotificationService, StorageService, UsersService, UtilsService } from 'src/app/services';
import { ServiceInfoService } from 'src/app/services/service-info/service-info.service';
import { images } from 'src/images';

@Component({
    selector: 'app-service-details',
    templateUrl: './service-details.component.html',
    styleUrl: './service-details.component.scss',
    standalone: false
})
export class ServiceDetailsComponent implements OnChanges {

  @Input() serviceInfoDetail: any = {} as IServiceInfoDetail;
  @Input() newServiceObject: any = {} as IServiceInfoDetail;
  @Input() newService: boolean;
  @Input() edit: boolean;
  @Output() editEvent = new EventEmitter<any>();
  @Output() createEventEmitter = new EventEmitter<IServiceInfoDetail>();
  @Output() editEventEmitter = new EventEmitter<IServiceInfoDetail>();
  @Output() closeDetails = new EventEmitter<any>();

  public expanded = false;
  // public edit = false;

  public images = images;
  public serviceForm: any[];

  public formGroup: FormGroup;

  public steps = [ 1 ];
  public numberSteps = 2;
  public currentStep = 1;

  constructor(public literalService: LiteralService,
              private storageService: StorageService,
              private usersService: UsersService,
              public utilsService: UtilsService) { }

  async ngOnChanges() {
    console.log(this.newServiceObject, this.serviceInfoDetail)
    this.formGroup = new FormGroup({});
    this.formGroup.setControl('tag', new FormControl(this.newService ? this.newServiceObject.tag : this.serviceInfoDetail.tag, [Validators.required]));
    this.formGroup.setControl('name', new FormControl(this.newService ? this.newServiceObject.name : this.serviceInfoDetail.name, [Validators.required]));
    await this.updateServiceForm();
  }

  public async updateServiceForm() {
    await getServiceForm(this.newService ? this.newServiceObject : this.serviceInfoDetail, this.newService, this.usersService, this.literalService, this.storageService, this.currentStep).then((res: any) => {
      this.serviceForm = res;
    });
    console.log(this.newServiceObject.clients)
  }

  close() {
    this.serviceInfoDetail = {} as IServiceInfoDetail;
    this.newServiceObject = {} as IServiceInfoDetail;
    this.steps = [ 1 ];
    this.currentStep = 1;
    this.edit = false;
    this.closeDetails.emit();
  }

  async changeData(data: any, element?: any) {
    console.log(data, element);
    
    let valueChanged: any;
    const valueToChange = element?.edit?.valueToChange;
  
    if (valueToChange === 'clients') {
      this.currentStep++;
      this.steps.push(2);
      this.newServiceObject.clientId = data.value.id;
      valueChanged = [data.value];
    } else if (valueToChange === 'towns') {
      this.newServiceObject.townId = data.value.id;
      valueChanged = [data.value];
    } else if (Array.isArray(valueToChange)) {
      valueChanged = this.setMultiSelectData(data, element);
    } else {
      const value = typeof data === 'object' ? data.target || data.options?.[0] : data;
      valueChanged = this.setNormalData(value, typeof data === 'object');
    }
  
    const targetObject = this.newService ? this.newServiceObject : this.serviceInfoDetail;
    if (targetObject && valueToChange) {
      targetObject[valueToChange] = valueChanged;
      this.formGroup.get(valueToChange)?.setValue(valueChanged);
    }
  
    console.log(this.formGroup);
    await this.updateServiceForm();
  }

  private setNormalData(data: any, isEvent = false) {
    return isEvent ? data.value : data;
  }

  private setMultiSelectData(data: any, element: any) {
    const selectedValues = element.edit?.selectedValues || [];
    const index = selectedValues.findIndex(({ id }: any) => id === data.id);

    index === -1 ? selectedValues.push(data) : selectedValues.splice(index, 1);
    this.newServiceObject[data.id] = index === -1;
  }

  async onSubmit() {
    console.log(this.newServiceObject)
    if (this.newService) {
      this.createEventEmitter.emit(this.newServiceObject);
    } else {
      this.editEventEmitter.emit(this.serviceInfoDetail);
    }
    this.ngOnChanges();
  }

}

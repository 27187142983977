import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LiteralService } from 'src/app/services/literal/literal.service';
import { images, userProfile } from 'src/images';

@Component({
    selector: 'app-user-profile-box',
    templateUrl: './user-profile-box.component.html',
    styleUrls: ['./user-profile-box.component.scss'],
    standalone: false
})
export class UserProfileBoxComponent {
  /* STATES */
  @Input() selected = false;
  @Input() language = 'en';

  @Input() image = '';
  @Input() text = '';
  @Input() value = '';
  @Input() arrow = '';

  public images = images;
  public userProfile = userProfile;

  constructor(
    public literalService: LiteralService,
    public translateService: TranslateService,
  ) {}

  getImage() {
    return this.userProfile.find((img) => img.name === this.image)?.img;
  }

  setSelected() {
      document.getElementById('dropdown-language')?.classList.contains('hidden') || false;
  }
}

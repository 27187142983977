import { Component, ElementRef, input, output, viewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Modal } from 'flowbite';
import { AnimationOptions } from 'ngx-lottie';
import { IBase, IClient, ITown } from 'src/app/models';
import { LiteralService, TownsService, UsersService, UtilsService } from 'src/app/services';
import { images } from 'src/images';

@Component({
  selector: 'app-modal-upload-csv',
  templateUrl: './modal-upload-csv.component.html',
  styleUrl: './modal-upload-csv.component.scss',
  standalone: false
})
export class ModalUploadCsvComponent {

  readonly id = input<string>();
  readonly modal = input<Modal>();
  readonly loading = input<boolean>(false);
  readonly action = output<any>();

  readonly form = viewChild<ElementRef>('form');
  public formGroup: FormGroup = new FormGroup({});

  public towns: any[] = [];
  public clients: any[] = [];

  public file: File;

  public images = images;
  public document = document;

  public selectTown = true;

  public importing: AnimationOptions = {
      path: '/assets/animations/importing.json'
    };

  constructor(public literalService: LiteralService,
              private usersService: UsersService,
              public utilsService: UtilsService) {}

  async ngOnInit() {
    await this.usersService.getPossibleClientTowns().then((res) => {
      res.towns.sort((firstItem: IBase, secondItem: IBase) => firstItem.id! - secondItem.id!);
      res.clients.forEach((client: IClient) => {
        this.clients.push({ id: client.id, value: client.id, label: client.name });
      });
    });
    this.formGroup = new FormGroup({});
    this.setControls();
  }

  onFileSelected(event: any, type: string) {
    const file: File = event.target.files[0];
    if (!file) return;
  
    const fileName = file.name;

    this.file = file;
    console.log(this.file)
  
    const control = this.formGroup.get(type);
    control?.setValue(fileName);
    control?.markAsTouched();
    control?.updateValueAndValidity();
  
    this.setControls();
  }

  async setControls() {
    this.formGroup.addControl('csv', new FormControl('', [Validators.required]));
    this.formGroup.addControl('townId', new FormControl('', [Validators.required]));
    this.formGroup.addControl('clientId', new FormControl('', [Validators.required]));
    if (this.clients.length === 1) {
      this.towns = [];
      this.formGroup.get('clientId')?.setValue(this.clients[0].id);
      await this.usersService.getTownsByClient([this.clients[0].id!]).then((res) => {
        const towns = res as ITown[];
        towns.forEach((town: ITown) => {
          this.towns.push({ id: town.id, value: town.id, label: town.name });
        });
      });
    }
  }

  async setClientId(event: any) {
    this.towns = [];
    this.formGroup.get('clientId')?.setValue(event.value);
    await this.usersService.getTownsByClient([event.value]).then((res) => {
      const towns = res as ITown[];
      towns.forEach((town: ITown) => {
        this.towns.push({ id: town.id, value: town.id, label: town.name });
      });
    });
  }
  
  setTownId(event: any) {
    this.formGroup.get('townId')?.setValue(event.value);
  }

  setTownName(event: any) {
    this.formGroup.get('townName')?.setValue(event.target.value);
  }

  changeCreationType(create?: boolean) {
    this.selectTown = !this.selectTown;
    if (create) {
      this.formGroup.removeControl('townId');
      this.formGroup.addControl('townName', new FormControl('', [Validators.required]));
    } else {
      this.formGroup.removeControl('townName');
      this.formGroup.addControl('townId', new FormControl('', [Validators.required]));
    }
    console.log(this.formGroup)
  }

  onSubmit() {
    let data = { file: this.file, clientId: this.formGroup.get('clientId')?.value };
    Object.assign(data, this.selectTown ? {townId: this.formGroup.get('townId')?.value} : {townName: this.formGroup.get('townName')?.value})
    this.action.emit(data);
  }

}

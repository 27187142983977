<div [id]="id" tabindex="-1" class="absolute top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
    <div class="relative w-[438px] rounded-lg primary-purple-light-2-background">
        <div class="relative rounded-lg shadow dark:bg-gray-700 p-10">
            <div class="justify-center items-center">
                <img [src]="images.error" class="h-14 w-full justify-center items-center" alt="alert" />
                <h3 class="w-full text-xl text-center font-bold primary-purple-text dark:text-gray-400 mt-5">{{ literalService.get(title, true) }}</h3>
                @if (services) {
                    @for (service of services; track $index) {
                        @if (service.alterationMessages.length > 0) {
                            <div class="flex justify-center items-center mt-5">
                                <app-service-tag [serviceInfo]="service" />
                                <label class="text-base text-center font-bold primary-purple-text dark:text-gray-400 ml-2">{{ service.name }}</label>
                            </div>
                            <p class="w-full whitespace-pre-line text-center text-base mb-10 primary-purple-text dark:text-gray-400 mt-4">
                                {{ getAlterationsText(service) }}
                            </p>
                        }
                    }
                }
            </div>
            <div class="flex justify-center items-center">
                <button type="button" class="primary mt-2 border-2 gap-4 h-14 font-semibold !text-lg w-full uppercase cursor-pointer" (click)="modal.hide()">{{ literalService.get('close', true) }}</button>
            </div>
        </div>
    </div>
</div>
import { HttpHeaders } from '@angular/common/http';
export const httpHeaders = new HttpHeaders({
  Accept: 'application/json, text/event-stream, application/octet-stream',
  'Access-Control-Allow-Origin': '*',
  'X-BOD-APP-CODE': 'NEMI',
  'Content-Type': 'application/json',
  // 'X-BOD-USER-ID': '1'   // NOTE: Uncomment for use in local
});

export const httpHeadersMultipart = new HttpHeaders({
  Accept: 'application/json, text/event-stream, multipart/form-data',
  'Access-Control-Allow-Origin': '*',
  'X-BOD-APP-CODE': 'NEMI',
});

import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Modal } from 'flowbite';
import moment from 'moment';
import { IBooking } from 'src/app/models';
import { InputType } from 'src/app/models/enums';
import { LiteralService } from 'src/app/services/literal/literal.service';
import { images } from 'src/images';

@Component({
    selector: 'app-modal-ticketing-status',
    templateUrl: './modal-ticketing-status.component.html',
    styleUrl: './modal-ticketing-status.component.scss',
    standalone: false
})
export class ModalTicketingStatusComponent implements OnChanges {
  @Input() id: string;
  @Input() modal: Modal;
  @Input() booking: IBooking;
  @Output() action = new EventEmitter<any>();

  public originalBooking: IBooking;
  public updated: boolean = false;
  public changes: string[] = [];

  public InputType = InputType;
  public images = images;
  public moment = moment;

  constructor(public literalService: LiteralService) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.originalBooking = { ...this.booking }; 
  }

  updateStatus(event: Event) {
    this.updated = true;
    event?.stopPropagation();
  }

  checkChanges() {
    this.changes = [];
    const originalPaidDateTime = this.originalBooking.paidDateTime ? moment(this.originalBooking.paidDateTime).format('DD/MM/YYYY') : undefined;
    const originalValidatedDateTime = this.originalBooking.validatedDateTime ? moment(this.originalBooking.validatedDateTime).format('DD/MM/YYYY') : undefined;
    const paidDateTime = this.booking.paidDateTime ? moment(this.booking.paidDateTime).format('DD/MM/YYYY') : undefined;
    const validatedDateTime = this.booking.validatedDateTime ? moment(this.booking.validatedDateTime).format('DD/MM/YYYY') : undefined;
    originalPaidDateTime !== paidDateTime && this.changes.push('payment');
    originalValidatedDateTime !== validatedDateTime && this.changes.push('validation');
  }

  changePayment() {
    this.booking && (this.booking.paidDateTime === undefined || this.booking.paidDateTime === null) ? this.booking.paidDateTime = moment().toString() : this.booking.paidDateTime = undefined;
    this.checkChanges();
  }

  changeValidation() {
    this.booking && (this.booking.validatedDateTime === undefined || this.booking.validatedDateTime === null) ? this.booking.validatedDateTime = moment().toString() : this.booking.validatedDateTime = undefined;
    this.checkChanges();
  }

  onSubmit() {
    this.action.emit();
    this.modal.toggle();
    this.updated = false;
  }
}

<div [id]="id" tabindex="-1" class="modal absolute top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
    <div class="relative w-2/3 max-h-full rounded-lg primary-purple-light-2-background">
        <div class="flex justify-center items-center">
            <button (click)="modal.hide()" type="button" class="absolute right-2 mt-10 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="changeDriver">
                <img [src]="images.close" class="h-6 w-6" />
            </button>
        </div>
        <div class="pl-6 pr-6 pt-2 pb-2">
            <!--p class="font-semibold text-xl w-full">{{ literalService.get('trips.modal.bookings.title', true) }}:</p>
            <p class="font-normal text-base w-full">{{ literalService.get('trips.modal.bookings.text', true) }}</p-->
        </div>
        <div class="pl-6 pr-6 mt-4 mb-4 max-h-96 overflow-y-auto">
            @for (booking of values; track $index) {
                <div class="w-full bg-white grid grid-cols-12 pt-1 pb-1 mb-3 last:mb-0 cursor-pointer" [ngClass]="{'border-2 primary-purple-light-border': bookingSelected === booking.id}"
                [ngClass]="{'bg-slate-100': booking.missed}">
                    <div class="flex border-r neutrals-grey-02-border col-span-1 justify-center items-center cursor-pointer"  (click)="bookingSelected = booking.id!">
                        <div>
                            @if (booking.changeover) {
                                <div class="flex w-full justify-center items-center cursor-pointer">
                                    <img [src]="images.driver" class="h-6 w-6 mr-2 cursor-pointer" />
                                    <label class="font-semibold text-lg text-center cursor-pointer">{{ booking.seats }}</label>
                                </div>
                            } @else {
                                <div class="flex w-full justify-center items-center cursor-pointer">
                                    <img [src]="images.pax" class="h-6 w-6 mr-2 cursor-pointer" />
                                    <label class="font-semibold text-lg text-center cursor-pointer">{{ booking.seats }}</label>
                                </div>
                                <div class="flex w-full justify-center items-center cursor-pointer">
                                    <img [src]="images.prm" class="h-6 w-6 mr-2 cursor-pointer" />
                                    <label class="font-semibold text-lg text-center cursor-pointer">{{ booking.prmSeats }}</label>
                                </div>
                            }
                        </div>
                    </div>
                    @if (!showDate) {
                        <div class="flex border-r neutrals-grey-02-border col-span-2 justify-center items-center cursor-pointer"  (click)="bookingSelected = booking.id!">
                            <label class="font-semibold text-lg text-center cursor-pointer">{{ booking.firstName + ' ' + booking.lastName }}</label>
                        </div>
                    }
                    <div class="border-r neutrals-grey-02-border col-span-4 justify-start items-center pl-2 cursor-pointer"  (click)="bookingSelected = booking.id!">
                        <div>
                            <div class="flex justify-start items-center mb-2 cursor-pointer">
                                <app-marker-od class="cursor-pointer" id="origin" [textColor]="'text-white'" [color]="'primary-purple-light-background'" [size]="4"></app-marker-od>
                                <label [id]="'origin-'+$index" class="truncate font-semibold text-lg ml-2 cursor-pointer" [tooltip]="utilsService.visibleTooltip('origin-'+$index!) ? booking.origin.name! : ''" [visibleTooltip]="utilsService.visibleTooltip('origin-'+$index!)" [position]="TooltipPosition.ABOVE">{{ booking.origin.name }}</label>
                            </div>
                            <div class="flex justify-start items-center cursor-pointer">
                                <app-marker-od class="cursor-pointer" id="destination" [textColor]="'text-black'" [color]="'primary-green-background'" [size]="4"></app-marker-od>
                                <label [id]="'destination-'+$index" class="truncate font-semibold text-lg ml-2 cursor-pointer" [tooltip]="utilsService.visibleTooltip('destination-'+$index!) ? booking.destination.name! : ''" [visibleTooltip]="utilsService.visibleTooltip('destination-'+$index!)" [position]="TooltipPosition.ABOVE">{{ booking.destination.name }}</label>
                            </div>
                        </div>
                    </div>
                    @if (showDate) {
                        <div class="flex border-r neutrals-grey-02-border col-span-2 justify-center items-center cursor-pointer"  (click)="bookingSelected = booking.id!">
                            <label class="font-semibold text-lg text-center cursor-pointer">{{ booking.date | date: 'dd-MM-yyyy' || '-' }}</label>
                        </div>
                    }
                    <div class="flex border-r neutrals-grey-02-border col-span-1 justify-center items-center cursor-pointer" [tooltip]="literalService.get('trips.modal.bookings.offered', true)" [position]="TooltipPosition.ABOVE" (click)="bookingSelected = booking.id!">
                        <div>
                            <div class="flex w-full justify-center items-center cursor-pointer">
                                <label class="font-semibold text-lg text-center cursor-pointer">{{ booking.initialOriginTime || '-' }}</label>
                            </div>
                            <div class="flex w-full justify-center items-center cursor-pointer">
                                <label class="font-semibold text-lg text-center cursor-pointer">{{ booking.initialDestinationTime || '-' }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="flex border-r neutrals-grey-02-border col-span-1 justify-center items-center cursor-pointer" [tooltip]="literalService.get('trips.modal.bookings.communicated', true)" [position]="TooltipPosition.ABOVE" (click)="bookingSelected = booking.id!">
                        <div>
                            <div class="flex w-full justify-center items-center cursor-pointer">
                                <label class="font-semibold text-lg text-center cursor-pointer">{{ booking.originCommunicatedTime || '-' }}</label>
                            </div>
                            <div class="flex w-full justify-center items-center cursor-pointer">
                                <label class="font-semibold text-lg text-center cursor-pointer">{{ booking.destinationCommunicatedTime || '-' }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="flex border-r neutrals-grey-02-border col-span-1 justify-center items-center cursor-pointer" [tooltip]="literalService.get('trips.modal.bookings.last', true)" [position]="TooltipPosition.ABOVE" (click)="bookingSelected = booking.id!">
                        <div>
                            <div class="flex w-full justify-center items-center cursor-pointer">
                                <label class="font-semibold text-lg text-center cursor-pointer">{{ booking.origin.time }}</label>
                            </div>
                            <div class="flex w-full justify-center items-center cursor-pointer">
                                <label class="font-semibold text-lg text-center cursor-pointer">{{ booking.destination.time }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="flex border-r neutrals-grey-02-border col-span-1 justify-center items-center cursor-pointer" [tooltip]="literalService.get('trips.modal.bookings.real', true)" [position]="TooltipPosition.ABOVE" (click)="bookingSelected = booking.id!">
                        <div>
                            <div class="flex w-full justify-center items-center cursor-pointer">
                                <label class="font-semibold text-lg text-center cursor-pointer">{{ booking.originPassingTime || '-' }}</label>
                            </div>
                            <div class="flex w-full justify-center items-center cursor-pointer">
                                <label class="font-semibold text-lg text-center cursor-pointer">{{ booking.destinationPassingTime || '-' }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="flex neutrals-grey-02-border col-span-1 justify-center items-center cursor-default">
                        <div>
                            <div class="flex w-full justify-center items-center">
                                @if (booking.originConfirmedTime) {
                                    <label class="font-semibold text-lg text-center" [tooltip]="literalService.get('trips.modal.bookings.validated', true)" [position]="TooltipPosition.ABOVE">{{ booking.originConfirmedTime }}</label>
                                } @else {
                                    <img [src]="images.confirm" class="h-6 w-6 mb-1 cursor-pointer" [tooltip]="literalService.get('trips.actions.validate.name', true)" [position]="TooltipPosition.ABOVE" (click)="validatePickUpEventEmitter.emit(booking.id)" />
                                }
                            </div>
                            <div class="flex w-full justify-center items-center">
                                @if (booking.destinationConfirmedTime) {
                                    <label class="font-semibold text-lg text-center" [tooltip]="literalService.get('trips.modal.bookings.validated', true)" [position]="TooltipPosition.ABOVE">{{ booking.destinationConfirmedTime }}</label>
                                } @else {
                                    <img [src]="images.confirm" class="h-6 w-6 cursor-pointer" [tooltip]="literalService.get('trips.actions.validate.name', true)" [position]="TooltipPosition.ABOVE"  (click)="validateDropOffEventEmitter.emit(booking.id)" />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
        @if (!values || !values.length) {
            <div class="justify-center items-center flex">
                <img [src]="images.booking.empty" class="h-28"/>
            </div>
            <div class="justify-center items-center flex mt-4">
                <label class="font-bold text-lg text-center uppercase neutrals-grey-04-text">{{ literalService.get('bookings.noBookings') }}</label>
            </div>
        }
        <div class="flex justify-end items-center pb-4 pr-6">
            <button type="button" class="secondary gap-4 p-4 border-2 font-semibold h-14 uppercase cursor-pointer bg-white" (click)="goToNewBooking()">{{ literalService.get('bookings.newBooking', true) }}</button>
            @if (values && values.length) {
                <button data-modal-hide="viewBookings" type="button" class="secondary ml-4 gap-4 p-4 border-2 font-semibold h-14 uppercase" [ngClass]="{'cursor-pointer bg-white': bookingSelected !== 0}" [disabled]="bookingSelected === 0" (click)="goToSelectedBooking(bookingSelected)">{{ literalService.get('trips.modal.bookings.button', true) }}</button>
            }
        </div>
    </div>
</div>
import { initialMessagesState } from './messages';
import { initialNotificationState } from './notification';
import { initialOperatorsState } from './operators';
import { initialPaginationState } from './pagination';
import { initialServicesState } from './services';
import { initialTownsState } from './towns';
import { initialUserDriverState } from './userDriver';
import { initialUserRegularState } from './userRegular';
import { initialUserState } from './users';
import { initialVehiclesState } from './vehicles';
import { initialTripsState } from './trips';
import { initialBookingsState } from './bookings';
import { initialServiceInfosState } from './service-infos';

export const initialStates = {
  messages: initialMessagesState,
  notification: initialNotificationState,
  operators: initialOperatorsState,
  pagination: initialPaginationState,
  bookings: initialBookingsState,
  serviceInfos: initialServiceInfosState,
  services: initialServicesState,
  town: initialTownsState,
  userDriver: initialUserDriverState,
  userInfo: initialUserState,
  userRegular: initialUserRegularState,
  vehicles: initialVehiclesState,
  trips: initialTripsState,
};

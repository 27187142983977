import { Component } from '@angular/core';
import { images } from '../../../images';
import { StorageService } from 'src/app/services';
import { Theme } from 'src/app/models/enums';

@Component({
    selector: 'app-dark-mode',
    templateUrl: './dark-mode.component.html',
    styleUrls: ['./dark-mode.component.scss'],
    standalone: false
})
export class DarkModeComponent {
  public darkMode = false;
  public images = images;

  constructor(private storageService: StorageService) {
    this.storageService.getTheme().then(
      (theme: Theme) => {
        theme
          ? this.storageService.setTheme(theme)
          : this.storageService.setTheme(Theme.LIGHT);
        theme === Theme.DARK && !this.darkMode && this.changeMode();
      },
      (error) => {
        console.log(error);
      },
    );
  }

  public changeMode = () => {
    this.darkMode
      ? document.documentElement.classList.remove('dark')
      : document.documentElement.classList.add('dark');
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    document.getElementById('tumbler')!.style.transform =
      `translateX(calc(${this.darkMode ? '10%' : '100%'} - 2px))`;
    this.darkMode = !this.darkMode;
    this.storageService.setTheme(this.darkMode ? Theme.DARK : Theme.LIGHT);
  };
}

<section class="mt-5 overflow-y-auto" [ngClass]="{'h-auto max-h-[calc(100vh-380px)]': !loadingAvailabilities}">
    @if (availabilities) {
        @for (data of availabilitiesArray; let first = $first, last = $last; track $index) {
            @if (showedDate === moment(data.date).format('DD/MM/YYYY') && data.type === 'success') {
                <article class="p-4 mx-2 rounded gap-20 cursor-pointer mr-4" 
                        [ngClass]="{'border-2 primary-purple-light-border': checkAvailabilitySelected(data.availability.availabilityId), $index, 'mb-4': !last, 'mb-1': last, 'mt-4': !first, 'mt-1': first}"
                        (click)="!data.availability.informative && selectAvailability(data.availability)"
                        [tooltip]="data.availability.informative ? literalService.get('bookings.bookingNotAvailable', true) : ''"
                        [position]="data.availability.informative ? TooltipPosition.DYNAMIC : undefined"
                        >
                    <div class="flex items-center mb-6">
                        <img [src]="images.alterationWarning" [hidden]="!hasAlterations(data.availability.serviceInfo.id!)" class="h-6 w-6 mr-2" [tooltip]="getAlterationMessageByService(data.availability.serviceInfo.id!)" [position]="TooltipPosition.DYNAMIC" />
                        <app-service-tag [serviceInfo]="data.availability.serviceInfo"></app-service-tag>
                        <label class="ml-[10px] w-full font-bold text-base cursor-pointer">{{ data.availability.serviceInfo.name }}</label>
                        <img [hidden]="!data.availability.ecoRoute" [src]="images.booking.eco" alt="eco" class="h-6 w-6" />
                    </div>
                    @if (checkIfAlternativeIsOnInitial(data.availability) && data.availability.alternative) {
                        <div class="flex mb-3">
                            <img [src]="images.booking.pedestrian" class="h-6 w-6 mr-2" alt="pedestrian" />
                            <label class="font-normal text-base neutrals-grey-03-text">{{ getAlterationDestinationName(data.availability, true) }}</label>
                        </div>
                        <img [src]="images.moreInfo" class="h-6 w-6 mr-2 mb-3" />
                    }
                    <div class="flex items-center h-6 mb-3">
                        <img [src]="images.route" class="h-6 w-6 mr-2" alt="bus" />
                        <label class="mr-2 flex font-semibold text-sm cursor-pointer text-left">{{ data.availability.inStop.name }}</label>
                        <div class="flex-grow min-w-11">
                            <div class="flex items-center">
                                <div class="flex h-4 w-4 justify-center items-end">
                                    <div class="flex h-4 w-4 rounded-[50%] justify-center items-center primary-purple-light-background">
                                        <div class="h-2 w-2 rounded-[50%] bg-white"></div>
                                    </div>
                                </div>
                                <div class="flex gradient h-1 w-5/6"></div>
                                <div class="flex h-4 w-4 justify-center items-end">
                                    <div class="flex h-4 w-4 rounded-[50%] justify-center items-center primary-green-background">
                                        <div class="h-2 w-2 rounded-[50%] bg-white"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <label class="ml-2 mr-4 flex font-semibold text-sm cursor-pointer text-left">{{ data.availability.outStop.name }}</label>
                    </div>
                    @if (checkIfAlternativeIsOnFinal(data.availability) && data.availability.alternative) {
                        <img [src]="images.moreInfo" class="h-6 w-6 mr-2 mb-3" />
                        <div class="flex mb-3">
                            <img [src]="images.booking.pedestrian" class="h-6 w-6 mr-2" alt="pedestrian" />
                            <label class="font-normal text-base neutrals-grey-03-text">{{ getAlterationDestinationName(data.availability, false) }}</label>
                        </div>
                    }
                    <div class="flex items-center mb-3">
                        <img [src]="images.clock" class="h-6 w-6 mr-2" />
                        <label class="mr-2 font-normal text-sm cursor-pointer">
                            {{ literalService.get('bookings.departure', true)}} 
                            <span class="font-semibold text-sm" [tooltip]="getVariabilityText(data.availability.inStop)" [position]="TooltipPosition.DYNAMIC" [visibleTooltip]="!data.availability.informative">
                                {{ moment(data.availability.inStop.dateTime).format('HH:mm')}} {{!data.availability.informative ? ' *' : ''}}
                            </span>
                        </label>
                        <label class="font-normal text-sm cursor-pointer">
                            {{ literalService.get('bookings.arrival', true)}} 
                            <span class="font-semibold text-sm" [tooltip]="getVariabilityText(data.availability.outStop)" [position]="TooltipPosition.DYNAMIC" [visibleTooltip]="!data.availability.informative">
                                {{ moment(data.availability.outStop.dateTime).format('HH:mm')}} {{!data.availability.informative ? ' *' : ''}}
                            </span>
                        </label>
                    </div>
                    <div class="flex items-center mb-3">
                        <div class="flex items-center">
                            <img [src]="images.busCar" class="h-6 w-6 mr-2" />
                            <label class="mr-2 font-normal text-sm cursor-pointer">{{ data.availability.vehiclePlate }}
                                @if (data.availability.tripTime) {
                                    <span>({{ literalService.get('bookings.departure', true)}} {{ moment(data.availability.tripTime, [moment.ISO_8601, 'HH:mm']).format('HH:mm') }})</span>
                                }
                            </label>
                        </div>
                        @if (data.availability.tripPassengers) { 
                            <div class="flex items-center">
                                <img [src]="images.booking.passengers" class="h-6 w-6 mr-2" />
                                <label class="mr-2 font-normal text-sm cursor-pointer">{{ data.availability.tripPassengers }}</label>
                            </div>
                        }
                    </div>
                    <div class="flex items-center">
                        <img [src]="images.booking.driver" class="h-6 w-6 mr-2" />
                        <label class="mr-2 font-normal text-sm cursor-pointer">{{ data.availability.driverName }}</label>
                    </div>
                </article>
            } @else if (showedDate === moment(data.date).format('DD/MM/YYYY') && data.type === 'failure') {
                @if (data.availability.failureReason === 'NoAvailabilitiesFound') {
                    <div class="flex justify-center">
                        <img [src]="images.booking.noOptions" class="h-48 w-48" />
                    </div>
                    <div class="text-center">
                        <h4 class="font-bold text-xl neutrals-grey-04-text">{{ literalService.get('bookings.noResults', true) }}</h4>
                        <label class="flex font-normal text-base mr-12 ml-12 mb-3 mt-2 neutrals-grey-04-text">{{ literalService.get('bookings.noResultsExplanation', true) }}</label>
                    </div>
                } @else {
                    @if (isFirstInSpecificDate($index, data.date)) {
                        <h2 class="font-semibold text-xl mt-4 mb-2">{{ literalService.get('bookings.notAvailable', true) }}</h2>
                    }
                    <article class="p-2 mb-4 rounded gap-20 cursor-pointer mr-4" [ngClass]="{'border-2 secondary-red-border': data.availability.failureReason}">
                        <div class="flex justify-between items-center align-middle">
                            <div class="failureReason mb-2 p-1 rounded w-fit">
                                <label class="font-semibold text-base">{{ literalService.get('bookings.' + data.availability.failureReason, true) }}</label>
                            </div>
                            <img [src]="images.info" class="h-6 w-6 mb-2" alt="info" [tooltip]="literalService.get('bookings.' + data.availability.failureReason + 'Text', true)" [position]="TooltipPosition.DYNAMIC"/>
                        </div>
                        @if (data.availability.serviceInfo.name && data.availability.failureReason !== 'Impossible') {
                            <div class="flex items-center mb-2">
                                <app-service-tag [serviceInfo]="data.availability.serviceInfo"></app-service-tag>
                                <label class="ml-2 w-full font-semibold text-sm cursor-pointer">{{ data.availability.serviceInfo.name }}</label>
                            </div>
                        }
                        @if (data.availability.inStop.name && data.availability.outStop.name) {
                            <div class="flex items-center mb-2">
                                <img [src]="images.route" class="h-6 w-6 mr-2" />
                                <label class="mr-2 font-semibold text-sm cursor-pointer">{{ data.availability.inStop.name }}</label>
                                <div class="flex w-12 items-center">
                                    <div class="flex h-4 w-4 justify-center items-end">
                                        <div class="flex h-4 w-4 rounded-[50%] justify-center items-center primary-purple-light-background">
                                            <div class="h-2 w-2 rounded-[50%] bg-white"></div>
                                        </div>
                                    </div>
                                    <div class="flex gradient h-1 w-2/3"></div>
                                    <div class="flex h-4 w-4 justify-center items-end">
                                        <div class="flex h-4 w-4 rounded-[50%] justify-center items-center primary-green-background">
                                            <div class="h-2 w-2 rounded-[50%] bg-white"></div>
                                        </div>
                                    </div>
                                </div>
                                <label class="ml-2 font-semibold text-sm cursor-pointer">{{ data.availability.outStop.name }}</label>
                            </div>
                        }
                        @if (data.availability.inStop.dateTime && data.availability.outStop.dateTime && data.availability.failureReason !== 'Impossible') {
                            <div class="flex items-center mb-2">
                                <img [src]="images.clock" class="h-6 w-6 mr-2" />
                                <label class="mr-2 font-normal text-sm cursor-pointer">{{ literalService.get('bookings.departure', true)}} <span class="font-semibold text-sm">{{ moment(data.availability.inStop.dateTime).format('HH:mm') }}</span></label>
                                <label class="font-normal text-sm cursor-pointer">{{ literalService.get('bookings.arrival', true)}} <span class="font-semibold text-sm">{{ moment(data.availability.outStop.dateTime).format('HH:mm') }}</span></label>
                            </div>
                        }
                        @if (data.availability.vehiclePlate && data.availability.failureReason !== 'Impossible') {
                            <div class="flex items-center mb-2">
                                <div class="flex items-center">
                                    <img [src]="images.busCar" class="h-6 w-6 mr-2" />
                                    <label class="mr-2 font-normal text-sm cursor-pointer">{{ data.availability.vehiclePlate }}
                                        @if (data.availability.tripTime) {
                                            <span>({{ literalService.get('bookings.departure', true)}} {{ moment(data.availability.tripTime, [moment.ISO_8601, 'HH:mm']).format('HH:mm') }})</span>
                                        }
                                    </label>
                                </div>
                                @if (data.availability.tripPassengers) {
                                    <div class="flex items-center">
                                        <img [src]="images.booking.passengers" class="h-6 w-6 mr-2" />
                                        <label class="mr-2 font-normal text-sm cursor-pointer">{{ data.availability.tripPassengers }}</label>
                                    </div>
                                }
                            </div>
                        }
                        @if (data.availability.driverName && data.availability.failureReason !== 'Impossible') {
                            <div class="flex items-center">
                                <img [src]="images.booking.driver" class="h-6 w-6 mr-2" />
                                <label class="mr-2 font-normal text-sm cursor-pointer">{{ data.availability.driverName }}</label>
                            </div>
                        }
                    </article>
                }
            }
        }
    }
</section>

@if (hasSuccessAvailabilities()) {
    @if (!allAvailabilitiesSelected) {
        <div class="flex justify-end items-center mt-6 mx-2">
            <img [src]="images.info" class="h-6 w-6 ml-2" [tooltip]="literalService.get('bookings.infoAvailabilitiesActions', true)" [position]="TooltipPosition.DYNAMIC" />
        </div>
        <div class="flex justify-center mt-2">
            <input type="button" class="secondary text-white gap-4 border-2 font-semibold h-14 w-full mr-2 uppercase cursor-pointer" (click)="multipleBooking()" [value]="literalService.get('bookings.automaticSelection')" />
        </div>
    }
    @if (allAvailabilitiesSelected) {
        <div class="flex justify-center mt-4">
            <input type="button" class="primary text-white gap-4 border-2 font-semibold h-14 w-full mr-2 uppercase cursor-pointer" (click)="makeBooking()" [value]="literalService.get(newBooking.multipleDates ? 'bookings.makeBookings' : 'bookings.makeBooking')" />
        </div>
    }
}

import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { images } from '../../../../images';
import { LiteralService } from '../../../services';

@Component({
  selector: 'app-seats-input',
  templateUrl: './seats-input.component.html',
  styleUrl: './seats-input.component.scss'
})
export class SeatsInputComponent implements OnInit, OnChanges {

  @Input() data: any;
  @Input() value: any;
  @Output() changeEventEmitter = new EventEmitter<any>();

  public images = images;

  public pax = 0;
  public prm = 0;

  @HostListener('change', ['$event'])
  onChange(event: Event) {
    console.log('onChange', event);
    setTimeout(() => {
      this.changeEventEmitter.emit(event);
    });
  }

  constructor(public literalService: LiteralService) { }

  ngOnInit(): void {
    console.log(this.data);
    console.log(this.value);
    this.setValue(this.data);
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    console.log(changes);
    console.log(this.data);
    console.log(this.value);
    this.setValue(this.data);
  }

  setValue = (data: any) => {
    console.log(data);
    this.pax = data && data.filter((seats: any) => !seats.prm).length || 0;
    this.prm = data && data.filter((seats: any) => seats.prm).length || 0;
    console.log(this.pax, this.prm);
  };

}

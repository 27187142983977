import { LocalDriverService } from 'src/app/services/storage/drivers/local-storage.service';
import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Cache } from '../../models/cache';

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  public cache: Map<HttpRequest<any>, Cache> = new Map();

  constructor(private storage: LocalDriverService) {}

  get(request: HttpRequest<any>): Cache {
    this.cache = new Map();
    const cacheItem = this.storage.get(request.url);
    this.cache = new Map(JSON.parse(cacheItem));
    let result: Cache = null as any;
    this.cache.forEach((value, key) => {
      if (key.url == request.url) {
        result = value;
      }
    });
    return result;
  }

  delete(cache: any) {
    cache.response.delete();
  }

  set(request: any, cache: any) {
    const cacheItem = this.cache.set(request, cache);
    this.storage.set(
      request.url,
      JSON.stringify(Array.from(cacheItem.entries())),
    );
  }

  checkToSaveCache(url: any, request: HttpRequest<any>) {
    return (
      (typeof url == 'string' && request.url.includes(url.toString())) ||
      (url instanceof RegExp && new RegExp(url).test(request.url))
    );
  }
}

@if (data.img && data.title && !data.detailDesign) {
    <div class="flex items-center mb-2">
        <img [src]="data.img" [ngClass]="{'noColor': !data.color}" alt="detailImage" class="h-5 w-5 mr-1" />
        <label class="text-base font-normal" [hidden]="data.informativeToNoChange">{{ literalService.get(data.title, true) }}</label>
        <span class="text-base font-normal neutrals-grey-04-text ml-1">{{ data.required && '(' + literalService.get('mandatory') + ')' || '' }}</span>
        <label class="text-base font-normal" [hidden]="!data.informativeToNoChange">{{ data.value && data.informativeToNoChangeText }}</label>
    </div>
}
@if (data.img && data.title && data.detailDesign) {
    <div class="flex items-center mb-2">
        <img [src]="data.img" [ngClass]="{'noColor': !data.color}" alt="detailImage" class="h-5 w-5 mr-1" />
        <label class="text-base font-normal">
            {{ literalService.get(data.title, true) }}
            <span class="text-base font-normal neutrals-grey-04-text ml-1">{{ data.required && '(' + literalService.get('mandatory') + ')' || '' }}</span>
        </label>
    </div>
    @if (data.informativeToNoChange) {
        <div [id]="'text' + data.title" class="flex border-b mb-2 mt-1 truncate h-10 items-center">
            <label class="text-base font-normal">{{ data.informativeToNoChangeText }}</label>
        </div>
    }
}
<div class="relative" [ngClass]="{'w-72': !independent, 'w-full': independent}" [hidden]="data.informativeToNoChange">
    <ng-container *ngIf="(data.edit.inputType === InputType.TEXT || data.edit.inputType === InputType.EMAIL || data.edit.inputType === InputType.TIME) && (edit || independent)">
        <div class="flex justify-end items-center relative">
            <input [type]="data.edit.inputType" [value]="data.value" [attr.formControlName]="data.edit.formControlName" (click)="cancelEvent($event)" class="block w-full h-10 px-3 py-2 bg-white border rounded-s rounded-e text-base font-normal placeholder-slate-400 focus:outline-none" (change)="changeEventEmitter.emit($event)" [required]="data.edit.required" [disabled]="data.edit.disabled" [ngClass]="{'invalid': hasErrors}" />
            <img [src]="images.error" class="absolute mr-2 w-6" alt="errorIcon" *ngIf="hasErrors" />
        </div>
        <label class="text-xs hasErrors" *ngIf="hasErrors">{{ error }}</label>
        <label class="text-xs neutrals-grey-04-text" *ngIf="!hasErrors && data.edit.inputType === InputType.EMAIL">(*) {{ literalService.get('requiredFieldOrNext', false) }}</label>
    </ng-container>

    <ng-container *ngIf="data.edit.inputType === InputType.PHONE && (edit || independent)">
        <div class="flex justify-end items-center relative">
            <app-select class="flex-auto w-48 z-10" [translateValue]="translateValue" [elements]="countries" [id]="dialCodeCountry" [image]="true" [value]="dialCodeCountry" (change)="changeEventEmitter.emit($event)" [hasErrors]="hasErrors" />
            <input [type]="data.edit.inputType" [value]="data.value || ''" (change)="changeEventEmitter.emit($event)" class="block w-full h-10 -ml-2 z-0 px-3 py-2 bg-white border rounded-s rounded-e  text-base font-normal placeholder-slate-400 focus:outline-none" [required]="data.edit.required" [disabled]="data.edit.disabled" [ngClass]="{'invalid': hasErrors}" />
            <img [src]="images.error" class="absolute mr-2 w-6" alt="errorIcon" *ngIf="hasErrors" />
        </div>
        <label class="text-xs hasErrors" *ngIf="hasErrors">{{ error }}</label>
    </ng-container>

    <ng-container *ngIf="data.edit.inputType === InputType.SELECT && (edit || independent)">
        <app-select [elements]="data.edit.values" [value]="data.value" [id]="data.edit.id" [translateValue]="translateValue" (change)="changeEventEmitter.emit($event)" [required]="data.edit.required" [disabled]="data.edit.disabled" [hasErrors]="hasErrors" [error]="error" />
    </ng-container>

    <ng-container *ngIf="data.edit.inputType === InputType.AUTOCOMPLETE_SEARCH && (edit || independent)">
        <select2 [id]="data.title" customSearchEnabled="true" [data]="data.edit.values" [value]="data.value" [disabled]="data.edit.disabled" [placeholder]="data.placeholder" (update)="setEventEmitter.emit($event)" (open)="onFocusEventEmitter.emit($event)" (close)="onClose()">
            <ng-template #option let-data="data">{{ data?.name }}</ng-template>
        </select2>
        @if(searching) {
         <div class="absolute w-full mt-10 p-1 bg-white border border-slate-400 rounded-b z-[2000] flex justify-center">
            <img [src]="images.loading" alt="loading" class="h-6 w-6 ml-4" />
         </div>
        }
    </ng-container>

    <ng-container *ngIf="data.edit.inputType === InputType.MULTISELECT && (edit || independent)">
        <app-multi-select [title]="data.title" [elements]="data.edit.values" [values]="data.edit.selectedValues" [translateValue]="translateValue" [width]="'72'" [showSearch]="data.edit.showSearch" [required]="data.edit.required" [disabled]="data.disabled" [hasErrors]="hasErrors" [error]="error" (onBlurEventEmitter)="changeEventEmitter.emit($event)" />
    </ng-container>

    <ng-container *ngIf="data.edit.inputType === InputType.DATE && (edit || independent)">
        <app-calendar [title]="data.title" [value]="data.value" [required]="data.edit.required" [disabled]="data.edit.disabled" [showOnTop]="data.calendar.showOnTop" [onlyFutureDays]="data.calendar.onlyFutureDays" [daysInAdvance]="data.calendar.daysInAdvance" [hideCalendar]="data.calendar.hideCalendar" [showYear]="data.calendar.showYear" [possibleMultipleDates]="data.calendar.possibleMultipleDates" [fullData]="data.fullData" (change)="change($event)" />
    </ng-container>

    <ng-container *ngIf="data.edit.inputType === InputType.INFINITE_SCROLL && (edit || independent)">
        <select2 #infiniteScroll class="block" [data]="data.edit.values" [value]="data.value" infiniteScroll="true" customSearchEnabled="true" (update)="setEventEmitter.emit($event)" (scroll)="scrollEventEmitter.emit($event)" (search)="searchSelect2($event)" [disabled]="data.edit.disabled" />
    </ng-container>

    <ng-container *ngIf="data.edit.inputType === InputType.SELECT_SEARCH && (edit || independent)">
        <select2 class="block" [data]="data.edit.values" [value]="data.value" [disabled]="data.edit.disabled" [multiple]="data.edit.multiple" (update)="setEventEmitter.emit($event)" (search)="searchEventEmitter.emit($event)" />
    </ng-container>

    <ng-container *ngIf="data.edit.inputType === InputType.SEATS && (edit || independent)">
        <app-seats-input class="block ml-20 mr-20" [data]="data.edit.values" [value]="data.value" (changeEventEmitter)="changeEventEmitter.emit($event)" />
    </ng-container>

    <ng-container *ngIf="data.edit.inputType === InputType.COLOR && (edit || independent)">
        <!-- <app-color [type]="data.edit.inputType" [value]="data.value" (click)="cancelEvent($event)" class="block w-full h-12 px-3 py-2 bg-white border rounded-s rounded-e text-base font-normal placeholder-slate-400 focus:outline-none" (change)="changeEventEmitter.emit($event)" [required]="data.edit.required" [disabled]="data.edit.disabled" [ngClass]="{'invalid': hasErrors}" /> -->
         @if (formGroup && data.edit.formControlName) {
            <app-color [type]="data.edit.inputType" [value]="data.value" [values]="data.edit.values" [formGroup]="formGroup!" [formControlName]="data.edit.formControlName" (changeEventEmitter)="changeEventEmitter.emit($event)" />
         } @else {
            <app-color [type]="data.edit.inputType" [value]="data.value" [values]="data.edit.values" (changeEventEmitter)="changeEventEmitter.emit($event)" />
         }
    </ng-container>

    <ng-container *ngIf="data.edit.inputType === InputType.MULTISELECT_CHECKBOXES && (edit || independent)">
         <app-multiselect-checkboxes [title]="data.title" [elements]="data.edit.values" [values]="data.edit.selectedValues" [translateValue]="translateValue" [extraInfo]="data.edit.extraInfo" (changeEventEmitter)="changeEventEmitter.emit($event)" />
    </ng-container>

    <ng-container *ngIf="data.edit.inputType === InputType.SELECT_WITH_ENUM && (edit || independent)">
        <app-select-with-enum [type]="data.edit.inputType" [value]="data.value" [id]="data.edit.id" [values]="data.edit.values" [translateValue]="translateValue" (changeEventEmitter)="changeEventEmitter.emit($event)" />
    </ng-container>
</div>


<article id="favouriteRoutes" class="flex flex-col absolute top-24 rounded-lg justify-start items-start gap-2.5 p-4">
    <label class="font-medium text-sm neutrals-grey-06-text">{{ literalService.get('favourites.routes', true)}}</label>
    <form #form novalidate (ngSubmit)="onSubmit()">
        @for (item of [0, 1, 2]; track $index) {
            <section class="flex w-full pt-2 pb-2 pl-8 pr-8 mb-2 items-center justify-start gap-9 bg-white rounded">
                <div class="flex items-center">
                    <input id="default-radio-1" type="radio" value="" name="default-radio" class="w-4 h-4">
                    <label for="default-radio-1" class="ms-2 text-sm font-medium">14:30</label>
                </div>
                <div class="divider-horizontal"></div>
                <label class="text-sm font-medium">Área 1</label>
                <div class="divider-horizontal"></div>
                <div class="flex items-center">
                    <img [src]="images.origin" alt="origin" class="w-4 h-4 mr-1" />
                    <label class="text-sm font-medium">Origin</label>
                </div>
                <div class="divider-horizontal"></div>
                <div class="flex items-center">
                    <img [src]="images.destination" alt="destination" class="w-4 h-4 mr-1" />
                    <label class="text-sm font-medium">Destination</label>
                </div>
                <div class="divider-horizontal"></div>
                <div class="flex items-center">
                    <img [src]="images.pax" alt="destination" class="w-4 h-4 mr-1" />
                    <label class="text-sm font-medium">3</label>
                </div>
                <div class="flex items-center">
                    <img [src]="images.prm" alt="destination" class="w-4 h-4 mr-1" />
                    <label class="text-sm font-medium">0</label>
                </div>
            </section>
        } @empty {
            Empty list of users
        }
        <div class="flex justify-end w-full mt-2.5">
            <input type="button" class="secondary bg-white gap-4 p-2 border-2 font-semibold h-12 w-40 uppercase cursor-pointer" [value]="literalService.get('favourites.book', true)" />
        </div>
    </form>
</article>

<div id="mapContainer" class="pt-20 pl-20" [ngClass]="{
        'sm:h-[calc(100vh+80px)] 2xl:h-[100vh]': (editService || newService || routeSelected) && !editRoute,
        'sm:h-[calc(100vh+160px)] 2xl:h-[100vh]': editRoute || newRoute ,
        'h-[100vh]': !(editService || newService || routeSelected || editRoute || newRoute)}">
    <app-service-modal [hidden]="routeSelected" class="absolute mt-5 ml-5 z-10" (addService)="addService()" (selectServiceInfo)="getServiceInfoDetail($event)" (openGTFSModal)="servicesGtfsModal.toggle()" (openExportGTFSModal)="servicesExportGtfsModal.toggle()" (openImportCSVModal)="servicesImportCsvModal.toggle()"  (showRoutes)="showRoutesEvent($event)"></app-service-modal>
    @if (showRoutes && routes) {
        <div [ngClass]="{'ml-16': !routeSelected, 'ml-5': routeSelected}">
            <app-service-routes-modal class="absolute mt-5 z-10" [ngClass]="{'ml-96': !routeSelected}" [routes]="routes" (selectRoute)="getRouteDetail($event)" (createEventEmitter)="addRoute()" (close)="closeRoutesModal()"></app-service-routes-modal>
        </div>
    }
    <app-change-area [fromLocation]="'services'" (changeEventEmitter)="filterByTowns($event)"></app-change-area>
    <div id="map" class="w-full h-full"></div>
    <!-- @if (serviceInfoSelected || newService) { -->
    @if (!showRoutes) {
        <app-service-details [serviceInfoDetail]="serviceInfoSelected || newService" [edit]="editService" [newService]="newService" [newServiceObject]="newServiceObject" (editEvent)="editServiceEvent()" (editEventEmitter)="updateService($event)" (createEventEmitter)="createService($event)" (closeDetails)="closeServiceDetails()"></app-service-details>
    }
    <!-- } -->
    @if (showRoutes) {
        <app-service-routes-details [route]="routeSelected || newRoute" [edit]="editRoute" [newRoute]="newRoute" [newRouteObject]="newRouteObject" [town]="routes.value[0].town" [managerClientName]="routes.value[0].managerClientName" (editEvent)="editRouteEvent()" (editEventEmitter)="updateRoute($event)" (createEventEmitter)="createRoute($event)" (closeDetails)="closeRouteDetails()"></app-service-routes-details>
    }
</div>
<!-- @if (servicesGtfsShowed) { -->
    <app-modal-gtfs [id]="'servicesGtfs'" [modal]="servicesGtfsModal" [hidden]="!servicesGtfsShowed" [edit]="false" [loading]="importingGTFS" (action)="importGTFS($event)"></app-modal-gtfs>
    <app-modal-upload-csv [id]="'servicesImportCsv'" [modal]="servicesImportCsvModal" [hidden]="!servicesImportCsvShowed" [loading]="importingCSV" (action)="importCSV($event)"></app-modal-upload-csv>
    <app-modal-export-gtfs [id]="'servicesExportGtfs'" [modal]="servicesExportGtfsModal" [hidden]="!servicesExportGtfsShowed" [serviceInfos]="allServices" [loading]="exportingGTFS" (action)="exportGTFS($event)"></app-modal-export-gtfs>
<!-- } -->
<select #select class="block w-full h-10 px-3 py-2 text-base font-normal bg-white border rounded-s rounded-e placeholder-slate-400 focus:outline-none" [ngClass]="{'simple': isSimple, 'withImage': haveImage, 'invalid': hasErrors}" [(ngModel)]="itemSelected" [ngModelOptions]="{standalone: true}" (change)="changeEventEmitter.emit(itemSelected)" [disabled]="disabled!" [required]="required!">
    @if (placeholder) {
        <option [value]="''" [selected]="this.id === ''">
            {{ placeholder }}
        </option>
    }
    @if (elements!.length > 0) {
        @for (item of elements; track $index) {
            <option [value]="item.id" [selected]="this.id === item.id">
                <label>{{ image ? item.image + " " + item.name : translateValue ? literalService.get(item.id.toString().toLowerCase(), true) : getValueToShow(item) }}</label>
            </option>
        }
    }
    <img [src]="images.error" class="absolute mr-8 w-6" alt="errorIcon" *ngIf="hasErrors" />
</select>
<label class="text-xs hasErrors" *ngIf="hasErrors">{{ error }}</label>
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService } from '../http/base.http.service';
import { ApiRouterService } from '../http/api.router';
import { RouteApiRoutes } from './route-api.routes';
import { objectToSnake, toSnake } from 'ts-case-convert';
import { IRouteDetails } from 'src/app/models';

@Injectable({
  providedIn: 'root',
})
export class RouteHttpService extends BaseHttpService {
  private baseURL = `services`;

  constructor(
    @Inject(HttpClient) httpClient: HttpClient,
    private routerService: ApiRouterService,
  ) {
    super(httpClient);
  }

  public getServices(page: number, size: number) {
    const url = this.routerService.mount(`${this.baseURL}?page=${page}&size=${size}`);
    return this.read(url);
  }

  public getService(idService: number) {
    const url = this.routerService.mount(`${this.baseURL}/${idService}`);
    return this.read(url);
  }

  public getServiceConfig(idService: number) {
    const url = this.routerService.mount(`${this.baseURL}/${idService}/config`);
    return this.read(url);
  }

  public getAvailablities(serviceId: number, page?: number, size?: number) {
    const url = this.routerService.mount(`${this.baseURL}/${serviceId}/availabilities?page=${page}&size=${size}`);
    return this.read(url);
  }

  public getAvailablityStops(availabilityId: number, page: number, size: number, inStopId?: number) {
    const inStopIdString = inStopId ? `&in_stop_id=${inStopId}` : '';
    const url = this.routerService.mount(`${this.baseURL}/${availabilityId}/availability-stops?page=${page}&size=${size}${inStopIdString}`);
    return this.read(url);
  }

  public importServiceFromGtfs(data: any, clientId: number, townId: number) {
    let url = this.routerService.mount(`${this.baseURL}/${RouteApiRoutes.IMPORT_GTFS}?client_id=${clientId}&town_id=${townId}`);
    const formData: FormData = new FormData();
    data.forEach((element: any) => {
      formData.append(toSnake(element.type), data.find((u: any) => u.type === element.type)!.file.value);
    });
    console.log(url, formData);
    return this.createMultipart(url, formData);
  }

  public importServiceFromCsv(data: any, clientId: number, townId?: number, townName?: string) {
    let url = this.routerService.mount(`${this.baseURL}/${RouteApiRoutes.IMPORT_CSV}?client_id=${clientId}`);
    url += townId ? `&town_id=${townId}` : '';
    url += townName ? `&town_name=${townName}` : '';
    const formData: FormData = new FormData();
    formData.append('services', data)
    console.log(url, formData);
    return this.createMultipart(url, formData);
  }

  public downloadGtfs(routeId: number) {
    let url = this.routerService.mount(`${this.baseURL}/${routeId}/${RouteApiRoutes.DOWNLOAD_GTFS}`);
    return this.readZip(url);
  }
  
  public getRoutesByServiceInfo(serviceIds: number[], page?: number, size?: number) {
    let url = this.routerService.mount(`${this.baseURL}?serviceInfos=${serviceIds}`);
    url += page && `&page=${page}`;
    url += size && `&size=${size}`;
    return this.read(url);
  }

  public getRelateds(serviceId: number) {
    let url = this.routerService.mount(`${this.baseURL}/${serviceId}/related`);
    return this.read(url);
  }

  public getSaes() {
    let url = this.routerService.mount(`${this.baseURL}/${RouteApiRoutes.GET_SAES}`);
    return this.read(url);
  }

  public updateRoute(route: IRouteDetails) {
    let url = this.routerService.mount(`${this.baseURL}/${route.id}`);
    return this.update(objectToSnake(route), url);
  }

  public createRoute(newRoute: IRouteDetails) {
    let url = this.routerService.mount(`${this.baseURL}`);
    return this.create(objectToSnake(newRoute), url);
  }

  public getTerrains(townIds?: number[]) {
    let url = this.routerService.mount(`${this.baseURL}/${RouteApiRoutes.GET_TERRAINS}`);
    url += townIds && `?townIds=${townIds}`;
    return this.read(url);
  }

}

/* eslint-disable @angular-eslint/use-lifecycle-interface */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Modal } from 'flowbite';
import { IBookingCreate } from 'src/app/models';
import { LiteralService } from 'src/app/services';
import { images } from 'src/images';

@Component({
    selector: 'app-modal-blocked-user-booking',
    templateUrl: './modal-blocked-user-booking.component.html',
    styleUrl: './modal-blocked-user-booking.component.scss',
    standalone: false
})
export class ModalBlockedUserBookingComponent {

  @Input() id: string;
  @Input() modal: Modal;
  @Input() title: string;
  @Input() text: string;
  @Input() img: string;
  @Input() newBooking: IBookingCreate;

  @Input() penaltyDate: Date;
  @Input() penaltyReason: string;
  @Output() action = new EventEmitter<any>();
  
  public images = images;
  
  constructor(public literalService: LiteralService) {
  }

  formatDate = (date: Date): string => {
    if (date !== undefined) {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
      const year = date.getFullYear();
    
      return `${day}/${month}/${year}`;
    }
    return "";
  };
  
  onSubmit = () => {
    this.modal.hide();
  };
}

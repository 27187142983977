@if (formDesign) {
    <div [id]="'input-'+title" #input class="input bg-white border rounded-t flex justify-end items-center rounded-b" [ngClass]="{'rounded-b': isHidden, 'rounded-none': !isHidden}" [ngClass]="{'invalid': hasErrors}">
        <input [id]="'dataText-'+title" #readOnlyInput (focus)="isHidden = false" [(ngModel)]="textValues" [ngModelOptions]="{standalone: true}" readonly class="border-none z-0 block w-full h-full px-3 py-2 bg-white border rounded-s rounded-e text-base font-normal placeholder-slate-400 focus:outline-none" />
        <img [src]="images.error" class="absolute mr-8 w-6" alt="errorIcon" *ngIf="hasErrors" />
        <img [hidden]="!isHidden" (click)="readOnlyInput.focus()" [src]="images.arrowDown" class="h-3 w-3 my-2 mx-3" />
        <img [hidden]="isHidden" (click)="isHidden = true" [src]="images.arrowUp" class="h-3 w-3 my-2 mx-3" />
    </div>
    <label class="text-xs hasErrors" *ngIf="hasErrors">{{ error }}</label>
    <div [id]="'dropdown-'+title" #dropdown [class]="'dropdown shadow z-50 absolute bg-white border rounded rounded-t-none border-t-0 ' + 'w-' + width" [ngClass]="{ 'fadeIn': !isHidden, 'fadeOut': isHidden, 'search': showSearch }">
        @if (showSearch) {
            <div class="border rounded-s rounded-e flex justify-start items-center m-2">
                <div class="relative w-full">
                    <div class="absolute inset-y-0 left-0 flex items-center pl-2 pointer-events-none">
                        <img [src]="images.search" class="w-6 h-6 text-gray-500 dark:text-gray-400" />
                    </div>
                    <input #search [id]="'search-'+title" (focus)="isHidden = false" [(ngModel)]="searchableText" [ngModelOptions]="{standalone: true}" (ngModelChange)="filterElements()" [placeholder]="'Search'" class="border-none pl-9 z-0 block w-full h-full px-3 py-2 bg-white border rounded-s rounded-e text-sm placeholder-slate-400 focus:outline-none" />
                </div>
            </div>
        }
        @for (item of elements; track $index) {
            <div [id]="getValue(item)" class="flex justify-start items-center w-full" [ngClass]="{'[&:not(:first-child)]:border-t': !showSearch, 'border-t': showSearch}">
                <input [id]="'checkbox'+getValue(item)" data-value="{{ item.id || item }}" type="checkbox" class="ml-2 focus:ring-transparent" (ngModelChange)="onChange($event)" [checked]="checked(item)" />
                <label [id]="'label'+getValue(item)" [for]="'checkbox'+getValue(item)" class="text-base font-normal truncate pl-2 w-full" [tooltip]="getValue(item)" [position]="TooltipPosition.DYNAMIC" [visibleTooltip]="utilsService.visibleTooltip('label'+getValue(item))">{{ getValue(item) }}</label>
            </div>
        }
    </div>
} @else {
    <div [id]="'dropdown-'+title" #dropdown [class]="'dropDownPill shadow z-50 absolute rounded rounded-t-none border-t-0 max-h-[500px] ' + 'w-' + width" [ngClass]="{ 'fadeIn': showDropDown, 'fadeOut': !showDropDown }">
        <!-- <div class="border rounded-s rounded-e flex justify-start items-center m-2">
            <div class="relative w-full">
                <div class="absolute inset-y-0 left-0 flex items-center pl-2 pointer-events-none">
                    <img [src]="images.search" class="w-6 h-6 text-gray-500 dark:text-gray-400" />
                </div>
                <input #search [id]="'search-'+title" (focus)="isHidden = false" [(ngModel)]="searchableText" [ngModelOptions]="{standalone: true}" (ngModelChange)="filterElements()" [placeholder]="'Search'" class="border-none pl-9 z-0 block w-full h-full px-3 py-2 bg-white border rounded-s rounded-e text-sm placeholder-slate-400 focus:outline-none" />
            </div>
        </div> -->
        @for (item of elements; track $index) {
            <div [id]="item.id" class="border rounded flex justify-start items-center mb-1 w-full bg-white first:mt-1" [tooltip]="item.name || item.code || item.id" [position]="TooltipPosition.DYNAMIC" [visibleTooltip]="utilsService.visibleTooltip(item.id)">
                <input [id]="'checkbox'+(item.id || item.name || item.code)" data-value="{{ item.id }}" type="checkbox" class="ml-2 p-2 focus:ring-transparent" (ngModelChange)="onChange($event)" [checked]="checked(item)" />
                <label [id]="'label'+(item.id || item.id || item.code)" [for]="'checkbox'+(item.id || item.name || item.code)" class="text-base font-normal cursor-pointer p-2 w-full truncate">{{ item.name || item.code || item.code }}</label>
            </div>
        }
    </div>
}
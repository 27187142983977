import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService } from '../http/base.http.service';
import { ApiRouterService } from '../http/api.router';
import { IServiceInfoDetail, ITown } from 'src/app/models';
import { objectToSnake } from 'ts-case-convert';
import { ServiceInfoApiRoutes } from './service-info-api.routes';

@Injectable({
  providedIn: 'root',
})
export class ServiceInfoHttpService extends BaseHttpService {
  private baseURL = `service-infos`;

  constructor(
    @Inject(HttpClient) httpClient: HttpClient,
    private routerService: ApiRouterService,
  ) {
    super(httpClient);
  }

  public getServiceInfos(size: number, page: number, towns?: ITown[]) {
    const townIds = towns && towns.map((town: ITown) => town.id);
    let url = this.routerService.mount(`${this.baseURL}?page=${page}&size=${size}`);
    url += townIds ? `&towns=${townIds}` : '';
    return this.read(url);
  }

  public getServiceInfo(serviceId: number) {
    const url = this.routerService.mount(`${this.baseURL}/${serviceId}`);
    return this.read(url);
  }

  public getServiceInfosByTowns(townIds: number[]) {
    const url = this.routerService.mount(`${this.baseURL}/${ServiceInfoApiRoutes.TOWNS}?town_ids=${townIds}`);
    return this.read(url);
  }

  public createService(newService: IServiceInfoDetail) {
    const url = this.routerService.mount(`${this.baseURL}`);
    const body = objectToSnake(
      {
        name: newService.name,
        tag: newService.tag,
        color: newService.color,
        forwardDirection: newService.forwardDirection,
        backwardsDirection: newService.backwardsDirection,
        showTheoreticalTimes: newService.showTheoreticalTimes,
        showRealTimes: newService.showRealTimes,
        sortCriteria: newService.sortCriteria,
        bookingTimePicker: newService.bookingTimePicker,
        towns: newService.towns.map((town: any) => {
          return { id: town.id, name: town.name}
        }),
        clients: newService.clients.map((client: any) => {
          return { id: client.id, name: client.name}
        })
      }
    );
    console.log(body)
    return this.create(body, url);
  }

  public updateService(service: IServiceInfoDetail) {
    const url = this.routerService.mount(`${this.baseURL}/${service.id}`);
    const body = objectToSnake(
      {
        id: service.id,
        name: service.name,
        tag: service.tag,
        color: service.color,
        forwardDirection: service.forwardDirection,
        backwardsDirection: service.backwardsDirection,
        showTheoreticalTimes: service.showTheoreticalTimes,
        showRealTimes: service.showRealTimes,
        sortCriteria: service.sortCriteria,
        bookingTimePicker: service.bookingTimePicker,
        routes: service.routes
        // towns: service.towns,
        // clients: service.clients
        // townId: service.towns![0].id,
        // clientId: service.clients![0].id
      }
    );
    return this.update(body, url);
  }

  public exportServiceFromGtfs(serviceInfoId: number) {
    let url = this.routerService.mount(`${this.baseURL}/${serviceInfoId}/${ServiceInfoApiRoutes.EXPORT_GTFS}`);
    return this.readZip(url);
  }
}

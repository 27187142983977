import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Modal } from 'flowbite';
import { LiteralService } from 'src/app/services';
import { images } from 'src/images';

@Component({
    selector: 'app-modal-max-reservation-time',
    templateUrl: './modal-max-reservation-time.component.html',
    styleUrl: './modal-max-reservation-time.component.scss',
    standalone: false
})
export class ModalMaxReservationTimeComponent {

  @Input() id: string;
  @Input() modal: Modal;
  @Input() title: string;
  @Input() text: string;
  @Input() img: string;
  @Output() action = new EventEmitter<any>();

  public images = images;

  constructor(public literalService: LiteralService) { }

  onSubmit = () => {

  };

}

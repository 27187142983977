<!-- <div modal-backdrop class="bg-gray-900 bg-opacity-50 dark:bg-opacity-80 absolute inset-0 z-40"></div> -->
<div [id]="id" tabindex="-1" class="absolute top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
    <div class="relative xl:w-5/12 2xl:w-1/3 max-h-full rounded-lg primary-purple-light-2-background">
        <div class="relative rounded-lg shadow dark:bg-gray-700">
            <button (click)="modal.hide()" type="button" class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="addToAppModal">
                <img [src]="images.close" class="h-6 w-6" />
            </button>
            <div class="p-6 pt-5">
                <h3 class="text-base text-center font-bold mb-5 text-gray-500 dark:text-gray-400 mt-5">{{ literalService.get(text, true) }}</h3>
                <!-- <app-checkbox *ngFor="let value of values" [value]="value" [checked]="value.userInApp" (change)="value.userInApp = !value.userInApp" /> -->
                <div *ngFor="let value of values" class="flex items-center mb-4 bg-white p-4">
                    <label>{{ value.name }}</label>
                    <div class="flex ml-auto">
                        <button type="button" class="primary text-white gap-4 p-2 border-2 font-semibold w-[7.5rem] uppercase cursor-pointer mr-2" (click)="action.emit(value.id)">{{ literalService.get('add', true) }}</button>
                        <!--button type="button" class="secondary gap-4 p-2 border-2 font-semibold w-[7.5rem] uppercase cursor-pointer bg-white" (click)="action.emit(value.id)">{{ literalService.get('reset', true) }}</button-->
                    </div>
                </div>
                <!-- <button data-modal-hide="addToAppModal" type="button" class="secondary gap-4 p-4 border-2 font-semibold h-14 w-[7.5rem] uppercase cursor-pointer bg-white" (click)="action.emit(values)">{{ literalService.get('add', true) }}</button> -->
            </div>
        </div>
    </div>
</div>
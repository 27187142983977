import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService } from '../http/base.http.service';
import { ApiRouterService } from '../http/api.router';
import { SingleStopReservationType } from 'src/app/models/enums';

@Injectable({
  providedIn: 'root',
})
export class TownsHttpService extends BaseHttpService {
  private baseURL = `towns`;

  constructor(
    httpClient: HttpClient,
    private routerService: ApiRouterService,
  ) {
    super(httpClient);
  }

  public getTown(townId: number) {
    const url = this.routerService.mount(`${this.baseURL}/${townId}`);
    return this.read(url);
  }

  public getTowns(page: number = 1, size: number = 10) {
    const path = `?page=${page}&size=${size}`;
    const url = this.routerService.mount(`${this.baseURL}${path}`);
    return this.read(url);
  }

  public getServices(townId: number) {
    const path = `/${townId}/services`;
    const url = this.routerService.mount(`${this.baseURL}${path}`);
    return this.read(url);
  }

  public getServicesList(townId: number, page?: number, size?: number) {
    const path = `/${townId}/services/list?page=${page}&size=${size}`;
    const url = this.routerService.mount(`${this.baseURL}${path}`);
    return this.read(url);
  }

  public getStopsFrom(townId: number, stopId?: number, mergedStops?: boolean) {
    let path = `/${townId}/stops-from`;
    path += stopId ? `?origin_stop_id=${stopId}` : '';
    path += mergedStops === false ? `${stopId ? '&' : '?'}merge_stops=${mergedStops}` : '';
    const url = this.routerService.mount(`${this.baseURL}${path}`);
    return this.read(url);
  }
  
  public getAvailabilities(townId: number, userId: number, inStopId: number, outStopId: number, seats: number, prmSeats: number, dateTimes: any, isArrivalTime: boolean = false, reservationId?: number, isSingleReservation?: boolean) {
    let singleStopReservationType = undefined;
    if (isSingleReservation) {
      singleStopReservationType = inStopId ? SingleStopReservationType.ORIGIN : SingleStopReservationType.DESTINATION;
    }
    let path = `/${townId}/closest-availabilities?in_stop_id=${singleStopReservationType === SingleStopReservationType.DESTINATION ? 0 : inStopId}&user_id=${userId}&date_times=${dateTimes}&is_arrival_time=${isArrivalTime}`;
    path += `&out_stop_id=${singleStopReservationType === SingleStopReservationType.ORIGIN ? 0 : outStopId}`;
    path += `&seats=${seats}`;
    path += `&prm_seats=${prmSeats}`;
    path += reservationId ? `&reservation_id=${reservationId}` : '';
    const url = this.routerService.mount(`${this.baseURL}${path}`);
    return this.read(url);
  }

  public getAvailabilitiesByPositions(townId: number, userId: number, inLatitude: number, inLongitude: number, inName: string, outLatitude: number, outLongitude: number, outName: string, seats: number, prmSeats: number, dateTimes: any, isArrivalTime: boolean = false, reservationId?: number) {
    let path = `/${townId}/closest-availabilities-position?user_id=${userId}&date_times=${dateTimes}&is_arrival_time=${isArrivalTime}`;
    path += `&in_latitude=${inLatitude}`;
    path += `&in_longitude=${inLongitude}`;
    path += `&in_name=${inName}`;
    path += `&out_latitude=${outLatitude}`;
    path += `&out_longitude=${outLongitude}`;
    path += `&out_name=${outName}`;
    path += `&seats=${seats}`;
    path += `&prm_seats=${prmSeats}`;
    path += reservationId ? `&reservation_id=${reservationId}` : '';
    const url = this.routerService.mount(`${this.baseURL}${path}`);
    return this.read(url);
  }
}

import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-marker-od',
    templateUrl: './marker-od.component.html',
    styleUrl: './marker-od.component.scss',
    standalone: false
})
export class MarkerOdComponent {
  @Input() text?: string;
  @Input() textColor: string;
  @Input() color: string;
  @Input() size: number = 6;

  getSize = () => {
    return `h-${this.size} w-${this.size}`;
  };

  getInnerSize = () => {
    return `h-${this.size / 2} w-${this.size / 2}`;
  };
}

import { Component, Input } from '@angular/core';
import { IBooking } from 'src/app/models';
import { images } from 'src/images';

@Component({
    selector: 'app-booking-seats',
    templateUrl: './booking-seats.component.html',
    styleUrl: './booking-seats.component.scss',
    standalone: false
})
export class BookingSeatsComponent {
  @Input() booking: IBooking;

  public images = images;
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService } from '../http/base.http.service';
import { ApiRouterService } from '../http/api.router';

@Injectable({
  providedIn: 'root',
})
export class TownsHttpService extends BaseHttpService {
  private baseURL = `towns`;

  constructor(
    httpClient: HttpClient,
    private routerService: ApiRouterService,
  ) {
    super(httpClient);
  }

  public getTown(townId: number) {
    const url = this.routerService.mount(`${this.baseURL}/${townId}`);
    return this.read(url);
  }

  public getTowns(page: number = 1, size: number = 10) {
    const path = `?page=${page}&size=${size}`;
    const url = this.routerService.mount(`${this.baseURL}${path}`);
    return this.read(url);
  }

  public getServices(townId: number) {
    const path = `/${townId}/services`;
    const url = this.routerService.mount(`${this.baseURL}${path}`);
    return this.read(url);
  }

  public getStopsFrom(townId: number, stopId: number) {
    const path = `/${townId}/stops-from/${stopId}`;
    const url = this.routerService.mount(`${this.baseURL}${path}`);
    return this.read(url);
  }

  public getAvailabilities(townId: number, userId: number, inStopId: number, dateTimes: any, is_arrival_time: boolean = false, outStopIds?: number[], reservationId?: number, prmOutStopIds?: number[]) {
    // const path = `/${townId}/closest-availabilities/${userId}`;
    let path = `/${townId}/closest-availabilities?in_stop_id=${inStopId}&user_id=${userId}&date_times=${dateTimes}&is_arrival_time=${is_arrival_time}`;
    path += outStopIds ? `&out_stop_ids=${outStopIds}` : '';
    path +=  reservationId ? `&reservation_id=${reservationId}` : '';
    // let exitPrmSeatsString = ''
    // if (exitPrmSeats > 0) {
    //   exitPrmSeatsString = repeatStringForService(
    //     '&prm-exit-stops=',
    //     destinationStopId,
    //     exitPrmSeats
    //   )
    // }
    path +=  prmOutStopIds ? `&prm_out_stop_ids=${prmOutStopIds}` : '';
    const url = this.routerService.mount(`${this.baseURL}${path}`);
    return this.read(url);
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IBase } from 'src/app/models';
import { UsersService } from 'src/app/services';
import { LiteralService } from 'src/app/services/literal/literal.service';
import { images } from 'src/images';
import { StorageService } from 'src/app/services';
import { from } from 'rxjs';

@Component({
    selector: 'app-change-area',
    templateUrl: './change-area.component.html',
    styleUrl: './change-area.component.scss',
    standalone: false
})
export class ChangeAreaComponent implements OnInit {
  @Input() fromLocation = 'areas';
  @Output() changeEventEmitter = new EventEmitter();
  public images = images;
  public towns: unknown[] = [];
  public selectedTowns: unknown[] = [];
  public showTowns = false;

  constructor(private usersService: UsersService,
              public literalService: LiteralService,
              private storageService: StorageService) {
  }

  async ngOnInit() {
    await this.usersService.getPossibleClientTowns().then((res) => {
      res.towns.sort((firstItem: IBase, secondItem: IBase) => firstItem.id! - secondItem.id!);
      this.towns = res.towns;
    });
    console.log(this.towns)
    switch (this.fromLocation) {
      case 'trips':
        this.storageService.getArea().then((areas: string) => {
          if (areas) this.selectedTowns = JSON.parse(areas);
        }, () => {
        });
        break;
      case 'services':
        this.storageService.getServicesAreas().then((areas: string) => {
          if (areas) this.selectedTowns = JSON.parse(areas);
        }, () => {
        });
        break;
    
      default:
        break;
    }
    
  }

  handleTowns = () => {
    this.showTowns = !this.showTowns;
  };

  openTowns = () => {
    this.showTowns = true;
  };

  closeTowns = () => {
    this.showTowns = false;
  };
}

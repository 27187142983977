import { Component, Input, output } from '@angular/core';
import { RouteService } from 'src/app/services';
import { images } from 'src/images';

@Component({
    selector: 'app-service-routes-row',
    templateUrl: './service-routes-row.component.html',
    styleUrl: './service-routes-row.component.scss',
    standalone: false
})
export class ServiceRoutesRowComponent {

  @Input() route: any;
  readonly selectRoute = output<number>();

  public images = images;

}

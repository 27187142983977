<div class="landscape h-screen w-screen bg-cover flex flex-col justify-center items-center">
  <!-- <p class="dark:text-white">sign-in works!</p> -->
  <!-- <app-dark-mode></app-dark-mode> -->
  <img [src]="images.logos.nemiWithoutBackground" class="w-96" />
  <form #form novalidate (ngSubmit)="onSubmit()">
    <div class="relative mb-6 mt-10">
      <div class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
        <img id="mailImg" [src]="images.login.mail" class="w-6" />
      </div>
      <input type="email" id="email" [placeholder]="literalService.get('login.mail', true)" [(ngModel)]="email" [ngModelOptions]="{standalone: true}" class="block pl-12 w-96 h-12 px-3 py-2 bg-white border rounded-s rounded-e text-sm shadow-sm placeholder-slate-400 focus:outline-none" />
    </div>
    <div class="relative mb-6">
      <div class="absolute z-10 inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
        <img id="passwordImg" [src]="images.login.password" class="w-6" />
      </div>
      <app-input-password [id]="'password'" [placeholder]="literalService.get('login.password', true)" (changeEventEmitter)="setPassword($event)" [hasIcon]="true" required />
    </div>
    <input type="submit" [disabled]="doingLogin" [value]="literalService.get('login.login', true)" class="primary cursor-pointer text-white font-semibold uppercase w-96 h-14" />
  </form>
</div>

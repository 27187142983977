import { Injectable } from '@angular/core';
import { ServiceInfoHttpService } from './service-info.http.service';
import { lastValueFrom } from 'rxjs';
import { objectToCamel } from 'ts-case-convert';
import { IServiceInfoDetail, ITown } from 'src/app/models';

@Injectable({
  providedIn: 'root'
})
export class ServiceInfoService {

  constructor(private serviceInfoHttpService: ServiceInfoHttpService) {}

  async getServiceInfos(size: number, page: number, towns?: ITown[]) {
    const source$ = this.serviceInfoHttpService.getServiceInfos(size, page, towns);
    const services = await lastValueFrom(source$);
    const result = this.getData(services);
    return result;
  }

  async getServiceInfo(serviceId: number) {
    const source$ = this.serviceInfoHttpService.getServiceInfo(serviceId);
    const response = await lastValueFrom(source$);
    const service = objectToCamel(response as object);
    return service;
  }

  async getServiceInfosByTowns(townIds: number[]) {
    const source$ = this.serviceInfoHttpService.getServiceInfosByTowns(townIds);
    const response = await lastValueFrom(source$);
    const service = objectToCamel(response as []);
    return service;
  }

  async createService(newService: IServiceInfoDetail) {
    const source$ = this.serviceInfoHttpService.createService(newService);
    const response = await lastValueFrom(source$);
    const service = objectToCamel(response as object);
    return service;
  }

  async updateService(serviceEdited: IServiceInfoDetail) {
    const source$ = this.serviceInfoHttpService.updateService(serviceEdited);
    const response = await lastValueFrom(source$);
    const service = objectToCamel(response as object);
    return service;
  }

  async exportServiceFromGtfs(serviceInfoId: number) {
    const source$ = this.serviceInfoHttpService.exportServiceFromGtfs(serviceInfoId);
    const response = await lastValueFrom(source$);
    return response;
  }
  
  getData(response: any) {
    return response.data;
  }
}

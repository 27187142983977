import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { StorageService, UtilsService } from 'src/app/services';
import { AuthService } from './auth.service';
import { RoleService } from '../role/role.service';
import { IUserPermission } from 'src/app/models/userPermission';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService {
  constructor(
    private auth: AuthService,
    public router: Router,
    private roleService: RoleService,
    private storageService: StorageService,
    private utilsService: UtilsService,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot) {
    if (!this.auth.isAuthenticated()) {
      this.router.navigate(['']);
      return false;
    }

    const allowedRoles: string[] = route.data['roles'] || [];
  
    const userRole = await this.storageService.getRole();
  
    if (!allowedRoles.includes(userRole)) {
      if (route.routeConfig?.path === 'services') {
        window.open(`${this.utilsService.getUrl()}services`, '_self');
      } else {
        this.router.navigate(['/']);
      }
      
      return false;
    }
  
    let path = route.routeConfig?.path;
    if (path) {
      path = path.split('/')[0];
      this.roleService.reloadPermissions();
      if (await this.entityPathExtractor(path) && path && localStorage.getItem('route') !== path) {
        localStorage.setItem('route', path);
        this.router.navigate([localStorage.getItem('route')!]);
      }
    }
  
    return true;
  }


  public async entityPathExtractor(path: string) {
    const pathParts = path.split('/');
    path = pathParts[0];
    const storedPermissions = await this.storageService.getPermissions();
    let permissionsArray: IUserPermission[] = [];
    if (storedPermissions) {
      permissionsArray = JSON.parse(storedPermissions);
    }
    for (let i = 0; i < permissionsArray.length; i++) {
      const permission = permissionsArray[i];
      switch (path) {
        case 'bookings': {
          if (permission.entityType === 'RESERVATION') {
            return true;
          }
          break;
        }
        case 'trips': {
          if (permission.entityType === 'EXPEDITION') {
            return true;
          }
          break;
        }
        default: {
          if (permission.entityType.toLowerCase() + 's' === path) {
            return true;
          }
          break;
        }
      }
    }
    return false;
  }
}

<div [id]="id" tabindex="-1" class="fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto h-full md:inset-0">
    <div class="w-72 max-h-full rounded-lg bg-white">
        <div class="fixed top-40 primary-purple-light-2-background pl-6 pr-6 rounded-lg shadow dark:bg-gray-700">
            <div class="flex justify-center">
                <h3 class="w-full text-base font-bold mb-3 dark:text-gray-400 mt-5">{{ literalService.get('bookings.modal.editField', true) }}</h3>
                <button (click)="modal.hide()" type="button"
                    class="absolute top-2 right-2 mr-2 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    data-modal-hide="changeSeats">
                    <img [src]="images.close" class="h-6 w-6" />
                </button>
            </div>
            <div class="pt-2 mb-5">
                <div class="flex items-center mb-2">
                    <img [src]="images.booking.seats" alt="detailImage" class="h-5 w-5 mr-1 imgColor" />
                    <label class="text-base font-normal">{{ literalService.get('bookings.data.seats', true)
                        }}</label>
                </div>
                @if(booking){
                    <app-seats-input [paxBooking]="booking.seats" [prmBooking]="booking.prmSeats" (changeEventEmitter)="change($event)" />
                }
            </div>
            <div class="flex items-center space-x-4 pb-4 w-full">
                <button data-modal-hide="changeSeats" type="button"
                    (click)="modal.hide()"
                    class="secondary mt-2 p-2 border-2 w-28 font-semibold uppercase cursor-pointer bg-white">{{
                    literalService.get('cancel', true) }}</button>
                <button data-modal-hide="changeSeats" type="button"
                    [disabled]="this.booking && this.booking.prmSeats == 0 && this.booking.seats == 0"
                    class="primary mt-2 p-2 border-2 w-28 font-semibold text-white uppercase cursor-pointer bg-white"
                    (click)="onSubmit()">{{ literalService.get('update', true) }}</button>
            </div>
        </div>
    </div>
</div>
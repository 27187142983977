@if (!isFullMap) {
  <section class="mt-28 ml-28 mr-28 h-full">
    <app-booking-search-bar
      [placeholder]="literalService.get('bookings.search', true)"
      [searching]="searching"
      (addBookingEventEmitter)="goToNewBooking()"
      (filter)="filter($event)"
      (searchEventEmitter)="search($event)"
    ></app-booking-search-bar>
  </section>
  <section class="mt-5 ml-28 mr-28 h-full">
    <div class="grid grid-cols-12 mb-4 text-center">
      <label class="ml-14 flex justify-center col-span-2 font-semibold text-base">
        <img [src]="images.booking.user" alt="user" class="h-6 w-6 mr-2" />
        {{ literalService.get("bookings.data.user", true) }}
      </label>
      <label class="flex justify-center col-span-2 font-semibold text-base">
        <img [src]="images.booking.service" alt="service" class="h-6 w-6 mr-2" />
        {{ literalService.get("bookings.data.service", true) }}
      </label>
      <label class="flex justify-center col-span-1 font-semibold text-base">
        <img [src]="images.booking.date" alt="date" class="h-6 w-6 mr-2" />
        {{ literalService.get("bookings.data.date", true) }}
      </label>
      <label class="flex justify-center col-span-4 font-semibold text-base">
        <img [src]="images.booking.stops" alt="stops" class="h-6 w-6 mr-2" />
        {{ literalService.get("bookings.data.stops", true) }}
      </label>
      <label class="flex justify-center col-span-1 font-semibold text-base">
        <img [src]="images.booking.seats" alt="seats" class="h-6 w-6 mr-2" />
        {{ literalService.get("bookings.data.seats", true) }}
      </label>
      <label
        class="col-span-2 flex justify-end items-center whitespace-nowrap font-semibold text-base"
      >
        {{
          bookings.pagination.totalElements &&
          (bookings.pagination.first
            ? 1
            : bookings.pagination.actualPage * 10 - 9) +
            "-" +
            (bookings.pagination.last
              ? bookings.pagination.totalElements
              : bookings.pagination.numberOfElements *
                bookings.pagination.actualPage) +
            " " +
            literalService.get("of") +
            " " +
            bookings.pagination.totalElements
        }}
        <img
          [src]="images.arrowLeft"
          alt="arrowLeft"
          class="h-5 w-5 ml-2 mr-2 cursor-pointer"
          [ngClass]="bookings.pagination.first ? 'disabled cursor-default' : ''"
          [attr.disabled]="bookings.pagination.first"
          (click)="!bookings.pagination.first && changePage(false)"
        />
        <img
          [src]="images.arrowRight"
          alt="arrowRight"
          class="h-5 w-5 mr-2 cursor-pointer"
          [ngClass]="bookings.pagination.last ? 'disabled cursor-default' : ''"
          [attr.disabled]="bookings.pagination.last"
          (click)="!bookings.pagination.last && changePage(true)"
        />
      </label>
    </div>
    <div id="bookingsContainer" *ngIf="!bookings.isFetchingBookings">
      <div
        id="{{'booking'+booking.id}}"
        class="bookingRow border rounded pt-2 pb-2 grid grid-cols-12 justify-center items-center mb-4"
        [ngClass]="{'blocked': booking.missed}"
        *ngFor="let booking of this.bookings.value; let i = index">
        <div [id]="'user-'+booking.id" class="item col-span-2 line-clamp-2 truncate" (click)="showBookingData(booking.id!)"  [tooltip]="utilsService.visibleTooltip('user-'+booking.id) ? booking.firstName + ' ' + booking.lastName : ''" [visibleTooltip]="utilsService.visibleTooltip('user-'+booking.id)" [position]="TooltipPosition.DYNAMIC" [ngClass]="{'h-full items-center justify-center pt-3': !utilsService.visibleTooltip('user-'+booking.id)}">
          <label class="h-12 truncate whitespace-pre-line pr-5 pl-5 font-semibold justify-center items-center text-base cursor-pointer">
            {{ booking.firstName }} {{ booking.lastName }}
          </label>
        </div>
        <div class="item col-span-2" (click)="showBookingData(booking.id!)">
          <label class="flex h-12 pr-5 pl-5 font-semibold text-base justify-between items-center cursor-pointer">
            <app-service-tag class="justify-self-start" [serviceInfo]="booking.service" />
            <span [id]="'service-'+booking.id" class="m-2 line-clamp-3" [tooltip]="utilsService.visibleTooltip('service-'+booking.id) ? booking.service.name : ''" [visibleTooltip]="utilsService.visibleTooltip('service-'+booking.id)" [position]="TooltipPosition.DYNAMIC">{{ booking.service && booking.service.name }}</span>
            <div></div>
          </label>
        </div>
        <div class="item col-span-1" (click)="showBookingData(booking.id!)">
          <label class="flex h-12 truncate pr-5 pl-5 justify-center items-center font-semibold text-base cursor-pointer">
            {{ booking.date | date: 'yyyy-MM-dd' }}
          </label>
        </div>
        <div class="item col-span-4 font-semibold text-base cursor-pointer" (click)="showBookingData(booking.id!)">
          <app-booking-stops [booking]="booking" [origin]="'origin'" />
          <app-booking-stops [booking]="booking" [destination]="'destination'" />
        </div>
        <div class="item truncate h-12 pr-5 pl-5 col-span-1 font-semibold text-base cursor-pointer" (click)="showBookingData(booking.id!)">
          <app-seats [booking]="booking" />
        </div>
        <div class="flex justify-center items-center col-span-2 cursor-default">
          <img [src]="images.user.star" alt="bookingFavorite" class="h-6 w-6 mr-4 cursor-pointer" [tooltip]="literalService.get('bookings.tooltips.favorite', true)" [position]="TooltipPosition.DYNAMIC" />
          <img [src]="images.trips.name" alt="bookingTrip" class="h-5 w-5 mr-4 cursor-pointer" [tooltip]="literalService.get('bookings.tooltips.trip', true)" (click)="goToTrip(booking.expeditionId)"/>
          <img [src]="images.pax" alt="bookingUser" class="h-6 w-6 cursor-pointer" [tooltip]="literalService.get('bookings.tooltips.user', true)" (click)="goToUser(booking.userId)"/>
        </div>
      </div>
    </div>
    <app-booking-details [booking]="bookingSelected"
                         (openInFullMap)="openInFullMap($event)"
                         (editBooking)="editBooking()"
                         (deleteBooking)="deleteBookingModal.toggle()"
                         (markAsAFault)="markAsFaultModal.toggle()"
                         (closeDetails)="closeDetails()">
                        </app-booking-details>
  </section>
} @else {
  <div id="mapContainer" class="pt-20 pl-20 h-[1000px]" [ngClass]="{'hidden': !isFullMap}">
    <img [src]="images.close" class="absolute bg-white rounded-[50%] z-10 h-8 w-8 right-5 p-1 mt-4 mr-4 cursor-pointer" (click)="close()" />
    @if (edit || newBooking) {
      <app-booking-modal class="absolute mt-5 ml-5 z-10" [newBooking]="newBookingObject" [creatingBooking]="creatingBooking" (selectODEventEmitter)="selectOD($event)" (getServiceInfosEventEmitter)="getServiceInfos($event)" (goToList)="bookingCreated()"  (showMaxReservationTimeModalEventEmitter)="showMaxReservationTimeModal()" (showExceedingKmLimitModalEventEmitter)="showExceedingKmLimitModal($event)" (selectStop)="selectStop($event)"></app-booking-modal>
    }
    @if (edit || newBooking) {
      <app-marker-od id="origin" class="hidden" [textColor]="'text-white'" [color]="'primary-purple-light-background'" [text]="literalService.get('origin').toUpperCase()"></app-marker-od>
      <app-marker-od id="destination" class="hidden" [textColor]="'text-black'" [color]="'primary-green-background'" [text]="literalService.get('destination').toUpperCase()"></app-marker-od>
    }
    <app-marker-stop [id]="'stopMarker'" class="invisible" [color]="'#000000'"></app-marker-stop>
    <app-marker-stop-disabled [id]="'stopMarkerDisabled'" class="invisible" [color]="'#848ea8'"></app-marker-stop-disabled>
    <div id="map" class="w-full h-full"></div>
  </div>
}
<app-modal-confirmation-text [id]="'deleteBooking'" [modal]="deleteBookingModal" [hidden]="!deleteBookingShowed" [text]="'bookings.actions.deleteBooking.description'" [title]="'bookings.actions.deleteBooking.name'" [reason]="reason" (action)="deleteBookingAction($event)" [deleting]="deletingBooking" />
<app-modal-max-reservation-time [id]="'maxReservationTime'" [modal]="maxReservationTimeModal" [img]="images.booking.maxReservationTime" [title]="literalService.get('bookings.maxReservationTime.title')" [text]="literalService.get('bookings.maxReservationTime.text')" [hidden]="!maxReservationTimeShowed"></app-modal-max-reservation-time>
<app-modal-exceeding-km-limit [id]="'exceedingKmLimit'" [modal]="exceedingKmLimitModal" [img]="images.booking.warning" [title]="literalService.get('bookings.kmLimitExceeded.title')" [text]="literalService.get('bookings.kmLimitExceeded.text')" [hidden]="!true" [newBooking]="newBookingObject" [kmDone]="distanceTravelled" [kmLimit]="maxKmsPerMonth" (action)="createBooking($event)"></app-modal-exceeding-km-limit>
<app-modal-confirmation [id]="'markAsFault'" [modal]="markAsFaultModal" [hidden]="!markAsFaultModalShowed" [text]="(bookingSelected && bookingSelected.missed) ? 'bookings.actions.unMarkAsAFault.modalText' : 'bookings.actions.markAsAFault.modalText'" [title]="(bookingSelected && bookingSelected.missed) ? 'bookings.actions.unMarkAsAFault.modalTitle' : 'bookings.actions.markAsAFault.modalTitle'" (action)="markAsAFault()" />

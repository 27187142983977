import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-color-detail',
  templateUrl: './color.component.html',
  styleUrl: './color.component.scss',
  standalone: false
})
export class ColorComponent {
  @Input() color: string;
}

<div [id]="id" tabindex="-1" class="modal absolute top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
    <div class="relative w-96 max-h-full rounded-lg">
        <div class="relative primary-purple-light-2-background rounded-lg shadow dark:bg-gray-700">
            @if (!deleting) {
                <div class="items-center p-2 pl-6 pr-6">
                    <h3 class="w-full text-xl font-bold text-gray-500 dark:text-gray-400 mt-5">{{ literalService.get(title, true) }}</h3>
                    <p class="w-full whitespace-pre-line text-base font-semibold text-gray-500 dark:text-gray-400">{{ literalService.get(text, true) }}</p>
                </div>
                <div class="pl-6 pr-6 pt-2 pb-2 mb-2">
                    <div class="flex">
                        <img [src]="images.comment" alt="comment" class="h-6 w-6 mb-2 mr-2" />
                        <label class="font-normal text-base">{{ literalService.get('comment', true) }}</label>
                    </div>
                    <textarea [(ngModel)]="reason" (change)="change(reason)" [ngModelOptions]="{standalone: true}" [placeholder]="literalService.get('writeComment', true)" class="block w-full h-32 px-3 py-2 bg-white border rounded-s rounded-e text-base font-normal shadow-sm placeholder-slate-400 focus:outline-none"></textarea>
                </div>
            }
            @if (deleting) {
                <article class="flex w-full pb-6 pt-4 ml-10 pl-7 pr-7 justify-center items-center">
                    <ng-lottie width="75%" height="75%" [options]="optionsBus" (animationCreated)="animationCreated($event)" />
                </article>
            }
            @if (!deleting) {
                <div class="flex justify-center items-center pb-4">
                    <button type="button" class="secondary mt-2 mr-10 gap-4 p-4 border-2 font-semibold h-14 w-[7.5rem] uppercase cursor-pointer bg-white" (click)="modal.hide()">{{ literalService.get('cancel', true) }}</button>
                    <button type="button" class="primary mt-2 gap-4 p-4 border-2 font-semibold h-14 w-[7.5rem] uppercase cursor-pointer text-white" (click)="action.emit(reason)">{{ literalService.get('confirm', true) }}</button>
                </div>
            }
            @if (deleting) {
                <div class="flex items-center justify-center">
                    <h3 class="text-xl font-bold mb-4 -mt-4 text-gray-500 dark:text-gray-400 primary-purple-light-text">{{ literalService.get(id, true)}}</h3>
                </div>
            }
        </div>
    </div>
</div>
import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-toggle',
    templateUrl: './toggle.component.html',
    styleUrl: './toggle.component.scss',
    standalone: false
})
export class ToggleComponent {
  @Input() id: string;
  @Input() checked: boolean;
  @Input() disabled: boolean;
}

<div [id]="id()" tabindex="-1" class="absolute top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
    <div class="relative w-fit max-h-full rounded-lg">
        <div class="relative primary-purple-light-2-background rounded-lg shadow dark:bg-gray-700 p-6">
            @if (!loading()) {
                <form novalidate #form (ngSubmit)="onSubmit()">
                    <section id="header">
                        <div class="flex items-center">
                            <h3 class="w-full text-xl font-bold primary-purple-text dark:text-gray-400">{{ literalService.get('services.modal.importCSV.title', true) }}</h3>
                            <button (click)="modal()!.hide()" type="button" class="right-6 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                                <img [src]="images.close" class="h-6 w-6" />
                            </button>
                        </div>
                    </section>
                    <section id="body" class="flex justify-center mt-6">
                        <input type="file" [id]="'csv'" class="file-input hidden" (change)="onFileSelected($event, 'csv')" #fileUpload accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                        <div class="flex justify-center items-center dashed-border" (click)="document.getElementById('csv')!.click()">
                            <div class="">
                                <img [src]="images.services.uploadDocument" class="w-full h-10" />
                                @if (!file) {
                                    <button type="button" class="primary mt-2 border-2 rounded-[14px] gap-4 h-8 font-semibold text-base cursor-pointer px-3" (click)="undefined">
                                        {{ literalService.get('services.modal.selectFile', true) }}
                                    </button>
                                } @else {
                                    <p class="text-base font-semibold mt-4">{{ file.name }}</p>
                                }
                            </div>
                        </div>
                    </section>
                    <section class="mt-6">
                        @if (clients.length > 1) {
                            <article class="w-full">
                                <div class="flex items-center mb-[6px]">
                                    <img [src]="images.services.client" alt="client" class="h-6 w-6 mr-[6px]" />
                                    <label class="font-normal text-base">{{ literalService.get('services.modal.client', true) }}</label>
                                </div>
                                <select2 class="block" [placeholder]="literalService.get('services.modal.selectClient', true)" [data]="clients" (update)="setClientId($event)" />
                            </article>
                        }
                        <article class="w-full mt-8">
                            <div class="flex items-center mb-[6px]">
                                @if (selectTown) {
                                    <img [src]="images.area" alt="town" class="h-6 w-6 mr-[6px]" />
                                    <label class="font-normal text-base">{{ literalService.get('services.modal.area', true) }}</label>
                                } @else {
                                    <img [src]="images.services.newArea" alt="town" class="h-6 w-6 mr-[6px]" />
                                    <label class="font-normal text-base">{{ literalService.get('services.modal.newArea', true) }}</label>
                                }
                            </div>
                            @if (selectTown) {
                                <select2 class="block" [placeholder]="literalService.get('services.modal.selectArea', true)" [data]="towns" (update)="setTownId($event)"  [disabled]="!formGroup.get('clientId')?.value" />
                            } @else {
                                <input type="text" [placeholder]="literalService.get('services.modal.newAreaPlaceholder', true)" class="block w-full h-10 px-3 py-2 bg-white border rounded-s rounded-e text-base font-normal placeholder-slate-400 focus:outline-none" (keyup)="setTownName($event)" />
                            }
                            @if (formGroup.get('clientId')?.value) {
                                @if (selectTown) {
                                    <label class="absolute text-sm font-normal underline primary-purple-light-text cursor-pointer right-6" (click)="changeCreationType(true)">{{ literalService.get('services.modal.createNewArea', true) }}</label>
                                } @else {
                                    <label class="absolute text-sm font-normal underline primary-purple-light-text cursor-pointer right-6" (click)="changeCreationType()">{{ literalService.get('services.modal.goToSelectArea', true) }}</label>
                                }
                            }
                        </article>
                    </section>
                    <section id="footer" class="flex mt-10">
                        <input [attr.data-modal-hide]="id()" type="button" class="secondary mt-2 gap-4 border-2 font-semibold p-2 w-full uppercase cursor-pointer items-center bg-white mr-1" (click)="modal()!.hide()" [value]="literalService.get('cancel', true)"/>
                        <input [attr.data-modal-hide]="id()" type="submit" [disabled]="formGroup.invalid" class="primary mt-2 gap-4 border-2 font-semibold p-2 w-full uppercase ml-1 cursor-pointer" [value]="literalService.get('upload', true)"/>
                    </section>
                </form>
            } @else {
                <article class="flex w-full pb-6 pt-4 pl-7 pr-7 justify-center rounded-es-lg rounded-ee-lg">
                    <ng-lottie class="w-52" [options]="importing" (animationCreated)="utilsService.animationCreated($event, 1)" />
                </article>
                <label class="flex justify-center items-center text-lg font-bold">{{ literalService.get('services.modal.importCSV.action', true) }}</label>
            }
        </div>
    </div>
</div>
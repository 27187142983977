import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService {
  constructor(
    private auth: AuthService,
    public router: Router,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (!this.auth.isAuthenticated()) {
      this.router.navigate(['']);
      return false;
    }
    let path = route.routeConfig?.path;
    if (path) {
      path = path.split('/')[0];
      if (path && localStorage.getItem('route') != path) {
        localStorage.setItem('route', path);
        this.router.navigate([localStorage.getItem('route')]);
      }
    }
    return true;
  }
}

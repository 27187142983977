/* eslint-disable @angular-eslint/use-lifecycle-interface */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Modal } from 'flowbite';
import { IBookingCreate } from 'src/app/models';
import { LiteralService } from 'src/app/services';
import { images } from 'src/images';

@Component({
  selector: 'app-modal-exceeding-km-limit',
  templateUrl: './modal-exceeding-km-limit.component.html',
  styleUrl: './modal-exceeding-km-limit.component.scss'
})
export class ModalExceedingKmLimitComponent {

  @Input() id: string;
  @Input() modal: Modal;
  @Input() title: string;
  @Input() text: string;
  @Input() img: string;
  @Input() newBooking: IBookingCreate;

  @Input() kmDone: number;
  @Input() kmLimit: number;
  @Output() action = new EventEmitter<any>();
  
  public images = images;
  public endOfMonthString = this.getEndOfMonth();

  constructor(public literalService: LiteralService) {
  }

  public getEndOfMonth(): string {
    const nextMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1);
    nextMonth.setDate(nextMonth.getDate() - 1);
    const day = String(nextMonth.getDate()).padStart(2, '0');
    const monthFormatted = String(nextMonth.getMonth() + 1).padStart(2, '0'); 
    return `${day}/${monthFormatted}`;
  }

  onSubmit = () => {
    this.action.emit(this.newBooking);
  };
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Modal } from 'flowbite';
import { IBookingDetails } from 'src/app/models/booking/bookingDetails';
import { LiteralService } from 'src/app/services/literal/literal.service';
import { images } from 'src/images';

@Component({
    selector: 'app-modal-change-booking-seats',
    templateUrl: './modal-change-booking-seats.component.html',
    styleUrl: './modal-change-booking-seats.component.scss',
    standalone: false
})
export class ModalChangeBookingSeatsComponent {
  @Input() id: string;
  @Input() modal: Modal;
  @Input() regularSeats: number = 0;
  @Input() prmSeats: number = 0;
  @Input() booking: IBookingDetails;
  @Output() action = new EventEmitter<any>();

  public images = images;

  constructor(public literalService: LiteralService) { }

  change = (event: any) => {
    if (event.type == "prm") {
      if (event.key == "plus") this.booking.prmSeats += 1;
      if (event.key == "minus") this.booking.prmSeats -= 1;
    } else {
      if (event.key == "plus") this.booking.seats += 1;
      if (event.key == "minus") this.booking.seats -= 1;
    }
  };

  onSubmit = () => {
    this.modal.toggle();
    this.action.emit(this.booking);
  };
}

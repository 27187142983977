import { IRouteDetails, IService } from 'src/app/models';
import { IPagination, IServicesState } from '../states';
import { ExpeditionCreationCriteria, ExpeditionType, RouteVisibility, TicketingType } from 'src/app/models/enums';

export const initialServicesState: IServicesState = {
  value: [] as IService[],
  isFetchingServices: false,
  pagination: {} as IPagination,
};

export function initialRouteCreate(): IRouteDetails {
  return {
    name: '',
    town: undefined,
    managerClient: undefined,
    additionalClients: [],
    apps: [],
    relatedServices: [],
    expeditionType: ExpeditionType[''],
    maxTime: undefined,
    centerBookingsInTime: false,
    bookOnReadyWithoutTrip: false,
    realTimeBookings: false,
    lastMinuteBookings: false,
    visibility: RouteVisibility.Public,
    terrain: undefined,
    outline: undefined,
    sms: false,
    push: false,
    email: false,
    publishingSites: [],
    criteria: ExpeditionCreationCriteria.None,
    showBusPosition: false,
    informative: false,
    maxPathTime: 0,
    minPathTime: 0,
    dynamicEndStop: false,
    showUserDataToDriver: false,
    cancelBookingsOnReady: false,
    maxVariationTime: 0,
    maxVariability: 0,
    showOnlyOptimalRoutes: false,
    notifyNewTrips: false,
    singleStopBookings: false,
    avoidCriteriasValidation: false,
    ticketingType: TicketingType.None,
    showRoute: false,
    saeActions: [],
    goBack: false,
    expeditionDetectionRadius: 300,
    segmentDetectionRadius: 20,
  };
}

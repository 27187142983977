<section class="mt-5">
    @if (availabilities && availabilities.success) {
        @for (availability of availabilities.success; track $index) {
            <article class="p-4 mb-4 rounded gap-20 cursor-pointer" 
                    [ngClass]="{'border-2 primary-purple-light-border': availabilitySelected === availability}"
                    (click)="!availability.informative && selectAvailability(availability)"
                    [tooltip]="availability.informative && literalService.get('bookings.bookingNotAvailable', true)"
                    [position]="availability.informative && TooltipPosition.DYNAMIC"
                    >
                <div class="flex items-center mb-6">
                    <app-service-tag [serviceInfo]="availability.serviceInfo"></app-service-tag>
                    <label class="ml-[10px] w-full font-bold text-base cursor-pointer">{{ availability.serviceInfo.name }}</label>
                    <img [hidden]="!availability.ecoRoute" [src]="images.booking.eco" alt="eco" class="h-6 w-6" />
                </div>
                @if (checkIfAlternativeIsOnInitial(availability) && availability.alternative) {
                    <div class="flex mb-3">
                        <img [src]="images.booking.pedestrian" class="h-6 w-6 mr-2" alt="pedestrian" />
                        <label class="font-normal text-base neutrals-grey-03-text">{{ getAlterationDestinationName(availability, true) }}</label>
                    </div>
                    <img [src]="images.moreInfo" class="h-6 w-6 mr-2 mb-3" />
                }
                <div class="flex items-center h-6 mb-3">
                    <img [src]="images.route" class="h-6 w-6 mr-2" alt="bus" />
                    <label class="mr-2 flex font-semibold text-sm cursor-pointer text-left">{{ availability.inStop.name }}</label>
                    <div class="flex-grow min-w-11">
                        <div class="flex items-center">
                            <div class="flex h-4 w-4 justify-center items-end">
                                <div class="flex h-4 w-4 rounded-[50%] justify-center items-center primary-purple-light-background">
                                    <div class="h-2 w-2 rounded-[50%] bg-white"></div>
                                </div>
                            </div>
                            <div class="flex gradient h-1 w-2/3"></div>
                            <div class="flex h-4 w-4 justify-center items-end">
                                <div class="flex h-4 w-4 rounded-[50%] justify-center items-center primary-green-background">
                                    <div class="h-2 w-2 rounded-[50%] bg-white"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <label class="ml-2 mr-4 flex font-semibold text-sm cursor-pointer text-left">{{ availability.outStop.name }}</label>
                </div>
                @if (checkIfAlternativeIsOnFinal(availability) && availability.alternative) {
                    <img [src]="images.moreInfo" class="h-6 w-6 mr-2 mb-3" />
                    <div class="flex mb-3">
                        <img [src]="images.booking.pedestrian" class="h-6 w-6 mr-2" alt="pedestrian" />
                        <label class="font-normal text-base neutrals-grey-03-text">{{ getAlterationDestinationName(availability, false) }}</label>
                    </div>
                }
                <div class="flex items-center mb-3">
                    <img [src]="images.clock" class="h-6 w-6 mr-2" />
                    <label class="mr-2 font-normal text-sm cursor-pointer">
                        {{ literalService.get('bookings.departure', true)}} 
                        <span class="font-semibold text-sm"  [tooltip]="literalService.get('bookings.tooltips.variability', true) + ' <strong>' + moment(availability.inStop.minDateTime).format('HH:mm') + ' - ' + moment(availability.inStop.maxDateTime).format('HH:mm') + '</strong>'" 
                            [position]="TooltipPosition.DYNAMIC" [visibleTooltip]="!availability.informative">
                            {{ moment(availability.inStop.dateTime).format('HH:mm')}} {{!availability.informative ? ' *' : ''}}
                        </span>
                    </label>
                    <label class="font-normal text-sm cursor-pointer">
                        {{ literalService.get('bookings.arrival', true)}} 
                        <span class="font-semibold text-sm" [tooltip]="literalService.get('bookings.tooltips.variability', true) + ' <strong>' + moment(availability.outStop.minDateTime).format('HH:mm') + ' - ' + moment(availability.outStop.maxDateTime).format('HH:mm') + '</strong>'" 
                            [position]="TooltipPosition.DYNAMIC" [visibleTooltip]="!availability.informative">
                            {{ moment(availability.outStop.dateTime).format('HH:mm')}} {{!availability.informative ? ' *' : ''}}
                        </span>
                    </label>
                </div>
                <div class="flex items-center mb-3">
                    <div class="flex items-center">
                        <img [src]="images.busCar" class="h-6 w-6 mr-2" />
                        <label class="mr-2 font-normal text-sm cursor-pointer">{{ availability.vehiclePlate }}
                            @if (availability.tripTime) {
                                <span>({{ literalService.get('bookings.departure', true)}} {{ moment(availability.tripTime, [moment.ISO_8601, 'HH:mm']).format('HH:mm') }})</span>
                            }
                        </label>
                    </div>
                    @if (availability.tripPassengers) { 
                        <div class="flex items-center">
                            <img [src]="images.booking.passengers" class="h-6 w-6 mr-2" />
                            <label class="mr-2 font-normal text-sm cursor-pointer">{{ availability.tripPassengers }}</label>
                        </div>
                    }
                </div>
                <div class="flex items-center">
                    <img [src]="images.booking.driver" class="h-6 w-6 mr-2" />
                    <label class="mr-2 font-normal text-sm cursor-pointer">{{ availability.driverName }}</label>
                </div>
            </article>  
        }
        @if (availabilities.success.length > 0) {
            <!-- <div class="flex items-center" [ngClass]="{'hidden': isManualBooking}">
                <label class="font-semibold text-xl">{{ literalService.get('bookings.selection', true)}}</label>
                <img [src]="images.info" class="h-6 w-6 ml-2" [tooltip]="literalService.get('bookings.infoAvailabilitiesActions')" [position]="TooltipPosition.DYNAMIC" />
            </div> -->
            <div class="flex justify-center mt-4 mr-2">
                <input type="button" [disabled]="!availabilitySelected" class="primary text-white gap-4 border-2 font-semibold h-14 w-full mr-2 uppercase cursor-pointer" (click)="confirmAvailability()" [value]="literalService.get('bookings.actions.makeBooking.title')" />
            </div>
            <!-- <div class="flex justify-center mt-4 mr-2" [ngClass]="{'rounded-e': availabilities.failure.length === 0}">
                <input type="button" class="primary text-white gap-4 border-2 font-semibold h-14 w-full mr-2 uppercase cursor-pointer" [ngClass]="{'hidden': isManualBooking}" (click)="multipleBooking()" [value]="literalService.get('bookings.recommended')" />
                <input type="button" class="primary text-white gap-4 border-2 font-semibold h-14 w-full ml-2 uppercase cursor-pointer" [ngClass]="{'hidden': isManualBooking}" (click)="manualBooking()" [value]="literalService.get('bookings.manual')" />
            </div> -->
        }
    }
    <!-- @if (availabilities.failure.length) {
        <h2 class="font-semibold text-xl mt-4 mb-2">{{ literalService.get('bookings.notAvailable', true) }}</h2>
    } -->
    @if (availabilities && availabilities.failure) {
        @for (availability of availabilities.failure; track $index) {
            @if (availability.failureReason !== 'NoAvailabilitiesFound') {
                @if (availabilities.failure.length && $index === 0) {
                    <h2 class="font-semibold text-xl mt-4 mb-2">{{ literalService.get('bookings.notAvailable', true) }}</h2>
                }
                <article class="p-2 mb-4 rounded gap-20 cursor-pointer" [ngClass]="{'border-2 secondary-red-border': availability.failureReason}">
                    <div class="failureReason mb-2 p-1 rounded w-fit">
                        <label class="font-semibold text-base">{{ literalService.get('bookings.' + availability.failureReason, true) }}</label>
                    </div>
                    @if (availability.serviceInfo.name && availability.failureReason !== 'Impossible') {
                        <div class="flex items-center mb-2">
                            <app-service-tag [serviceInfo]="availability.serviceInfo"></app-service-tag>
                            <label class="ml-2 w-full font-semibold text-sm cursor-pointer">{{ availability.serviceInfo.name }}</label>
                        </div>
                    }
                    @if (availability.inStop.name && availability.outStop.name) {
                        <div class="flex items-center mb-2">
                            <img [src]="images.route" class="h-6 w-6 mr-2" />
                            <label class="mr-2 font-semibold text-sm cursor-pointer">{{ availability.inStop.name }}</label>
                            <div class="flex w-12 items-center">
                                <div class="flex h-4 w-4 justify-center items-end">
                                    <div class="flex h-4 w-4 rounded-[50%] justify-center items-center primary-purple-light-background">
                                        <div class="h-2 w-2 rounded-[50%] bg-white"></div>
                                    </div>
                                </div>
                                <div class="flex gradient h-1 w-2/3"></div>
                                <div class="flex h-4 w-4 justify-center items-end">
                                    <div class="flex h-4 w-4 rounded-[50%] justify-center items-center primary-green-background">
                                        <div class="h-2 w-2 rounded-[50%] bg-white"></div>
                                    </div>
                                </div>
                            </div>
                            <label class="ml-2 font-semibold text-sm cursor-pointer">{{ availability.outStop.name }}</label>
                        </div>
                    }
                    @if (availability.inStop.dateTime && availability.outStop.dateTime && availability.failureReason !== 'Impossible') {
                        <div class="flex items-center mb-2">
                            <img [src]="images.clock" class="h-6 w-6 mr-2" />
                            <label class="mr-2 font-normal text-sm cursor-pointer">{{ literalService.get('bookings.departure', true)}} <span class="font-semibold text-sm">{{ moment(availability.inStop.dateTime).format('HH:mm') }}</span></label>
                            <label class="font-normal text-sm cursor-pointer">{{ literalService.get('bookings.arrival', true)}} <span class="font-semibold text-sm">{{ moment(availability.outStop.dateTime).format('HH:mm') }}</span></label>
                        </div>
                    }
                    @if (availability.vehiclePlate && availability.failureReason !== 'Impossible') {
                        <div class="flex items-center mb-2">
                            <div class="flex items-center">
                                <img [src]="images.busCar" class="h-6 w-6 mr-2" />
                                <label class="mr-2 font-normal text-sm cursor-pointer">{{ availability.vehiclePlate }}
                                    @if (availability.tripTime) {
                                        <span>({{ literalService.get('bookings.departure', true)}} {{ moment(availability.tripTime, [moment.ISO_8601, 'HH:mm']).format('HH:mm') }})</span>
                                    }
                                </label>
                            </div>
                            @if (availability.tripPassengers) {
                                <div class="flex items-center">
                                    <img [src]="images.booking.passengers" class="h-6 w-6 mr-2" />
                                    <label class="mr-2 font-normal text-sm cursor-pointer">{{ availability.tripPassengers }}</label>
                                </div>
                            }
                        </div>
                    }
                    @if (availability.driverName && availability.failureReason !== 'Impossible') {
                        <div class="flex items-center">
                            <img [src]="images.booking.driver" class="h-6 w-6 mr-2" />
                            <label class="mr-2 font-normal text-sm cursor-pointer">{{ availability.driverName }}</label>
                        </div>
                    }
                </article>
            } @else {
                <div class="flex justify-center">
                    <img [src]="images.booking.noOptions" class="h-48 w-48" />
                </div>
                <div class="text-center">
                    <h4 class="font-bold text-xl neutrals-grey-04-text">{{ literalService.get('bookings.noResults', true) }}</h4>
                    <label class="flex font-normal text-base mr-12 ml-12 mb-3 mt-2 neutrals-grey-04-text">{{ literalService.get('bookings.noResultsExplanation', true) }}</label>
                </div>
            }
        }
    }
    @if (!availabilities || (!availabilities.failure && !availabilities.success) || (availabilities.failure.length === 0 && availabilities.success.length === 0)) {
        <div class="flex justify-center">
            <img [src]="images.booking.noOptions" class="h-48 w-48" />
        </div>
        <div class="text-center">
            <h4 class="font-bold text-xl neutrals-grey-04-text">{{ literalService.get('bookings.noResults', true) }}</h4>
            <label class="flex font-normal text-base mr-12 ml-12 mb-3 mt-2 neutrals-grey-04-text">{{ literalService.get('bookings.noResultsExplanation', true) }}</label>
        </div>
    }
</section>

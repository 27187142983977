export enum ServiceInfoColors {
  'Purple' = '#280A54',
  'PurpleLight' = '#8136DB',
  'PrimaryGreen' = '#0DEDAB',
  'SecondaryGreen' = '#06CB60',
  'Yellow' = '#F5C600',
  'Orange01' = '#ED8936',
  'Orange02' = '#E77230',
  'Pink' = '#D8359C',
  'Red' = '#E42313',
  'Blue' = '#6083FF',
  'Grey' = '#232530',
}

<nav
  class="fixed lg:ml-20 md:ml-20 lg:pr-20 md:pr-20 sm:ml-0 sm:pr-20 h-20 top-0 z-40 w-full bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700"
  [ngClass]="{
    transitionOut: hidden,
    'lg:ml-52 md:ml-52 lg:pr-52 md:pr-52 transitionIn': !hidden
  }"
  (click)="handleClick()"
>
  <div class="flex px-3 py-3 lg:px-5 lg:pl-3 h-20">
    <div class="flex items-center justify-between w-full">
      <div class="flex items-center justify-start">
        <button
          data-drawer-target="sidebar"
          data-drawer-toggle="sidebar"
          aria-controls="sidebar"
          type="button"
          class="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden focus:outline-none dark:text-gray-400"
        >
          <img
            [src]="images.menuBurger"
            alt="menuBurger"
            height="22"
            width="22"
          />
        </button>

        <h1
          id="title"
          class="self-center text-2xl font-bold sm:text-xl whitespace-nowrap dark:text-white uppercase pl-6"
          [ngClass]="{ transitionOut: hidden, transitionIn: !hidden }"
        >
          {{ literalService.get('sidebar.'+routeName) }}
        </h1>
      </div>
      <app-user-profile [arrow]="arrow"></app-user-profile>
    </div>
  </div>
</nav>

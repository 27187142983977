import { AfterViewInit, Component, input, OnChanges, OnInit, output, SimpleChanges } from '@angular/core';
import { Modal } from 'flowbite';
import { AnimationOptions } from 'ngx-lottie';
import { IServiceInfo } from 'src/app/models';
import { LiteralService, UtilsService } from 'src/app/services';
import { images } from 'src/images';

@Component({
  selector: 'app-modal-export-gtfs',
  templateUrl: './modal-export-gtfs.component.html',
  styleUrl: './modal-export-gtfs.component.scss',
  standalone: false,
})
export class ModalExportGtfsComponent implements OnChanges {

  readonly id = input<string>();
  readonly modal = input<Modal>();
  readonly edit = input<boolean>(false);
  readonly loading = input<boolean>(false);
  readonly serviceInfos = input<IServiceInfo[]>([]);
  readonly action = output<any>();

  public serviceIdSelected: number;

  public allServiceInfo: any = [];

  public images = images;

  public exporting: AnimationOptions = {
    path: '/assets/animations/exporting.json'
  };

  constructor(public literalService: LiteralService, public utilsService: UtilsService) {}

  ngOnChanges(): void {
    this.allServiceInfo = [];
    this.serviceInfos().forEach((serviceInfo: IServiceInfo) => {
      this.allServiceInfo.push({id: serviceInfo.id, value: serviceInfo.id, label: serviceInfo.name || serviceInfo.tag});
    });
  }

  changeService(event: any) {
    this.serviceIdSelected = event.value;
  }

  onSubmit() {
    this.action.emit(this.serviceIdSelected);
  }
}

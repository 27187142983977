<section class="mt-28 ml-28 mr-28 h-full">
  <app-search-bar
    [placeholder]="literalService.get('users.search', true)"
    [searching]="searching"
    (addUserEventEmitter)="addUser()"
    (addUserDriverEventEmitter)="addUserDriver()"
    (filter)="filterUsers($event)"
    (searchEventEmitter)="searchUsers($event)"
    (sort)="sortUsers()"
  ></app-search-bar>
</section>
<section class="mt-5 ml-28 mr-28 h-full">
  <div class="grid grid-cols-9 mb-4 text-center">
    <label class="ml-14 flex justify-center col-span-2 font-semibold text-base">
      <img [src]="images.user.name" alt="name" class="h-6 w-6 mr-2" />
      {{ literalService.get("users.data.name", true) }}
    </label>
    <label class="flex justify-center col-span-2 font-semibold text-base">
      <img [src]="images.user.mail" alt="mail" class="h-6 w-6 mr-2" />
      {{ literalService.get("users.data.mail", true) }}
    </label>
    <label class="flex justify-center col-span-2 font-semibold text-base">
      <img [src]="images.user.phone" alt="phone" class="h-6 w-6 mr-2" />
      {{ literalService.get("users.data.phone", true) }}
    </label>
    <label class="flex justify-center col-span-2 font-semibold text-base">
      <img [src]="images.user.id" alt="id" class="h-6 w-6 mr-2" />
      {{ literalService.get("users.data.dni", true) }}
    </label>
    <label
      class="col-span-1 flex justify-center items-center whitespace-nowrap font-semibold text-base"
    >
      {{
        this.users.pagination.totalElements &&
        (users.pagination.first
          ? 1
          : this.users.pagination.actualPage * 10 - 9) +
          "-" +
          (users.pagination.last
            ? this.users.pagination.totalElements
            : users.pagination.numberOfElements *
              this.users.pagination.actualPage) +
          " " +
          literalService.get("of") +
          " " +
          this.users.pagination.totalElements
      }}
      <img
        [src]="images.arrowLeft"
        alt="arrowLeft"
        class="h-5 w-5 ml-2 mr-2 cursor-pointer"
        [ngClass]="users.pagination.first ? 'disabled cursor-default' : ''"
        [attr.disabled]="users.pagination.first"
        (click)="!users.pagination.first && changePage(false)"
      />
      <img
        [src]="images.arrowRight"
        alt="arrowRight"
        class="h-5 w-5 mr-2 cursor-pointer"
        [ngClass]="users.pagination.last ? 'disabled cursor-default' : ''"
        [attr.disabled]="users.pagination.last"
        (click)="!users.pagination.last && changePage(true)"
      />
    </label>
  </div>
  <div id="usersContainer" *ngIf="!users.isFetchingUsers">
    <div
      id="{{'user'+user.id}}"
      class="userRow border rounded pt-2 pb-2 grid grid-cols-9 justify-center items-center mb-4"
      *ngFor="let user of this.users.value; let i = index"
      [ngClass]="{ 'inactive': user.status?.toUpperCase() === userStatus.INACTIVE, 'blocked': user.status?.toUpperCase() === userStatus.BLOCKED, 'deleted': user.status?.toUpperCase() === userStatus.DELETED  }"
    >
      <label [id]="'name-'+user.id" class="truncate pr-5 pl-5 ml-14 col-span-2 text-base cursor-pointer" [tooltip]="utilsService.visibleTooltip('name-'+user.id!) ? user.firstName + ' ' + user.lastName : ''" [visibleTooltip]="utilsService.visibleTooltip('name-'+user.id!)" [position]="TooltipPosition.DYNAMIC" (click)="showUserData(user)">
        {{ user.firstName }} {{ user.lastName }}
      </label>
      <label [id]="'email-'+user.id" class="truncate pr-5 pl-5 col-span-2 text-base cursor-pointer" [tooltip]="utilsService.visibleTooltip('email-'+user.id!) ? user.email! : ''" [visibleTooltip]="utilsService.visibleTooltip('email-'+user.id!)" [position]="TooltipPosition.DYNAMIC" (click)="showUserData(user)">
        {{ user.email || "-" }}
      </label>
      <label class="truncate pr-5 pl-5 col-span-2 text-base cursor-pointer" (click)="showUserData(user)">
        {{ user.phone || "-" }}
      </label>
      <label class="truncate pr-5 pl-5 col-span-2 text-base cursor-pointer" (click)="showUserData(user)">
        {{ $any(user).dni || "-" }}
      </label>
      <div class="flex justify-center cursor-default">
        <img [src]="images.user.calendar" alt="calendar" class="h-6 w-6 mr-6 cursor-pointer" [tooltip]="literalService.get('users.tooltips.bookings' , true)" [position]="TooltipPosition.DYNAMIC" (click)="showViewBookings(user.id)" />
        <img [src]="images.user.star" alt="favorite" class="h-6 w-6 cursor-pointer" [tooltip]="literalService.get('users.tooltips.favoriteRoutes' , true)" [position]="TooltipPosition.DYNAMIC" (click)="showFavouriteRoutes($event, 'user'+user.id)" />
      </div>
    </div>
  </div>
  <app-user-details [user]="userSelected || newUser" [newUser]="newUser" (userEdited)="userEdited($event)" (closeDetails)="closeDetails()" (showViewBookingsEventEmitter)="showViewBookings($event)" (showChangeStatusEventEmitter)="showChangeStatus($event)"></app-user-details>
  <!-- <app-favourite-routes></app-favourite-routes> -->
</section>
<app-modal-block-user [id]="'blockUser'" [modal]="blockUser" [hidden]="!blockUserShowed" [reason]="reason" (action)="blockUserAction($event)"></app-modal-block-user>
<app-modal-confirmation [id]="'changeStatus'" [modal]="changeStatus" [hidden]="!changeStatusShowed" [text]="'users.actions.' + changeStatusSelected + '.description'" [title]="'users.actions.' + changeStatusSelected + '.title'" [icon]="changeStatusSelected && changeStatusSelected.toUpperCase() === userStatus.DELETED ? images.notification.warning : null" [sameLineButtons]="changeStatusSelected && changeStatusSelected.toUpperCase() === userStatus.DELETED ? false : true" (action)="changeStatusAction()" />
<app-modal-view-bookings [id]="'viewBookings'" [modal]="viewBookings" [hidden]="!viewBookingsShowed" [values]="bookings" [showDate]="showDate" (action)="viewBookingsAction()" (validatePickUpEventEmitter)="validatePickUpAction($event)" (validateDropOffEventEmitter)="validateDropOffAction($event)" />

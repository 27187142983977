import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Modal } from 'flowbite';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { InputType } from 'src/app/models/enums';
import { LiteralService } from 'src/app/services/literal/literal.service';
import { images } from 'src/images';

@Component({
  selector: 'app-modal-change-password',
  templateUrl: './modal-change-password.component.html',
  styleUrls: ['./modal-change-password.component.scss'],
})
export class ModalChangePasswordComponent {
  @Input() id: string;
  @Input() modal: Modal;
  @Input() password: string = '';
  @Input() confirmPassword: string = '';
  @Input() loading: boolean = false;
  @Output() action = new EventEmitter<any>();

  public InputType = InputType;
  public images = images;

  public buttonDisabled = false;

  public changePasswordFormControl: FormGroup;
  public invalid: any[] = [];
  public errors: any[] = [];
  public hasErrors: boolean = false;

  constructor(public literalService: LiteralService) {}

  emitEvent() {
    this.buttonDisabled = this.password !== this.confirmPassword ? true : false;
    this.action.emit(this.password);
    this.password = '';
    this.confirmPassword = '';
  }

  onBackdropClick(event: MouseEvent): void {
    if (event.target === event.currentTarget) {
      this.password = '';
      this.confirmPassword = '';
      this.modal.hide();
    }
  }

  public findInvalidControls() {
    this.changePasswordFormControl = new FormGroup(
      {
        password: new FormControl(
          this.password,
          Validators.compose([Validators.required, Validators.minLength(4)]),
        ),
        confirmPassword: new FormControl(
          this.confirmPassword,
          Validators.compose([Validators.required, Validators.minLength(4)]),
        ),
      },
      {
        validators: this.matchValidator('password', 'confirmPassword'),
      },
    );
    this.invalid = [];
    const controls = this.changePasswordFormControl.controls;
    console.log(controls);
    for (const name in controls) {
      if (controls[name].invalid) {
        this.invalid.push(name);
      }
    }
    this.errors = [];
    this.invalid.map((value: any) => {
      this.errors.push({ value, reason: controls[value].errors });
    });
    this.hasErrors = Boolean(this.errors.length);
    console.log('errors', this.errors);
    console.log(this.invalid);
    console.log(this.changePasswordFormControl.valid);
  }

  matchValidator(
    controlName: string,
    matchingControlName: string,
  ): ValidatorFn {
    return (abstractControl: AbstractControl) => {
      const control = abstractControl.get(controlName);
      const matchingControl = abstractControl.get(matchingControlName);

      if (matchingControl!.errors && !matchingControl!.errors?.['match']) {
        return null;
      }

      if (control!.value !== matchingControl!.value) {
        const error = { match: 'errors.match' };
        control!.setErrors(error);
        matchingControl!.setErrors(error);
        return error;
      }
      control!.setErrors(null);
      matchingControl!.setErrors(null);
      return null;
    };
  }

  getError(input: string) {
    const res = this.errors.find((error: any) => {
      return error.value === input && error.reason && error.reason.match
        ? error.reason.match
        : error.reason;
    });

    if ('match' in res.reason) {
      return this.literalService.get(res.reason.match, true);
    } else if ('minlength' in res.reason) {
      return this.literalService
        .get('errors.minlength', true)
        .replace('{0}', res.reason.minlength.requiredLength);
    } else if ('required' in res.reason) {
      return this.literalService.get('errors.requiredField', true);
    }
    return this.literalService.get('errors.incorrectField', true);
  }

  setPassword(event: any) {
    console.log(event);
    event.id === 'password' ? (this.password = event.value) : null;
    event.id === 'confirmPassword'
      ? (this.confirmPassword = event.value)
      : null;
  }

  close = () => {
    this.password = '';
    this.confirmPassword = '';
    this.modal.hide();
  };

  onSubmit() {
    this.findInvalidControls();
    if (this.errors.length === 0) this.emitEvent();
  }
}

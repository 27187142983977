<!-- <div modal-backdrop class="bg-gray-900 bg-opacity-50 dark:bg-opacity-80 absolute inset-0 z-40"></div> -->
<div [id]="id" tabindex="-1" class="absolute top-20 left-0 right-0 z-50 hidden w-full p-4 md:inset-0 h-[calc(100%-1rem)] max-h-full" (click)="onBackdropClick($event)">
    <div class="relative w-80 max-w-md max-h-full rounded-lg primary-purple-light-2-background" (click)="$event.stopPropagation()">
        @if (!loading) {
            <div class="relative rounded-lg shadow dark:bg-gray-700">
                @if (id === 'changePasswordModalGeneral') {
                    <button (click)="close()" type="button" class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="changePasswordModalGeneral">
                        <img [src]="images.close" class="h-6 w-6" />
                    </button>
                } @else {
                    <button (click)="close()" type="button" class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="changePasswordGeneral">
                        <img [src]="images.close" class="h-6 w-6" />
                    </button>
                }
                <div class="p-6 pt-5">
                    <form #form novalidate (ngSubmit)="onSubmit()">
                        <h3 class="text-base text-center font-bold mb-5 text-gray-500 dark:text-gray-400 mt-5">{{ literalService.get('users.actions.changePassword.title', true) }}</h3>
                        <div>
                            <div class="justify-end items-center relative">
                                <label class="text-xs text-left">{{ literalService.get('users.actions.changePassword.newPassword', true) }}</label>
                                <app-input-password [id]="'password'" [value]="password" [placeholder]="literalService.get('users.actions.changePassword.newPassword', true)" (changeEventEmitter)="setPassword($event)" [hasErrors]="invalid.includes('password')" required />
                            </div>
                            <label class="text-xs hasErrors" *ngIf="invalid.includes('password')">{{ getError('password') }}</label>
                        </div>
                        <div class="mt-2">
                            <div class="justify-end items-center relative">
                                <label class="text-xs text-left">{{ literalService.get('users.actions.changePassword.confirmPassword', true) }}</label>
                                <app-input-password [id]="'confirmPassword'" [value]="confirmPassword" [placeholder]="literalService.get('users.actions.changePassword.confirmPassword', true)" (changeEventEmitter)="setPassword($event)" [hasErrors]="invalid.includes('confirmPassword')" required />
                            </div>
                            <label class="text-xs hasErrors" *ngIf="invalid.includes('confirmPassword')">{{ getError('confirmPassword') }}</label>
                        </div>
                        @if (id === 'changePasswordModalGeneral') {
                            <div class="w-full flex justify-center items-center mt-4">
                                <input data-modal-hide="changePasswordModalGeneral" type="submit" class="secondary gap-4 p-4 border-2 font-semibold h-14 uppercase cursor-pointer bg-white" [disabled]="buttonDisabled" [value]="literalService.get('update', true)" />
                            </div>
                        } @else {
                            <div class="w-full flex justify-center items-center mt-4">
                                <input data-modal-hide="changePasswordModal" type="submit" class="secondary gap-4 p-4 border-2 font-semibold h-14 uppercase cursor-pointer bg-white" [disabled]="buttonDisabled" [value]="literalService.get('update', true)" />
                            </div>
                        }
                    </form>
                </div>
            </div>
        } @else {
            <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                <article class="flex w-full px-10 pt-10 pb-5 justify-center">
                    <img [src]="images.loading" alt="loading" class="h-10 w-10" />
                </article>
                <div class="flex items-center px-10 justify-center">
                    <h3 class="text-xl font-bold mb-10 text-gray-500 dark:text-gray-400 primary-purple-light-text">{{ literalService.get('users.actions.changePassword.changingPassword', true) }}…</h3>
                </div>
            </div>
        }
    </div>
</div>
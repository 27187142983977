<article class="flex border-2 rounded neutrals-grey-02-border items-center h-14 justify-between px-[15px] py-2 mb-[10px]" (click)="selectRoute.emit(route.id)">
    <div class="absolute w-[52px] h-[52px] left-[22px] cursor-pointer"></div>
    <img [src]="images.visibility" alt="visible" class="w-6 h-6 mr-[15px]" />

    <label class="mx-[10px] w-full font-normal text-base cursor-pointer truncate">{{ route.name }}</label>

    <div class="w-[1px] h-full border-r-[1px] neutrals-grey-02-border"></div>

    <div class="absolute w-[52px] h-[52px] right-[22px] cursor-pointer"></div>
    <img [src]="images.services.routes.settings" class="w-6 h-6 ml-[15px]" />
</article>

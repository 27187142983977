import { Component } from '@angular/core';
import { LiteralService } from 'src/app/services/literal/literal.service';
import { images } from 'src/images';

@Component({
  selector: 'app-favourite-routes',
  templateUrl: './favourite-routes.component.html',
  styleUrl: './favourite-routes.component.scss',
})
export class FavouriteRoutesComponent {
  public images = images;

  constructor(public literalService: LiteralService) {}

  public onSubmit() {}
}

import { images } from '../../images';
import { ColorComponent } from '../components';
import { IClient, IServiceInfoDetail, ITown } from '../models';
import { Colors, InputType, SortCriteria } from '../models/enums';
import { StorageService, UsersService } from '../services';
import { LiteralService } from '../services/literal/literal.service';
import { formData } from './serviceData';

export function getShowTimesValues(serviceInfoDetail?: IServiceInfoDetail, literalService?: LiteralService): unknown[] {
  if (serviceInfoDetail) {
    const data = [];
    serviceInfoDetail.showTheoreticalTimes && data.push({ id: 'showTheoreticalTimes', name: literalService?.get('showTheoreticalTimes', true), code: 'showTheoreticalTimes' });
    serviceInfoDetail.showRealTimes && data.push({ id: 'showRealTimes', name: literalService?.get('showRealTimes', true), code: 'showRealTimes' });
    return data;
  }
  return [
    { id: 'showTheoreticalTimes', name: literalService?.get('showTheoreticalTimes', true), code: 'showTheoreticalTimes' },
    { id: 'showRealTimes', name: literalService?.get('showRealTimes', true), code: 'showRealTimes' }
  ];
}

export async function getClientsTowns(usersService: UsersService): Promise<any> {
  return await usersService.getPossibleClientTowns().then((res) => {
    const clients: any[] = [];
    res.clients.map((client: IClient) => {
      clients.push({ id: client.id, value: client, label: client.name });
    });
    return { clients: clients };
  });
}

export async function getTownsByClient(usersService: UsersService, clientId: any[]): Promise<any> {
  return await usersService.getTownsByClient(clientId).then((res: any) => {
    const towns: any[] = [];
    res.map((town: ITown) => {
      towns.push({ id: town.id, value: town, label: town.name });
    });
    return { towns: towns };
  });
}

export async function getBookingTimePicker(serviceInfoDetail: IServiceInfoDetail, literalService: LiteralService): Promise<any> {
  const yesOption = { id: 'true', value: true, label: literalService.get('yes', true) };
  const noOption = { id: 'false', value: false, label: literalService.get('no', true) };

  const bookingTimePickerValues = serviceInfoDetail.bookingTimePicker ? [yesOption, noOption] : [noOption, yesOption];
  const bookingTimePickerValue = serviceInfoDetail.bookingTimePicker ? [yesOption] : [noOption];

  return { bookingTimePickerValues, bookingTimePickerValue };
}

let clients: unknown[] = [];
let towns: unknown[] = [];

export async function getServiceForm(serviceInfoDetail: IServiceInfoDetail, newService: boolean, usersService: UsersService, literalService: LiteralService, storageService: StorageService, currentStep: number): Promise<unknown[]> {
  (newService && serviceInfoDetail.clients.length === 0) && await getClientsTowns(usersService).then((res) => {
    clients = res.clients;
  });
  
  (newService && serviceInfoDetail.clients.length > 0 && serviceInfoDetail.towns.length === 0) && await getTownsByClient(usersService, [serviceInfoDetail.clients[0].id]).then((res) => {
    towns = res.towns;
  });
  
  const { bookingTimePickerValues, bookingTimePickerValue } = await getBookingTimePicker(serviceInfoDetail, literalService)

  console.log(clients)
  console.log(towns)
  console.log("ATT", serviceInfoDetail)
  const data: formData[] = [
    {
      img: images.services.client,
      title: 'services.data.client',
      value: serviceInfoDetail.clients && serviceInfoDetail.clients.length > 0 ? serviceInfoDetail.clients[0] : undefined,
      required: true,
      expanded: false,
      color: true,
      hidden: !newService,
      informativeToNoChange: serviceInfoDetail.clients && serviceInfoDetail.clients.length > 0 ? true : false,
      informativeToNoChangeText: serviceInfoDetail.clients && serviceInfoDetail.clients.length > 0 ? serviceInfoDetail.clients[0].name : '',
      detailDesign: true,
      edit: {
        formControlName: 'clients',
        inputType: InputType.SELECT_SEARCH,
        hidden: !newService,
        translateValue: false,
        required: true,
        values: clients,
        valueToChange: 'clients',
      },
    },
    {
      img: images.area,
      title: 'services.data.town',
      value: serviceInfoDetail.towns && serviceInfoDetail.towns.length > 0 ? serviceInfoDetail.towns[0] : undefined,
      required: true,
      expanded: false,
      color: true,
      hidden: !newService,
      edit: {
        formControlName: 'towns',
        inputType: InputType.SELECT_SEARCH,
        hidden: currentStep === 1 || !newService,
        translateValue: false,
        required: true,
        disabled: !(serviceInfoDetail.clients!.length > 0),
        values: towns,
        valueToChange: 'towns',
      },
    },
    {
      img: images.services.tag,
      title: 'services.data.tag',
      value: serviceInfoDetail.tag,
      required: true,
      expanded: false,
      color: true,
      edit: {
        inputType: InputType.TEXT,
        formControlName: 'tag',
        hidden: currentStep === 1 && newService,
        required: true,
        valueToChange: 'tag',
      },
    },
    {
      img: images.services.color,
      title: 'services.data.color',
      component: ColorComponent,
      value: serviceInfoDetail.color,
      required: true,
      expanded: false,
      color: true,
      edit: {
        inputType: InputType.COLOR,
        formControlName: 'color',
        hidden: currentStep === 1 && newService,
        required: true,
        values: Object.values(Colors),
        valueToChange: 'color',
      },
    },
    {
      img: images.services.name,
      title: 'services.data.name',
      value: serviceInfoDetail.name,
      required: true,
      expanded: false,
      color: true,
      edit: {
        inputType: InputType.TEXT,
        formControlName: 'name',
        hidden: currentStep === 1 && newService,
        required: true,
        valueToChange: 'name',
      },
    },
    {
      img: images.services.forward,
      title: 'services.data.forwardDirection',
      value: serviceInfoDetail.forwardDirection,
      required: false,
      expanded: false,
      color: true,
      edit: {
        inputType: InputType.TEXT,
        hidden: currentStep === 1 && newService,
        required: false,
        valueToChange: 'forwardDirection',
      },
    },
    {
      img: images.services.backward,
      title: 'services.data.backwardsDirection',
      value: serviceInfoDetail.backwardsDirection,
      required: false,
      expanded: false,
      color: true,
      edit: {
        inputType: InputType.TEXT,
        hidden: currentStep === 1 && newService,
        required: false,
        valueToChange: 'backwardsDirection',
      },
    },
    {
      img: images.services.criteria,
      title: 'services.data.stopsOrder',
      value: serviceInfoDetail.sortCriteria,
      required: false,
      expanded: false,
      color: true,
      translateValue: true,
      edit: {
        id: serviceInfoDetail.sortCriteria,
        inputType: InputType.SELECT_WITH_ENUM,
        hidden: currentStep === 1 && newService,
        required: false,
        values: Object.values(SortCriteria),
        translateValue: true,
        valueToChange: 'sortCriteria',
      },
    },
    {
      img: images.services.showTimes,
      title: 'services.data.showTimes',
      value: getShowTimesValues(serviceInfoDetail, literalService),
      required: false,
      expanded: false,
      array: true,
      color: true,
      edit: {
        inputType: InputType.MULTISELECT_CHECKBOXES,
        hidden: currentStep === 1 && newService,
        required: false,
        values: getShowTimesValues(undefined, literalService),
        selectedValues: getShowTimesValues(serviceInfoDetail, literalService),
        translateValue: true,
        valueToChange: ['showRealTimes', 'showTheoreticalTimes'],
      },
    },
    {
      img: images.services.client,
      title: 'services.data.client',
      value: serviceInfoDetail.clients && serviceInfoDetail.clients.length > 0 && serviceInfoDetail.clients[0].name,
      required: false,
      expanded: false,
      color: true,
      hidden: newService,
      detailDesign: true,
      edit: {
        inputType: InputType.TEXT,
        hidden: newService,
        translateValue: false,
        required: true,
        disabled: true,
        values: clients,
        valueToChange: 'client',
      },
    },
    {
      img: images.area,
      title: 'services.data.town',
      value: serviceInfoDetail.towns && serviceInfoDetail.towns.length > 0 && serviceInfoDetail.towns[0].name,
      required: false,
      expanded: false,
      color: true,
      hidden: newService,
      edit: {
        inputType: InputType.TEXT,
        hidden: newService,
        translateValue: false,
        required: true,
        disabled: true,
        values: towns,
        valueToChange: 'town',
      },
    },
    {
      img: images.services.bookingTimePicker,
      title: 'services.data.boookingTimePicker',
      value: !newService ? serviceInfoDetail.bookingTimePicker ? literalService.get('yes', true) : literalService.get('no', true) : serviceInfoDetail.bookingTimePicker ? true : false,
      required: false,
      expanded: false,
      array: false,
      color: true,
      edit: {
        id: serviceInfoDetail.bookingTimePicker,
        inputType: InputType.SELECT_SEARCH,
        hidden: currentStep === 1 && newService,
        required: false,
        values: bookingTimePickerValues,
        selectedValues: bookingTimePickerValue,
        translateValue: false,
        valueToChange: 'bookingTimePicker',
      }
    }
  ];
  console.log("ATTT", data)
  return data;
}
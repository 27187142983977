@if (text) {
    <article class="absolute h-20 w-20 z-40">
        <article class="absolute h-20 w-auto z-40 -mt-12 -ml-8">
            <div [class]="'flex pl-2 pr-2 pt-1 pb-1 w-full justify-center items-end -mt-1 rounded ' + color ">
                <label [class]="'text-base font-semibold ' + textColor">{{ text }}</label>
            </div>
            <div class="flex w-full justify-center items-end -mb-3">
                <div [class]="'flex h-5 w-2 ' + color"></div>
            </div>
            <div class="flex w-full justify-center items-end">
                <div [class]="'flex h-6 w-6 rounded-[50%] justify-center items-center ' + color">
                    <div class="h-3 w-3 rounded-[50%] bg-white"></div>
                </div>
            </div>
        </article>
    </article>
} @else {
    <div class="flex w-full justify-center items-end">
        <div [class]="'flex ' + getSize() + ' rounded-[50%] justify-center items-center ' + color">
            <div [class]="getInnerSize() + ' rounded-[50%] bg-white'"></div>
        </div>
    </div>
}
<div [id]="id" tabindex="-1" class="absolute top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
    <div class="relative w-[45%] max-h-full rounded-lg">
        <div class="relative primary-purple-light-2-background rounded-lg shadow dark:bg-gray-700 p-10">
            @if (!loading) {
                <form novalidate #form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
                    <section id="header">
                        <div class="flex items-center">
                            <h3 class="w-full text-xl font-bold primary-purple-text dark:text-gray-400">{{ literalService.get('services.modal.' + (edit ? 'editGTFS.title' : 'updateGTFS.title'), true) }}</h3>
                            <button (click)="modal.hide()" type="button" class="right-6 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                                <img [src]="images.close" class="h-6 w-6" />
                            </button>
                        </div>
                    </section>
                    <section id="body" class="mt-6">
                        <label class="text-base font-semibold primary-purple-text">{{ literalService.get('services.modal.' + (edit ? 'editGTFS.text' : 'updateGTFS.text'), true) }}</label>
                        <article class="mt-4">
                            <div class="grid grid-rows-4 grid-flow-col">
                                @for (item of uploaded; track $index) {
                                    <input type="file" [id]="item.type" class="file-input hidden" (change)="onFileSelected($event, item.type)" #fileUpload>
                                    <div class="flex items-center py-4 px-6 item"
                                        [tooltip]="item.file.fileName"
                                        [position]="item.file.fileName ? TooltipPosition.DYNAMIC : undefined"
                                        (click)="document.getElementById(item.type)!.click()"
                                        [ngClass]="{'primary-purple-border primary-purple-text': !item.value, 'primary-purple-light-border uploaded': item.value, 'mr-4': $index !== 4 && $index !== 5 && $index !== 6, 'ml-4': $index === 4 || $index === 5 || $index === 6}">
                                        <img [src]="images.services.uploadGTFS" alt="{{item.type}}" class="w-8 h-8" />
                                        <label class="text-base font-bold ml-4 flex-shrink-0" [innerHTML]="literalService.get('services.modal.data.' + item.type, true)"></label>
                                        <label class="text-base" [ngClass]="{'font-bold': item.required, 'ml-1': !item.required}">{{ item.required ? '*' : '(' + literalService.get('services.modal.data.optional') + ')' }}</label>
                                        <!-- <span class="text-base font-normal ml-1 mr-8 truncate" [hidden]="!item.value">{{ item.file.fileName }}</span> -->
                                        <img [src]="images.check" alt="{{item.type}}Uploaded" [hidden]="!item.value || item.file.fileName === ''" class="w-6 h-6 absolute right-0" />
                                        @if (item.extraInfo) {
                                            <label class="flex absolute mt-9 ml-12 text-[10px] font-light items-end">{{ literalService.get(item.extraInfo) }}</label>
                                        }
                                    </div>
                                }
                            </div>
                        </article>
                        <section class="flex justify-between mt-6">
                            @if (clients.length > 1) {
                                <article class="mt-4 w-full mr-[16px]">
                                    <div class="flex items-center mb-[6px]">
                                        <img [src]="images.services.client" alt="client" class="h-6 w-6 mr-[6px]" />
                                        <label class="font-normal text-base">{{ literalService.get('services.modal.client', true) }}</label>
                                    </div>
                                    <select2 class="block" [placeholder]="literalService.get('services.modal.selectClient', true)" [data]="clients" (update)="setClientId($event)" />
                                </article>
                            }
                            <article class="mt-4 w-full ml-[16px]">
                                <div class="flex items-center mb-[6px]">
                                    <img [src]="images.area" alt="town" class="h-6 w-6 mr-[6px]" />
                                    <label class="font-normal text-base">{{ literalService.get('services.modal.area', true) }}</label>
                                </div>
                                <select2 class="block" [placeholder]="literalService.get('services.modal.selectArea', true)" [data]="towns" (update)="setTownId($event)" [disabled]="!formGroup.get('clientId')?.value" />
                            </article>
                        </section>
                    </section>
                    <section id="footer" class="flex mt-10">
                        <input [attr.data-modal-hide]="id" type="button" class="secondary mt-2 gap-4 border-2 font-semibold p-2 w-full uppercase cursor-pointer items-center bg-white mr-4" (click)="modal.hide()" [value]="literalService.get('cancel', true)"/>
                        <input [attr.data-modal-hide]="id" type="submit" [disabled]="formGroup.invalid" class="primary mt-2 gap-4 border-2 font-semibold p-2 w-full uppercase ml-4 cursor-pointer" [value]="literalService.get('upload', true)"/>
                    </section>
                </form>
            } @else {
                <article class="flex w-full pb-6 pt-4 pl-7 pr-7 justify-center rounded-es-lg rounded-ee-lg">
                    <ng-lottie [options]="importing" (animationCreated)="utilsService.animationCreated($event, 1)" />
                </article>
                <label class="flex justify-center items-center text-lg font-bold">{{ literalService.get('services.modal.importGTFS.action', true) }}</label>
            }
        </div>
    </div>
</div>
export enum InputType {
  TEXT = 'text',
  EMAIL = 'email',
  PHONE = 'tel',
  URL = 'url',
  SEARCH = 'search',
  NUMBER = 'number',
  SELECT = 'select',
  MULTISELECT = 'multiselect',
  DATE = 'date',
  CHECKBOX = 'checkbox',
  INFINITE_SCROLL = 'select2',
  SELECT_SEARCH = 'select_search',
  SEATS = 'SEATS',
  TIME = 'TIME'
}

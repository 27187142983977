<div [id]="id" tabindex="-1" class="absolute top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
    <!-- <div class="relative max-h-full rounded-lg primary-purple-light-2-background" [ngClass]="{'lg:w-1/3 2xl:w-1/4': !width, 'lg:w-1/3 2xl:w-1/6': width }"> -->
        <div class="relative max-h-full rounded-lg primary-purple-light-2-background" [class]="width ? 'lg:w-1/4 2xl:w-'+width : 'lg:w-1/3 2xl:w-1/4'">
        <div class="relative rounded-lg shadow dark:bg-gray-700 p-8">
            @if (icon) {
                <div class="flex justify-center items-center">
                    <img [src]="icon" alt="icon" class="h-16 w-16" />
                </div>
            }
            <div class="justify-center items-center">
                <div class="flex justify-center items-center" [ngClass]="{'text-center': centerText}">
                    <h3 class="w-full text-lg font-bold dark:text-gray-400" [ngClass]="{'mt-5': icon}">{{ literalService.get(title, true) }}</h3>
                    @if (!icon) {
                        <button (click)="modal.hide()" type="button" class="absolute right-2 mr-2 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="addPax">
                            <img [src]="images.close" class="h-6 w-6" />
                        </button>
                    }
                </div>
                <p class="w-full whitespace-pre-line text-base mb-8 primary-purple-text dark:text-gray-400" [ngClass]="{'text-center': centerText, 'mt-1': icon, 'mt-6': !icon}">{{ literalService.get(text, true) }}</p>
            </div>
            <div class="justify-center items-center" [ngClass]="{'flex': sameLineButtons}">
                <button type="button" class="secondary mt-2 mr-[15px] gap-4 px-4 border font-semibold h-11 w-full uppercase cursor-pointer" (click)="modal.hide()">{{ literalService.get('cancel', true) }}</button>
                <button type="button" class="primary mt-2 gap-4 px-4 border font-semibold h-11 w-full uppercase cursor-pointer bg-white text-white" (click)="action.emit(true)">{{ literalService.get('confirm', true) }}</button>
            </div>
        </div>
    </div>
</div>
import { IUserDriver } from 'src/app/models';
import { UserRole, UserStatus, UserType } from 'src/app/models/enums';
import { UtilsService } from 'src/app/services';

const utilsService = new UtilsService();

export function initialUserDriverState(language: string): IUserDriver {
  return {
    name: '',
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    language: language,
    phone: '',
    additionalPhone: '',
    clients: [],
    town: undefined,
    role: UserRole.USER,
    status: utilsService.capitalize(UserStatus.ACTIVE.toLowerCase()),
    type: UserType.Driver,
    licenseNumber: '',
    operator: undefined,
  };
}

import { Injectable } from '@angular/core';
import { Theme, UserSortType } from 'src/app/models/enums';
import { UserRole } from 'src/app/models/enums/userRole';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private keys = {
    language: `language`,
    routeName: `route`,
    theme: `theme`,
    token: `token`,
    role: `role`,
    userSortType: `userSortType`,
    userInfo: `userInfo`,
    area: `area`,
    userSelected: `userSelected`
  };

  public async setLanguage(language: string): Promise<void> {
    return this.setByKey(this.keys.language, language);
  }

  public async getLanguage(): Promise<string> {
    return this.getByKey(this.keys.language);
  }

  public async removeLanguage(): Promise<void> {
    return this.removeByKey(this.keys.language);
  }

  public async setRouteName(routeName: string): Promise<void> {
    return this.setByKey(this.keys.routeName, routeName);
  }

  public async getRouteName(): Promise<string | null> {
    return this.getByKey(this.keys.routeName);
  }

  public async removeRouteName(): Promise<void> {
    return this.removeByKey(this.keys.routeName);
  }

  public async setToken(token: string): Promise<void> {
    return this.setByKey(this.keys.token, token);
  }

  public async getToken(): Promise<string | null> {
    return this.getByKey(this.keys.token);
  }

  public async removeToken(): Promise<void> {
    return this.removeByKey(this.keys.token);
  }

  public async setTheme(theme: Theme): Promise<void> {
    return this.setByKey(this.keys.theme, theme);
  }

  public async getTheme(): Promise<Theme> {
    return this.getByKey(this.keys.theme);
  }

  public async removeTheme(): Promise<void> {
    return this.removeByKey(this.keys.theme);
  }

  public async setRole(role: UserRole): Promise<void> {
    return this.setByKey(this.keys.role, role);
  }

  public async getRole(): Promise<UserRole> {
    return this.getByKey(this.keys.role);
  }

  public async removeRole(): Promise<void> {
    return this.removeByKey(this.keys.role);
  }

  public async setUserSortType(userSortType: UserSortType): Promise<void> {
    return this.setByKey(this.keys.userSortType, userSortType);
  }

  public async getUserSortType(): Promise<UserSortType> {
    return this.getByKey(this.keys.userSortType);
  }

  public async removeUserSortType(): Promise<void> {
    return this.removeByKey(this.keys.userSortType);
  }

  public async setUserInfo(userInfo: any): Promise<void> {
    return this.setByKey(this.keys.userInfo, userInfo);
  }

  public async getUserInfo(): Promise<unknown> {
    return this.getByKey(this.keys.userInfo);
  }

  public async removeUserInfo(): Promise<void> {
    return this.removeByKey(this.keys.userInfo);
  }

  public async getUserSelected(): Promise<unknown> {
    return this.getByKey(this.keys.userSelected);
  }

  public async setUserSelected(userSelected: any): Promise<void> {
    return this.setByKey(this.keys.userSelected, userSelected);
  }

  public async removeUserSelected(): Promise<void> {
    return this.removeByKey(this.keys.userSelected);
  }

  public async setArea(area: any): Promise<void> {
    return this.setByKey(this.keys.area, area);
  }

  public async getArea(): Promise<string> {
    return this.getByKey(this.keys.area);
  }

  public async removeArea(): Promise<void> {
    return this.removeByKey(this.keys.area);
  }

  public async clearStorage(): Promise<void> {
    localStorage.clear();
  }

  // eslint-disable-next-line
  private async getByKey(key: string): Promise<any> {
    try {
      const value = await localStorage.getItem(key);
      return value;
    } catch (error) {
      console.error(error);
    }
  }

  private async setByKey(key: string, value: string): Promise<void> {
    try {
      await localStorage.setItem(key, value);
    } catch (error) {
      console.error(error);
    }
  }

  // eslint-disable-next-line
  private async removeByKey(key: string): Promise<any> {
    try {
      await localStorage.removeItem(key);
    } catch (error) {
      console.error(error);
    }
  }
}

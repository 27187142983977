<div [id]="id" tabindex="-1" class="absolute top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
    <div class="relative w-96 max-h-full rounded-lg">
        <div class="relative primary-purple-light-2-background rounded-lg shadow dark:bg-gray-700 p-[25px]">
            <form #form novalidate (ngSubmit)="onSubmit()">
                <div class="flex items-center">
                    <h3 class="w-full text-xl font-bold primary-purple-text dark:text-gray-400">{{ literalService.get('bookings.actions.ticketingStatus.title', true) }}</h3>
                    <button (click)="modal.hide()" type="button" class="right-6 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                        <img [src]="images.close" class="h-6 w-6" />
                    </button>
                </div>
                <div class="flex items-center mt-8">
                    <div class="flex flex-col w-full">
                        <label class="w-full text-base font-bold primary-purple-text" [ngClass]="{'neutrals-grey-03-text': updated}">{{ literalService.get('bookings.actions.ticketingStatus.payment', true) }}</label>
                        <span class="text-base font-normal mt-1" [ngClass]="{'neutrals-grey-03-text': updated}">
                            @if (booking && booking.paidDateTime) {
                                {{ literalService.get('bookings.actions.ticketingStatus.paymentMade', true).replace('{0}', moment(booking.paidDateTime).format('DD/MM/YYYY')).replace('{1}', moment(booking.paidDateTime).format('HH:mm')) }}
                            } @else {
                                {{ literalService.get('bookings.actions.ticketingStatus.paymentNotMade', true) }}
                            }
                        </span>
                    </div>
                    <app-toggle [id]="'payment'" [checked]="booking && !!booking.paidDateTime" [disabled]="updated" (change)="changePayment()"></app-toggle>
                </div>
                <div class="flex items-center mt-6">
                    <div class="flex flex-col w-full">
                        <label class="w-full text-base font-bold primary-purple-text" [ngClass]="{'neutrals-grey-03-text': updated}">{{ literalService.get('bookings.actions.ticketingStatus.validation', true) }}</label>
                        <span class="text-base font-normal mt-1" [ngClass]="{'neutrals-grey-03-text': updated}">
                            @if (booking && booking.validatedDateTime) {                            
                                {{ literalService.get('bookings.actions.ticketingStatus.validationMade', true).replace('{0}', moment(booking.validatedDateTime).format('DD/MM/YYYY')).replace('{1}', moment(booking.validatedDateTime).format('HH:mm')) }}
                            } @else {
                                {{ literalService.get('bookings.actions.ticketingStatus.validationNotMade', true) }}
                            }
                        </span>
                    </div>
                    <app-toggle [id]="'validation'" [checked]="booking && !!booking.validatedDateTime" [disabled]="updated" (change)="changeValidation()"></app-toggle>
                </div>
                @if (updated) {
                    <div class="flex items-center mt-10">
                        <div class="flex flex-col w-full">
                            <label class="w-full text-base font-bold primary-purple-text dark:text-gray-400">{{ literalService.get('bookings.actions.ticketingStatus.pleaseVerifyChanges', true) }}</label>
                            <span class="text-base font-normal mt-2">
                                {{ literalService.get('bookings.actions.ticketingStatus.ticketRecorded', true) }}
                                <span class="primary-purple-light-text">
                                    @if (!booking.paidDateTime && !booking.validatedDateTime) {
                                        {{ literalService.get('bookings.actions.ticketingStatus.notPaidTextAndNotValidatedText') }}
                                    } @else if (booking.paidDateTime && !booking.validatedDateTime) {
                                        {{ literalService.get('bookings.actions.ticketingStatus.paidTextAndNotValidatedText') }}
                                    } @else if (!booking.paidDateTime && booking.validatedDateTime) {
                                        {{ literalService.get('bookings.actions.ticketingStatus.notPaidTextAndValidatedText') }}
                                    } @else if (booking.paidDateTime && booking.validatedDateTime) {
                                        {{ literalService.get('bookings.actions.ticketingStatus.paidAndValidatedText') }}
                                    }
                                </span>
                            </span>
                        </div>
                    </div>
                }
                <div class="flex justify-center items-center mt-10">
                    <button type="button" class="secondary mt-2 mr-5 gap-4 p-2 border-2 font-semibold w-full uppercase cursor-pointer" (click)="updated ? updated = false : modal.hide()">{{ literalService.get('cancel', true) }}</button>
                    @if (!updated) {
                        <button type="button" class="primary mt-2 gap-4 p-2 border-2 font-semibold w-full uppercase cursor-pointer text-white" [disabled]="changes.length === 0" (click)="updateStatus($event)">{{ literalService.get('update', true) }}</button>
                    } @else {
                        <button type="submit" class="primary mt-2 gap-4 p-2 border-2 font-semibold w-full uppercase cursor-pointer text-white">{{ literalService.get('confirm', true) }}</button>
                    }
                </div>
            </form>
        </div>
    </div>
</div>
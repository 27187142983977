import { SignInComponent } from './sign-in/sign-in.component';
import { ClientsComponent } from './clients/clients.component';
import { IdentityProviderComponent } from './identity-provider/identity-provider.component';
import { AppsComponent } from './apps/apps.component';
import { UsersComponent } from './users/users.component';
import { OperatorsComponent } from './operators/operators.component';
import { VehiclesComponent } from './vehicles/vehicles.component';
import { TownsComponent } from './towns/towns.component';
import { ServicesComponent } from './services/services.component';
import { BookingsComponent } from './bookings/bookings.component';
import { MessageUsersComponent } from './message-users/message-users.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { TripsComponent } from './trips/trips.component';

export {
  SignInComponent,
  ClientsComponent,
  IdentityProviderComponent,
  AppsComponent,
  UsersComponent,
  OperatorsComponent,
  VehiclesComponent,
  TownsComponent,
  ServicesComponent,
  TripsComponent,
  BookingsComponent,
  MessageUsersComponent,
  DashboardComponent,
};

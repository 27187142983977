import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { Modal } from 'flowbite';
import { Subscription } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { IBooking, IUser, IUserDriver, IUserRegular } from 'src/app/models';
import { NotificationTypes, UserRole, UserStatus, UserType } from 'src/app/models/enums';
import { UsersResolver } from 'src/app/resolvers/users.resolver';
import { AuthService, NotificationService, StorageService, TripsService, UsersService } from 'src/app/services';
import { ApiRouterService } from 'src/app/services/http/api.router';
import { LiteralService } from 'src/app/services/literal/literal.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { TooltipPosition } from 'src/app/shared/tooltip/tooltip-position';
import { initialUserDriverState } from 'src/app/store/initialisation/userDriver';
import { initialUserRegularState } from 'src/app/store/initialisation/userRegular';
import { initialUserState } from 'src/app/store/initialisation/users';
import { images } from 'src/images';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit, OnDestroy {
  public images = images;
  public userSelected: any;
  public newUser: any;
  public users = initialUserState;
  public TooltipPosition = TooltipPosition;
  public towns: any[] = [];
  public userType = UserType;
  public userStatus = UserStatus;
  public adminRole: UserRole;

  public bookings: IBooking[] = [];
  public viewBookings: Modal;
  public viewBookingsShowed: boolean = false;
  public showDate = true;
  private routerSubscription: Subscription;

  private filtered: boolean = false;
  private clientsSelected: number[];
  private townsSelected: number[];
  private typesSelected: UserType[];
  private statusSelected: UserStatus[];
  private searchValue: string = '';
  private language: string = 'en';
  public searching = false;

  public controller = new AbortController();
  public interval: any;
  public getBookingsInterval: any;

  public changeStatus: Modal;
  public changeStatusShowed: boolean = false;
  public changeStatusSelected: string;

  public blockUser: Modal;
  public blockUserShowed: boolean = false;
  public reason: string;
  
  constructor(
    renderer: Renderer2,
    public literalService: LiteralService,
    private usersService: UsersService,
    private usersResolver: UsersResolver,
    private activatedRoute: ActivatedRoute,
    public utilsService: UtilsService,
    public routerService: ApiRouterService,
    public storageService: StorageService,
    public authService: AuthService,
    private titleService: Title,
    public router: Router,
    public notificationService: NotificationService,
    public tripsService: TripsService,
    public appComponent: AppComponent
  ) {
    renderer.listen('window', 'click', (e: Event) => {
      const modal = document.getElementById('userDetailsContainer')!;
      const modals: Node[] = [];
      modals.push(document.getElementById('viewBookings') as Node);
      modals.push(document.getElementById('changeStatus') as Node);
      modals.push(document.getElementById('blockUser') as Node);
      const componentOpen = document.getElementById('usersContainer')!;
      const close = utilsService.closeCustomModalsWithModals(e, modal, modals, componentOpen);
      if (close) {
        this.userSelected = undefined;
      }
    });
  }

  async ngOnInit() {
    this.getUsersListener();
    this.language = await this.utilsService.getLanguage();
    this.adminRole = await this.storageService.getRole();
    this.titleService.setTitle(this.literalService.get('sidebar.users', true) + ' - NEMI Backoffice');

    // Retrieve the object ID from the route parameters
    this.activatedRoute.params && this.activatedRoute.params.subscribe((params) => {
      const userId: number = params['id'];
      const userTypeLowercase = params['type'];
      const userType: UserType = userTypeLowercase ? userTypeLowercase[0].toUpperCase() + userTypeLowercase.slice(1) : UserType.Regular;
      userId && userType && this.showUserData({type: userType, id: userId});
    });

    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.viewBookingsShowed) this.viewBookings.toggle();
        if (this.changeStatusShowed) this.changeStatus.toggle();
        if (this.blockUserShowed) this.blockUser.toggle();
      }
    });
    this.initializeModals();
  }

  userEdited = (userEdited: IUserRegular | IUserDriver) => {
    const index = this.users.value.findIndex((user: IUserRegular | IUserDriver) => user.id === userEdited.id);
    if (index !== -1) {
      this.users.value[index] = userEdited;
    } else {
      this.refreshData(); // create user also calls this
      this.newUser = false;
      this.userSelected = userEdited;
    }
  };

  getUsersListener = async () => {
    this.interval = setInterval(() => {
      this.refreshData();
    }, 300000);
  };

  refreshData() {
    if (this.filtered) {
      this.usersResolver.filter(this.activatedRoute.snapshot, this.clientsSelected, this.townsSelected, this.typesSelected, this.statusSelected, this.searchValue);
    } else {
      this.usersResolver.getUsers(this.activatedRoute.snapshot, this.searchValue);
    }
  }

  goToUser(user: IUser): void {
    this.router.navigate(['/users/' + user.type + '/' + user.id]);
  }

  async showUserData(user: {type?: UserType, id?: number}) {
    try {
      this.userSelected =
        user.type === UserType.Regular
          ? ((await this.usersService.getUser(
              user.type!,
              user.id!,
            )) as IUserRegular)
          : ((await this.usersService.getUser(
              user.type!,
              user.id!,
            )) as IUserDriver);
      this.changeStatusSelected = this.userSelected.status;
      this.newUser = undefined;
    } catch {
      // try again in case it may be a driver
      if (user.type === UserType.Regular) {
          this.router.navigate(['/users/driver/' + user.id]);
      } else {
      // go to users again if user not found
      this.router.navigate(['/users']);
      }
    }
  }

  async changePage(isNext: boolean = true) {
    this.users.pagination.actualPage = isNext
      ? this.users.pagination.actualPage + 1
      : this.users.pagination.actualPage - 1;
    this.refreshData();
    this.abortFetchEventSource();
    this.getUsersListener();
  }

  public getTooltipName(name: string, i: number): string {
    return `${name}-${i}`;
  }

  public closeDetails() {
    this.userSelected = undefined;
    this.newUser = undefined;
  }

  public addUser = () => {
    this.newUser = initialUserRegularState(this.language);
  };

  public addUserDriver = () => {
    this.newUser = initialUserDriverState(this.language);
  };

  public showFavouriteRoutes(event: Event, id: string) {
    const userRow = document.getElementById(id)?.getBoundingClientRect();
    if (userRow) {
      document.getElementById('favouriteRoutes')!.style.top =
        (userRow.top + 50).toString() + 'px';
      document.getElementById('favouriteRoutes')!.style.right =
        userRow.left.toString() + 'px';
    }
    event.stopPropagation();
  }

  public filterUsers = async (event: {selectedClients: number[], selectedTowns: number[], selectedTypes: UserType[], selectedStatus: UserStatus[]}) => {
    this.searching = true;
    this.users = initialUserState;
    this.users.value = [];
    this.filtered = true;
    this.clientsSelected = event.selectedClients;
    this.townsSelected = event.selectedTowns;
    this.typesSelected = event.selectedTypes;
    this.statusSelected = event.selectedStatus;
    this.refreshData();
    this.abortFetchEventSource();
    await this.getUsersListener();
    this.searching = false;
  };

  public searchUsers = async (data: any) => {
    this.searching = true;
    this.users = initialUserState;
    this.users.value = [];
    if (typeof data === 'object') {
      this.searchValue = data.target.value;
    } else {
      this.searchValue = data;
    }
    this.refreshData();
    this.abortFetchEventSource();
    await this.getUsersListener();
    this.searching = false;
  };

  public sortUsers = () => {
    this.refreshData();
    this.abortFetchEventSource();
    this.getUsersListener();
  };

  abortFetchEventSource = () => {
    clearInterval(this.interval);
  };

  /*goToSelfBookings(userId?: number) {
    this.storageService.setUserSelected(userId)
    this.router.navigate(['/bookings']);
  }*/

  initializeModals = () => {
    this.viewBookings = new Modal(document.getElementById('viewBookings'), {
      placement: 'center',
      closable: true,
      onHide: () => {
        clearInterval(this.getBookingsInterval);
        this.viewBookingsShowed = false;
      },
      onShow: () => (this.viewBookingsShowed = true),
    });
    this.changeStatus = new Modal(document.getElementById('changeStatus'), {
      placement: 'center',
      closable: true,
      onHide: () => (this.changeStatusShowed = false),
      onShow: () => (this.changeStatusShowed = true),
    });
    this.blockUser = new Modal(document.getElementById('blockUser'), {
      placement: 'center',
      closable: true,
      onHide: () => {
        (this.blockUserShowed = false),
        this.reason = '';
      },
      onShow: () => (this.blockUserShowed = true),
    });
  };

  validatePickUpAction = async (bookingId: any) => {
    const bookingIndex = this.bookings.findIndex(
      (booking: IBooking) => bookingId === booking.id,
    )!;
    const booking = await this.tripsService.validatePickUp(bookingId);
    this.bookings[bookingIndex] = booking;
    this.notificationService.image = images.confirm;
    this.notificationService.title = this.literalService.get(
      'trips.actions.validate.title',
      true,
    );
    this.notificationService.message = this.literalService.get(
      'trips.actions.validate.text',
      true,
    );
    this.notificationService.show(NotificationTypes.SUCCESS);
  };

  validateDropOffAction = async (bookingId: any) => {
    const bookingIndex = this.bookings.findIndex(
      (booking: IBooking) => bookingId === booking.id,
    )!;
    const booking = await this.tripsService.validateDropOff(bookingId);
    this.bookings[bookingIndex] = booking;
    this.notificationService.image = images.confirm;
    this.notificationService.title = this.literalService.get(
      'trips.actions.validate.title',
      true,
    );
    this.notificationService.message = this.literalService.get(
      'trips.actions.validate.text',
      true,
    );
    this.notificationService.show(NotificationTypes.SUCCESS);
  };

  viewBookingsAction = () => {
  };

  showViewBookings = async (userId?: number) => {
    if (!userId) return;
    this.bookings = await this.usersService.getBookingsByUser(userId!);
    this.getBookingsInterval = setInterval(async () => {
      this.bookings = await this.usersService.getBookingsByUser(userId!);
    }, 60000);
    this.viewBookings.toggle();
  };

  changeStatusAction = async () => {
    this.userSelected = await this.usersService.changeStatus(this.userSelected, this.changeStatusSelected);
    this.userEdited(this.userSelected);
    this.changeStatus.toggle();
    this.notificationService.image = images.sidebar.users;
    this.notificationService.title = this.literalService.get(
      `users.actions.edit.title`,
      true,
    );
    this.notificationService.message = this.literalService.get(
      `users.actions.edit.text`,
      true,
    );
    this.notificationService.show(NotificationTypes.SUCCESS);
  };

  blockUserAction = async (data: any) => {
    console.log('blockUserAction', data);
    this.userSelected = await this.usersService.changeStatus(this.userSelected, this.utilsService.capitalize(UserStatus.BLOCKED.toLowerCase()), data.date, data.reason);
    this.userEdited(this.userSelected);
    this.blockUser.toggle();
    this.notificationService.image = images.sidebar.users;
    this.notificationService.title = this.literalService.get(
      `users.actions.edit.title`,
      true,
    );
    this.notificationService.message = this.literalService.get(
      `users.actions.edit.text`,
      true,
    );
    this.notificationService.show(NotificationTypes.SUCCESS);
  };

  showChangeStatus = async (status: UserStatus) => {
    this.changeStatusSelected = status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
    if (status === UserStatus.BLOCKED) this.blockUser.toggle();
    else this.changeStatus.toggle();
  };

  ngOnDestroy(): void {
    clearInterval(this.interval);
    this.routerSubscription.unsubscribe();
  }
}

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiRouterService } from '../http/api.router';
import { BaseHttpService } from '../http/base.http.service';

@Injectable({
  providedIn: 'root',
})
export class AppsHttpService extends BaseHttpService {
  private baseURL = `apps`;

  constructor(
    @Inject(HttpClient) httpClient: HttpClient,
    private routerService: ApiRouterService,
  ) {
    super(httpClient);
  }

  public getApps() {
    const url = this.routerService.mount(`${this.baseURL}/list`);
    return this.read(url);
  }
}

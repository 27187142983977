import { images } from '../../images';
import { IClient, IRouteDetails, ITown } from '../models';
import { ExpeditionCreationCriteria, ExpeditionType, InputType, PublishingSite, RouteVisibility, TicketingType, UserRole } from '../models/enums';
import { AppsService, RouteService, StorageService, UsersService, UtilsService } from '../services';
import { LiteralService } from '../services/literal/literal.service';
import { formData } from './routeData';

export function getNotificationsValues(route?: IRouteDetails): unknown[] {
  const notifications = [
    { id: 'email', name: 'EMAIL', code: 'EMAIL' },
    { id: 'push', name: 'PUSH', code: 'PUSH' },
    { id: 'sms', name: 'SMS*', code: 'SMS' },
  ];

  return route
    ? notifications.filter(notification => route[notification.id as keyof Object])
    : notifications;
}

export function getExpeditionAndBookingManagement(route?: IRouteDetails, literalService?: LiteralService): unknown[] {
  const functionalities = [
    { id: "dynamicEndStop", value: route?.dynamicEndStop, label: "dynamicEndStop" },
    { id: "goBack", value: route?.goBack, label: "goBack" },
    { id: "singleStopBookings", value: route?.singleStopBookings, label: "singleStopBookings" },
    { id: "bookOnReadyWithoutTrip", value: route?.bookOnReadyWithoutTrip, label: "bookOnReadyWithoutTrip" },
    { id: "realTimeBookings", value: route?.realTimeBookings, label: "realTimeBookings" },
    { id: "lastMinuteBookings", value: route?.lastMinuteBookings, label: "lastMinuteBookings" },
    { id: "cancelBookingsOnReady", value: route?.cancelBookingsOnReady, label: "cancelBookingsOnReady" },
    { id: "avoidCriteriasValidation", value: route?.avoidCriteriasValidation, label: "avoidCriteriasValidation" },
  ];

  return route
    ? functionalities.filter(functionality => route[functionality.id as keyof Object])
    : functionalities;
}

export function getVisualizationOptions(route?: IRouteDetails): unknown[] {
  const functionalities = [
    { id: "notifyNewTrips", value: route?.notifyNewTrips, label: "notifyNewTrips" },
    { id: "showOnlyOptimalRoutes", value: route?.showOnlyOptimalRoutes, label: "showOnlyOptimalRoutes" },
    { id: "showUserDataToDriver", value: route?.showUserDataToDriver, label: "showUserDataToDriver" },
    { id: "showRoute", value: route?.showRoute, label: "showRoute" },
    { id: "showBusPosition", value: route?.showBusPosition, label: "showBusPosition" },
    { id: "informative", value: route?.informative, label: "informative" }
  ];

  return route
    ? functionalities.filter(functionality => route[functionality.id as keyof Object])
    : functionalities;
}

export async function getClientsTown(usersService: UsersService) {
  return await usersService.getPossibleClientTowns().then((res: any) => {
    const towns: any[] = [];
    const clients: any[] = [];
    const additionalClients: any[] = [];
    const fullClientsData: any[] = [];
    res.towns.map((town: ITown) => {
      towns.push({ id: town.id, value: town.id, label: town.name });
    });
    res.clients.map((client: IClient) => {
      fullClientsData.push(client);
      clients.push({ id: client.id, value: client.id, label: client.name });
      additionalClients.push({ id: client.id, name: client.name, code: client.name, maxKmsPerMonth: client.maxKmsPerMonth });
    });
    return { towns, clients, additionalClients, fullClientsData };
  });
}

export async function getApps(appsService: AppsService) {
  return await appsService.getApps().then((res: any) => {
    console.log(res)
    let apps: any[] = [];
    res.forEach((app: any) => {
      // apps.push({ id: app.id, name: app.code, label: app.code });
      apps.push(app);
    });
    return apps;
    // return res;
  });
}

export async function getRouteForm(route: IRouteDetails, newRoute: boolean, usersService: UsersService, literalService: LiteralService, storageService: StorageService, appsService: AppsService, utilsService: UtilsService, routeService: RouteService, town: ITown, managerClientName: string, edit?: boolean): Promise<unknown[]> {
  let towns: any[] = [];
  let clients: any[] = [];
  let additionalClients: any[] = [];
  let fullClientsData: any[] = [];
  let relateds: any[] = [];
  let saes: any[] = [];
  let expeditionTypes: any[] = [];
  let visibilities: any[] = [];
  let expeditionCreationCriterias: any[] = [];
  let ticketingTypes: any[] = [];

  console.log(route)

  const notifications = getNotificationsValues();
  const notificationsValues = getNotificationsValues(route);
  const apps = await getApps(appsService);

  const publishingSitesValues = Object.values(PublishingSite);
  const publishingSites: string[] = [];
  publishingSitesValues.forEach((publishingSite: string) => {
    // publishingSites.push({ id: publishingSite, value: publishingSite, label: publishingSite });
    publishingSites.push(publishingSite);
  });
  console.log(publishingSites)

  const res = await getClientsTown(usersService);
  towns = res.towns;
  clients = res.clients;
  fullClientsData = res.fullClientsData;
  additionalClients = res.additionalClients;

  if (edit) {
    relateds = await routeService.getRelateds(route.id!);
  }
  console.log(relateds)
  saes = await routeService.getSaes();
  console.log(saes)

  const userRole = await storageService.getRole();

  const managerClientForNewRoute = fullClientsData.find((client: IClient) => client.name === managerClientName)
  console.log(managerClientForNewRoute)
  route.town = town;
  route.managerClient = managerClientForNewRoute;

  Object.values(ExpeditionType).forEach((expeditionType: ExpeditionType) => {
    expeditionTypes.push({id: expeditionType, value: expeditionType, label: literalService.get(expeditionType, true)})
  });

  Object.values(RouteVisibility).forEach((routeVisibility: RouteVisibility) => {
    visibilities.push({id: routeVisibility, value: routeVisibility, label: literalService.get(routeVisibility, true)})
  });

  Object.values(ExpeditionCreationCriteria).forEach((expeditionCreationCriteria: ExpeditionCreationCriteria) => {
    expeditionCreationCriterias.push({id: expeditionCreationCriteria, value: expeditionCreationCriteria, label: literalService.get(expeditionCreationCriteria, true)})
  });

  Object.values(TicketingType).forEach((ticketingType: TicketingType) => {
    ticketingTypes.push({id: ticketingType, value: ticketingType, label: literalService.get(ticketingType, true)})
  });

  const data: formData[] = [
    {
      img: images.services.routes.area,
      title: 'services.routes.data.area',
      value: newRoute ? town.id : edit ? route.town?.id : route.town?.name,
      required: false,
      expanded: false,
      color: true,
      hidden: true,
      edit: {
        inputType: InputType.SELECT_SEARCH,
        required: false,
        // disabled: true,
        // selectedValues: route.town.id,
        values: towns,
        valueToChange: 'area',
      },
    },
    {
      img: images.services.routes.client,
      title: 'services.routes.data.client',
      value: newRoute ? managerClientForNewRoute.id : edit ? route.managerClient?.id : route.managerClient?.name,
      required: false,
      expanded: false,
      color: true,
      hidden: true,
      edit: {
        inputType: InputType.SELECT_SEARCH,
        required: false,
        // disabled: true,
        // selectedValues: route.managerClient.id,
        values: clients,
        valueToChange: 'client',
      },
    },
    {
      img: images.services.routes.route,
      title: 'services.routes.data.name',
      value: route.name,
      required: true,
      expanded: false,
      color: true,
      placeholder: '',
      edit: {
        inputType: InputType.TEXT,
        required: true,
        valueToChange: 'name',
      },
    },
    {
      img: images.services.routes.expeditionType,
      title: 'services.routes.data.expeditionType',
      value: route.expeditionType || ExpeditionType[''],
      required: true,
      expanded: false,
      color: true,
      translateValue: true,
      edit: {
        id: route.expeditionType,
        inputType: InputType.SELECT_SEARCH,
        required: true,
        values: expeditionTypes,
        translateValue: true,
        valueToChange: 'expeditionType',
      },
    },
    {
      img: images.services.routes.time,
      title: 'services.routes.data.maxTime',
      value: utilsService.convertSecondsToMinutesAndSeconds(route.maxTime!),
      required: true,
      expanded: false,
      color: true,
      placeholder: '',
      edit: {
        inputType: InputType.TEXT,
        required: true,
        valueToChange: 'maxTime',
      },
    },
    {
      img: images.services.routes.time,
      title: 'services.routes.data.maxVariationTime',
      value: route.maxVariationTime || -1,
      required: true,
      expanded: false,
      color: true,
      edit: {
        inputType: InputType.TEXT,
        required: true,
        valueToChange: 'maxVariationTime',
      },
    },
    {
      img: images.services.routes.visualization,
      title: 'services.routes.data.expeditionAndBookingManagement',
      value: getExpeditionAndBookingManagement(route, literalService),
      array: true,
      required: false,
      expanded: false,
      color: true,
      edit: {
        id: getExpeditionAndBookingManagement(route, literalService),
        inputType: InputType.MULTISELECT,
        required: false,
        translateValue: true,
        values: getExpeditionAndBookingManagement(),
        selectedValues: getExpeditionAndBookingManagement(route, literalService),
        valueToChange: ['dynamicEndStop',
                        'goBack',
                        'singleStopBookings',
                        'bookOnReadyWithoutTrip',
                        'realTimeBookings',
                        'lastMinuteBookings',
                        'cancelBookingsOnReady',
                        'avoidCriteriasValidation'],
      },
    },
    {
      img: images.services.routes.visibility,
      title: 'services.routes.data.visualizationOptions',
      value: getVisualizationOptions(route),
      array: true,
      required: false,
      expanded: false,
      color: true,
      edit: {
        id: getVisualizationOptions(route),
        inputType: InputType.MULTISELECT,
        required: false,
        translateValue: true,
        values: getVisualizationOptions(),
        selectedValues: getVisualizationOptions(route),
        valueToChange: ['notifyNewTrips',
                        'showOnlyOptimalRoutes',
                        'showUserDataToDriver',
                        'showRoute',
                        'showBusPosition',
                        'informative'],
      },
    },
    {
      img: images.services.routes.notifications,
      title: 'services.routes.data.notificationsConfigurations',
      value: getNotificationsValues(route),
      array: true,
      required: false,
      expanded: false,
      color: true,
      edit: {
        inputType: InputType.MULTISELECT_CHECKBOXES,
        required: false,
        values: getNotificationsValues(),
        selectedValues: getNotificationsValues(route),
        extraInfo: 'services.routes.data.sms.smsAdditionalCost',
        valueToChange: ['sms', 'email', 'push'],
      },
    },
    {
      img: images.services.routes.visibility,
      title: 'services.routes.data.visibility',
      // value: literalService.get(route.visibility, true),
      value: route.visibility,
      required: false,
      expanded: true,
      color: true,
      translateValue: true,
      edit: {
        id: route.visibility,
        inputType: InputType.SELECT_SEARCH,
        required: false,
        values: visibilities,
        translateValue: true,
        valueToChange: 'visibility',
      },
    },
    {
      img: images.services.routes.criteria,
      title: 'services.routes.data.criteria',
      value: route.criteria,
      required: false,
      expanded: true,
      color: true,
      edit: {
        id: route.criteria,
        inputType: InputType.SELECT_SEARCH,
        required: false,
        values: expeditionCreationCriterias,
        translateValue: true,
        valueToChange: 'criteria',
      },
    },
    {
      img: images.services.routes.additionalClients,
      title: 'services.routes.data.additionalClients',
      value: route.additionalClients,
      array: true,
      dataToShow: 'code',
      required: false,
      expanded: true,
      color: true,
      hidden: userRole !== UserRole.SUPERADMIN,
      edit: {
        inputType: InputType.MULTISELECT,
        required: false,
        showSearch: true,
        values: additionalClients,
        selectedValues: additionalClients.length <= 1 ? additionalClients : route.additionalClients,
        valueToChange: 'additionalClients',
      },
    },
    {
      img: images.services.routes.apps,
      title: 'services.routes.data.apps',
      value: route.apps,
      array: true,
      dataToShow: 'code',
      required: false,
      expanded: true,
      color: true,
      hidden: userRole !== UserRole.SUPERADMIN,
      edit: {
        inputType: InputType.MULTISELECT,
        required: false,
        values: apps,
        selectedValues: route.apps,
        valueToChange: 'apps',
      },
    },
    {
      img: images.services.routes.publishingSites,
      title: 'services.routes.data.publishingSites',
      value: route.publishingSites,
      array: true,
      dataToShow: '',
      required: false,
      expanded: true,
      color: true,
      hidden: userRole !== UserRole.SUPERADMIN,
      edit: {
        inputType: InputType.MULTISELECT,
        required: false,
        values: publishingSites,
        selectedValues: route.publishingSites,
        valueToChange: 'publishingSites',
      },
    },
    {
      img: images.services.routes.relateds,
      title: 'services.routes.data.relateds',
      value: route.relatedServices,
      array: true,
      dataToShow: 'name',
      required: false,
      expanded: true,
      color: true,
      hidden: userRole !== UserRole.SUPERADMIN,
      edit: {
        id: route.relatedServices,
        inputType: InputType.MULTISELECT,
        required: false,
        values: relateds,
        selectedValues: route.relatedServices,
        valueToChange: 'relatedServices',
      },
    },
    {
      img: images.services.routes.ticketing,
      title: 'services.routes.data.ticketingType',
      value: route.ticketingType,
      required: false,
      expanded: true,
      color: true,
      hidden: userRole !== UserRole.SUPERADMIN,
      translateValue: true,
      edit: {
        id: route.ticketingType,
        inputType: InputType.SELECT_SEARCH,
        required: false,
        values: ticketingTypes,
        translateValue: true,
        valueToChange: 'ticketingType',
      },
    },
    {
      img: images.services.routes.sae,
      title: 'services.routes.data.saeIntegration',
      value: route.saeActions,
      required: false,
      expanded: true,
      color: true,
      edit: {
        inputType: InputType.SELECT,
        required: false,
        values: [],
        selectedValues: [],
        valueToChange: 'saeIntegration',
      },
    },
    {
      img: images.services.routes.distance,
      title: 'services.routes.data.segmentDetectionRadius',
      value: route.segmentDetectionRadius || 300,
      required: false,
      expanded: true,
      color: true,
      edit: {
        inputType: InputType.TEXT,
        required: false,
        valueToChange: 'segmentDetectionRadius',
      },
    },
    {
      img: images.services.routes.distance,
      title: 'services.routes.data.expeditionDetectionRadius',
      value: route.expeditionDetectionRadius || 20,
      required: false,
      expanded: true,
      color: true,
      edit: {
        inputType: InputType.TEXT,
        required: false,
        valueToChange: 'expeditionDetectionRadius',
      },
    },
    {
      img: images.services.routes.routeTimes,
      title: 'services.routes.data.maxVariability',
      value: utilsService.convertSecondsToMinutesAndSeconds(route.maxVariability!),
      required: false,
      expanded: true,
      color: true,
      edit: {
        inputType: InputType.TEXT,
        required: false,
        disabled: true,
        valueToChange: 'maxVariability',
      },
    },
    {
      img: images.services.routes.routeTimes,
      title: 'services.routes.data.maxRouteTime',
      value: utilsService.convertSecondsToMinutesAndSeconds(route.maxPathTime!),
      required: false,
      expanded: true,
      color: true,
      edit: {
        inputType: InputType.TEXT,
        required: false,
        disabled: true,
        valueToChange: 'maxRouteTime',
      },
    },
    {
      img: images.services.routes.routeTimes,
      title: 'services.routes.data.minRouteTime',
      value: utilsService.convertSecondsToMinutesAndSeconds(route.minPathTime!),
      required: false,
      expanded: true,
      color: true,
      edit: {
        inputType: InputType.TEXT,
        required: false,
        disabled: true,
        valueToChange: 'minRouteTime',
      },
    }
  ];
  console.log(data)
  return data;
}
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Modal } from 'flowbite';
import { InputType } from 'src/app/models/enums';
import { LiteralService } from 'src/app/services/literal/literal.service';
import { images } from 'src/images';
import moment from 'moment';

@Component({
  selector: 'app-modal-block-user',
  templateUrl: './modal-block-user.component.html',
  styleUrl: './modal-block-user.component.scss',
})
export class ModalBlockUserComponent {
  @Input() id: string;
  @Input() modal: Modal;
  @Input() reason: string;
  @Output() action = new EventEmitter<any>();

  public InputType = InputType;
  public images = images;
  public moment = moment;

  public date: any = moment().add(1, 'days').format('YYYY-MM-DD');

  constructor(public literalService: LiteralService) {}

  changeReason = (reason: any) => {
    this.reason = reason;
  };

  changeDate = (date: any) => {
    this.date = moment(date.target.value).format('YYYY-MM-DD');
  };
}

import { Component, EventEmitter, Input, Output, Renderer2 } from '@angular/core';
import { ITrip } from 'src/app/models';
import { UtilsService, RouteService, RoleService } from 'src/app/services';
import { LiteralService } from 'src/app/services/literal/literal.service';
import { TooltipPosition } from 'src/app/shared/tooltip/tooltip-position';
import { images } from 'src/images';
import { ClipboardService } from 'ngx-clipboard';

@Component({
    selector: 'app-trip-row',
    templateUrl: './trip-row.component.html',
    styleUrl: './trip-row.component.scss',
    standalone: false
})
export class TripRowComponent {
  @Input() trip: ITrip;
  @Input() language: string;
  @Input() index: number;
  @Output() getTripEventEmitter = new EventEmitter<number>();
  @Output() showChangeDriverEventEmitter = new EventEmitter<number>();
  @Output() showChangeVehicleEventEmitter = new EventEmitter<number>();
  @Output() showViewBookingsEventEmitter = new EventEmitter<number>();
  @Output() showSetReadyEventEmitter = new EventEmitter<number>();
  @Output() showSetClosedEventEmitter = new EventEmitter<number>();
  @Output() showDeleteTripEventEmitter = new EventEmitter<number>();

  public showOptions: boolean = false;

  public TooltipPosition = TooltipPosition;
  public images = images;

  constructor(
    renderer: Renderer2,
    public utilsService: UtilsService,
    public literalService: LiteralService,
    private clipboardService: ClipboardService,
    private routeService: RouteService,
    public roleService: RoleService
  ) {
    renderer.listen('window', 'click', (e: Event) => {
      const modal = document.getElementById('actionsModal' + this.trip.id)!;
      const close = utilsService.closeCustomModals(e, modal, modal);
      if (close) {
        this.showOptions = false;
      }
    });
  }

  moreInfo = (event: any, trip: ITrip) => {
    event.stopPropagation();
    this.showOptions = !this.showOptions;
    const row = document
      .getElementById('tripMoreOptions' + trip.id)
      ?.getBoundingClientRect();
    if (row) {
      document.getElementById('actionsModal' + trip.id)!.style.top =
        (row.top - 120).toString() + 'px';
      document.getElementById('actionsModal' + trip.id)!.style.left =
        (row.left - 90).toString() + 'px';
    }
  };

  isLate(delay?: number): boolean {
    return delay ? delay > 5 : false;
  }

  isEarly(delay?: number): boolean {
    return delay ? delay < -3 : false;
  }

  shouldHaveStarted(dateTime?: Date): boolean {
    return dateTime ? new Date(dateTime) < new Date() : false;
  }

  copyLink(tripId: number): void {
    const domain = this.routeService.getCurrentDomain();
    this.clipboardService.copyFromContent(domain + '/trips/' + tripId);
    this.showOptions = false;
  }

}

import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { Observable, of } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { CacheService, UtilsService } from 'src/app/services';
import { environment } from 'src/environments/environment';
import { Cache } from '../../models/cache';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  private env = environment;

  constructor(
    private utilsService: UtilsService,
    private cacheService: CacheService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (request.method !== 'GET') {
      return next.handle(request);
    }
    if (request.headers.get('reset')) {
      this.cacheService.cache.delete(request);
    }
    const resp = this.cacheService.get(request);
    const cachedResponse: Cache = resp
      ? new Cache(resp.url, resp.response, resp.expire)
      : (null as any);
    const expiredCache =
      new Date().getTime() >
      request.headers['headers'].get('expire')?.getTime();
    if (cachedResponse && !expiredCache) {
      const response: HttpResponse<any> = cachedResponse.response;
      return of(response.clone());
    }
    expiredCache ? this.cacheService.cache.delete(request) : null;
    return next.handle(request).pipe(
      map((event) => {
        if (event instanceof HttpResponse) {
          this.utilsService.urlsToCache.forEach((url: any) => {
            if (this.cacheService.checkToSaveCache(url, request)) {
              request.headers['headers'].set(
                'expire',
                moment(new Date()).add(this.env.cacheTime, 'm').toDate(),
              );
              const cache = new Cache(
                request.url,
                event.clone(),
                moment(new Date()).add(this.env.cacheTime, 'm').toDate(),
              );
              this.cacheService.set(request, cache);
            }
          });
        }
        return event;
      }),
      share(),
    );
  }
}

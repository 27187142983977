import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LiteralService } from 'src/app/services';

@Component({
    selector: 'app-multiselect-checkboxes',
    templateUrl: './multiselect-checkboxes.component.html',
    styleUrl: './multiselect-checkboxes.component.scss',
    standalone: false
})
export class MultiselectCheckboxesComponent {
  @Input() title: string;
  @Input() elements?: any[] = [];
  @Input() values?: any[];
  @Input() translateValue?: boolean;
  @Input() extraInfo?: string;
  @Output() changeEventEmitter = new EventEmitter<unknown>();

  constructor(public literalService: LiteralService) {}

  public checked(item: any) {
    return this.values?.find((value: any) => value && value.id === item.id);
  }
}

import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { Modal } from 'flowbite';
import { Subscription } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { IBooking, IFavourites, IUser, IUserDriver, IUserRegular } from 'src/app/models';
import { IDocument } from 'src/app/models/documents/document';
import { IDocumentCreate } from 'src/app/models/documents/documentCreate';
import { NotificationTypes, UserRole, UserStatus, UserType } from 'src/app/models/enums';
import { IDocumentType } from 'src/app/models/enums/documentType';
import { UsersResolver } from 'src/app/resolvers/users.resolver';
import { AuthService, BookingsService, NotificationService, StorageService, TripsService, UsersService } from 'src/app/services';
import { FavouritesService } from 'src/app/services/favourites/favourites.service';
import { ApiRouterService } from 'src/app/services/http/api.router';
import { LiteralService } from 'src/app/services/literal/literal.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { TooltipPosition } from 'src/app/shared/tooltip/tooltip-position';
import { initialUserDriverState } from 'src/app/store/initialisation/userDriver';
import { initialUserRegularState } from 'src/app/store/initialisation/userRegular';
import { initialUserState } from 'src/app/store/initialisation/users';
import { images } from 'src/images';

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss'],
    standalone: false
})
export class UsersComponent implements OnInit, OnDestroy {
  public images = images;
  public userSelected: any;
  public newUser: any;
  public users = initialUserState;
  public TooltipPosition = TooltipPosition;
  public towns: any[] = [];
  public userType = UserType;
  public userStatus = UserStatus;
  public adminRole: UserRole;

  public updateDocuments: Modal;
  public updateDocumentsShowed: boolean = false;
  public documents: IDocument[] = [];

  public bookings: IBooking[] = [];
  public viewBookingsUserId: number;
  public viewBookings: Modal;
  public viewBookingsShowed: boolean = false;
  public showDate = true;
  private routerSubscription: Subscription;

  public deleteBooking: Modal;
  public deleteBookingShowed: boolean = false;
  public deletingBooking = false;
  public bookingToDelete: number;
  public comment: string;

  private filtered: boolean = false;
  private clientsSelected: number[];
  private townsSelected: number[];
  private typesSelected: UserType[];
  private statusSelected: UserStatus[];
  private searchValue: string = '';
  private language: string = 'en';
  public searching = false;

  public controller = new AbortController();
  public interval: any;
  public getBookingsInterval: any;

  public changeStatus: Modal;
  public changeStatusShowed: boolean = false;
  public changeStatusSelected: string;

  public blockUser: Modal;
  public blockUserShowed: boolean = false;
  public reason: string;
  public viewFavourites: Modal;
  public viewFavouritesShowed: boolean = false;
  public viewFavouritesSelected: string;
  public favourites: IFavourites[] = [];
  public favouriteUserId: number;

  constructor(
    renderer: Renderer2,
    public literalService: LiteralService,
    private usersService: UsersService,
    private usersResolver: UsersResolver,
    private activatedRoute: ActivatedRoute,
    public utilsService: UtilsService,
    public routerService: ApiRouterService,
    public storageService: StorageService,
    public authService: AuthService,
    private titleService: Title,
    public router: Router,
    public notificationService: NotificationService,
    public tripsService: TripsService,
    public favouritesService: FavouritesService,
    public bookingsService: BookingsService,
    public appComponent: AppComponent
  ) {
    renderer.listen('window', 'click', (e: Event) => {
      if (!this.utilsService.isTextSelected()) {
        const modal = document.getElementById('userDetailsContainer')!;
        const componentOpen = document.getElementById('usersContainer')!;
        const modals: Node[] = [];
        modals.push(document.getElementById('viewBookings') as Node);
        modals.push(document.getElementById('updateDocuments') as Node);
        modals.push(document.getElementById('changeStatus') as Node);
        modals.push(document.getElementById('blockUser') as Node);
        document.getElementById('toast-success') && modals.push(document.getElementById('toast-success') as Node);
        document.getElementById('toast-warning') && modals.push(document.getElementById('toast-warning') as Node);
        document.getElementById('toast-danger') && modals.push(document.getElementById('toast-danger') as Node);
        const close = utilsService.closeCustomModalsWithModals(e, modal, modals, componentOpen);
        if (close) {
          this.userSelected = undefined;
        }
      }
    });
  }

  async ngOnInit() {
    this.getUsersListener();
    this.language = await this.utilsService.getLanguage();
    this.adminRole = await this.storageService.getRole();
    this.titleService.setTitle(this.literalService.get('sidebar.users', true) + ' - NEMI Backoffice');

    // Retrieve the object ID from the route parameters
    this.activatedRoute.params && this.activatedRoute.params.subscribe((params) => {
      const userId: number = params['id'];
      const userTypeLowercase = params['type'];
      const userType: UserType = userTypeLowercase ? userTypeLowercase[0].toUpperCase() + userTypeLowercase.slice(1) : UserType.Regular;
      userId && userType && this.showUserData({ type: userType, id: userId });
      if (history.state.fromFavouriteBooking) {
        this.showFavouriteRoutes(null, userId);
      }
    });

    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.viewBookingsShowed) this.viewBookings.toggle();
        if (this.changeStatusShowed) this.changeStatus.toggle();
        if (this.blockUserShowed) this.blockUser.toggle();
        if (this.updateDocumentsShowed) this.updateDocuments.toggle();
        if (this.deleteBookingShowed) this.deleteBooking.toggle();
      }
    });
  }

  ngAfterViewInit(): void {
    this.initializeModals();
  }

  userEdited = (userEdited: IUserRegular | IUserDriver) => {
    const index = this.users.value.findIndex((user: IUserRegular | IUserDriver) => user.id === userEdited.id);
    if (index !== -1) {
      this.users.value[index] = userEdited;
    } else {
      this.refreshData(); // create user also calls this
      this.newUser = false;
      this.userSelected = userEdited;
    }
  };

  getUsersListener = async () => {
    this.interval = setInterval(() => {
      this.refreshData();
    }, 300000);
  };

  async refreshData() {
    if (this.filtered) {
      await this.usersResolver.filter(this.activatedRoute.snapshot, this.clientsSelected, this.townsSelected, this.typesSelected, this.statusSelected, this.searchValue);
    } else {
      await this.usersResolver.getUsers(this.activatedRoute.snapshot, this.searchValue);
    }
  }

  goToUser(user: IUser): void {
    this.router.navigate(['/users/' + user.type + '/' + user.id]);
  }

  async showUserData(user: { type?: UserType, id?: number }) {
    try {
      this.userSelected =
        user.type === UserType.Regular
          ? ((await this.usersService.getUser(
            user.type!,
            user.id!,
          )) as IUserRegular)
          : ((await this.usersService.getUser(
            user.type!,
            user.id!,
          )) as IUserDriver);
      this.changeStatusSelected = this.userSelected.status;
      this.newUser = undefined;
    } catch {
      // try again in case it may be a driver
      if (user.type === UserType.Regular) {
        this.router.navigate(['/users/driver/' + user.id]);
      } else {
        // go to users again if user not found
        this.router.navigate(['/users']);
      }
    }
  }

  goToNewBooking(): void {
    this.appComponent.setRouteName('bookings');
    this.router.navigate(['/bookings/new']);
  }

  async changePage(event: any) {
    const { isNext, specificPage } = event;
    if (!specificPage) {
      this.users.pagination.actualPage = isNext
        ? this.users.pagination.actualPage + 1
        : this.users.pagination.actualPage - 1;
    } else {
      this.users.pagination.actualPage = specificPage;
    }
    await this.refreshData();
    this.abortFetchEventSource();
    this.getUsersListener();
  }

  public getTooltipName(name: string, i: number): string {
    return `${name}-${i}`;
  }

  public closeDetails() {
    this.userSelected = undefined;
    this.newUser = undefined;
  }

  public addUser = async() => {
    this.newUser = initialUserRegularState(this.language);
  };

  public addUserDriver = async() => {
    this.newUser = initialUserDriverState(this.language);
  };

  showFavouriteRoutes = async (event: Event | null, id: number) => {
    const favourites = await this.favouritesService.getFavourites(id);
    this.favourites = favourites.content;
    this.favouriteUserId = id;
    this.viewFavourites.toggle();
    event && event.stopPropagation();
  };

  public filterUsers = async (event: { selectedClients: number[], selectedTowns: number[], selectedTypes: UserType[], selectedStatus: UserStatus[] }) => {
    this.searching = true;
    this.users = initialUserState;
    this.users.value = [];
    this.filtered = true;
    this.clientsSelected = event.selectedClients;
    this.townsSelected = event.selectedTowns;
    this.typesSelected = event.selectedTypes;
    this.statusSelected = event.selectedStatus;
    this.users.pagination.actualPage = 0;
    await this.refreshData();
    this.abortFetchEventSource();
    await this.getUsersListener();
    this.searching = false;
  };

  public searchUsers = async (data: any) => {
    this.searching = true;
    this.users = initialUserState;
    this.users.value = [];
    if (typeof data === 'object') {
      this.searchValue = data.target.value;
    } else {
      this.searchValue = data;
    }
    this.users.pagination.actualPage = 0;
    await this.refreshData();
    this.abortFetchEventSource();
    await this.getUsersListener();
    this.searching = false;
  };

  public sortUsers = async () => {
    await this.refreshData();
    this.abortFetchEventSource();
    this.getUsersListener();
  };

  abortFetchEventSource = () => {
    clearInterval(this.interval);
  };

  /*goToSelfBookings(userId?: number) {
    this.storageService.setUserSelected(userId)
    this.router.navigate(['/bookings']);
  }*/

  initializeModals = () => {
    this.viewBookings = new Modal(document.getElementById('viewBookings'), {
      placement: 'center',
      closable: true,
      onHide: () => {
        clearInterval(this.getBookingsInterval);
        this.viewBookingsShowed = false;
      },
      onShow: () => (this.viewBookingsShowed = true),
    });
    this.changeStatus = new Modal(document.getElementById('changeStatus'), {
      placement: 'center',
      closable: true,
      onHide: () => (this.changeStatusShowed = false),
      onShow: () => (this.changeStatusShowed = true),
    });
    this.blockUser = new Modal(document.getElementById('blockUser'), {
      placement: 'center',
      closable: true,
      onHide: () => {
        this.blockUserShowed = false,
        this.reason = '';
      },
      onShow: () => {
        this.blockUserShowed = true,
        this.reason = '';
      },
    });
    this.updateDocuments = new Modal(document.getElementById('updateDocuments'), {
      placement: 'center',
      closable: true,
      onHide: () => {
        (this.updateDocumentsShowed = false),
          this.reason = '';
      },
      onShow: () => (this.updateDocumentsShowed = true),
    });
    this.viewFavourites = new Modal(document.getElementById('viewFavourites'), {
      placement: 'center',
      closable: true,
      onHide: () => (this.viewFavouritesShowed = false),
      onShow: () => (this.viewFavouritesShowed = true),
    });
    this.deleteBooking = new Modal(document.getElementById('deleteBooking'), {
      placement: 'center',
      closable: true,
      onHide: () => {
        (this.deleteBookingShowed = false);
        this.comment = '';
      },
      onShow: () => (this.deleteBookingShowed = true),
    });
  };

  validatePickUpAction = async (bookingId: any) => {
    const bookingIndex = this.bookings.findIndex(
      (booking: IBooking) => bookingId === booking.id,
    )!;
    const booking = await this.tripsService.validatePickUp(bookingId);
    this.bookings[bookingIndex] = booking;
    this.utilsService.showNotification(images.confirm, 'trips.actions.validate', NotificationTypes.SUCCESS);
  };

  validateDropOffAction = async (bookingId: any) => {
    const bookingIndex = this.bookings.findIndex(
      (booking: IBooking) => bookingId === booking.id,
    )!;
    const booking = await this.tripsService.validateDropOff(bookingId);
    this.bookings[bookingIndex] = booking;
    this.utilsService.showNotification(images.confirm, 'trips.actions.validate', NotificationTypes.SUCCESS);
  };

  showViewBookings = async (userId?: number) => {
    if (!userId) return;
    this.viewBookingsUserId = userId;
    this.bookings = await this.usersService.getBookingsByUser(userId!);
    this.getBookingsInterval = setInterval(async () => {
      this.bookings = await this.usersService.getBookingsByUser(userId!);
    }, 60000);
    this.viewBookings.toggle();
  };

  showDocumentsModal = async () => {
    if (this.userSelected.documents) {
      this.documents = this.userSelected.documents.reduce((groups: any, document: IDocument) => {
        const { documentType } = document;
        if (!groups[documentType]) {
          groups[documentType] = [];
        }
        groups[documentType].push(document);
        return groups;
      }, {} as { [key in IDocumentType]: IDocument[] });
      this.updateDocuments.toggle();
    }
  };

  onUploadDocuments = async (documentCreateRequests: IDocumentCreate[]) => {
    try {
      await this.usersService.createDocuments(this.userSelected.id, documentCreateRequests);
      this.utilsService.showNotification(images.document, 'users.documents.actions.upload.success', NotificationTypes.SUCCESS);
      this.userSelected = (await this.usersService.getUser(
        this.userSelected.type!,
        this.userSelected.id!,
      )) as IUserRegular;
      this.documents = this.userSelected.documents.reduce((groups: any, document: IDocument) => {
        const { documentType } = document;
        if (!groups[documentType]) {
          groups[documentType] = [];
        }
        groups[documentType].push(document);
        return groups;
      }, {} as { [key in IDocumentType]: IDocument[] });
    } catch (error) {
      this.utilsService.showNotification(images.document, 'users.documents.actions.upload.error', NotificationTypes.DANGER);
    }
  };

  deleteDocument = async (document: IDocument) => {
    try {
      await this.usersService.deleteDocument(this.userSelected.id, document.id);
      this.userSelected = (await this.usersService.getUser(
        this.userSelected.type!,
        this.userSelected.id!,
      )) as IUserRegular;
      this.documents = this.userSelected.documents.reduce((groups: any, document: IDocument) => {
        const { documentType } = document;
        if (!groups[documentType]) {
          groups[documentType] = [];
        }
        groups[documentType].push(document);
        return groups;
      }, {} as { [key in IDocumentType]: IDocument[] });
      this.utilsService.showNotification(images.document, 'users.documents.actions.delete.success', NotificationTypes.SUCCESS);
    } catch (error) {
      this.utilsService.showNotification(images.document, 'users.documents.actions.delete.error', NotificationTypes.DANGER);
      console.log("Error trying to delete document: ", error);
    }
  };
  
  downloadDocument = async (document: IDocument) => {
    try {
      await this.usersService.getDocumentDownload(this.userSelected.id, document.id);
      this.utilsService.showNotification(images.document, 'users.documents.actions.download.success', NotificationTypes.SUCCESS);
    } catch (error) {
      this.utilsService.showNotification(images.document, 'users.documents.actions.download.error', NotificationTypes.DANGER);
      console.log("Error trying to delete document: ", error);
    }
  };

  changeStatusAction = async () => {
    this.userSelected = await this.usersService.changeStatus(this.userSelected, this.changeStatusSelected);
    this.userEdited(this.userSelected);
    const isActive = this.userSelected.status.toUpperCase() === this.userStatus.ACTIVE;
    this.changeStatus.toggle();
    this.notificationService.image = this.userSelected.status.toUpperCase() === this.userStatus.DELETED ? images.notification.userDeleted : (isActive ? images.notification.userActive : images.notification.userInactive);
    this.notificationService.title = this.literalService.get(
      `users.actions.${ this.userSelected.status }.successTitle`,
      true,
    );
    this.notificationService.message = this.literalService.get(
      `users.actions.${ this.userSelected.status.toUpperCase() === this.userStatus.DELETED ? 'Deleted' : 'edit' }.successText`,
      true,
    );
    this.notificationService.show(NotificationTypes.SUCCESS);
  };

  blockUserAction = async (data: any) => {
    console.log('blockUserAction', data);
    this.userSelected = await this.usersService.changeStatus(this.userSelected, this.utilsService.capitalize(UserStatus.BLOCKED.toLowerCase()), data.date, data.reason);
    this.userEdited(this.userSelected);
    this.blockUser.toggle();
    this.notificationService.image = images.notification.userBlocked;
    this.notificationService.title = this.literalService.get(
      `users.actions.Blocked.successTitle`,
      true,
    );
    this.notificationService.message = this.literalService.get(
      `users.actions.edit.successText`,
      true,
    );
    this.notificationService.show(NotificationTypes.SUCCESS);
  };

  showChangeStatus = async (status: UserStatus) => {
    this.changeStatusSelected = status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
    if (status === UserStatus.BLOCKED) this.blockUser.toggle();
    else this.changeStatus.toggle();
  };

  bookWithFavourite = async (favourite: IFavourites) => {
    this.viewFavourites.toggle();
    this.appComponent.setRouteName('bookings');
    this.router.navigate(
      ['/bookings/new'],
      {
        queryParams:
        {
          'favouriteId': favourite.id,
          'userId': this.favouriteUserId
        }
      }
    );
  };

  deleteFavourite = async (favouriteId: number) => {
    this.favouritesService.deleteFavourite(favouriteId).then(async () => {
      const favourites = await this.favouritesService.getFavourites(this.favouriteUserId);
      this.favourites = favourites.content;
      this.utilsService.showNotification(images.sidebar.bookings, 'bookings.actions.viewFavourite.delete', NotificationTypes.SUCCESS, 'successTitle', 'successText');
    }, () => {
      this.utilsService.showNotification(images.sidebar.bookings, 'bookings.actions.viewFavourite.delete', NotificationTypes.DANGER, 'errorTitle', 'errorText');

    });
  };

  prepareToDeleteBooking = (bookingId: any) => {
    this.bookingToDelete = bookingId;
    this.deleteBooking.toggle();
  };

  deleteBookingAction = async() => {
    this.deletingBooking = true;
    await this.bookingsService.deleteBooking(this.bookingToDelete, this.comment).then(() => {
      this.utilsService.showNotification(images.sidebar.bookings, 'bookings.actions.deleteBooking', NotificationTypes.SUCCESS);
    }, () => {
    });
    this.deleteBooking.toggle();
    this.viewBookings.toggle();
    this.deletingBooking = false;
  };

  ngOnDestroy(): void {
    clearInterval(this.interval);
    this.routerSubscription.unsubscribe();
  }
}

import { Injectable } from '@angular/core';
import { BaseHttpService } from '../http/base.http.service';

@Injectable({
  providedIn: 'root',
})
export class OperatorsService extends BaseHttpService {
  // constructor() {
  //   super()
  // }
  // public getOperator = async (id: number): Promise<IOperator> => {
  //   return this.get(`operators/${id}`)
  // }
  // public getOperators = async (): Promise<IOperator[]> => {
  //   return this.get(`operators`)
  // }
}

import { Injectable, isDevMode } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LocalDriverService } from '../storage/drivers/local-storage.service';
import { APP } from 'src/app/config/app';
import { AuthHttpService } from './auth.http.service';
import { lastValueFrom } from 'rxjs';
import { JWTTokenService } from './jwtToken.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private jwtHelper: JwtHelperService,
    private storage: LocalDriverService,
    private authHttpService: AuthHttpService,
    private jwtTokenService: JWTTokenService,
  ) {}

  public isAuthenticated(): boolean {
    let token = '';
    if (isDevMode()) {
      token =
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJuZW1pLWF1dGhlbnRpY2F0aW9uLWdhdGV3YXkiLCJleHAiOjE4MTAyNjMyODMsInN1YiI6IjEiLCJhdWQiOiJiYWNrb2ZmaWNlLWRhc2hib2FyZC1hcGkiLCJ1c2VyX2ZpcnN0X25hbWUiOiJQaWxkbyIsInJvbGUiOiJTVVBFUkFETUlOIiwidG93bl9uYW1lIjoiQ29pbWJyYSIsImlkIjoiMSIsInVzZXJuYW1lIjoicGlsZG9AcGlsZG8uY29tIn0=._7Sl8Tc9L4p87U04amIviSrRNVFHBPqxqvuxa-yFu3A';
    } else {
      token = localStorage.getItem('token')!;
    }
    return !this.jwtHelper.isTokenExpired(token);
  }

  public setToken(token: any) {
    if (isDevMode()) {
      this.storage.set(
        APP.LOCAL_STORAGE_TOKEN_KEY_NAME,
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJuZW1pLWF1dGhlbnRpY2F0aW9uLWdhdGV3YXkiLCJleHAiOjE4MTAyNjMyODMsInN1YiI6IjEiLCJhdWQiOiJiYWNrb2ZmaWNlLWRhc2hib2FyZC1hcGkiLCJ1c2VyX2ZpcnN0X25hbWUiOiJQaWxkbyIsInJvbGUiOiJTVVBFUkFETUlOIiwidG93bl9uYW1lIjoiQ29pbWJyYSIsImlkIjoiMSIsInVzZXJuYW1lIjoicGlsZG9AcGlsZG8uY29tIn0=._7Sl8Tc9L4p87U04amIviSrRNVFHBPqxqvuxa-yFu3A',
      );
      this.jwtTokenService.setToken(
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJuZW1pLWF1dGhlbnRpY2F0aW9uLWdhdGV3YXkiLCJleHAiOjE4MTAyNjMyODMsInN1YiI6IjEiLCJhdWQiOiJiYWNrb2ZmaWNlLWRhc2hib2FyZC1hcGkiLCJ1c2VyX2ZpcnN0X25hbWUiOiJQaWxkbyIsInJvbGUiOiJTVVBFUkFETUlOIiwidG93bl9uYW1lIjoiQ29pbWJyYSIsImlkIjoiMSIsInVzZXJuYW1lIjoicGlsZG9AcGlsZG8uY29tIn0=._7Sl8Tc9L4p87U04amIviSrRNVFHBPqxqvuxa-yFu3A',
      );
    } else {
      this.storage.set(APP.LOCAL_STORAGE_TOKEN_KEY_NAME, token);
      this.jwtTokenService.setToken(token);
    }
    return this;
  }

  public setRole() {
    if (isDevMode()) {
      this.storage.set(
        APP.LOCAL_STORAGE_TOKEN_KEY_NAME,
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJuZW1pLWF1dGhlbnRpY2F0aW9uLWdhdGV3YXkiLCJleHAiOjE4MTAyNjMyODMsInN1YiI6IjEiLCJhdWQiOiJiYWNrb2ZmaWNlLWRhc2hib2FyZC1hcGkiLCJ1c2VyX2ZpcnN0X25hbWUiOiJQaWxkbyIsInJvbGUiOiJTVVBFUkFETUlOIiwidG93bl9uYW1lIjoiQ29pbWJyYSIsImlkIjoiMSIsInVzZXJuYW1lIjoicGlsZG9AcGlsZG8uY29tIn0=._7Sl8Tc9L4p87U04amIviSrRNVFHBPqxqvuxa-yFu3A',
      );
      this.jwtTokenService.setToken(
        'SUPERADMIN',
      );
    } else {
      this.storage.set(APP.LOCAL_STORAGE_ROLE, this.jwtTokenService.getRole());
    }
    return this;
  }

  public getToken() {
    return this.storage.get(APP.LOCAL_STORAGE_TOKEN_KEY_NAME);
  }

  public setTokenType(type: any) {
    this.storage.set(APP.LOCAL_STORAGE_TOKEN_TYPE_KEY_NAME, type);
    return this;
  }

  public clearStorage() {
    Object.keys(localStorage).forEach((key) => {
      this.storage.remove(key);
    });
  }

  async login(email: string, password: string) {
    const source$ = this.authHttpService.login(email, password);
    const response = await lastValueFrom(source$);
    return response;
  }
}

import { Component, OnInit } from '@angular/core';
import { INotificationState } from 'src/app/store/states';
import { images } from '../../../images';
import { initialStates } from 'src/app/store/initialisation';
import { LiteralService, NotificationService } from 'src/app/services';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
  public notification: INotificationState = initialStates.notification;
  public images = images;

  constructor(public notificationService: NotificationService, public literalService: LiteralService) {
    // this.notification = configureStore.getState().notification
  }

  ngOnInit(): void {
    console.log(this.notification);
  }

  public dismiss = () => {
    this.notification.show = false;
    // configureStore.dispatch(clearNotificationAction())
  };
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-marker-stop-disabled',
  templateUrl: './marker-stop-disabled.component.html',
  styleUrl: './marker-stop-disabled.component.scss',
})
export class MarkerStopDisabledComponent {
  @Input() color: string;

  get backgroundColor(): any {
    return {
      'background-color': `${this.color}`
    };
  }
}

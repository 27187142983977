import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserRole } from 'src/app/models/enums';
import { StorageService, RoleService } from 'src/app/services';
import { LiteralService } from 'src/app/services/literal/literal.service';
import { images } from 'src/images';

@Component({
    selector: 'app-search-bar',
    templateUrl: './search-bar.component.html',
    styleUrls: ['./search-bar.component.scss'],
    standalone: false
})
export class SearchBarComponent implements OnInit {
  @Input() placeholder = '';
  @Input() searching = false;
  @Output() addUserEventEmitter = new EventEmitter();
  @Output() addUserDriverEventEmitter = new EventEmitter();
  @Output() filter = new EventEmitter();
  @Output() searchEventEmitter = new EventEmitter<string>();
  @Output() sort = new EventEmitter();

  public filterOpened = false;
  public sortedOpened = false;

  public images = images;

  public adminRole: UserRole;

  constructor(
    public literalService: LiteralService,
    public storageService: StorageService,
    public roleService: RoleService
  ) {}

  async ngOnInit() {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    const sort = document.getElementById('sort')?.getBoundingClientRect();
    if (sort) {
      document.getElementById('sortModal')!.style.top =
        (sort.top - 10).toString() + 'px';
      document.getElementById('sortModal')!.style.left =
        (sort.left - 20).toString() + 'px';
    }

    this.adminRole = await this.storageService.getRole();
  }

  search = (event: any) => {
    this.searchEventEmitter.emit(event);
  };
}

import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Modal } from 'flowbite';
import { NotificationService, UsersService, UtilsService } from 'src/app/services';
import { LiteralService } from 'src/app/services/literal/literal.service';
import { images } from 'src/images';
import { IDocumentType } from 'src/app/models/enums/documentType';
import { NotificationTypes } from 'src/app/models/enums';
import { IDocumentCreate } from 'src/app/models/documents/documentCreate';
import { AnimationOptions } from 'ngx-lottie';
import { AnimationItem } from 'lottie-web';

@Component({
    selector: 'app-modal-update-documents',
    templateUrl: './modal-update-documents.component.html',
    styleUrl: './modal-update-documents.component.scss',
    standalone: false
})
export class ModalUpdateDocumentsComponent implements OnChanges {
  @Input() id: string;
  @Input() modal: Modal;
  @Input() text: string;
  @Input() user: any;
  @Input() uploading: boolean;
  @Input() documents?: any;
  @Output() uploadDocuments = new EventEmitter<any>();
  @Output() downloadDocument = new EventEmitter<any>();
  @Output() deleteAction = new EventEmitter<any>();

  @ViewChild('fileInput') fileInput!: ElementRef;

  selectedDocumentType: IDocumentType = IDocumentType.ID_CARD;

  documentCreateRequests: IDocumentCreate[] = [];

  isUploading: boolean = false;

  public loadingAnimation: AnimationOptions = {
    path: '/assets/animations/loading-login-white.json'
  };

  public images = images;

  constructor(
    public literalService: LiteralService,
    public userService: UsersService,
    public notificationService: NotificationService,
    public utilsService: UtilsService
  ) { }

  getDocumentTypes(): string[] {
    return Object.keys(this.documents);
  }

  getAllDocumentTypes () {
    return Object.values(IDocumentType).map((value, index) => {
      return { id: index + 1, name: this.literalService.get('users.documents.' + value, true) };
    });
  }

  onClose () {
    this.modal.toggle();
  }

  changeType(element: any) {
    this.selectedDocumentType = Object.values(IDocumentType)[element.value - 1];
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['documents'] && changes['documents'].previousValue) {
      if ((changes['documents'].previousValue[this.selectedDocumentType] !== undefined && changes['documents'].previousValue[this.selectedDocumentType].length < changes['documents'].currentValue[this.selectedDocumentType].length) ||
          (changes['documents'].previousValue[this.selectedDocumentType] === undefined && changes['documents'].currentValue[this.selectedDocumentType] !== undefined)) 
        {
          this.documentCreateRequests = [];
          this.isUploading = false;
        }
    }
  }
  onSelectFile(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      this.convertFilesToBase64(Array.from(input.files));
    }
    if (this.fileInput) {
      this.fileInput.nativeElement.value = '';
    }
  }

  onUpload(): void {
    this.uploadDocuments.emit(this.documentCreateRequests);
    this.isUploading = true;
  }

  deleteDocumentCreateRequest(documentCreate: IDocumentCreate) {
    this.documentCreateRequests = this.documentCreateRequests.filter(
      (request: IDocumentCreate) => request.fileName !== documentCreate.fileName
    );
  }

  convertFilesToBase64(files: File[]): void {
    files.forEach(file => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = (reader.result as string);
        const newDocument: IDocumentCreate = {
          base64: base64String,
          type: this.selectedDocumentType,
          fileName: file.name,
        };
        if (this.documentCreateRequests.some((request: IDocumentCreate) => request.fileName === file.name)) {
          this.notificationService.image = images.document;
          this.notificationService.title = this.literalService.get('users.documents.actions.load.error.title', true);
          this.notificationService.message = this.literalService.get('users.documents.actions.load.error.text', true);
          this.notificationService.show(NotificationTypes.DANGER);
        } else {
          this.documentCreateRequests.push(newDocument);
        }
      };
      reader.readAsDataURL(file);
    });
  }
}

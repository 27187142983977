import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Modal } from 'flowbite';
import { IFavourites } from 'src/app/models';
import { LiteralService } from 'src/app/services';
import { images } from 'src/images';

@Component({
    selector: 'app-modal-favourite-routes',
    templateUrl: './modal-favourite-routes.component.html',
    styleUrl: './modal-favourite-routes.component.scss',
    standalone: false
})
export class ModalFavouriteRoutesComponent {

  @Input() id: string;
  @Input() modal: Modal;
  @Input() text: string;
  @Input() favourites: IFavourites[];
  @Input() isFromUser: boolean;
  @Output() book = new EventEmitter<IFavourites>();
  @Output() delete = new EventEmitter();
  @Output() goToNewBookingEmitter = new EventEmitter();

  public selectedFavourite: IFavourites | undefined;

  public images = images;

  constructor(public literalService: LiteralService) {}

  selectFavouriteRoute = (favourite: IFavourites) => {
    this.selectedFavourite = favourite;
  };
  
  onDelete = () => {
    this.delete.emit(this.selectedFavourite!.id);
  };

  newBooking = () => {
    this.book.emit(this.selectedFavourite);
  };

  closeModal(): void {
    this.selectedFavourite = undefined;
    this.modal.hide();
  }

}

export enum ErrorsCode {
  ENTITY_DOES_NOT_EXIST = 1001,
  ENTITY_ALREADY_EXISTS = 1002,
  PERMISSION_DENIED = 1003,
  INVALID_REQUEST = 1004,
  NO_AVAILABILITIES_FOUND = 1005,
  TICKET_CREATION = 1006,
  PAYMENT = 1007,
  MAX_CAPACITY = 1008,
  UNFEASIBLE_BOOKING = 1009,
  INVALID_TRIP_STATUS = 1010,
  DATABASE_CONFLICT = 1011,
}

<div [id]="id" tabindex="-1" class="absolute top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full"
    (click)="closeModal()">
    <div class="relative w-2/3 max-h-[90%] rounded-lg primary-purple-light-2-background p-[30px]" (click)="$event.stopPropagation()">
        <div class="flex items-center">
            <div>
                <p class="font-bold text-lg neutrals-grey-06-text primary-purple-text">{{
                    literalService.get('favourites.title', true) }}:</p>
                @if (favourites.length) {
                    <p class="font-normal text-base neutrals-grey-06-text pb-2 primary-purple-text">{{
                        literalService.get('favourites.select', true) }}</p>
                }
            </div>
            <button (click)="closeModal()" type="button"
                class="absolute right-8 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-hide="viewFavourites">
                <img [src]="images.close" class="h-6 w-6" />
            </button>
        </div>
        <div class="max-h-[400px]" [ngClass]="{'overflow-y-auto': favourites.length > 0, 'overflow-y-hidden': favourites.length === 0}">
            <form #form novalidate>
                @for (favourite of favourites; track $index) {
                    <section
                        class="grid grid-cols-11 cursor-pointer w-full pt-2 pb-2 border-2 neutrals-grey-02-border mb-2 items-center justify-start bg-white rounded"
                        (click)="selectFavouriteRoute(favourite)"
                        [ngClass]="{'primary-purple-light-border': this.selectedFavourite === favourite}">
                        <div class="flex h-full items-center justify-center text-center col-span-2 border-r">
                            <label class="text-lg font-semibold cursor-pointer">{{ favourite.name }}</label>
                        </div>
                        <div class="flex h-full items-center justify-center text-center col-span-3 border-r">
                            <label class="text-lg font-semibold cursor-pointer">{{ favourite.town?.name }}</label>
                        </div>
                        <div class="flex h-full items-center justify-center col-span-1 border-r">
                            <label class="text-lg font-semibold cursor-pointer">{{ favourite.time?.split(':')![0] + ':' +
                                favourite.time?.split(':')![1] }}</label>
                        </div>
                        <div class="h-full col-span-4 border-r ml-8 pr-8">
                            <div class="flex items-center">
                                <img [src]="images.origin" alt="origin" class="w-4 h-4 mr-1" />
                                <label class="text-lg font-semibold cursor-pointer truncate">{{ favourite.inStop?.name }}</label>
                            </div>
                            <div class="flex items-center">
                                <img [src]="images.destination" alt="destination" class="w-4 h-4 mr-1" />
                                <label class="text-lg font-semibold cursor-pointer truncate">{{ favourite.outStop?.name }}</label>
                            </div>
                        </div>
                        <div class="h-full items-center col-span-1 ml-4">
                            <div class="flex items-center">
                                <img [src]="images.pax" alt="destination" class="w-4 h-4 mr-1" />
                                <label class="text-lg font-semibold cursor-pointer">{{ favourite.passengers }}</label>
                            </div>
                            <div class="flex items-center">
                                <img [src]="images.prm" alt="destination" class="w-4 h-4 mr-1" />
                                <label class="text-lg font-semibold cursor-pointer">{{ favourite.prmPassengers }}</label>
                            </div>
                        </div>
                    </section>
                } @empty {
                    <div class="flex items-center justify-center">
                        <img [src]="images.favourites.noOptions" class="h-96 w-96" alt="favouriteNoOptions" />
                    </div>
                    <div class="flex items-center justify-center -mt-5 mb-5">
                        <label class="font-bold text-lg text-center uppercase neutrals-grey-04-text">{{
                            literalService.get('favourites.noFavourites') }}</label>
                    </div>
                }
            </form>
        </div>
        <div>
            @if (favourites.length > 0) {
                <div class="flex mt-6">
                    <div class="flex justify-end w-full">
                        <input (click)="onDelete()" type="button"
                            class="secondary gap-4 p-2 border-2 font-semibold h-12 w-40 uppercase cursor-pointer"
                            [value]="literalService.get('bookings.actions.viewFavourite.delete.title', true)"
                            [disabled]="favourites.length > 1 && !selectedFavourite" />
                        <input (click)="newBooking()" type="button"
                            class="primary text-white gap-4 p-2 border-2 font-semibold h-12 w-40 uppercase cursor-pointer ml-2"
                            [value]="isFromUser ? literalService.get('bookings.actions.viewFavourite.newBooking', true) : literalService.get('bookings.actions.viewFavourite.select', true)"
                            [disabled]="favourites.length > 1 && !selectedFavourite" />
                    </div>
                </div>
            }
        </div>
    </div>
</div>
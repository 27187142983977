import { Injectable } from '@angular/core';
import { AppsHttpService } from './apps.http.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppsService {

  constructor(
    private appsHttpService: AppsHttpService
  ) {}

  async getApps() {
      const source$ = this.appsHttpService.getApps();
      const response = await lastValueFrom(source$);
      console.log(response)
      return response;
    }
}

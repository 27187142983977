import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { IBase } from 'src/app/models';
import { UsersService } from 'src/app/services';
import { LiteralService } from 'src/app/services/literal/literal.service';
import { images } from 'src/images';
import { StorageService } from 'src/app/services';

@Component({
  selector: 'app-trip-change-area',
  templateUrl: './trip-change-area.component.html',
  styleUrl: './trip-change-area.component.scss',
})
export class TripChangeAreaComponent implements OnInit {
  @Output() changeEventEmitter = new EventEmitter();
  public images = images;
  public towns: unknown[] = [];
  public selectedTowns: unknown[] = [];
  public showTowns = false;

  constructor(private usersService: UsersService,
              public literalService: LiteralService,
              private storageService: StorageService) {
  }

  async ngOnInit() {
    await this.usersService.getPossibleClientTowns().then((res) => {
      res.towns.sort((firstItem: IBase, secondItem: IBase) => firstItem.id! - secondItem.id!);
      this.towns = res.towns;
    });
    this.storageService.getArea().then((areas: string) => {
      if (areas) this.selectedTowns = JSON.parse(areas);
    }, () => {
    });
  }

  handleTowns = () => {
    this.showTowns = !this.showTowns;
  };

  openTowns = () => {
    this.showTowns = true;
  };

  closeTowns = () => {
    this.showTowns = false;
  };
}

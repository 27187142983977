import { Component, Input } from '@angular/core';
import { Modal } from 'flowbite';
import { IServiceInfo } from 'src/app/models';
import { LiteralService } from 'src/app/services';
import { images } from 'src/images';

@Component({
    selector: 'app-modal-alteration',
    templateUrl: './modal-alteration.component.html',
    styleUrl: './modal-alteration.component.scss',
    standalone: false
})
export class ModalAlterationComponent {

  @Input() id: string;
  @Input() modal: Modal;
  @Input() title: string;
  @Input() services?: IServiceInfo[];
  @Input() text: string;

  public images = images;

  constructor(public literalService: LiteralService) {}

  getAlterationsText = (serviceInfo: IServiceInfo) => {
    let text = '';
    const serviceInfoSelected = this.services?.find((si: IServiceInfo) => si.id === serviceInfo.id);
    serviceInfoSelected?.alterationMessages.forEach((message: string) => {
        text += message;
    });
    return text;
  };

}

import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Colors, InputType } from 'src/app/models/enums';
import { LiteralService } from 'src/app/services';

@Component({
    selector: 'app-color',
    templateUrl: './color.component.html',
    styleUrl: './color.component.scss',
    standalone: false
})
export class ColorComponent implements OnChanges {

  @Input() type: InputType;
  @Input() value: any;
  @Input() values: any[];
  @Input() formControlName?: string;
  @Input() formGroup?: FormGroup;
  @Output() changeEventEmitter = new EventEmitter<unknown>();

  public Colors = Colors;

  constructor(public literalService: LiteralService) {}

  ngOnChanges(): void {
    console.log("COOOLOR", this.value)
    console.log("COOOLORS", this.values)
    console.log("COOOLORSS", !this.value, this.value === '#FFFFFFFF' || this.value === 'Transparent')
    if (this.formControlName && this.formGroup) {
      this.formGroup!.setControl(this.formControlName!, new FormControl(this.value, [Validators.required]));
      this.formGroup!.get(this.formControlName!)?.setValue(this.value); 
      console.log(this.formGroup)
    }
  }

  changeColor(event: any) {
    this.value = event.target.value;
    (this.formControlName && this.formGroup) && this.formGroup!.get(this.formControlName!)?.setValue(this.value);
    this.changeEventEmitter.emit(this.value);
  }

  getValue() {
    if (!this.value) {
      return Colors.NONE;
    } else if (this.value === '#FFFFFFFF' || this.value === 'Transparent') {
      return '#FFFFFFFF';
    } else {
      return this.value;
    }
  }

}

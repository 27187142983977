import { Component, Input } from '@angular/core';
import { IBooking } from 'src/app/models';
import { UtilsService } from 'src/app/services';
import { TooltipPosition } from 'src/app/shared/tooltip/tooltip-position';
import { images } from 'src/images';

@Component({
  selector: 'app-booking-stops',
  templateUrl: './booking-stops.component.html',
  styleUrl: './booking-stops.component.scss',
})
export class BookingStopsComponent {
  @Input() booking: IBooking;
  @Input() origin?: string;
  @Input() destination?: string;

  public images = images;
  public TooltipPosition = TooltipPosition;

  constructor(public utilsService: UtilsService) {}
}

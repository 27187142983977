import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { IBase, ITripDetail, ITripPosition, ITripStop } from 'src/app/models';
import { IBookingDetails } from 'src/app/models/bookings/bookingDetails';
import { ExpeditionStatus, ExpeditionType } from 'src/app/models/enums';
import { objectToCamel } from 'ts-case-convert';
import { BookingsHttpService } from '../bookings/bookings.http.service';
import { TripsHttpService } from './trips.http.service';

@Injectable({
  providedIn: 'root',
})
export class TripsService {
  constructor(
    private tripsHttpService: TripsHttpService,
    private bookingsHttpService: BookingsHttpService
  ) {}

  async getTrips(
    size: number,
    page: number,
    towns: IBase[]
  ) {
    const source$ = this.tripsHttpService.getTrips(
      size,
      page,
      towns
    );
    const response = await lastValueFrom(source$);
    const tripsArray = this.getData(response);
    return tripsArray;
  }

  async getTripsFiltered(
    size: number,
    page: number,
    expeditionClients: IBase[],
    expeditionOperators: IBase[],
    expeditionTowns: IBase[],
    expeditionTypes: ExpeditionType[],
    expeditionStatus: ExpeditionStatus[],
    searchValue?: string
  ) {
    const source$ = this.tripsHttpService.getTripsFiltered(
      size,
      page,
      expeditionClients,
      expeditionOperators,
      expeditionTowns,
      expeditionTypes,
      expeditionStatus,
      searchValue
    );
    const response = await lastValueFrom(source$);
    const tripsArray = this.getData(response);
    return tripsArray;
  }

  async getTrip(tripId: number) {
    const source$ = this.tripsHttpService.getTrip(tripId);
    const response = await lastValueFrom(source$);
    const trip = response as ITripDetail;
    trip.stops = objectToCamel(trip.stops) as Array<ITripStop>;
    return objectToCamel(trip);
  }

  async getBookingsByTrip(tripId: number) {
    const source$ = this.tripsHttpService.getBookingsByTrip(tripId);
    const response = await lastValueFrom(source$);
    const bookings = response as IBookingDetails[];
    return bookings;
  }

  async getBookingsByStop(stopId: number, pickUps: boolean) {
    const source$ = this.tripsHttpService.getBookingsByStop(stopId, pickUps);
    const response = await lastValueFrom(source$);
    const bookings = response as IBookingDetails[];
    return bookings;
  }

  async getChangeoversByStop(stopId: number) {
    const source$ = this.tripsHttpService.getChangeoversByStop(stopId);
    const response = await lastValueFrom(source$);
    const bookings = response as IBookingDetails[];
    return bookings;
  }

  async getTripPosition(tripId: number) {
    const source$ = this.tripsHttpService.getTripPosition(tripId);
    const response = await lastValueFrom(source$);
    const position = response as ITripPosition;
    return objectToCamel(position);
  }

  async getTownsPositions(townsIds: number[]) {
    const source$ = this.tripsHttpService.getTownsPositions(townsIds);
    const response = await lastValueFrom(source$);
    const positions = response as ITripPosition[];
    return objectToCamel(positions);
  }

  async changeDriver(tripId: number, driverId: number) {
    const source$ = this.tripsHttpService.changeDriver(tripId, driverId);
    const response = await lastValueFrom(source$);
    const trip = response as ITripDetail;
    return objectToCamel(trip);
  }

  async changeVehicle(tripId: number, vehicleId: number) {
    const source$ = this.tripsHttpService.changeVehicle(tripId, vehicleId);
    const response = await lastValueFrom(source$);
    const trip = response as ITripDetail;
    return objectToCamel(trip);
  }

  async addPax(tripId: number, nPax: number) {
    const source$ = this.tripsHttpService.addPax(tripId, nPax);
    const response = await lastValueFrom(source$);
    const tripDetails = response as ITripDetail;
    return objectToCamel(tripDetails);
  }

  async setStatus(tripId: number, status: ExpeditionStatus) {
    const source$ = this.tripsHttpService.setStatus(tripId, status);
    const response = await lastValueFrom(source$);
    const trip = response as ITripDetail;
    return objectToCamel(trip);
  }

  async deleteTrip(tripId: number, reason?: string) {
    const source$ = this.tripsHttpService.deleteTrip(tripId, reason);
    const response = await lastValueFrom(source$);
    return response;
  }

  async validatePickUp(bookingId: number) {
    const source$ = this.bookingsHttpService.validatePickUp(bookingId);
    const response = await lastValueFrom(source$);
    const bookingDetails = response as IBookingDetails;
    return objectToCamel(bookingDetails);
  }

  async validateDropOff(bookingId: number) {
    const source$ = this.bookingsHttpService.validateDropOff(bookingId);
    const response = await lastValueFrom(source$);
    const bookingDetails = response as IBookingDetails;
    return objectToCamel(bookingDetails);
  }

  getData(response: any) {
    return response.data;
  }
}

<div [id]="id" tabindex="-1" class="absolute top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
    <div class="relative w-96 max-w-md max-h-full rounded-lg primary-purple-light-2-background">
        <div class="relative rounded-lg shadow dark:bg-gray-700">
            <!-- <button (click)="modal.hide()" type="button" class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="addToAppModal">
                <img [src]="images.close" class="h-6 w-6" />
            </button> -->
            <div class="p-6 pt-5">
                <form #form novalidate class="text-center" (ngSubmit)="onSubmit()">
                    <img [src]="img" class="h-20 w-full mt-4 mb-2 justify-center items-center" />
                    <h3 class="text-[22px] text-center font-bold mb-5 text-gray-500 dark:text-gray-400 mt-5">{{ title }}</h3>
                    <label class="text-lg text-center font-normal mb-5 text-gray-500 dark:text-gray-400 mt-5">{{ text }}</label>
                    <input type="button" (click)="modal.hide()" data-modal-hide="maxReservationTime" class="secondary bg-white gap-4 p-2 border-2 font-semibold h-12 w-full uppercase cursor-pointer mt-6" [value]="literalService.get('close', true)" />
                </form>
            </div>
        </div>
    </div>
</div>
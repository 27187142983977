import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { httpHeaders } from './RequestHeaders.module';

@Injectable()
export class BaseHttpService {
  public httpHeaders = new HttpHeaders();
  private requests: Observable<unknown> = new Observable<unknown>();

  constructor(@Inject(HttpClient) private httpClient: HttpClient) {
    this.setHeaders();
  }

  public create(body: unknown, url: string): Observable<unknown> {
    this.requests = this.httpClient.post<unknown>(url, body, {
      headers: this.httpHeaders,
    });
    this.intercept(this.requests);
    return this.requests;
  }

  public update(body: any, url: string): Observable<unknown> {
    this.requests = this.httpClient.put<unknown>(url, body, {
      headers: this.httpHeaders,
    });
    this.intercept(this.requests);
    return this.requests;
  }

  public updatePatch(body: any, url: string): Observable<unknown> {
    this.requests = this.httpClient.patch<unknown>(url, body, {
      headers: this.httpHeaders,
    });
    this.intercept(this.requests);
    return this.requests;
  }

  public read(url: string): Observable<unknown> {
    this.requests = this.httpClient.get(url, { headers: this.httpHeaders });
    this.intercept(this.requests);
    return this.requests;
  }

  public list(url: string, params: any): Observable<unknown> {
    this.requests = this.httpClient.get(url, {
      params: params,
      headers: this.httpHeaders,
    });
    this.intercept(this.requests);
    return this.requests;
  }

  public delete(url: string, body: any = {}): Observable<unknown> {
    this.requests = this.httpClient.delete(url, { headers: this.httpHeaders, body: body });
    this.intercept(this.requests);
    return this.requests;
  }

  setHeaders() {
    this.httpHeaders = httpHeaders;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private intercept(observable: Observable<unknown>) {
    this.setHeaders();
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LiteralService } from 'src/app/services';
import { images } from 'src/images';

@Component({
    selector: 'app-service-search-bar',
    templateUrl: './service-search-bar.component.html',
    styleUrl: './service-search-bar.component.scss',
    standalone: false
})
export class ServiceSearchBarComponent {

  @Input() placeholder = '';
  @Input() searching = false;
  @Output() filter = new EventEmitter();
  @Output() searchEventEmitter = new EventEmitter<string>();
  @Output() sort = new EventEmitter();

  public filterOpened = false;
  public sortedOpened = false;

  public images = images;

  constructor(public literalService: LiteralService) {}

  search(event: any) {
    this.searchEventEmitter.emit(event);
  }

}

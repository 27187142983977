import { Component, OnChanges, input, output } from '@angular/core';
import { getRouteForm } from 'src/app/forms/routeForm';
import { IClient, IRouteDetails, ITown } from 'src/app/models';
import { AppsService, LiteralService, RouteService, StorageService, UsersService, UtilsService } from 'src/app/services';
import { images } from 'src/images';

@Component({
    selector: 'app-service-routes-details',
    templateUrl: './service-routes-details.component.html',
    styleUrl: './service-routes-details.component.scss',
    standalone: false
})
export class ServiceRoutesDetailsComponent implements OnChanges {

  route: any = input<IRouteDetails>();
  newRouteObject: any = input<IRouteDetails>();
  readonly newRoute: any = input<boolean>();
  edit: any = input<boolean>();
  town: any = input<ITown>();
  managerClientName: any = input<string>();
  readonly editEvent = output();
  readonly editEventEmitter = output<IRouteDetails>();
  readonly createEventEmitter = output<IRouteDetails>();
  readonly closeDetails = output();

  private modifiedRoute: any = {} as IRouteDetails; // TODO: use it for detect changes

  public images = images;
  // public edit = false;
  public routeForm: any[];
  public expanded = false;

  constructor(public literalService: LiteralService,
              private usersService: UsersService,
              public utilsService: UtilsService,
              private routeService: RouteService,
              private storageService: StorageService,
              private appsService: AppsService) {}

  async ngOnChanges() {
    // await getRouteForm(this.route()!, this.townsService, this.usersService, this.literalService, this.storageService, this.edit).then((res: any) => {
    //   this.routeForm = res;
    // });
    console.log(this.edit(), this.newRoute())
    if (this.newRoute()) this.expanded = true;
    if (this.route() || this.newRoute()) {
      this.updateRouteForm();
    }
  }

  public async updateRouteForm() {
    console.log(this.newRoute())
    console.log(this.newRoute() ? this.newRouteObject() : this.route())
    console.log(this.newRouteObject(), this.route())
    await getRouteForm(this.newRoute() ? this.newRouteObject() : this.route(), this.newRoute(), this.usersService, this.literalService, this.storageService, this.appsService, this.utilsService, this.routeService,  this.town(),  this.managerClientName(), this.edit()).then((res: any) => {
      this.routeForm = res;
    });
    console.log(this.routeForm)
    this.modifiedRoute = {...this.newRoute() ? this.newRouteObject() : this.route()};
  }

  close() {
    this.routeForm = [];
    this.closeDetails.emit();
    console.log(this.modifiedRoute)
  }

  async downloadGTFS() {
    const response = await this.routeService.downloadGtfs(this.modifiedRoute.id);
    this.utilsService.downloadDocument(response as any, 'gtfs', 'zip');
  }

  changeData(data: any, element?: any) {
    console.log(data, element)
    if (element.edit?.valueToChange === 'publishingSites') {
      return;
    } else if (Array.isArray(element.edit?.valueToChange) || element.array) {
      this.setMultiSelectData(element);
    } else {
      const value = typeof data === 'object' ? data.target || data.options[0] : data;
      this.modifiedRoute[element.edit?.valueToChange] = this.setNormalData(value, typeof data === 'object');
    }
    console.log(this.modifiedRoute)
  }

  private setNormalData(data: any, isEvent = false) {
    return isEvent ? data.value : data;
  }

  private setMultiSelectData(element: any) {
    // element.edit.valueToChange.forEach((value: string) => {
    //   const res = element.edit?.selectedValues.some(
    //     (selectedValue: any) => selectedValue.id === value
    //   );
    //   console.log(res)
    //   this.modifiedRoute[value] = element.edit?.selectedValues.some(
    //     (selectedValue: any) => selectedValue.id === value
    //   );
    // });
    element.edit.valueToChange.map((value: string) => {
      let checked = false;
      if (element.edit?.selectedValues.some((selectedValue: any) => selectedValue.id === value)) {
        checked = true; 
      }
      this.modifiedRoute[value] = checked;
    })
  }

  onSubmit() {
    console.log(this.modifiedRoute)
    if (this.newRoute()) {
      this.createEventEmitter.emit(this.modifiedRoute);
    } else {
      this.editEventEmitter.emit(this.modifiedRoute);
    }
  }

}

import { NotificationTypes } from 'src/app/models/enums';
import { INotificationState } from '../states';

export const initialNotificationState: INotificationState = {
  type: NotificationTypes.SUCCESS,
  image: '',
  title: '',
  message: '',
  typeError: '',
  link: null,
  show: false,
  translate: false
};

import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService } from '../http/base.http.service';
import { ApiRouterService } from '../http/api.router';
import { ExpeditionStatus, ExpeditionType } from 'src/app/models/enums';
import { IBase } from 'src/app/models';

@Injectable({
  providedIn: 'root',
})
export class TripsHttpService extends BaseHttpService {
  private baseURL = `trips`;

  constructor(
    @Inject(HttpClient) httpClient: HttpClient,
    private routerService: ApiRouterService,
  ) {
    super(httpClient);
  }

  public getTrips(
    size: number,
    page: number,
    towns: IBase[],
    searchValue?: string
  ) {
    let path = `?page=${page}&size=${size}&expedition_status=${ExpeditionStatus.OPEN},${ExpeditionStatus.READY},${ExpeditionStatus.ONGOING}`;
    if (towns.length > 0) {
      const townsIds = towns.map(town => town.id).join(',');
      path += `&towns=${townsIds}`;
    }
    path +=
      searchValue
        ? `&value=${searchValue}`
        : '';
    const url = this.routerService.mount(`${this.baseURL}${path}`);
    return this.read(url);
  }

  public getTripsFiltered(
    size: number,
    page: number,
    expeditionClients: IBase[],
    expeditionOperators: IBase[],
    expeditionTowns: IBase[],
    expeditionTypes: ExpeditionType[],
    expeditionStatus: ExpeditionStatus[],
    searchValue?: string
  ) {
    let path = `/filter?page=${page}&size=${size}`;
    if (expeditionClients.length > 0) {
      const clientIds = expeditionClients.map(client => client.id).join(',');
      path += `&clients=${clientIds}`;
    }
    if (expeditionOperators.length > 0) {
      const operatorsIds = expeditionOperators.map(operator => operator.id).join(',');
      path += `&operators=${operatorsIds}`;
    }
    if (expeditionTowns.length > 0) {
      const townsIds = expeditionTowns.map(town => town.id).join(',');
      path += `&towns=${townsIds}`;
    }
    if (expeditionTypes.length > 0) {
      const types = expeditionTypes.map(types => types).join(',');
      path += `&types=${types}`;
    }
    if (expeditionStatus.length > 0) {
      const status = expeditionStatus.map(status => status).join(',');
      path += `&status=${status}`;
    }
    path +=
      searchValue
        ? `&search=${searchValue}`
        : '';
    const url = this.routerService.mount(`${this.baseURL}${path}`);
    return this.read(url);
  }

  public getTrip(tripId: number) {
    const path = `/${tripId}`;
    const url = this.routerService.mount(`${this.baseURL}${path}`);
    return this.read(url);
  }

  public changeDriver(tripId: number, driverId: number) {
    const path = `/${tripId}/driver/${driverId}`;
    const url = this.routerService.mount(`${this.baseURL}${path}`);
    return this.update({}, url);
  }

  public changeVehicle(tripId: number, driverId: number) {
    const path = `/${tripId}/vehicle/${driverId}`;
    const url = this.routerService.mount(`${this.baseURL}${path}`);
    return this.update({}, url);
  }

  public addPax(tripId: number, nPax: number) {
    const path = `/${tripId}/pax`;
    const requestParam = `n_pax=${nPax}`;
    const url = this.routerService.mount(`${this.baseURL}${path}?${requestParam}`);
    return this.updatePatch({}, url);
  }

  public setStatus(tripId: number, status: ExpeditionStatus) {
    const path = `/${tripId}/status`;
    const requestParam = `status=${status}`;
    const url = this.routerService.mount(`${this.baseURL}${path}?${requestParam}`);
    return this.updatePatch({}, url);
  }

  public deleteTrip(tripId: number, reason?: string) {
    const body = {
      'id': tripId,
      'comment': reason,
    };
    const url = this.routerService.mount(`${this.baseURL}`);
    return this.delete(url, body);
  }

  public getBookingsByTrip(tripId: number) {
    const path = `/${tripId}/bookings`;
    const url = this.routerService.mount(`${this.baseURL}${path}`);
    return this.read(url);
  }

  public getBookingsByStop(tripId: number, stopId: number, pickUps: boolean) {
    const path = `/${tripId}/stop/${stopId}/bookings`;
    const requestParam = `pickups=${pickUps}`;
    const url = this.routerService.mount(`${this.baseURL}${path}?${requestParam}`);
    return this.read(url);
  }

  public getChangeoversByStop(stopId: number) {
    const path = `/stop/${stopId}/changeovers`;
    const url = this.routerService.mount(`${this.baseURL}${path}`);
    return this.read(url);
  }

  public getTripPosition(tripId: number) {
    const path = `/${tripId}/real-time-positions`;
    const url = this.routerService.mount(`${this.baseURL}${path}`);
    return this.read(url);
  }

  public getTownsPositions(townsIds: number[]) {
    const path = `/real-time-positions?town_ids=${townsIds}`;
    const url = this.routerService.mount(`${this.baseURL}${path}`);
    return this.read(url);
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Modal } from 'flowbite';
import { InputType } from 'src/app/models/enums';
import { LiteralService } from 'src/app/services/literal/literal.service';
import { images } from 'src/images';
import moment from 'moment';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-modal-block-user',
    templateUrl: './modal-block-user.component.html',
    styleUrl: './modal-block-user.component.scss',
    standalone: false
})
export class ModalBlockUserComponent {
  @Input() id: string;
  @Input() modal: Modal;
  @Input() reason: string;
  @Output() action = new EventEmitter<any>();

  public InputType = InputType;
  public images = images;
  public moment = moment;

  public formControl: FormGroup;
  public invalid: any[] = [];

  public date: any = moment().add(1, 'days').format('YYYY-MM-DD');

  public hasError: boolean = false;
  public error: string = '';
  public lengthError = true;

  public chars = 0;
  public maxLength = 150;

  constructor(public literalService: LiteralService) {}

  changeReason = (reason: any) => {
    this.reason = reason;
  };

  onChange = () => {
    this.chars = this.reason.length;
    this.lengthError = this.chars > this.maxLength;
  };

  doAction = (element: any) => {
    this.action.emit(element);
    this.reason = "";
  };

  changeDate = (date: any) => {
    this.date = moment(date.target.value, 'DD/MM/YYYY').format('YYYY-MM-DD');
  };

  onSubmit = (element: any) => {
    const errors = this.findInvalidControls();
    if (errors && 'required' in errors && this.reason.length === 0) {
      this.hasError = true;
      this.error = this.literalService.get('errors.requiredField', true);
    } else if (errors && 'maxlength' in errors) {
      this.hasError = true;
      this.error = this.literalService.get('errors.maxlength', true).replace('{0}', this.maxLength.toString());
    } else {
      this.hasError = false;
      this.error = '';
      this.doAction(element); 
    }
  };

  findInvalidControls = () => {
    this.formControl = new FormGroup({
      reason: new FormControl(this.reason, [
        Validators.required,
        Validators.maxLength(this.maxLength),
      ])
    });
    return this.formControl.controls['reason'].errors;
  };
}

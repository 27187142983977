<form #form novalidate (ngSubmit)="onSubmit()">
  <div id="bookingDetailsContainer" class="container absolute top-20 right-0 hiddenDetails w-0 h-screen" [ngClass]="{ 'openDetails md:w-[47%] 2xl:w-[35%]': booking, 'openDetails md:w-[68%] 2xl:w-[54%]': booking && expanded }">
    @if (booking) {
      <section class="topbar w-100 h-14 mt-4 flex items-center">
        @if (booking.id) {
          <h1 class="ml-8 text-xl font-[600] max-w-[40%] truncate">
            {{ literalService.get("bookings.name", true) }}
          </h1>
          <section id="actions" class="flex ml-auto mr-8">
            <div class="flex items-center justify-center content-image mr-2" (click)="copyLink(booking.id)">
              <img
                [tooltip]="literalService.get('copyLink', true)"
                [position]="TooltipPosition.DYNAMIC"
                [src]="images.link"
                alt="copy"
                class="h-6 w-6 mr-4 ml-4 cursor-pointer"
              />
            </div>
            <div class="flex items-center justify-center content-image mr-2">
              <img
                [tooltip]="literalService.get('bookings.tooltips.favorite', true)"
                [position]="TooltipPosition.DYNAMIC"
                [src]="images.booking.actions.favorite"
                alt="favorite"
                class="h-6 w-6 mr-4 ml-4 cursor-pointer"
              />
            </div>
            <div class="flex items-center justify-center content-image mr-2" (click)="goToTrip(booking.expeditionId)">
              <img
                [tooltip]="literalService.get('bookings.tooltips.trip', true)"
                [position]="TooltipPosition.DYNAMIC"
                [src]="images.booking.actions.trip"
                alt="trip"
                class="h-6 w-6 mr-4 ml-4 cursor-pointer"
              />
            </div>
            <div class="flex items-center justify-center content-image mr-2" (click)="goToUser(booking.userId)">
              <img
                [tooltip]="literalService.get('bookings.tooltips.user', true)"
                [position]="TooltipPosition.DYNAMIC"
                [src]="images.booking.actions.user"
                alt="user"
                class="h-6 w-6 mr-4 ml-4 cursor-pointer"
              />
            </div>
            <!--div class="flex items-center justify-center content-image mr-2">
              <img
                [tooltip]="literalService.get('bookings.tooltips.open', true)"
                [position]="TooltipPosition.DYNAMIC"
                [src]="images.booking.actions.open"
                alt="open"
                class="h-6 w-6 mr-4 ml-4 cursor-pointer"
              />
            </div-->
            @if (expanded) {
              <!--div
                class="flex items-center justify-center content-image mr-2"
                (click)="editBooking.emit(booking)"
              >
                <img
                  [tooltip]="literalService.get('bookings.tooltips.edit', true)"
                  [position]="TooltipPosition.DYNAMIC"
                  [src]="images.booking.actions.edit"
                  alt="edit"
                  class="h-6 w-6 mr-4 ml-4 cursor-pointer"
                />

              </div-->
              <div
                class="flex items-center justify-center content-image mr-2"
                (click)="markAsAFault.emit()"
              >
                <img
                  [tooltip]="booking.missed ? literalService.get('bookings.tooltips.unMarkAsAFault', true) : literalService.get('bookings.tooltips.markAsAFault', true)"
                  [position]="TooltipPosition.DYNAMIC"
                  [src]="images.booking.actions.missed"
                  alt="missed"
                  class="h-6 w-6 mr-4 ml-4 cursor-pointer"
                  [ngClass]="{'missed': booking.missed }"
                />
              </div>
              <div
                class="flex items-center justify-center content-image mr-2"
                (click)="deleteBooking.emit()"
              >
                <img
                  [tooltip]="literalService.get('bookings.tooltips.delete', true)"
                  [position]="TooltipPosition.DYNAMIC"
                  [src]="images.booking.actions.delete"
                  alt="delete"
                  class="h-6 w-6 mr-4 ml-4 cursor-pointer"
                />
              </div>
            }
            <div class="flex items-center justify-center cursor-pointer mr-2" (click)="close()">
              <img
                [src]="images.close"
                alt="closeDetails"
                class="h-6 w-6"
              />
            </div>
          </section>
        }
      </section>
      <section class="w-100 mt-8 ml-8 mr-20 items-center">
        <div class="grid grid-rows-6 grid-flow-col gap-5 gap-x-12" [ngClass]="{ 'grid-rows-6': booking }">
          <article *ngFor="let element of bookingForm">
            @if (expanded ? true : !element.expanded) {
              <app-detail [data]="element" [value]="element.value" />
            }
          </article>
          @if (expanded) {
            <!-- <button id="viewMap" type="button" class="flex items-center font-semibold mb-5" (click)="openInFullMap.emit(booking)">
              <img  [src]="images.world" alt="viewMap" class="h-5 w-5 cursor-pointer" />
              {{ literalService.get('bookings.data.viewMap', true) }}
            </button> -->
          }
        </div>
      </section>
      <section class="flex items-center absolute right-5 top-0 bottom-0">
        <img alt="arrowToExpand" [src]="images.arrowLeft" class="h-5 w-5 cursor-pointer expand" [ngClass]="{ actionRotateArrowOpen: !expanded, actionRotateArrowClose: expanded }" (click)="expanded = !expanded" />
      </section>
    }
    <section>
      <div id="mapContainer" class="p-5 h-[400px]" [ngClass]="{'hidden': !viewMap}">
        <img [src]="images.openInFull" class="absolute bg-white rounded-[50%] z-10 h-10 w-10 right-5 p-2 mt-4 mr-4 cursor-pointer" (click)="openInFullMap.emit(booking)" />
        <app-marker-od id="origin" [ngClass]="{ hidden: !booking }" [textColor]="'text-white'" [color]="'primary-purple-light-background'" [text]="literalService.get('origin').toUpperCase()"></app-marker-od>
        <app-marker-od id="destination" [ngClass]="{ hidden: !booking }" [textColor]="'text-black'" [color]="'primary-green-background'" [text]="literalService.get('destination').toUpperCase()"></app-marker-od>
        <div id="map" class="w-full h-full"></div>
      </div>
    </section>
  </div>
</form>

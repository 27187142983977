import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Modal } from 'flowbite';
import { InputType } from 'src/app/models/enums';
import { LiteralService } from 'src/app/services/literal/literal.service';
import { images } from 'src/images';

@Component({
  selector: 'app-modal-add-pax',
  templateUrl: './modal-add-pax.component.html',
  styleUrl: './modal-add-pax.component.scss',
})
export class ModalAddPaxComponent {
  @Input() id: string;
  @Input() modal: Modal;
  @Input() nPax: number = 1;
  @Output() action = new EventEmitter<any>();

  public InputType = InputType;
  public images = images;

  public buttonDisabled = false;

  public changeDriverFormControl: FormGroup;
  public hasErrors: boolean = false;
  public error: string = '';

  constructor(public literalService: LiteralService) {}

  change = (nPax: any) => {
    this.nPax = nPax;
  };
}

import { Injectable } from '@angular/core';
import { BaseHttpService } from '../http/base.http.service';

@Injectable({
  providedIn: 'root',
})
export class MessageService extends BaseHttpService {
  // constructor() {
  //   super()
  // }
  // public getMessage = async (id: number): Promise<IMessage> => {
  //   return this.get(`messages/${id}`)
  // }
  // public getMessages = async (): Promise<IMessage[]> => {
  //   return this.get(`messages`)
  // }
}

<form #form novalidate (ngSubmit)="onSubmit()">
    <div id="routeDetailsContainer" class="container absolute top-20 right-0 z-10 hiddenDetails" [ngClass]="{ 'openDetails': route() || newRoute(), 'expanded': expanded, 'sm:h-[calc(100vh)] 2xl:h-[calc(100vh-80px)]': route() && !edit() || newRoute(), 'sm:h-[calc(100vh+80px)] 2xl:h-[calc(100vh-80px)]': route() && edit() || newRoute() }">
        <section class="topbar h-14 mt-4 flex items-center">
            @if (route() && route().id) {
                <h1 class="ml-8 text-xl font-[600] max-w-[25%] truncate">{{ literalService.get('services.routes.routeTitle', true) }}</h1>
                <section id="actions" class="flex ml-auto mr-8">
                    <!-- <div class="flex items-center justify-center content-image mr-2" (click)="copyLink(user.id)">
                        <img [tooltip]="literalService.get('copyLink', true)" [position]="TooltipPosition.DYNAMIC" [src]="images.link" alt="copy" class="h-6 w-6 mr-4 ml-4 cursor-pointer" >
                    </div> -->
                    <div class="flex items-center justify-center content-image mr-2" (click)="editEvent.emit()">
                        <img [src]="images.services.edit" [tooltip]="literalService.get('services.routes.tooltips.edit', true)" alt="edit" class="h-6 w-6 mr-4 ml-4 cursor-pointer" >
                    </div>
                    <!-- <div class="flex items-center justify-center content-image mr-2">
                        <img [src]="images.services.routes.downloadGTFS" alt="downloadGTFS" class="w-6 h-6 mr-4 cursor-pointer" (click)="downloadGTFS()"  />
                    </div> -->
                    <div class="flex items-center justify-center cursor-pointer mr-2" (click)="close()">
                        <img [src]="images.close" alt="closeDetails" class="h-6 w-6"/>
                    </div>
                </section>
            } @else {
                <h1 class="ml-8 text-xl font-[600] max-w-[25%] truncate">{{ literalService.get('services.routes.newRoute', true) }}</h1>
                <section id="actions" class="flex ml-auto mr-8">
                    <div class="flex items-center justify-center cursor-pointer mr-2" (click)="close()">
                        <img [src]="images.close" alt="closeDetails" class="h-6 w-6"/>
                    </div>
                </section>
            }
        </section>  
        <section class="h-14 mt-8 ml-8 items-center">
            <div class="flex">
                <div class="grid grid-rows-7 grid-flow-col gap-0" [ngClass]="{'containerDetails': route(), 'gap-[3px]': edit() || newRoute(), 'gap-10': !edit() && !newRoute()}">
                    <ng-container>
                    @if (!edit() && !newRoute()) {
                        <article *ngFor="let element of routeForm" class="-mb-[14px]" [ngClass]="{'hidden': element.hidden}">
                            @if (expanded ? true : !element.expanded) {
                                <app-detail [data]="element" [value]="element.value" />
                            }
                        </article>
                    } @else if (edit() || newRoute()) {
                        <article *ngFor="let element of routeForm" class="mr-8" [ngClass]="{'hidden': element.hidden}">
                            @if (expanded && element.edit ? true : !element?.expanded) {
                                <app-input  [data]="element"
                                            [edit]="true"
                                            [independent]="false"
                                            [translateValue]="element.edit?.translateValue"
                                            (setEventEmitter)="changeData($event, element)"
                                            (changeEventEmitter)="changeData($event, element)" />

                            }
                        </article>
                    }
                    </ng-container>
                </div>
                <section class="flex items-center absolute right-5 top-0 bottom-0">
                    <img alt="arrowToExpand" [src]="images.arrowLeft" class="h-5 w-5 cursor-pointer expand" [ngClass]="{'actionRotateArrowOpen': !expanded, 'actionRotateArrowClose': expanded}" (click)="expanded = !expanded" />
                </section>
            </div>
            <div class="flex absolute bottom-8 right-24">
                @if (edit() || newRoute()) {
                    <div class="flex">
                        <input type="submit" class="primary text-white gap-4 border-2 font-semibold h-10 w-[7.5rem] uppercase mr-2.5 cursor-pointer" [value]="literalService.get('save', true)" />
                        <!-- <input type="submit" class="primary text-white gap-4 border-2 font-semibold h-10 w-[7.5rem] uppercase mr-2.5 cursor-pointer" [disabled]="formGroup.invalid" [value]="literalService.get('save', true)" /> -->
                        <input type="button" class="secondary gap-4 border-2 font-semibold h-10 w-[7.5rem] uppercase cursor-pointer" [value]="literalService.get('cancel', true)" (click)="close()" />
                    </div>
                }
            </div>
        </section>
    </div>
</form>
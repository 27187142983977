import { Injectable } from '@angular/core';
import CryptoJS from 'crypto-js';
import tinycolor from 'tinycolor2';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  public urlsToCache = [];

  public storageService = new StorageService();

  public getLanguage = async () => {
    return this.storageService.getLanguage();
  };

  public capitalize = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  public visibleTooltip = (id: any) => {
    const e = document.getElementById(id);
    return e!.offsetWidth < e!.scrollWidth || e!.offsetHeight < e!.scrollHeight;
  };

  public isArray = (value: any) => {
    return Array.isArray(value);
  };

  public closeComponent = (
    e: Event,
    inputs: HTMLElement[],
    dropdowns: HTMLElement[],
    title: string,
  ): boolean => {
    const input = inputs.find(
      (input: HTMLElement) => input.id === 'input-' + title,
    );
    const dropdown = dropdowns.find(
      (dropdown: HTMLElement) => dropdown.id === 'dropdown-' + title,
    );

    if (input && dropdown) {
      const isClickInsideInput = input.contains(e.target as Node);
      const isClickInsideDropdown = dropdown.contains(e.target as Node);

      if (!isClickInsideInput && !isClickInsideDropdown) {
        return true;
      }
    }
    return false;
  };

  public closeComponentOnlyWithDropdown = (
    e: Event,
    dropdownsParent: HTMLElement,
    dropdowns: HTMLElement[],
    title: string,
  ): boolean => {
    const dropdown = dropdowns.find(
      (dropdown: HTMLElement) => dropdown.id === 'dropdown-' + title,
    );

    if (dropdown && dropdownsParent) {
      const isClickInsideDropdown = dropdown.contains(e.target as Node);
      const isClickInsideDropdownParent = dropdownsParent.contains(
        e.target as Node,
      );

      if (!isClickInsideDropdown && !isClickInsideDropdownParent) {
        return true;
      }
    }
    return false;
  };

  public openComponentOnlyWithDropdown = (
    e: Event,
    dropdownsParent: HTMLElement,
    dropdowns: HTMLElement[],
    title: string,
  ): boolean => {
    const dropdown = dropdowns.find(
      (dropdown: HTMLElement) => dropdown.id === 'dropdown-' + title,
    );

    if (dropdown && dropdownsParent) {
      const isClickInsideDropdown = dropdown.contains(e.target as Node);
      const isClickInsideDropdownParent = dropdownsParent.contains(
        e.target as Node,
      );

      if (isClickInsideDropdown || isClickInsideDropdownParent) {
        return true;
      }
    }
    return false;
  };

  public closeCustomModals = (
    e: Event,
    modal: HTMLElement,
    componentOpen: HTMLElement,
  ): boolean => {
    if (modal && componentOpen) {
      const isClickInsideModal = modal.contains(e.target as Node);
      const isClickInsideComponentOpen = componentOpen.contains(
        e.target as Node,
      );

      if (!isClickInsideModal && !isClickInsideComponentOpen) {
        return true;
      }
    }
    return false;
  };

  public closeCustomModalsWithModals = (
    e: Event,
    modal: HTMLElement,
    modals: Node[],
    componentOpen: HTMLElement,
  ): boolean => {
    if (modal && componentOpen) {
      const isClickInsideModal = modal.contains(e.target as Node);
      const isClickInsideComponentOpen = componentOpen.contains(
        e.target as Node,
      );
      const isClickInsideModals = modals.find((modal: Node) => {
        return modal.contains(e.target as Node);
      });

      if (!isClickInsideModal && !isClickInsideComponentOpen && !isClickInsideModals) {
        return true;
      }
    }
    return false;
  };

  public isDark(hexcolor: string) {
    return hexcolor ? tinycolor(hexcolor).isDark() : false;
  }

  public getSvgImageData = async (image: string) => {
    return await fetch(image)
      .then((response) => response.text())
      .then((svg) => svg);
  };

  public encryptSHA256 = (password: string): string => {
    return CryptoJS.SHA256(password).toString();
  };

  public compareObjects(obj1: any, obj2: any): string[] {
    return Object.keys(obj1)
      .filter((key) => obj1[key] !== obj2[key])
      .concat(Object.keys(obj2).filter((key) => obj2[key] && !obj1[key]));
  }

  public numberHasDecimals = (number: number) => {
    const result = number - Math.floor(number) !== 0;
    if (result) return true;
    return false;
  };

  public compare(propName: string) {
    return function(a: any, b: any) {
        if (a[propName] < b[propName])
            return -1;
        if (a[propName] > b[propName])
            return 1;
        return 0;
    };
  }
}

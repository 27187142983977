import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiRouterService {
  private env = environment;

  constructor(private router: Router) {}

  public mount(endpoint: string, auth: boolean = false): string {
    return this._mount(endpoint, auth);
  }

  private _mount(endpoint: string, auth: boolean): string {
    // TODO: Before concat we could check if there are some slashes (/) that it should not be there.
    return `${this.getEnv(auth)}/${endpoint}`;
  }

  private getEnv(auth: boolean): string {
    // TODO: We could check if exists that key before returning, just to ensure that isn't null and everything keeps working.
    return auth ? this.env.authUrl : this.env.apiUrl;
  }
}

<section id="filterContainer">
    <article class="pl-5 pt-5">
        <h1 class="title">{{ literalService.get('filterBy', true) }}</h1>
    </article>
    <article class="lg:flex sm:relative pl-5 pt-5 pr-5 place-content-between">
        <!--label class="caption w-32 mr-4">{{ literalService.get('filterByField', true) }}</label>
        <label class="w-48 text-center title-columns mr-4"></label-->
        @if(showClients) {        
            <label class="w-48 text-center title-columns mr-4">{{ literalService.get('users.data.clients', true) }}</label>
        }
        <label class="w-48 text-center title-columns mr-4">{{ literalService.get('users.data.area', true) }}</label>
        <label class="w-48 text-center title-columns mr-4">{{ literalService.get('users.data.userType', true) }}</label>
        <label class="w-48 text-center title-columns mr-4">{{ literalService.get('users.data.status', true) }}</label>
    </article>
    <form #form novalidate>
        <article class="lg:flex sm:relative pl-5 pt-5 pr-5">
            <!--select class="lg:flex box pt-2 pb-2 pl-4 pr-4 rounded w-32 h-12 mr-4 mb-2" (change)="changeFilter($event)">
                @for (filter of filters; track $index) {
                    <option [value]="filter.key">{{ filter.value }}</option>
                }
                
                <<option value="all">{{ literalService.get('users.data.all', true) }}</option>
                <option value="firstName">{{ literalService.get('users.data.name', true) }}</option>
                <option value="lastName">{{ literalService.get('users.data.lastName', true) }}</option>
                <option value="email">{{ literalService.get('users.data.mail', true) }}</option>
                <option value="phoneNumber">{{ literalService.get('users.data.phone', true) }}</option>
                <option value="dni">{{ literalService.get('users.data.dni', true) }}</option> >
            </select-->
            <!--article class="w-48 mr-4">
                <article class="lg:flex box pt-1 pb-4 pl-4 pr-4 rounded w-48 h-12 mr-4 mb-2 bg-white">
                    <app-input-search [placeholder]="literalService.get('filter', true)" [width]="'40'" (submitEventEmitter)="addFilter($event)"></app-input-search>
                </article>
                @for (filter of filtersAdded; track $index) {
                    <article class="bg-white w-96 rounded-s-3xl rounded-e-3xl">
                        <article class="lg:flex filter items-start gap-1.5 py-1.5 px-2.5 w-96 rounded-s-3xl rounded-e-3xl mb-2">
                            <div class="flex justify-between items-center w-full">
                                <label class="font-bold">{{ literalService.get('users.data.' + filter.key, true) }} </label>
                                <label> {{ filter.value }}</label>
                                <img [id]="'deleteFilter' + $index" [src]="images.close" class="w-6 h-6 cursor-pointer" (click)="filtersAdded.splice($index, 1)" />
                            </div>
                        </article>
                    </article>
                }
            </article-->
            
            @if(showClients) {        
                <article class="lg:flex rounded w-48 h-12 items-center mr-4">
                    <app-multi-select [title]="'clients'" [elements]="clients" [values]="selectedValuesClients" [width]="'48'" />
                </article>
            }
            <article class="lg:flex rounded w-48 h-12 items-center mr-4">
                <app-multi-select [title]="'towns'" [elements]="towns" [values]="selectedValuesTowns" [width]="'48'" />
            </article>
            <article>
                @for (type of userTypes; track $index) {
                    <article class="lg:flex box pt-2 pb-2 pl-4 pr-4 rounded w-48 h-12 mr-4 mb-2 items-center bg-white">
                        <input type="checkbox" class="mr-2" [checked]="type.checked" (change)="type.checked = !type.checked" />
                        <img [src]="getImage(type.value, 'role')" class="mr-2" />
                        <label>{{ literalService.get('users.userTypes.' + type.value.toLowerCase(), true) }}</label>
                    </article>
                }
            </article>
            <article>
                @for (status of userStatus; track $index) {
                    <article class="lg:flex box pt-2 pb-2 pl-4 pr-4 rounded w-48 h-12 mr-4 mb-2 items-center bg-white">
                        <input type="checkbox" class="mr-2" [checked]="status.checked" (change)="status.checked = !status.checked" />
                        <img [src]="getImage(status.value, 'status')" class="mr-2" [ngClass]="{
                            'active': status.value.toUpperCase() === UserStatus.ACTIVE,
                            'inactive': status.value.toUpperCase() === UserStatus.INACTIVE,
                            'deleted': status.value.toUpperCase() === UserStatus.DELETED,
                            'blocked': status.value.toUpperCase() === UserStatus.BLOCKED
                        }" />
                        <label>{{ literalService.get(status.value.toLowerCase(), true) }}</label>
                    </article>
                }
            </article>
        </article>
        <article class="pt-10 pl-5 pb-5">
            <article class="flex pt-5 place-content-between">
                <div class="flex">
                    <article class="lg:flex box pt-2 pb-2 pl-4 pr-4 rounded w-48 h-12 mr-4 mb-2 items-center bg-white">
                        <input #select id="selectAll" type="checkbox" class="mr-2" (change)="selectAll($event)" />
                        <label for="selectAll">{{ literalService.get('selectAll', true) }}</label>
                    </article>
                    <article class="lg:flex box pt-2 pb-2 pl-4 pr-4 rounded w-48 h-12 mr-4 mb-2 items-center">
                        <label class="cursor-pointer hover:underline" (click)="clearSelection()">{{ literalService.get('clearSelection', true) }}</label>
                    </article>
                </div>
                <article class="lg:flex box pt-2 pb-2 pl-4 pr-4 rounded w-48 h-12 mr-4 mb-2 items-center">
                    <input type="button" class="primary text-white gap-4 p-2 border-2 font-semibold h-12 w-48 uppercase cursor-pointer" [value]="literalService.get('filter', true)" (click)="onSubmit(); closeFilter.emit()" />
                </article>
            </article>
        </article>
    </form>
</section>


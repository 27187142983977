import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Modal } from 'flowbite';
import { InputType } from 'src/app/models/enums';
import { LiteralService } from 'src/app/services/literal/literal.service';
import { images } from 'src/images';

@Component({
  selector: 'app-modal-add-to-app',
  templateUrl: './modal-add-to-app.component.html',
  styleUrls: ['./modal-add-to-app.component.scss'],
})
export class ModalAddToAppComponent {
  @Input() id: string;
  @Input() modal: Modal;
  @Input() text: string;
  @Input() values?: any[];
  @Output() action = new EventEmitter<any>();

  public InputType = InputType;
  public images = images;

  public buttonDisabled = false;

  constructor(public literalService: LiteralService) {}
}

import { Injectable } from '@angular/core';
import { StorageDriverService } from './storage.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LocalDriverService implements StorageDriverService {
  handler: Storage;
  changes$ = new Subject();

  constructor() {
    this.handler = window.localStorage;
  }

  get isLocalStorageSupported(): boolean {
    return !!this.handler;
  }

  get(key: string, parseToJson?: boolean): any {
    if (this.isLocalStorageSupported) {
      const value = this.handler.getItem(key);

      // TEMPORAL FIX, UNTIL ALL CODE BASE IT'S BEEN REFACTORED TO USE STORAGEDRIVER
      return parseToJson === true ? this.getParsed(value) : value;
    }

    return null;
  }

  getParsed(value: any) {
    try {
      return JSON.parse(value);
    } catch (error) {
      return value;
    }
  }

  set(key: string, value: any, parseToJson?: boolean): any {
    value = parseToJson === true ? JSON.stringify(value) : value;
    if (this.isLocalStorageSupported) {
      this.handler.setItem(key, value);
      this.changes$.next({
        type: 'set',
        key,
        value,
      });
    }

    return this;
  }

  remove(key: string): any {
    if (this.isLocalStorageSupported) {
      this.handler.removeItem(key);
      this.changes$.next({
        type: 'remove',
        key,
      });
    }

    return this;
  }
}

<section class="w-[30rem] h-auto">
  <article id="header" class="flex primary-green-2-background rounded-ss-lg rounded-se-lg">
    <div class="mt-4 ml-8 mb-4 w-full">
      @if (currentStep === 1) {
        <h1 class="font-bold text-[22px] primary-purple-text">{{ literalService.get('bookings.newBooking', true) }}</h1>
        <label class="font-semibold text-lg">{{ literalService.get('bookings.information', true) }}</label>
      } @else if (currentStep === 2) {
        <div class="flex items-center">
          <img [src]="images.arrowLeft" alt="back" class="h-6 w-6 cursor-pointer" (click)="back()" />
          <div class="ml-5">
            <h1 class="font-bold text-[22px] primary-purple-text">{{ literalService.get('bookings.newBooking', true) }}</h1>
            <label class="font-semibold text-lg">{{ literalService.get('bookings.information', true) }}</label>
          </div>
        </div>
      } @else if (currentStep === 3) {
        <div class="flex items-center">
          <img [src]="images.arrowLeft" alt="back" class="h-6 w-6 cursor-pointer" (click)="back()" />
          <div class="ml-5">
            <h1 class="font-bold text-[22px] primary-purple-text">{{ literalService.get('bookings.newBooking', true) }}</h1>
            <label class="font-semibold text-lg">{{ literalService.get('bookings.availabilities', true) }}</label>
          </div>
        </div>
      }
    </div>
    <div class="mt-4 mr-5 justify-end">
      <div class="flex main rounded-[50%] h-14 w-14 justify-center items-center">
        <div class="quarter quarter1" [ngClass]="{ 'block': steps.includes(1), 'hidden': !steps.includes(1) }"></div>
        <div class="quarter quarter2" [ngClass]="{ 'block': steps.includes(2), 'hidden': !steps.includes(2) }"></div>
        <div class="quarter quarter3" [ngClass]="{ 'block': steps.includes(3), 'hidden': !steps.includes(3) }"></div>
        <label class="flex font-bold text-[22px] primary-purple-text cutout justify-center items-center">
          {{ currentStep }}/{{ numberSteps }}
        </label>
      </div>
    </div>
  </article>
  @if (currentStep === 3 && !loadingAvailabilities && (availabilities.failure.length > 0 || availabilities.success.length > 0)) {
    <article class="flex items-center primary-purple-light-2-background p-2 pl-8">
      <div class="flex items-center">
        <img [src]="images.booking.date" class="h-6 w-6 mr-2" />
        <label class="font-normal text-base mr-6">{{ getDate() }}</label>
        <img [src]="images.pax" class="h-6 w-6 mr-1" />
        <label class="font-normal text-base mr-2">{{ pax }}</label>
        <img [src]="images.prm" class="h-6 w-6 mr-1" />
        <label class="font-normal text-base">{{ prm }}</label>
      </div>
      <div class="felx absolute right-0 mr-12" (click)="currentPage > 0 && currentPage = currentPage - 1" [ngClass]="{'hidden': !isManualBooking}">
        <label class="font-normal text-base">{{ currentElement }} - {{ totalElements }}</label>
      </div>
      <div class="cursor-pointer absolute right-0 mr-12" (click)="currentPage > 0 && currentPage = currentPage - 1">
        <img [src]="images.arrowLeft" class="h-5 w-5" [ngClass]="{'opacity-40': currentPage === 0, 'hidden': isManualBooking}" />
      </div>
      <div class="cursor-pointer absolute right-0 mr-8" (click)="(currentPage < Object.keys(availabilitiesSeparateByDate).length - 1) && currentPage = currentPage + 1">
        <img [src]="images.arrowRight" class="h-5 w-5" [ngClass]="{'opacity-40': currentPage === Object.keys(availabilitiesSeparateByDate).length - 1, 'hidden': isManualBooking}" />
      </div>
    </article>
  }
  @if (currentStep === 1 || currentStep === 2) {
    <article id="body" class="bg-white pb-6 pt-1 pl-7 pr-7">
      @if (changingTown) {
        <img [src]="images.loading" alt="loading" class="h-10 w-10 absolute right-7 top-[104px]" />
      }
      @for (element of bookingForm; track $index) {
        <div class="mt-4" [ngClass]="{'hidden': !steps.includes(element.step) }">
          <app-input  [data]="element"
                      [edit]="true"
                      [independent]="true"
                      (setEventEmitter)="set($event.value, element.edit?.valueToChange)"
                      (scrollEventEmitter)="scroll($event)"
                      (searchEventEmitter)="search($event)"
                      (changeEventEmitter)="change($event, element.edit?.valueToChange)" />
          
        </div>
      }
    </article>
    <article id="footer" class="flex bg-white pb-6 justify-center rounded-es-lg rounded-ee-lg pt-1 pl-7 pr-7" [ngClass]="{'hidden': !newBooking.targetUserId }">
      <input type="button" class="primary text-white gap-4 border-2 font-semibold h-14 w-3/4  uppercase cursor-pointer" (click)="getAvailabilities()" [value]="literalService.get('bookings.viewAvailabilities', true)" />
    </article>
  } @else if (currentStep === 3) {
    @if (loadingAvailabilities) {
      <article class="flex bg-white w-full pb-6 pt-4 pl-7 pr-7 justify-center">
          <ng-lottie [options]="optionsPin" (animationCreated)="animationCreated($event)" />
      </article>
    } @else if (creatingBooking) {
      <article class="flex bg-white w-full pb-6 pt-4 pl-7 pr-7 justify-center">
        <ng-lottie [options]="optionsBus" (animationCreated)="animationCreated($event)" />
      </article>
    } @else {
      <article id="body" class="bg-white pb-6 pt-1 pl-7 pr-7">
        <app-booking-availabilities [availabilities]="availabilitiesSeparateByDate[availabilitiesDates[currentPage]]" [newBooking]="newBooking" [userId]="newBooking.targetUserId" (manual)="manualBooking()" (book)="createBooking($event)" (bookMultiple)="bookMultiple($event)" (showMaxReservationTimeModalEventEmitter)="showMaxReservationTimeModal()" (showExceedingKmLimitModalEventEmitter)="showExceedingKmLimitModal($event)"></app-booking-availabilities>
      </article>
    }
  }
</section>

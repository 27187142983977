import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import { IBase, IClient, ITown } from 'src/app/models';
import { ExpeditionStatus, ExpeditionType } from 'src/app/models/enums';
import { TripsService, UsersService, UtilsService } from 'src/app/services';
import { LiteralService } from 'src/app/services/literal/literal.service';
import { TooltipPosition } from 'src/app/shared/tooltip/tooltip-position';
import { images } from 'src/images';

@Component({
  selector: 'app-booking-filter',
  templateUrl: './filter.component.html',
  styleUrl: './filter.component.scss',
})
export class BookingFilterComponent implements OnInit {
  public expeditionStatus: any[] = [];
  public expeditionTypes: any[] = [];
  public ExpeditionStatus = ExpeditionStatus;
  public ExpeditionTypes = ExpeditionType;
  public closedStatusSelected = false;

  public clients: unknown[] = [];
  public selectedValuesClients: IBase[] = [];
  public towns: unknown[] = [];
  public selectedValuesTowns: IBase[] = [];

  public images = images;
  public TooltipPosition = TooltipPosition;

  public filterSelected = 'all';
  public filters: any[] = [];
  public showClients: boolean = false;

  @Output() filter = new EventEmitter();
  @Output() closeFilter = new EventEmitter();

  @Input() componentOpen: string;

  @ViewChild('select') select: ElementRef;

  constructor(
    renderer: Renderer2,
    public literalService: LiteralService,
    public tripsService: TripsService,
    public utilsService: UtilsService,
    public usersService: UsersService
  ) {
    this.expeditionStatus.push({value: 'Ongoing', checked: true });
    this.expeditionStatus.push({value: 'Ready', checked: true });
    this.expeditionStatus.push({value: 'Open', checked: true });
    
    (Object.keys(ExpeditionType) as Array<keyof typeof ExpeditionType>).map(
      (expeditionTypes: keyof typeof ExpeditionType) => {
        this.expeditionTypes.push({ value: expeditionTypes, checked: false });
      },
    );

    renderer.listen('window', 'click', (e: Event) => {
      const modal = document.getElementById('filterContainer')!;
      const componentOpen = document.getElementById(this.componentOpen)!;
      const close = utilsService.closeCustomModals(e, modal, componentOpen);
      if (close) {
        this.closeFilter.emit();
      }
    });
  }

  async ngOnInit() {
    const { clients, towns } = await this.usersService.getPossibleClientTowns();
    clients.map((client: IClient) => {
      this.clients.push({
        id: client.id,
        code: client.name,
        name: client.name,
      });
    });
    towns.map((town: ITown) => {
      this.towns.push({ id: town.id, code: town.name, name: town.name });
    });

    this.showClients = clients.length > 1;
  }

  changeFilter = (event: any) => {
    this.filterSelected = event.target.value;
  };

  /*getImage = (val: string, imageFrom: string) => {
    const indexImage =
      imageFrom === 'status'
        ? Object.keys(images.user).findIndex((key: string) => {
            return (
              key === 'user' + this.utilsService.capitalize(val.toLowerCase())
            );
          })
        : Object.keys(images.roles).findIndex((key: string) => {
            return key === val.toLowerCase();
          });
    const image = Object.values(
      imageFrom === 'status' ? images.user : images.roles,
    ).find((value, index) => {
      return index === indexImage ? value : null;
    });
    return image;
  };*/

  selectAll = (event: any) => {
    this.expeditionStatus.map(
      (status: any) => (status.checked = event.currentTarget.checked),
    );
    this.expeditionTypes.map(
      (type: any) => (type.checked = event.currentTarget.checked),
    );
    if (event.currentTarget.checked) {
      this.clients.map((client: any) =>
        this.selectedValuesClients.push({ id: client.id, name: client.code }),
      );
      this.towns.map((town: any) =>
        this.selectedValuesTowns.push({ id: town.id, name: town.code }),
      );
    } else {
      this.selectedValuesClients = [];
      this.selectedValuesTowns = [];
    }
  };

  clearSelection = () => {
    this.expeditionStatus.map((status: any) => (status.checked = false));
    this.expeditionTypes.map((type: any) => (type.checked = false));
    this.selectedValuesClients = [];
    this.selectedValuesTowns = [];
    this.select.nativeElement.checked = false;
  };

  onSubmit = async () => {
    const selectedClients: IBase[] = [];
    this.selectedValuesClients.forEach((client: IBase) => {
      selectedClients.push(client);
    });

    const selectedTowns: IBase[] = [];
    this.selectedValuesTowns.forEach((town: IBase) => {
      selectedTowns.push(town);
    });
        
    const selectedTypes: string[] = [];
    this.expeditionTypes.forEach((type: any) => {
      type.checked && selectedTypes.push(type.value);
    });

    const selectedStatus = [];
    if (this.closedStatusSelected) selectedStatus.push(ExpeditionStatus.CLOSED);
    else {
      this.expeditionStatus.forEach((status: any) => {
        if (status.checked) selectedStatus.push(status.value) ;
      });
    }

    this.filter.emit({selectedClients, selectedTowns, selectedTypes, selectedStatus});
  };
}

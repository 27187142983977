import { Component, Input, OnInit } from '@angular/core';
import { LiteralService } from 'src/app/services/literal/literal.service';
import { arrows, images } from 'src/images';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() routeName = '';

  public hidden = true;
  public arrows = arrows;
  public images = images;
  public arrow = 'down';

  private elementsId = [
    { id: 'adminsSectionList', event: 'mouseover', hidden: false },
    { id: 'adminsSectionList', event: 'mouseleave', hidden: true },
    { id: 'sidebar-submenu', event: 'mouseover', hidden: false },
    { id: 'sidebar-submenu', event: 'mouseleave', hidden: true },
    { id: 'adminsSubmenuSectionList', event: 'mouseover', hidden: false },
    { id: 'adminsSubmenuSectionList', event: 'mouseleave', hidden: true },
    { id: 'sidebar-submenu-admin', event: 'mouseover', hidden: false },
    { id: 'sidebar-submenu-admin', event: 'mouseleave', hidden: true },
  ];

  constructor(public literalService: LiteralService) {}

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    this.elementsId.forEach((element) => {
      document
        .getElementById(element.id)
        ?.addEventListener(element.event, () => {
          this.hidden = element.hidden;
        });
    });
  }

  public handleClick = () => {
    document.getElementById('dropdown-user')?.classList.contains('hidden')
      ? 'up'
      : 'down';
    this.arrow = !document
      .getElementById('dropdown-user')
      ?.classList.contains('hidden')
      ? 'up'
      : 'down';
  };
}

<div class="input flex items-center relative">
    @if (getValue() !== Colors.NONE) {
        <app-color-detail [color]="getValue()" />
    } @else {
        <label>-</label>
    }
    <select class="absolute left-20 w-[70%] border-0 bg-transparent" [formGroup]="formGroup!" [attr.formControlName]="formControlName" (change)="changeColor($event)">
        @for (item of values; track $index) {
            <option [value]="item" [selected]="getValue() === item">{{ literalService.get('colors.'+item, true) }}</option>    
        }
    </select>
</div>
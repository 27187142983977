import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ServicesService {
  constructor() { }
  // public getService = async (id: number): Promise<IService> => {
  //   return this.get(`services/${id}`)
  // }
  // public getServices = async (): Promise<IService[]> => {
  //   return this.get(`services`)
  // }

  getCurrentDomain(): string {
    return window.location.origin;
  }
}

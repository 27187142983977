import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
} from '@angular/core';
import { IBase } from 'src/app/models';
import { LiteralService } from 'src/app/services/literal/literal.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { images } from 'src/images';
import { TooltipPosition } from '../tooltip/tooltip-position';
import { InputType } from 'src/app/models/enums';
import { ServiceTagComponent } from 'src/app/components/service-tag/service-tag.component';
import { BookingSeatsComponent } from 'src/app/components/booking/booking-seats/booking-seats.component';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
})
export class DetailComponent implements OnInit, OnChanges {
  @Input() data: any;
  @Input() value?: any;

  public images = images;
  public textValues = '';
  public text = '';

  public TooltipPosition = TooltipPosition;
  public InputType = InputType;
  public ServiceTagComponent = ServiceTagComponent;
  public BookingSeatsComponent = BookingSeatsComponent;

  constructor(
    public literalService: LiteralService,
    public utilsService: UtilsService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    console.log(this.data, this.value);
    this.data.array ? this.getTextFromArray() : this.getText();
    this.changeDetectorRef.detectChanges();
  }

  ngOnChanges(): void {
    this.changeDetectorRef.detectChanges();
  }

  getText() {
    this.text = '';
    this.text = this.data.value;
    console.log("DATA", this.data, this.data.value);
  }

  getTextFromArray() {
    this.textValues = '';
    this.data.value &&
      this.data.value.sort(
        (firstItem: IBase, secondItem: IBase) => firstItem.id! - secondItem.id!,
      );
    this.data.value &&
      this.data.value.map((value: any) => {
        const isLast = value.id === this.data.value?.at(-1)?.id;
        this.textValues += this.data.dataToShow
          ? value[this.data.dataToShow] + (!isLast ? ', ' : '')
          : value.name + (!isLast ? ', ' : '');
      });
    this.textValues === '' ? (this.textValues = '-') : null;
  }
}

import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { getBookingForm } from 'src/app/forms/bookingForms';
import { IBookingDetails } from 'src/app/models/bookings/bookingDetails';
import { MapUtilsService, ServicesService } from 'src/app/services';
import { LiteralService } from 'src/app/services/literal/literal.service';
import { TooltipPosition } from 'src/app/shared/tooltip/tooltip-position';
import { environment } from 'src/environments/environment';
import { images } from 'src/images';
import { AppComponent } from 'src/app/app.component';
import { Router } from '@angular/router';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'app-booking-details',
  templateUrl: './booking-details.component.html',
  styleUrl: './booking-details.component.scss',
})
export class BookingDetailsComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() booking: IBookingDetails = {} as IBookingDetails;
  @Output() openInFullMap = new EventEmitter<IBookingDetails>();
  @Output() editBooking = new EventEmitter<IBookingDetails>();
  @Output() deleteBooking = new EventEmitter();
  @Output() markAsAFault = new EventEmitter();
  @Output() closeDetails = new EventEmitter();

  public map: google.maps.Map;

  public expanded = false;
  public edit = false;
  public newBooking = false;
  public isFullMap = false;
  public viewMap = false;

  public bookingForm: any;

  public images = images;
  public TooltipPosition = TooltipPosition;

  public faultModalShow = false;

  constructor(
    public literalService: LiteralService,
    private mapUtilsService: MapUtilsService,
    public router: Router,
    public appComponent: AppComponent,
    private clipboardService: ClipboardService,
    private servicesService: ServicesService
  ) {}

  async ngOnInit() {
    await this.initMap();
  }

  ngAfterViewInit(): void {
    this.loadFormAndMap();
  }

  async ngOnChanges() {
    await this.initMap();
    this.map?.data.forEach((feature: any) => this.map.data.remove(feature));
    this.loadFormAndMap();
  }

  initMap = async () => {
    const { Map } = (await google.maps.importLibrary(
      'maps',
    )) as google.maps.MapsLibrary;
    this.map = new Map(document.getElementById('map') as HTMLElement, {
      zoom: 15,
      disableDefaultUI: true,
      mapId: environment.mapId,
    });
  };

  loadFormAndMap = () => {
    console.log(this.map, this.booking);
    if (this.map && this.booking) {
      this.booking &&
        getBookingForm(this.booking).then((res: any) => {
          this.bookingForm = res;
        });
      this.map.data.setStyle({
        strokeColor: this.booking.service && this.booking.service.color !== '#FFFFFFFF' ? this.booking.service.color : '#000000',
        strokeWeight: 5,
      });
      console.log(this.booking, this.bookingForm);
      this.map.data.addGeoJson(this.booking.path);
      const bounds = new google.maps.LatLngBounds();
      const markers: any[] = [];
      const markerOrigin = document.getElementById('origin') as HTMLElement;
      const markerDestination = document.getElementById(
        'destination',
      ) as HTMLElement;
      markers.push({
        element: markerOrigin,
        latitude: this.booking.path.features[0].geometry.coordinates[0][1],
        longitude: this.booking.path.features[0].geometry.coordinates[0][0],
      });
      markers.push({
        element: markerDestination,
        latitude: this.booking.path.features
          .at(-1)
          .geometry.coordinates.at(-1)[1],
        longitude: this.booking.path.features
          .at(-1)
          .geometry.coordinates.at(-1)[0],
      });
      this.mapUtilsService.addCustomMarkers(this.map, markers);
      this.booking.path.features.map((feature: any) => {
        bounds.union(
          new google.maps.LatLngBounds(
            new google.maps.LatLng(
              feature.geometry.coordinates[0][1],
              feature.geometry.coordinates[0][0],
            ),
          ),
        );
      });
      setTimeout(() => {
        this.map.fitBounds(bounds);
      }, 500);
    }
  };

  close = () => {
    this.expanded = false;
    this.edit = false;
    this.newBooking = false;
    this.closeDetails.emit();
  };

  goToUser(userId: number): void {
    this.appComponent.setRouteName('users');
    this.router.navigate(['/users/' + userId]);
  }

  goToTrip(tripId: number): void {
    this.appComponent.setRouteName('trips');
    this.router.navigate(['/trips/' + tripId]);
  }

  copyLink(bookingId: number): void {
    const domain = this.servicesService.getCurrentDomain();
    this.clipboardService.copyFromContent(domain + '/bookings/' + bookingId);
  }

  onSubmit = () => {};
}

export class APP {
  // STORAGE
  public static LOCAL_STORAGE_TOKEN_KEY_NAME = 'token';
  public static LOCAL_STORAGE_TOKEN_DECODED = 'decoded_token';
  public static LOCAL_STORAGE_TOKEN_TYPE_KEY_NAME = 'token_type';
  public static LOCAL_STORAGE_ROLE = 'role';

  // AUTH
  public static HEADER_RESPONSE_TOKEN_AUTH_KEY = 'Authorization';
}

// http-interceptors/index.ts

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthHeadersInterceptor } from './auth-headers';
import { CacheInterceptor } from './cache-interceptor';
import { ResponseInterceptor } from './response-interceptor';

export const httpInterceptProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthHeadersInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
];

import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserSortType } from 'src/app/models/enums';
import { UsersResolver } from 'src/app/resolvers/users.resolver';
import { StorageService, UtilsService } from 'src/app/services';
import { LiteralService } from 'src/app/services/literal/literal.service';
import { images } from 'src/images';

@Component({
  selector: 'app-sort',
  templateUrl: './sort.component.html',
  styleUrl: './sort.component.scss',
})
export class SortComponent implements OnInit {
  public images = images;
  public userSortType = UserSortType;
  public userSortTypeSelected: UserSortType;
  public sortTypes: UserSortType[];

  @Output() closeSort = new EventEmitter();
  @Output() sort = new EventEmitter();
  @Input() componentOpen: string;

  constructor(
    renderer: Renderer2,
    public literalService: LiteralService,
    public storageService: StorageService,
    public usersResolver: UsersResolver,
    public activatedRoute: ActivatedRoute,
    public utilsService: UtilsService,
  ) {
    this.sortTypes = [UserSortType.ALPHABETICALLY, UserSortType.CREATION];

    renderer.listen('window', 'click', (e: Event) => {
      const modal = document.getElementById('sortContainer')!;
      const componentOpen = document.getElementById(this.componentOpen)!;
      const close = utilsService.closeCustomModals(e, modal, componentOpen);
      if (close) {
        this.closeSort.emit();
      }
    });
  }

  async ngOnInit() {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    this.userSortTypeSelected =
      (await this.storageService.getUserSortType()) ||
      (await this.storageService.setUserSortType(UserSortType.CREATION));
  }

  changeSort = async (userSortType: UserSortType) => {
    this.userSortTypeSelected = userSortType;
    await this.storageService.setUserSortType(userSortType);
    //this.usersResolver.resolve(this.activatedRoute.snapshot)
    this.sort.emit();
  };

  getImage = (sortType: UserSortType) => {
    const indexImage = Object.keys(images.sortOptions).findIndex(
      (key: string) => {
        return key === sortType.toLowerCase();
      },
    );
    const image = Object.values(images.sortOptions).find((value, index) => {
      return index === indexImage ? value : null;
    });
    return image;
  };
}

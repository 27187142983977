import { IServiceInfo, IServiceInfoDetail } from 'src/app/models';
import { IPagination } from '../states';
import { IServicesInfoDetailsState, IServicesInfosState } from '../states/service-infos';
import { Colors, SortCriteria } from 'src/app/models/enums';

export const initialServiceInfosState: IServicesInfosState = {
  value: [] as IServiceInfo[],
  isFetchingServiceInfos: false,
  pagination: {} as IPagination,
};

export const initialServiceInfoDetailsState: IServicesInfoDetailsState = {
  value: [] as IServiceInfoDetail[],
  isFetchingServiceInfos: false,
  pagination: {} as IPagination,
};

export function initialServiceInfoCreate(): IServiceInfoDetail {
  return {
    backwardsDirection: '',
    color: Colors.NONE,
    forwardDirection: '',
    managerClient: undefined,
    showRealTimes: false,
    showTheoreticalTimes: false,
    sortCriteria: SortCriteria.BY_CODE,
    bookingTimePicker: false,
    tag: '',
    name: '',
    clients: [],
    towns: [],
    clientId: 0,
    townId: 0,
  };
}

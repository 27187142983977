import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import moment from 'moment';
import { Select2ScrollEvent, Select2SearchEvent } from 'ng-select2-component';
import { AnimationOptions } from 'ngx-lottie';
import { UserStatus, UserType } from 'src/app/models/enums';
import { images } from 'src/images';
import { newBookingForm, UserBookingData } from '../../../forms/bookingForms';
import { IAvailability, IBookingCreate, IBookingSeat, IFindResult, IReservationAvailability, IReservationAvailabilityFailure, IServiceInfo, IUserRegular } from '../../../models';
import { BookingsService, LiteralService, TownsService, UsersService, UtilsService } from '../../../services';

@Component({
  selector: 'app-booking-modal',
  templateUrl: './booking-modal.component.html',
  styleUrl: './booking-modal.component.scss',
})
export class BookingModalComponent implements OnInit {

  @Input() newBooking: IBookingCreate = {} as IBookingCreate;
  @Input() creatingBooking: boolean = false;
  @Output() getServiceInfosEventEmitter = new EventEmitter<any>();
  @Output() selectODEventEmitter = new EventEmitter<any>();
  @Output() goToList = new EventEmitter<any>();
  @Output() showMaxReservationTimeModalEventEmitter = new EventEmitter<any>();
  @Output() showExceedingKmLimitModalEventEmitter = new EventEmitter<any>();
  @Output() selectStop = new EventEmitter<any>();
  public bookingForm: any;
  public availabilities: IFindResult = { success: [], failure: [] };

  public serviceInfos: IServiceInfo[] = [];

  public currentStep: number = 1;
  public numberSteps: number = 3;

  public steps = [ 1 ];

  private destinationsStops: unknown[];

  public availabilitiesDates: any[] = [];
  public availabilitiesSeparateByDate: any = {};
  public loadingAvailabilities = false;
  public currentPage = 0;

  public pax = 0;
  public prm = 0;

  public isManualBooking = false;
  public currentElement = 1;
  public totalElements = 0;

  public images = images;
  public Object = Object;
  public moment = moment;

  public isBack = false;

  public changingTown = false;

  public optionsPin: AnimationOptions = {
    path: '/assets/animations/pin.json'
  };

  public optionsBus: AnimationOptions = {
    path: '/assets/animations/bus.json'
  };
  
  constructor(private usersService: UsersService,
              private townsService: TownsService,
              private bookingsService: BookingsService,
              private utilsService: UtilsService,
              public literalService: LiteralService) {}
  
  async ngOnInit() {
    this.newBooking.destinationStops = [];
    this.newBooking.date = moment().format('YYYY-MM-DD');
    this.newBooking.time = moment().format('HH:mm');
    this.bookingForm = await newBookingForm(this.newBooking, this.usersService, new UserBookingData({showMoreUsers: false}));
  }

  set = async (data: any, valueToChange: string) => {
    if (data !== undefined) {
      switch (valueToChange) {
        case 'targetUserId': {
            this.newBooking.targetUserId = data.id;
            this.newBooking.user = await this.usersService.getUser(UserType.Regular, data.id) as IUserRegular;
            this.newBooking.townId = data.town.id;
            if (this.newBooking.user && this.newBooking.user.status?.toUpperCase() === UserStatus.BLOCKED) {
              // TODO: Make modal for blocked users
            }
            this.bookingForm = await newBookingForm(this.newBooking, this.usersService, new UserBookingData({showMoreUsers: false}), undefined, undefined, ['user']);
            this.steps.push(2);
          }
          break;
        case 'town': {
            this.changingTown = true;
            this.newBooking.townId = data;
            this.serviceInfos = await this.townsService.getServices(this.newBooking.townId);
            this.bookingForm = await newBookingForm(this.newBooking, this.usersService, undefined, this.serviceInfos, undefined, !this.isBack && ['town']);
            this.getServiceInfosEventEmitter.emit({serviceInfos: this.serviceInfos, townId: this.newBooking.townId});
            this.isBack = false;
            this.changingTown = false;
          }
          break;
        case 'originStopId': {
            this.newBooking.originStopId = data;
            this.setLocation(data);
            this.destinationsStops = await this.townsService.getStopsFrom(this.newBooking.townId, this.newBooking.originStopId);
            this.bookingForm = await newBookingForm(this.newBooking, this.usersService, undefined, undefined, this.destinationsStops, ['stop']);
            this.selectODEventEmitter.emit({newBooking: this.newBooking, type: 'origin'});
            this.selectStop.emit({stopId: this.newBooking.originStopId, townId: this.newBooking.townId});
          }
          break;
        case 'destinationsStops': {
            let destination: any = 0;
            if (typeof data === 'object') {
              destination = {...this.newBooking.destinationStops};
              const bookingSeat : IBookingSeat = { exitStop: { id: destination[0].exitStop.id, name: destination[0].exitStop.name }, prm: data.type === 'prm' };
              let index = -1;
              const exists = this.newBooking.destinationStops.find((destination: any, elementIndex: number) => {
                index = elementIndex;
                return destination.prm === bookingSeat.prm;
              });
              if (exists && data.key === 'minus') {
                this.newBooking.destinationStops.splice(index, 1);
              } else if (data.key === 'plus') {
                this.newBooking.destinationStops.push(bookingSeat);
              }
              this.setLocation(destination[0].id, false);
            } else {
              destination = this.destinationsStops.find((destination: any) => destination.id === data);
              if (this.newBooking.destinationStops.length > 1) {
                this.newBooking.destinationStops = this.newBooking.destinationStops.map((dest: IBookingSeat) => {
                  const bookingSeat : IBookingSeat = { exitStop: { id: destination.id, name: destination.name }, prm: dest.prm };
                  return bookingSeat;
                });
              } else {
                const bookingSeat : IBookingSeat = { exitStop: { id: destination.id, name: destination.name }, prm: this.newBooking.user.prm };
                this.newBooking.destinationStops[0] = bookingSeat;
              }
              this.setLocation(destination.id, false);
            }
            this.newBooking.destinationStops = [...this.newBooking.destinationStops];
            this.getPassengers();
            this.destinationsStops = await this.townsService.getStopsFrom(this.newBooking.townId, this.newBooking.originStopId);
            this.bookingForm = await newBookingForm(this.newBooking, this.usersService, undefined, undefined, this.destinationsStops);
            this.selectODEventEmitter.emit({newBooking: this.newBooking, type: 'destination'});
          }
          break;
        case 'date':
          this.newBooking.date = data;
          this.bookingForm = await newBookingForm(this.newBooking, this.usersService);
          break;
        case 'time':
          this.newBooking.time = data;
          this.bookingForm = await newBookingForm(this.newBooking, this.usersService);
          break;
        default:
          break;
      }
      this.currentStep = this.steps.at(-1)!;
    }    
  };

  setLocation = (id: number, origin = true) => {
    this.serviceInfos.map((serviceInfo: IServiceInfo) => {
      serviceInfo.stops.find((stop: any) => {
        if (stop.id === id) {
          origin ?
            this.serviceInfos.map((serviceInfo: IServiceInfo) => {
              serviceInfo.stops.find((stop: any) => {
                if (stop.id === id) {
                  this.newBooking.originStopLocation = {
                    lat: stop.location.geometry.coordinates[1],
                    lng: stop.location.geometry.coordinates[0]
                  };
                }
              });
            })
          :
            this.serviceInfos.map((serviceInfo: IServiceInfo) => {
              serviceInfo.stops.find((stop: any) => {
                if (stop.id === id) {
                  this.newBooking.destinationStopLocation = {
                    lat: stop.location.geometry.coordinates[1],
                    lng: stop.location.geometry.coordinates[0]
                  };
                }
              });
            });
        }
      });
    });
  };

  scroll = async (event: Select2ScrollEvent) => {
    if (event.way === 'down') {
      if (event.search !== '') {
        this.bookingForm = await newBookingForm(this.newBooking, this.usersService, new UserBookingData({showMoreUsers: true, search: true, value: event.search}));
      } else {
        this.bookingForm = await newBookingForm(this.newBooking, this.usersService, new UserBookingData({showMoreUsers: true}));
      }
    }
  };

  search = async (event: Select2SearchEvent) => {
    const dropDown = document.getElementsByClassName('select2-results__options')[0];
    const value = event.search;
    if (value !== '') {
      this.bookingForm = await newBookingForm(this.newBooking, this.usersService, new UserBookingData({showMoreUsers: false, search: true, value: value}));
      dropDown.scroll({
        top: 0
      });
    } else {
      // TODO: FIX IN REMOVE
      this.bookingForm = await newBookingForm(this.newBooking, this.usersService, new UserBookingData({showMoreUsers: false}));
    }
  };

  change = (data: any, valueToChange: string) => {
    if (valueToChange === 'destinationsStops') {
      this.set(data, 'destinationsStops');
    } else if (valueToChange === 'date') {
      this.set(data.target.value, 'date');
    } else if (valueToChange === 'time') {
      this.set(data.target.value, 'time');
    } 
  };

  getAvailabilities = async () => {
    window.scrollTo(0, 0);
    this.loadingAvailabilities = true;
    this.steps.push(3);
    this.currentStep = this.steps.at(-1)!;
    this.availabilities =  { success: [], failure: [] };
    this.availabilitiesDates = [];
    this.availabilitiesSeparateByDate =  {};
    const paxOutStopId: number[] = [];
    const prmOutStopId: number[] = [];
    this.newBooking.destinationStops && this.newBooking.destinationStops.filter((seats: IBookingSeat) => {
      if (!seats.prm) {
        paxOutStopId.push(seats.exitStop.id!);
      } else {
        prmOutStopId.push(seats.exitStop.id!);
      }
    });
    const dates = this.newBooking.date.split(',').filter((date: string) => {
      return date !== '';
    });
    const dateTimes = dates.map((date: any) => {
      return moment(new Date(date + ' ' + this.newBooking.time)).format('YYYY-MM-DD[T]HH:mm');
    });
    await this.townsService.getAvailabilities(
      this.newBooking.townId,
      this.newBooking.targetUserId,
      this.newBooking.originStopId,
      dateTimes,
      undefined,  // is_arrival_time: boolean = false
      paxOutStopId,
      undefined,  // reservationId?: number
      prmOutStopId
    ).then((resp: any) => {
      this.availabilities = resp;
    }, (error: any) => {
      console.log("ERROR", error);
      this.back();
    });

    this.availabilities.success.map((successAvailabilities: IReservationAvailability) => {
      const date = moment(successAvailabilities.dateTime).format('YYYY-MM-DD');
      if (!this.availabilitiesSeparateByDate[date]) this.availabilitiesSeparateByDate[date] = { success: [], failure: [] };
      this.availabilitiesSeparateByDate[date]['success'].push(successAvailabilities);
      if (!this.availabilitiesDates.includes(date)) this.availabilitiesDates.push(date);
    });
    this.availabilities.failure.map((failureAvailabilities: IReservationAvailabilityFailure) => {
      const date = moment(failureAvailabilities.dateTime).format('YYYY-MM-DD');
      if (!this.availabilitiesSeparateByDate[date]) this.availabilitiesSeparateByDate[date] = { success: [], failure: [] };
      this.availabilitiesSeparateByDate[date]['failure'].push(failureAvailabilities);
      if (!this.availabilitiesDates.includes(date)) this.availabilitiesDates.push(date);
    });
    this.totalElements = Object.keys(this.availabilitiesSeparateByDate).length;
    this.loadingAvailabilities = false;
  };

  getPassengers = () => {
    this.pax = this.newBooking.destinationStops.filter((destination: any) => !destination.prm).length;
    this.prm = this.newBooking.destinationStops.filter((destination: any) => destination.prm).length;
  };

  manualBooking = () => {
    this.isManualBooking = true;
  };

  createBooking = async (availability: IReservationAvailability) => {
    this.creatingBooking = true;
    this.newBooking.availabilityId = availability.availabilityId;
    this.newBooking.originStopId = availability.inStop.id!;
    this.newBooking.destinationStops.forEach((destination: any) => {
      destination.exitStop.id = availability.outStop.id!;
      destination.exitStop.name = availability.outStop.name!;
    });
    this.newBooking.destinationId = availability.outStop.id!;
    this.newBooking.serviceAvailabilityResponseId = availability.serviceAvailabilityResponseId;
    this.newBooking.serviceId = availability.serviceId;
    this.newBooking.tripId = availability.expeditionId;
    this.bookingsService.createBooking(this.newBooking).then(() => {
      if (this.currentElement === this.totalElements) {
        this.goToList.emit();
      } else {
        this.currentPage++;
        this.currentElement++;
      }
      this.creatingBooking = false;
    }, (error) => {
      console.log("ERROR", error);
      this.back();
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  bookMultiple = async (availabilities: IReservationAvailability[]) => {
    const availabilitiesToBook = this.availabilitiesDates.map((date: any) => {
      return this.availabilitiesSeparateByDate[date].success[0];
    });
    const newBookings: IBookingCreate[] = [] as IBookingCreate[];
    availabilitiesToBook.map((availability: IReservationAvailability, index: number) => {
      newBookings[index] = this.newBooking;
      newBookings[index].availabilityId = availability.availabilityId;
      newBookings[index].serviceAvailabilityResponseId = availability.serviceAvailabilityResponseId;
      newBookings[index].serviceId = availability.serviceId;
      newBookings[index].tripId = availability.expeditionId;
    });
  };

  showMaxReservationTimeModal = () => { 
    this.showMaxReservationTimeModalEventEmitter.emit();
    this.back();
  };
  
  showExceedingKmLimitModal = (availability: IAvailability) => { 
    this.newBooking.availabilityId = availability.availabilityId;
    this.newBooking.originStopId = availability.inStop.id!;
    this.newBooking.destinationStops.forEach((destination: any) => {
      destination.exitStop.id = availability.outStop.id!;
      destination.exitStop.name = availability.outStop.name!;
    });
    this.newBooking.destinationId = availability.outStop.id!;
    this.newBooking.serviceAvailabilityResponseId = availability.serviceAvailabilityResponseId;
    this.newBooking.serviceId = availability.serviceId;
  
    this.showExceedingKmLimitModalEventEmitter.emit({newBooking: this.newBooking, maxKmsPerMonth: availability.serviceInfo.managerClient.maxKmsPerMonth});
  };

  getDate = () => {
    return this.availabilitiesSeparateByDate[this.availabilitiesDates[this.currentPage]] && this.availabilitiesSeparateByDate[this.availabilitiesDates[this.currentPage]].success[0] ?
      moment(this.availabilitiesSeparateByDate[this.availabilitiesDates[this.currentPage]].success[0].dateTime).format('YYYY-MM-DD')
      :
      this.availabilitiesSeparateByDate[this.availabilitiesDates[this.currentPage]] && moment(this.availabilitiesSeparateByDate[this.availabilitiesDates[this.currentPage]].failure[0].dateTime).format('YYYY-MM-DD');
  };

  back = async () => {
    this.steps.pop();
    this.currentStep = this.steps.at(-1)!;
    this.isBack = true;
    if (this.currentStep === 1) {
      this.newBooking = {} as IBookingCreate;
      this.newBooking.destinationStops = [];
      this.newBooking.date = moment().format('YYYY-MM-DD');
      this.newBooking.time = moment().format('HH:mm');
    } else {
      this.creatingBooking = false;
    }
    this.bookingForm = await newBookingForm(this.newBooking, this.usersService, new UserBookingData({showMoreUsers: false}));
  };

  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
    animationItem.setSpeed(1.5);
  }

}

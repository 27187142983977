export enum InputType {
  TEXT = 'text',
  EMAIL = 'email',
  PHONE = 'tel',
  URL = 'url',
  SEARCH = 'search',
  NUMBER = 'number',
  SELECT = 'select',
  SELECT_WITH_ENUM = 'SELECT_WITH_ENUM',
  MULTISELECT = 'multiselect',
  DATE = 'date',
  CHECKBOX = 'checkbox',
  INFINITE_SCROLL = 'select2',
  SELECT_SEARCH = 'select_search',
  SEATS = 'SEATS',
  TIME = 'TIME',
  AUTOCOMPLETE_SEARCH = 'autocomplete_search',
  COLOR = 'COLOR',
  MULTISELECT_CHECKBOXES = 'MULTISELECT_CHECKBOXES',
}
